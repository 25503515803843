

import React , { useState , useEffect} from "react";


import {  useCourtier } from "../../../state/reactquerycabinets";


import FiltresCompagnies from "../../FiltresCompagnies";
import TabloCompagnieGammes from "./TabloCompagnieGammes";


function Compagnies(){

    const [listecompagnies, setListeompagnies]=useState([])  ;
    const [listecompagniesaffichees, setListeompagniesaffichees]=useState([])  ;
    const [compagniesgammesaffichees, setCompagniesgammesaffichees]=useState({})  ;
    const [actuauths, setActuauths] = useState({})

    const {data:courtier}  = useCourtier() ; 
    const jsonauths = JSON.parse(courtier?.autorisation||'{}') ;

    useEffect(()=>{ setActuauths(jsonauths)
                    setListeompagnies(Object.keys(jsonauths)) ;
                    setListeompagniesaffichees(Object.keys(jsonauths))  ;
                    setCompagniesgammesaffichees(jsonauths) ; 
    },[courtier])

    const clickCompagnie =(e)=>{
        const  newliste = listecompagniesaffichees.includes(e.target.id)?listecompagniesaffichees.filter(x=>x!==e.target.id):[...listecompagniesaffichees,e.target.id];
        let newcompgammes = {} ;
        newliste.forEach(l=>newcompgammes[l] =jsonauths[l] ) ;
        setCompagniesgammesaffichees(newcompgammes)  ;
        setListeompagniesaffichees(newliste)
    }

    const clickboxcompagnies= ()=>{
        listecompagniesaffichees?.length>0? setListeompagniesaffichees([]):setListeompagniesaffichees([...listecompagnies]);
        listecompagniesaffichees?.length>0? setCompagniesgammesaffichees({}):setCompagniesgammesaffichees(jsonauths);
    }

    return( <><FiltresCompagnies    listenomscompagnies ={listecompagnies?.sort()?.map(comp=>jsonauths[comp]?.nom_compagnie)}
                                    listecompagnies={listecompagnies?.sort()}
                                    listecompagniesaffichees={listecompagniesaffichees?.sort()} 
                                    clickCompagnie={clickCompagnie}  
                                    clickboxcompagnies={clickboxcompagnies} /> 

              <TabloCompagnieGammes compagniesgammes={compagniesgammesaffichees} 
                                    actuauths={actuauths} 
                                    setActuauths={setActuauths} />               
              </>)

}



export default Compagnies



