
import React , { useState , useEffect  }  from "react";
import { useSelector  } from "react-redux";
import axios from "axios";

import { useDevis , useYousign} from "../../state/reactquery";
import {useUser } from "../../state/reactquerycabinets";
 
import { modifyoneleadfront  } from "../../utils/actions_lead";
import { formatdate } from "../../utils/utils_dates";
import { devistoformules } from "../../utils/utils_devis";

import Button from "../Buttons/Button";
import FiltresBesoins from "../../Ecrans/EcranFicheClient/FiltresBesoins";
import Boulettes from "./Boulettes";

import './ContratWinFic.scss' ;
         
const classNames = require('classnames');
function ContratWinFicContratSend(){
        const lead = useSelector(state=>state.lead.leadactu) ;
        const etape =lead?.['sign_bulletin']||0 ;
        const {data: user} = useUser(localStorage.getItem('token'))  ;
        const {data: devis} = useDevis(lead?.id) ; 
        const {data: yousign} = useYousign(lead?.id_contrat) ; 

        const [horaire, setHoraire]  = useState(null) ; 
        const [loading, setLoading]  = useState(false) ; 

        useEffect(()=>{yousign?.status==='ongoing' && setHoraire(formatdate(yousign?.dae_initialisation)) ;
                       yousign?.status==='done' && setHoraire(formatdate(yousign?.date_signature)) ;
        },[yousign?.id])

        const textbottom = etape==0?"Signez avec ":etape==1?`Envoyé le ${horaire} avec `:`Signé le ${horaire} avec ` ;

        let jsonconseil = {lead:lead, user} ;

       
        const mesformules = devistoformules(devis)

        let [formule1 , formule2 ,formule3] = mesformules ;
 
        const sendconseil= async () =>{
                if(etape===0){await sendficbulletintoyousign()}
                if(etape===1){await renvoiliensignature()}
        }
        const renvoiliensignature= async () =>{
                setLoading(true) ;
                await axios.post(`/yousign/renvoiliensignature/`,{lead,id:yousign.id,typedocument:'ficcontrat'},{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
                setTimeout(()=>{setLoading(false) ;},1000); 
        }

        const sendficbulletintoyousign= async () =>{
                setLoading(true) ;
               if(!document.getElementById('form0')?.textContent ){return }

               if(document.getElementById('form0')){formule1.prix = document.getElementById('form0')?.textContent.trim()} ;
               if(document.getElementById('form1')){formule2['prix'] = document.getElementById('form1')?.textContent.trim()} ;
               if(document.getElementById('form2')){formule3['prix'] = document.getElementById('form2')?.textContent.trim()} ;

               jsonconseil['sol1'] = formule1; 
               formule2?.compagnie && (jsonconseil['sol2'] =  formule2) ; 
               formule3?.compagnie && (jsonconseil['sol3'] =  formule3) ; 
                const req = await axios.post(`/ficbulletin/ficbulletintoyousign/`,{...jsonconseil},{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
                const id_contrat= req.data?.contrat ;
                setTimeout(()=>{setLoading(false) ;
                                modifyoneleadfront({...lead,'sign_fic':1,'sign_bulletin':1,'id_contrat':id_contrat,'statut_client':'attente_signature'}) ;   
                                setHoraire(new Date().toLocaleString('fr-FR')); },1000); 

       }

        const lineformule = (form,i)=>( <div className={classNames("contratwinfic_lineformule",{"fontbold":i===0})}> 
                                          <span  > &#8226;</span>
                                          <span  >{form?.compagnie}</span> <span  >,</span>
                                          <span >{form?.gamme}</span> <span  >,</span>
                                          <span  >{form?.nom_formule}</span> <span  >-</span>
                                           {form?.compagnie && <><span id={'form'+i}
                                                                        className="ficeditableeprix" 
                                                                        contentEditable="true" 
                                                                        suppressContentEditableWarning={true}>
                                                                                {form?.prix||' '} 
                                                                </span> € </> }
                                        </div>) ;

        return(<div className='contratwin_box'>
                        <div className='contratwin_box_top'>
                                <div className='contratfic_box_top_texte'>Besoins exprimés </div>
                                <FiltresBesoins />
                                <div className="ht10" ></div>
                                <div className='contratfic_box_top_texte'>Documents envoyés automatiquement </div>
                                <div className='contratfic_flex'> 
                                <div className='contratfic_flex_middle1'>
                                        <div className='bulletin_listedoc'>BUlletin de souscription</div>  
                                        <div className='bulletin_listedoc'>Conditions générales</div>         
                                        <div className='bulletin_listedoc'>Tableau de garantie</div>         
                                        <div className='bulletin_listedoc'>IPID</div>    
                                </div>
                                <div className='contratfic_flex_middle2'>
                                <div className='bulletin_listedoc'>Fiche Information & Conseil</div>
                                        {[formule1, formule2, formule3].length > 0 && 
                                                        <table className="contratwinfic_lineformule" >
                                                                <tbody>
                                                                        {[...mesformules].map((f, index) => lineformule(f, index))}
                                                                </tbody>
                                                                </table>}
                                </div>
                                </div>
                        </div>
                        <div className='contratwin_box_bottom'>
                                <div className='contratwin_box_bottom_left'>
                                        <Boulettes total={3} etape={etape} />
                                        <div className='contratwin_box_bottom_message'>{textbottom}</div>
                                </div>
                                <Button texte={loading?'Envoi en cours':etape===0?"Envoi pour signature":etape===1?"Relancer":"Signé"}
                                        color={etape===2?'bouton_backcolor_orangelight':'bouton_color_orange'}
                                        disable={etape === 2}
                                        loading={loading}
                                        size='bouton_size_normal'
                                        onclick={sendconseil} />
                        </div>
                </div>);
}

export default ContratWinFicContratSend ;

