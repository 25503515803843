import { redirect } from "react-router-dom";
import { nanoid } from '@reduxjs/toolkit';
import axios from 'axios' ;
import { useQuery ,useMutation, useQueryClient} from '@tanstack/react-query' ; 


import { store } from "./redux";
import { queryClient } from './queryClient';

import { ordergroupe } from "../utils/utils_courtier";




// TICKETS
 const queryTickets = async()=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  if(!user?.postmail) { return  [] }
  let response = await axios({method:'POST',url:`/tickets/gettickets`,data:{'postmail' : user.postmail},headers:{'Accept':'application/json','Content-Type':'application/json','Authorization':`Bearer ${localStorage.getItem('token')}`}})
  return response?.data || []
} 

export const useTickets = ()=>useQuery({ queryKey: ['tickets'], queryFn: queryTickets ,    staleTime: Infinity  });

export const useAddTicket = () => {
  const queryClient= useQueryClient();
  return useMutation({mutationFn:  (ticket)=>{  return  ticket},
                      onSuccess:(ticket)=>{ queryClient.setQueryData(['tickets'],[...queryClient.getQueryData(['tickets']),ticket])   },})
}

export const addTicket= (ticket)=>{
  const tickets = queryClient.getQueryData(['tickets'])  ?? [];
  queryClient.setQueryData(['tickets'],[...tickets,ticket])
}



// DOCUMENTS 
const queryDocuments= async(id_lead)=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  let response = await axios({method:'POST',url:`/files/getclientdocs`,data: {"id_lead": id_lead,"courtier":user?.courtier}});
  return response.data
} 

export const useDocuments = (id_lead)=>useQuery({ queryKey: ['documents',id_lead], queryFn:async () => await queryDocuments(id_lead) ,    staleTime: 10*60*1000  });

export const useAddDocuments = (id_lead) => {
  const queryDocuments= useQueryClient();
  return useMutation({
    mutationFn:  (docu)=>{  return  docu},
    onSuccess:(docu)=>{ 
      const docactus = queryDocuments.getQueryData(['documents',id_lead])  ?? [];
      queryDocuments.setQueryData(['documents',id_lead],[...docactus,docu])
    }
  })
}

export const addDocument = (id_lead,docu)=>{
  const docactus = queryClient.getQueryData(['documents',id_lead])  ?? [];
  queryClient.setQueryData(['documents',id_lead],[...docactus,docu])
}




// HISTORY
const queryHistories= async(id_lead)=>{ 
  let response = await axios.post( `/memory/gethorodatagebyid`,{id_lead});
  return response.data
} 

export const useHistories = (id_lead)=>{  return   useQuery({ queryKey: ['histories',id_lead], queryFn:async () =>queryHistories(id_lead) ,    staleTime: 10*60*1000  }); }
  
export const addEltHistory = async(type,description)=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  const lead = store.getState().lead.leadactu ;
  const id = nanoid()  ;                                  
  await axios.post(  `/memory/writehorodatage/`,{type,description,id,'id_lead':lead.id ,'courtier':user?.['nomdomaine'],'commercial':user?.['nom']},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
  queryClient.invalidateQueries({ queryKey: ['histories',lead?.id]})     
}

export const addEltHistoryIdLead = async(id_lead,type,description)=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  const lead = store.getState().lead.leadactu ;
  const id = nanoid()  ;                                  
  await axios.post(  `/memory/writehorodatage/`,{type,description,id,'id_lead':id_lead ,'courtier':user?.['nomdomaine'],'commercial':user?.['nom']},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
  queryClient.invalidateQueries({ queryKey: ['histories',lead?.id]})     
}

export const useAddHistories = (id_client) => {
  const queryClient= useQueryClient();

  return useMutation({
    mutationFn:  (history)=>{  return  history},
    onSuccess:(history)=>{ 
      const newhistory = {...history,date: new Date()}
      const historyactus = queryClient.getQueryData(['histories',id_client])  ?? [];
      queryClient.setQueryData(['histories',id_client],[...historyactus,newhistory])
    }
  })
}

// EMAILS  POUR UN CLIENT
const queryListmails= async(userpostmail,leadactuemail)=>{
  let response =  await axios({method:'POST',url :`/email/getclientemails`,data:{"emails" : [userpostmail,leadactuemail]}  ,headers:{'Accept':'application/json','Content-Type':'application/json'} });
  return response.data
} 

export const useListmails = (userpostmail,leadactuemail)=>useQuery({ queryKey: ['clientmails',userpostmail,leadactuemail], queryFn: () =>queryListmails(userpostmail,leadactuemail) ,    staleTime: 10*60*1000  });


// EMAILS   POUR LA BOITE MAIL
const queryAllmails= async()=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  let response =  await axios({method:'POST',url :`/email/getallemails`,data:{"postmail" : user.postmail}  ,headers:{'Accept':'application/json','Content-Type':'application/json'} });
  return response.data
} 

export const useAllmails = ()=>useQuery({ queryKey: ['listmails'], queryFn: () =>queryAllmails() ,    staleTime: Infinity  });


// EMAILS   POUR LA BOITE MAIL  GESTION
const queryGestionmails= async()=>{
  let response =  await axios({method:'GET',url :`/email/getgestionmails`,headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`    } })
  return response.data
} 

export const useGestionmails = ()=>useQuery({ queryKey: ['listgestionmails'], queryFn: () =>queryGestionmails() ,    staleTime: Infinity  });


// NOTIFICATIONS
const queryNotifs = async()=>{
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  if(!user?.postmail) { return  [] }
  let response = await axios({method:'POST',url:`/notifs/getnotifs`,data:{'postmail' : user.postmail },headers:{'Accept':'application/json','Content-Type':'application/json'}})
  return response?.data|| []
} 

export const useNotifs = ()=>useQuery({ queryKey: ['notifs'], queryFn: queryNotifs ,    staleTime: Infinity  });

const updateNotif = async(notif)=>{  await axios({method:'POST',url:`/notifs/updatenotif`,data:notif,headers:{'Accept':'application/json','Content-Type':'application/json'}});return  notif}

export const useUpdateNotif= () => {
  const queryClient= useQueryClient();
  return useMutation({mutationFn: updateNotif,
                      onSuccess:(notif)=>{  queryClient.setQueryData(['notifs'],[...queryClient.getQueryData(['notifs']).filter(x=>x.id!==notif.id),notif])   },})
}

export const useAddNotif = () => {
  const queryClient= useQueryClient();
  return useMutation({mutationFn:  (notif)=>{  return  notif},
                      onSuccess:(notif)=>{ queryClient.setQueryData(['notifs'],[...queryClient.getQueryData(['notifs']),notif])},})

}

export const updatenotif = (notif) =>{
    axios({method:'POST',url:`/notifs/updatenotif`,data:notif,headers:{'Accept':'application/json','Content-Type':'application/json'}});
    const othersnotifs = queryClient.getQueryData(['notifs']).filter(x=>x.id!==notif.id)  ; 
    queryClient.setQueryData(['notifs'],[...othersnotifs,notif]);
}

export const deletenotif = (notif) =>{

    axios({method:'POST',url:`/notifs/deletenotif`,data:{id:notif.id},headers:{'Accept':'application/json','Content-Type':'application/json'}});
    const othersnotifs = queryClient.getQueryData(['notifs']).filter(x=>x.id!==notif.id)  ; 
    queryClient.setQueryData(['notifs'],[...othersnotifs]);
}



export const addnotif = (notif) => {
  const notifsactus = queryClient.getQueryData(['notifs'])  ?? []
   queryClient.setQueryData(['notifs'],[...notifsactus,notif]) 
}


export const addnotiffromfront = async (notif) => {
  await axios({method:'POST',url:`/notifs/addnotif`,data:notif,headers:{'Accept':'application/json','Content-Type':'application/json'}});
  queryClient.invalidateQueries({ queryKey: ['notifs']}) ;     
}


// NOTES
const queryNotes= async(id_lead)=>{
  let response = await axios.post( `/memory/getnotebyid`,{'id':id_lead});
  return response.data
} 

export const useNotes = (id_lead)=>{return   useQuery({ queryKey: ['notes',id_lead], queryFn:async () =>queryNotes(id_lead) ,    staleTime: 10*60*1000  });}
  
export const addOneNote= (id_lead,note)=>{
  const lead = store.getState().lead.leadactu ;
  const newnote = { 'id_lead':lead.id ,id_note:nanoid() , 'note': note?.replace(/\n/g,'<br />'),date_note: new Date()}
  const notesactus = queryClient.getQueryData(['notes',lead.id])  ?? [];
  axios.post(  `/memory/writenote/`,{id:id_lead,  note},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
  queryClient.setQueryData(['notes',lead.id],[...notesactus,newnote])
}

// CONTRATS
const queryContrats= async(email,courtier)=>{
  let response = await axios.post(`/contrats/getcontratsbyemail/`,{email,courtier},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
  return response.data
}      

export const useContrats = (email,courtier)=>{ 
       return  useQuery({ queryKey:['contrats',email,courtier],queryFn:async() =>queryContrats(email,courtier),staleTime: 10*60*1000}); }
  
export const deleteContrat = (indexe)=>{
    const lead = store.getState().lead.leadactu ;
    const contratsactus =  queryClient.getQueryData(['contrats',lead.email,lead.courtier])  ?? [];
    const newcontrats = contratsactus.filter(x=>x.indexe!==indexe);
    axios.post(`/contrats/deletecontrat/`,{indexe},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
    queryClient.setQueryData(['contrats',lead.email,lead.courtier],[...newcontrats]) ;
}

// // CONTRATBYIDCONTRANT
// const queryContratIdcontrat= async(id_contrat)=>{
//   console.log('queryContratIdcontrat',id_contrat) ; 
//   if(!id_contrat){return {}  }
//   let response = await axios.post(`/contrats/getcontratbyid/`,{'id_contrat':id_contrat},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
//   console.log('queryContratIdcontrat',response.data)
//   return response.data
// }      

// export const useContratIdcontrat = (id_contrat)=>{ 
//   console.log('useContratIdcontrat',id_contrat)
      
//        return  useQuery({ queryKey:['contratbyidcontrat',id_contrat],queryFn:async() =>queryContratIdcontrat(id_contrat),staleTime: 10*60*1000}); }
  

// DEVIS
const queryYousign= async(id)=>{
  if(!id){return {}}
  let response = await axios.post(`/yousign/searchyousignbyid`,{id},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
  return response.data
}      

export const useYousign = (id)=>{  ; 
   return  useQuery({ queryKey: ['yousign',id], queryFn:async () =>queryYousign(id), staleTime: 10*60*1000});
}
// DEVIS
const queryDevis= async(id_lead)=>{
  let response = await axios.post(`/devis/finddevis/`,{id_lead},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
  return response.data
}      

export const useDevis = (id_lead)=>{  ; 
   return  useQuery({ queryKey: ['devis',id_lead], queryFn:async () =>queryDevis(id_lead), staleTime: 10*60*1000});
}
  
// FIC
const queryFic= async(id_fic)=>{
  let response = await axios.post(`/conseil/getconseilbyid/`,{'id':id_fic},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
  return response.data
}      

export const useFic = (id_fic)=>{ 
   return  useQuery({ queryKey: ['fic',id_fic], queryFn:async () =>queryFic(id_fic), staleTime: 10*60*1000});
}
    
// RESIL 
const queryResil= async(id_resil)=>{
  let response = await axios.post(`/resiliation/getresiliationbyid`,{'id':id_resil},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
  return response.data
}      

export const useResil = (id_resil)=>{ 
   return  useQuery({ queryKey: ['resil',id_resil], queryFn:async () =>queryResil(id_resil), staleTime: 10*60*1000});
}


// EQUIPEMENTS
const queryEquipements= async(id_lead)=>{
  let response = await axios.post(`/equipement/findbyIdlead/`,{id_lead},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
  return response.data
}      

export const useEquipements = (id_lead)=>{ 
       return  useQuery({ queryKey:['equipement',id_lead],queryFn:async() =>queryEquipements(id_lead),staleTime: 10*60*1000}); 
}
  