import React from "react";
import { useSelector , useDispatch} from "react-redux";


import { modifyPopupmultitel } from '../../state/redux';
import { handlephone }  from '../../utils/phone'

import './PopupMultiTel.scss'
function PopupMultiTel(){
    const dispatch = useDispatch()
    const ficheclient = useSelector(state=>state.lead.leadactu);

    const telefone= (e)=>{e.target.id==="1"? handlephone(ficheclient.telephone) :  handlephone(ficheclient.telephone2) ;
                            dispatch(modifyPopupmultitel(false)) ; 	
                        }

    return(<div  className="popupmultitel">
                <div id="1" onClick ={telefone}>{ficheclient.telephone}</div>
                {ficheclient.telephone2  &&   <div id="2" onClick ={telefone}>{ficheclient.telephone2 }</div> }
           </div>)
}



export  default PopupMultiTel




