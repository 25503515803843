import React , {useState} from "react";
import axios from "axios";
import { useSelector , useDispatch } from "react-redux";

import { defineleads , defineaffLeads} from "../../state/redux";

import Boulettes from "../../Components/ContratWin/Boulettes";
import ButtonSimple from "../../Components/Buttons/ButtonSimple";

import croixclose from '../../images/croixclose.svg';


import './PopupReassigner.scss' ;

const listestatus = {
    "nontraite": "Non Traité", 'attente_document':"Attente Document", 'attente_signature':"Attente Signature",
    "echec_curieux":"Curieux","echec_tropcher":"Trop cher","echec_pasproduitadapte":"Pas de produit adapté","echec_signeconcurrence":"Signé à la concurrence",
    "echec_plusdereponse":"Ne répond plus","echec_mutuelleentreprise":"Mutuelle entreprise","echec_mutuellelabellisee":"Mutuelle labellisée","echec_cmu":"CMU",
  'gagne':'Gagné', 'gagne_Impayé_1mois':'Gagné Impayé 1 mois', 'gagne_Impayé_2mois':'Gagné Impayé 2 mois', 'gagne_Impayé_3mois':'Gagné Impayé 3 mois',
   'gagne_resilieecheanceria':'Gagné Resilie RIA', 'gagne_radiation':'Gagné Radié',
  }
function PopupChangeStatut({setShowpopupchangestatut,listidchangestatut,setListidchange}){
    const dispatch = useDispatch() ;

    const {leads,affLeads} = useSelector(state=>state.gestion);

    const [etape, setEtape] = useState(1) ;
    const [newstatus, setNewstatus] = useState(null) ;

    const changestatut =async (id_lead)=>{ await  axios.post(`/clients/statutchange/`,{id_lead,newstatus}); }


    const requalification= () =>{
        listidchangestatut.forEach((id)=>changestatut(id));
        const otherleads = leads.filter(x=>!listidchangestatut.includes(x.id));
        const leadschange = leads.filter(x=>listidchangestatut.includes(x.id)).map(x=>{return {...x, 'statut_client' :newstatus }})
        dispatch(defineleads([...otherleads,...leadschange]));
        const otherleadsaff = affLeads.filter(x=>!listidchangestatut.includes(x.id));
        const leadschangeaff = affLeads.filter(x=>listidchangestatut.includes(x.id)).map(x=>{return {...x, 'statut_client' :newstatus }})
        dispatch(defineaffLeads([...otherleadsaff,...leadschangeaff]));
        setListidchange([]) ;
        setShowpopupchangestatut(null) ; 
    }


    const recule = ()=>{setEtape(1)}
    const avance = ()=>{ etape===1? setEtape(2) : requalification() }

    const defineewstatut = (e)=>{e.target.value!=='Statut'  && setNewstatus(e.target.value) ;}

    return(<div  className="containergray">
               <div className="ecrangestionreassigner">
               <div className="cardresilitationclose" ><img src={croixclose}  alt="imgclose" onClick={()=> setShowpopupchangestatut(null) }  /></div>

                <div className="ecrangestionreassigner_titre center" >Changement de Statut</div>
                <Boulettes total={3} etape={etape} />
             { etape===1 ? <> 
                <div className="ecrangestionreassigner_titre left" >Quel statut voulez vous affecter a ces {listidchangestatut?.length||0} leads ?</div>  

             <div className="ecrangestionreassigner_texte">Nom de l’utilisateur</div>
                <select     className='selectcom' onChange={defineewstatut} >
                                        <option className='selectcom_option' value={undefined} >Statut</option>
                                        {Object.keys(listestatus)?.map((x,i)=><option key={i}  value={x} >{listestatus[x]}</option>)}
                 </select><br/><br/>
                <div className="ecrangestionreassigner_btns" >
                    <ButtonSimple  texte="Continuer" containerbouton='fullbutonX'  color='bouton_color_orange' size='bouton_size_normal' disable={newstatus===null} onclick={avance}  />
                </div></>:<>
                <div className="ecrangestionreassigner_titre center"  >Souhaitez-vous réellement requalifier ces {listidchangestatut?.length||0} leads à {newstatus?.toUpperCase()} ?</div>
                <div  className="ecrangestionreassigner_texte center" >Cette opération est irréversible</div><br/><br/>
                <div className="ecrangestionreassigner_btns" >
                    <ButtonSimple  texte="Retour"  color='bouton_color_blancgris' size='bouton_size_normal' disable={newstatus===null} onclick={recule}  />
                    <ButtonSimple  texte="Confirmer"  color='bouton_color_orange' size='bouton_size_normal' disable={newstatus===null||newstatus==='Statut'} onclick={avance}  />
                </div></>}
               </div>
           </div>)
}


export default PopupChangeStatut