
import React ,{ useEffect, useState } from "react";
import axios from "axios";

import {useUser , useUsers ,useCourtier ,useEquipesManagers } from "../../../state/reactquerycabinets";
import { queryClient } from '../../../state/queryClient';
 
import ButtonSimple from "../../Buttons/ButtonSimple";

import './AjoutModifEquipe.scss' ;
const classNames = require('classnames');
function AjoutModifEquipe({setShowajoutequipe,setShowmodifequipe, equipeactu,typeaddmodif}){
    const {data:user} = useUser(localStorage.getItem('token'))  ;
    const {data:courtier}  = useCourtier() ; 
    const {data:users} = useUsers(user?.courtier) ;
    const managers = users?.filter(x=>x.role === "MANAGER"||x.role === "ADMIN")  ; 

    const listeidmanagers = [...users?.filter(u=>u.role === "MANAGER")?.map(x=>x.id) , ...users?.filter(u=>u.ADMIN===1)?.map(x=>x.id)] ;
    const {data:equipes_managers} = useEquipesManagers(listeidmanagers)  ;

    const initlistemanagersids = typeaddmodif==='modif'?(equipes_managers?.[equipeactu['id']]?Array.from(equipes_managers[equipeactu['id']]):[]):[] ;
    const [listemanagersids, setListemanagersids ]= useState([]);

    const initnewequipe = typeaddmodif==='modif'?{...equipeactu}:{'id_courtier': courtier?.id_courtier,'courtier':user?.courtier};
   
    const [newequipe, setNewequipe] = useState(initnewequipe);

    useEffect(()=>{setListemanagersids(initlistemanagersids)},[equipes_managers]) ; 
    
    const annuler = () =>{setShowajoutequipe(false) ; setShowmodifequipe(false)};

    const handlechange = (e)=>{setNewequipe({...newequipe, [e.target.id]: e.target.value})};

    
    const ajoutermodifier = async() =>{
        const endpoint = typeaddmodif==='modif'?`/cabinets/modifyequipe/`:`/cabinets/createequipe/`;
        await axios.post(endpoint,{'equipe':newequipe,listemanagersids});
        queryClient.invalidateQueries({ queryKey: ['equipes', user?.courtier]})  ;
        queryClient.invalidateQueries({ queryKey: ['equipes_managers',listeidmanagers]})  ;
        setShowajoutequipe(false) ; setShowmodifequipe(false)
    };

    const tooglemanagerequipe = (id_manager)=>{
        const newliste = listemanagersids?.includes(id_manager)?listemanagersids.filter(id=>id!==id_manager): [...listemanagersids,id_manager] ;
        setListemanagersids(newliste) ;
    }

   return( <div className="containergray">
                <div  className="equipeajoutmodif parametresmodal">
                    <div className="equipeajoutmodif_titre">{typeaddmodif==='modif'?'Modifier une équipe':'Ajouter une équipe'}</div>
                    <div className="equipeajoutmodif_soustitre">Détails de l’équipe</div>
                    <div className="groupeinput">
                            <div className="containerlabelinput" >
                                <div className="inputtextelabel">Nom</div>
                                <input id='nom' type="text" autoComplete="off" onChange={handlechange} value={newequipe.nom} />
                            </div>
                            <div className="containerlabelinput" >
                                <div className="inputtextelabel">Description</div>
                                <input id='description' type="text" autoComplete="off" onChange={handlechange} value={newequipe.description} />
                            </div>
                    </div>
                    <div  className="equipeajoutmodif_soustitre">Manager(s)</div>
                    <div className="equipeajoutmodif_managers">
                    {managers.map(man=><div key={man.id} className="equipeajoutmodif_onemanager" > 
                                                <div className={classNames({"ismanager":listemanagersids?.includes(man.id)},{"notmanager":!listemanagersids?.includes(man.id)})} onClick={()=>tooglemanagerequipe(man.id)}></div  >
                                                <div className="equipeajoutmodif_onemanager_texte"  > {man.firstname}</div>
                                       </div> )}
                    </div>
                    <div className="groupeboutons">
                        <ButtonSimple  texte="Retour" containerbouton='fullbuton white'   color='bouton_color_blancgris  noborder' size='bouton_size_normal'  onclick={annuler}    />   
                        <ButtonSimple  texte={typeaddmodif==='modif'?'Modifier':"Ajouter"} containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' disable={!newequipe.nom} onclick={ajoutermodifier}  />    
                    </div>
                </div>
            </div>
)

}

export default AjoutModifEquipe