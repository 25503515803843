import React , {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import axios from "axios";



import { queryClient } from "../../state/queryClient";

import {useUser, useUsers } from "../../state/reactquerycabinets";

import { changestatuticket } from "../../utils/tickets";
import { convertdateAA } from "../../utils/utils_dates";
import { raccrocher_twilio  , phone} from "../../utils/twilio";

import ButtonSimple from "../../Components/Buttons/ButtonSimple";
import SelectUser from "../../Components/SelectUser";

import './Ticket.scss';
import './TicketModal.scss';
const classNames = require('classnames');
function TicketModal({ticket,setModal}){
  const {data: user} = useUser(localStorage.getItem('token'))  ; 
  const {data:users} = useUsers(user?.courtier) ;

  const { etat} = useSelector(state=>state.tel);  

  const [sourcemusic, setSourcemusic] = useState(0);
  const [btnplay, setBtnplay] = useState(true);
  const [userticket,setUserticket] = useState(null) ;
  const [displayuserchange , setDisplayuserchange] = useState(false) ;

  const audioElement = document.querySelector("audio");

  useEffect(()=>{ ticket && setSourcemusic(ticket['recording_url']) ; 
                   setUserticket({'id_user': ticket?.['id_user'],  'postmail':ticket?.['postmail'] })  
            },[ticket])

  useEffect(()=>{
    audioElement &&  audioElement.addEventListener("timeupdate", () => {   
      document.getElementById('audioplayer_filled') && (document.getElementById('audioplayer_filled').style.width = parseInt(audioElement.currentTime * 280 / audioElement.duration )+'px');
      });})
  
  const changegestionnaire = (e)=>{ 
    setDisplayuserchange(true)
      let userfromliste = users.filter(x=>x.id===parseInt(e.target.id))[0]  ; 
      setUserticket({'id_user': userfromliste?.['id'],  'postmail':userfromliste?.['postmail'] , 'firstname': userfromliste?.['firstname'] , 'lastname': userfromliste?.['lastname']  })
  }
  
const confirmchangeticket = ()=>{
  queryClient.setQueryData(['tickets'],[...queryClient.getQueryData(['tickets']).filter(x=>x.id!==ticket.id)])
  axios.post(  `/tickets/reaffectationticket/`,{...ticket, 'postmail': userticket?.['postmail'] ,'statut': 'new' });
  setModal(false) ; 
}

const playpause = ()=>{setBtnplay(btnplay=>!btnplay); audioElement && (btnplay?audioElement.play() : audioElement.pause());}

function movetime(event) {
    const coef  = (event.clientX - document.getElementById('audioplayer_filled').getBoundingClientRect().x) / 280 ; 
    audioElement &&  (audioElement.currentTime = parseInt(coef* (audioElement.duration)) );
    document.getElementById('audioplayer_filled').style.width = (coef*280)+'px' ;
}

const clotureticket = ()=> {changestatuticket({...ticket,'statut': 'finish'}); setModal(false) ;}

const handlephone = ()=>{etat==='normal'?  phone(ticket['tel_from']) : raccrocher_twilio() }

  return(<div className="ticketmodal_container" >
            <div className="ticketmodal" >
                  <div className="ticketmodaltop" >
                         <div className="ticketmodaltop_titre_grp" >
                                <div className="ticketmodaltop_titre" >   {ticket['date_ticket'] &&  convertdateAA(ticket['date_ticket'])}</div>  
                                {(user?.role==="ADMIN" ) &&     <div className={classNames("phone",{'telanimation':etat==='sonnerie'},{'telrouge':etat==='appel'})} onClick={handlephone}> </div>  }
                        </div>
                        <div className="ticket_detail" >
                            {(ticket['nom']  || ticket['prenom'] ) &&  <div className="ticket_detail_line1" > {ticket['nom'] ?ticket['nom']:'' }  {ticket['prenom'] ?ticket['prenom']:'' } </div>  }
                            {(ticket['tel_from'] ) &&  <div className="ticket_detail_line" > {ticket['tel_from'] }  </div>  }
                            {(ticket['email'] ) &&  <div className="ticket_detail_line" > {ticket['email'] }  </div>  }
                            {(user?.role==="ADMIN" && ticket['nom_seller']    ) &&     <div className="ticket_detail_line" >Commercial: {ticket['nom_seller'] }  {ticket['prenom_seller'] } </div>  }
                        </div>
                      {ticket['ticket_categorie'] &&   <div className="ticket_categorie" >{ticket['ticket_categorie']}</div>  } <br/>
                      {ticket?.['ticket_tags']  &&  ticket?.['ticket_tags'].split(',').map((x,i)=> ( <div key={i}  className="ticket_tag" >{x}</div>) )}
                  </div>
                 <div className="ticketmodal_texte" >
                                                            <span className="ticketmodal_texte_titre" > Attribution:</span> <br/> <br/>
                                                            <SelectUser  useractu={users?.filter(x=>parseInt(x?.id)===parseInt(userticket?.id_user))[0]}  changegestionnaire={changegestionnaire} />  
                                                         {displayuserchange && <div className="ticketmodal_change" >
                                                                                      <div  className="ticketmodal_change_texte" >Affectation du ticket à {userticket?.['lastname']}   {userticket?.['firstname']}   </div>
                                                                                      <div  className="ticketmodal_change_btn" onClick={confirmchangeticket} >Valider</div>
                                                                             </div>}
                   </div> 
                  {ticket['ticket_resume'] && <div className="ticketmodal_texte" >
                                                                <span className="ticketmodal_texte_titre" > Résumé  :</span> <br/>
                                                                <span className="ticketmodal_texte_content" >{ticket['ticket_resume']}</span>
                                                            </div>}       
                  {ticket['recording_url'] && <> <div className="audioplayer" >
                                                      <div  className={"audioplayer_btn "+(btnplay?'play':'pause')} onClick={playpause} ></div>
                                                      <div id="audioplayer_progress" className="audioplayer_progress"  onClick={movetime}  >
                                                                <div  id="audioplayer_filled" className="audioplayer_filled"></div> 
                                                      </div>
                                                  </div>
                                                  <audio src={sourcemusic}> </audio>
                                                  <div className="ticket_divider" ></div>
                                                  </>   }
                  {ticket['voicemessage_transcription'] && <div className="ticketmodal_texte" >
                                                                <span className="ticketmodal_texte_titre" > Transcription :</span> <br/>
                                                                <span className="ticketmodal_texte_content" >{ticket['voicemessage_transcription']}</span>
                                                            </div>}          
                  <div className="modalgrpbtn" >
                      <ButtonSimple  texte="Fermer" containerbouton='fullbuton white' color='bouton_color_blancgris noborder' size='bouton_size_normal' onclick={()=>{setModal(false)}}    />   
                      <ButtonSimple  texte=" Clôturer"  containerbouton='fullbuton' color='bouton_color_orange' size='bouton_size_normal' onclick={clotureticket}   />      
                  </div>
              </div>
          </div>)
}



export default TicketModal