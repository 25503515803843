import React , { useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import FiltresCompagnies from "./FiltresCompagnies";
import { filterFormules,   defineListeCompagniesAffichees} from '../state/redux';
import djerbaredux from "../utils/djerbaredux";




function FiltresCompagniesTarifs(){
    const dispatch = useDispatch();

    const listecompagnies =  useSelector(state=>state.formules.listecompagnies);
    const listecompagniesaffichees =  useSelector(state=>state.formules.listecompagniesaffichees);

    const clickCompagnie =(e)=>{
            if(listecompagniesaffichees.indexOf(e.target.id)===-1){  
                dispatch(defineListeCompagniesAffichees([...listecompagniesaffichees,e.target.id]));
            } else{ dispatch(defineListeCompagniesAffichees(listecompagniesaffichees.filter(x=>x!==e.target.id)));}
            dispatch(filterFormules({'type':'liste', 'liste':djerbaredux()}));
    }

    const clickboxcompagnies= ()=>{
        listecompagniesaffichees?.length>0? dispatch(defineListeCompagniesAffichees([])):dispatch(defineListeCompagniesAffichees([...listecompagnies]));
        dispatch(filterFormules({'type':'liste', 'liste':djerbaredux()}));
    }

    
    return( <FiltresCompagnies  listenomscompagnies={listecompagnies}
                                listecompagnies={listecompagnies}
                                listecompagniesaffichees={listecompagniesaffichees} 
                                clickCompagnie={clickCompagnie}  
                                clickboxcompagnies={clickboxcompagnies} /> )
}


export default FiltresCompagniesTarifs;

