import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {  modifyDisplaymodal  } from "../state/redux";
import {  useUser} from "../state/reactquerycabinets";
 

import changemdp  from '../images/changemdp.svg';
import deconnexion  from '../images/deconnexion.svg';


import './InfoUser.scss'
function InfoUser(){
    let navigate = useNavigate();
    const dispatch = useDispatch() ;
    const {data: user} = useUser(localStorage.getItem('token'))  ;

    const avatar2 = ()=>(<div className='avatar2' >{user['firstname']?.charAt(0).toUpperCase() + user['lastname']?.charAt(0).toUpperCase()  }</div>)
    const deconnecte = ()=>{  localStorage.removeItem('token')  ;  navigate('/login')   ; }

    return (<div className='infouser'>
                <div className='infouser_top'>
                    <div>{localStorage.getItem('imgavatar')?<img  className='avatar' src={localStorage.getItem('imgavatar')} alt='avatar' width='34' height="34"  /> : avatar2()} </div>
                    <div className='infouser_top_right'>
                        <span className='infouser_nomprenom'> {user?.lastname} {user?.firstname}</span><br/>
                        <span className='infouser_emailtel'> {user?.email} </span><br/>
                        <span className='infouser_emailtel'> {user?.telephone} </span>
                    </div>
                </div>
                <div className='infouser_changemdp'  onClick={()=>dispatch(modifyDisplaymodal('mod_changemdp'))  } >
                    <div> <img  className='imgchangemdp' src={changemdp} alt='changemdp' width='16' height="16"  />  </div>
                    <div className='infouser_textchangemdp'>Changer mot de passe</div>
                </div>
                <div className='infouser_changemdp infouser_bottom' >
                    <div> <img  className='imgchangemdp' src={deconnexion} alt='deconnexion' width='16' height="16"  />  </div>
                    <div className='infouser_textchangemdp' onClick={deconnecte}  >Déconnexion</div>
                </div>
            </div> )
    
}


export default InfoUser


