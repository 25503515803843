import React from "react";
import axios from "axios";
import { useSelector , useDispatch } from "react-redux";

import { defineleads , defineaffLeads} from "../../state/redux";


import ButtonSimple from "../../Components/Buttons/ButtonSimple";

import croixclose from '../../images/croixclose.svg';


import './PopupReassigner.scss' ;

function PopupSupprimer({setShowpopupsupprimer,listidsuppression,setListidchange}){
    const dispatch = useDispatch() ;

    const {leads,affLeads} = useSelector(state=>state.gestion);


    const supprimerleads =async  ()=>{
        const otherleads = leads.filter(x=>!listidsuppression.includes(x.id));
        dispatch(defineleads([...otherleads]));
        const otherleadsaff = affLeads.filter(x=>!listidsuppression.includes(x.id));
        dispatch(defineaffLeads([...otherleadsaff]));
        await axios.post(`/clients/delete/`,{listidsuppression});
        setListidchange([]) ;
        setShowpopupsupprimer(null) ; 
        
    }


    return(<div  className="containergray">
               <div className="ecrangestionreassigner">
               <div className="cardresilitationclose" ><img src={croixclose}  alt="imgclose" onClick={()=> setShowpopupsupprimer(null) }  /></div>

                <div className="ecrangestionreassigner_titre center" >Suppression</div>
            
                <div className="ecrangestionreassigner_titre center"  >Souhaitez-vous réellement supprimer ces  {listidsuppression?.length||0} leads ?</div>
                <div  className="ecrangestionreassigner_texte center" >Cette opération est irréversible</div><br/><br/>
                <div className="ecrangestionreassigner_btns" >
                    <ButtonSimple  texte="Retour"  color='bouton_color_blancgris' size='bouton_size_normal' disable={false} onclick={()=> setShowpopupsupprimer(null)}  />
                    <ButtonSimple  texte="Confirmer"  color='bouton_color_orange' size='bouton_size_normal' disable={false} onclick={supprimerleads}  />
                </div>
               </div>
           </div>)
}


export default PopupSupprimer