import React , {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { useUser } from '../../state/reactquerycabinets';
import { modifyDisplaymodal } from "../../state/redux";

import croixclose from '../../images/croixclose.svg';

import EnterLeadsFauxNumeros from "./EnterLeadsFauxNumeros";

import './EcranGestionLeads.scss'
var classNames = require('classnames');
const listestatusclient =['attente','contact','echec','gagne','nontraite'] ;

function EcranGestionLeads(){
    const dispatch = useDispatch() ;
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const [listleads, setListeleads]  = useState([]);
    const [popup, setPopup]  = useState(false);
    const [tablo, setTablo] = useState({})
    const [listchamps, setListechapms]  = useState({commerciaux :[], campagnes:[] , origines:[] , status:[]})
    const [multiselect, setMltiselect]  = useState({debut:(new Date()).toISOString().split('T')[0], fin : (new Date()).toISOString().split('T')[0] , 
                                                    'statut_client':'all', 'campagne': 'all'  , 'commercial': 'all' , "origine":'all' , 'type_date':'date_origine',
                                                    'courtier':user['courtier']}) ;

    const filterselect =(e) =>{  setMltiselect({...multiselect,[e.target.id]: e.target.value }) }
   

//     function listleadscom(myleads){  
//         let listcamp =[]; let statjson ={} ;
//                 for(let mylead of myleads){ 
//                     let com = mylead.campagne?(mylead.campagne.split('/')[1]?mylead.campagne.split('/')[1].trim():''):'' ; 
//                     let camp = mylead.campagne?(mylead.campagne.split('/')[0]?mylead.campagne.split('/')[0].trim().toLowerCase():''):'' ;
//                     !listcamp.includes(camp) && listcamp.push(camp)  ; 
//                         if(statjson.hasOwnProperty(com)){
//                                 if(statjson[com].hasOwnProperty(camp)){ statjson[com][camp]+=1 }
//                                 else{statjson[com][camp]=1 }
//                         }else{statjson[com]={}; statjson[com][camp]=1 }
    
//         delete statjson[undefined] ; delete statjson[''] ; delete statjson['simon'] ;
//         }
//     }
//   console.log(listleads) ;


function statsleads(resultdatas){

    let jsonreponsefinal = {} ;let jsonresult={} ;

    for(let lead of resultdatas){   
        if(jsonresult.hasOwnProperty(lead.commercial)){
            jsonresult[lead.commercial]=[...jsonresult[lead.commercial],  lead];
        }else{jsonresult[lead.commercial]=[lead]}
    }

    let commercio = Object.keys(jsonresult)  ;
    for(let com of commercio){
        const  myleads = jsonresult[com] ;
        let jsonstats = {} ;

        for(let lead of myleads){
            let statutclient = lead.statut_client ; 
            if(jsonstats.hasOwnProperty(statutclient)){ jsonstats[statutclient]+=1 }
            else{jsonstats[statutclient]=1 }
         }
        jsonreponsefinal[com]=jsonstats 
    }
    return jsonreponsefinal ;
}

    const getleads = async()=>{
      const response =  await axios.post(`/gestion/getdataleads`,{...multiselect });
      console.log('multiselect',multiselect) ; 
      setListeleads(response?.data?.sort((a,b)=>new Date(a?.date_origine) - new Date(b?.date_origine))) ;
      setPopup(true) ;
      setTablo(statsleads(response?.data))
      //listleadscom([...response?.data])
    }
    
    const getchamps = async()=>{
        const response =  await axios.post(`/gestion/getchamps`,{'courtier':user['courtier']});
        setListechapms(response?.data)
      }



    useEffect(()=>{getleads() ; getchamps()},[])

    const   selectleads  = ()=>{getleads()}

    const generecsv = ()=>{
        const csvString = [ ["Date Reception","Email", "Telephone","Origine","Campagne" , "Commercial" , "Status" ],...[...listleads].map(item => [item.date_origine,item.email,item.telephone,item.origine,item.campagne,item.commercial,item.statut_client])]
                             .map(e => e.join(",")) .join("\n");
        var hiddenElement = document.createElement('a');  
        hiddenElement.href='data:text/csv;charset=utf-8,'+encodeURI(csvString);hiddenElement.target='_blank';hiddenElement.download ='fauxnumeros.csv';  
        hiddenElement.click();  
    } 
const focusinput = () => { dispatch(modifyDisplaymodal('mod_searchgestion')) ; 
                            
 }

 const popupresult  = ()=>(<div className="popupresult" >
                            <div className="cardresilitationclose" ><img src={croixclose}  alt="imgclose" onClick={()=>{setPopup(false)}}  /></div>
                            <div  className="displayflex">
                             {Object.keys(tablo).map(com =><div  className="colonnecom">
                                                                    <div className="colonnecom_com">{com}</div>
                                                                    <div>{Object.keys(tablo[com]).map(statut=><div className="displayflexsb">
                                                                                                                <div className="colonnecom_statut">{statut}</div>
                                                                                                                <div>{tablo[com][statut]}</div>
                                                                                                           </div>)}</div>
                                                                </div>
                                                            )}
                                </div>                            
                            </div>) ;

    return( 
<div className="divbilanleads" >
   {(popup &&  listleads.length >0 )&& popupresult()}
            <input className="leadstop_input" placeholder='Recherche' type="text" onFocus={focusinput} />
            <div className="divbilanleads_choices">
                <div className="nombre" >{listleads?.length ?? 0}</div>
                <div className="containerselection wd150" >
                    <div className="labelinput" >Type de Dates</div>
                    <select id="type_date" className='selectchoice wd150' onChange={filterselect} >
                        <option value='date_origine' >Date d origine</option>
                        <option value='date_lastmodif' >Derniere Modification</option>
                        <option value='date_efet' >Date effet Contrat</option>
                    </select>
                </div>
                <div className="containerselection wd125" >
                    <div className="labelinput" >Debut </div>
                    <input id='debut' type='date' value={multiselect?.debut} onChange={filterselect} />
                </div>
                <div className="containerselection  wd125" >
                    <div className="labelinput" >Fin </div>
                    <input id='fin' type='date' value={multiselect?.fin} onChange={filterselect} />
                </div>
                <div className="containerselection wd150" >
                    <div className="labelinput" >Origines</div>
                    <select id='origine' className='selectchoice wd150' onChange={filterselect} >
                        <option value='all' >Toutes origines</option>
                        {listchamps.origines?.length > 0 && [...new Set(listleads?.map(x => x.origine)), ...listchamps.origines]?.map((x, i) => <option key={i} value={x} >{x}</option>)}
                    </select>
                </div>
                <div className="containerselection wd150 "  >
                    <div className="labelinput" >Campagnes</div>
                    <select id='campagne' className='selectchoice wd150' onChange={filterselect} >
                        <option value='all' >Toutes campagnes</option>
                        {listchamps.campagnes?.length > 0 && [...new Set(listleads?.map(x => x.campagne)), ...listchamps.campagnes]?.map((x, i) => <option key={i} value={x} >{x}</option>)}
                    </select>
                </div>
                <div className="containerselection" >
                    <div className="labelinput" >Commercial</div>
                    <select id='commercial' className='selectchoice  wd140' onChange={filterselect} >
                        <option value='all' >Tous commerciaux</option>
                        {listchamps.commerciaux?.length > 0 && [...new Set(listleads?.map(x => x.commercial)), ...listchamps.commerciaux]?.map((x, i) => <option key={i} value={x} >{x}</option>)}
                    </select>
                </div>
                <div className="containerselection" >
                    <div className="labelinput" >Statuts</div>
                    <select id='statut_client' className='selectchoice  wd180' onChange={filterselect} >
                        <option value='all' >Tous status</option>
                        { listestatusclient.map((x, i) => <option key={i} value={x} >{x}</option>)}
                        {/* {listchamps.status?.length > 0 && [...new Set(listleads?.map(x => x.statut_client)), ...listchamps.status]?.map((x, i) => <option key={i} value={x} >{x}</option>)} */}
                    </select>
                </div>
            </div>
            <button className='bouton vert' onClick={selectleads}  >Valider</button>
            <button className='bouton rouge' onClick={generecsv}  >CSV</button>
            <div className="scrolltable" >
                {listleads.length > 0 && <table className="rowleadstable ">
                    <thead><th>Date Reception</th> <th>Email</th> <th>Telephone</th> <th>Campagne</th> <th>Commercial</th> <th>Statut</th> </thead>
                    <tbody>
                        <tr></tr>{listleads.map((x, i) => <tr key={i}><td>{(new Date(x.date_origine)).toLocaleString("fr-FR", { timeZone: "Europe/Paris" })}</td>
                            <td>{x.email}</td><td>{x.telephone}</td> <td>{x.campagne}</td><td>{x.commercial}</td>   <td>{x.statut_client}</td>                                                     </tr>)}
                    </tbody>
                </table>}
            </div>
        
            <EnterLeadsFauxNumeros   />  
</div>
)
}


export default EcranGestionLeads

