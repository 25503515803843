import React from 'react';
import { useCourtier } from '../../../state/reactquerycabinets';

import FormCourtier from './FormCourtier';

import './Coordonnees.scss'
function Coordonnees(){
  const {data:courtier}  = useCourtier() ; 
      return(<>  
                 <div className="parametres_titre" >Coordonnées de votre cabinet de courtage</div>
                <div className="coordonnes_soustitre" >Mettez à jour vos informations commerciales et légales</div>
                <div className='coordonnes_divider' ></div>
                <FormCourtier courtier={courtier}  />
              </>)
  }
  
  
  export default  Coordonnees


