
import React from 'react';
import './Img.scss'
const classNames = require('classnames'); 
function Imgcolor(props){
	const orange = (props.color==='orange' && props.selected!==true);
    const gris = (props.color==='gris' && props.selected!==true);
    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 447.9 447.9" >
<path id="Tracé_719" className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true},{'svgcolor0_noselect':props.selected!==true &&  props.color!=='orange'})} 
		d="M368.7,322.1c-7.2,29.3-34.1,49.6-64.3,48.3c-35.5,0-80.7-25.8-80.7-52.5c0-26.6,27.3-56.3,62.3-62.6C356,242.9,388,244.8,368.7,322.1z"/>
		<path id="Tracé_720" className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true},{'svgcolor0_noselect':props.selected!==true &&  props.color!=='orange'})} 
		d="M42.9,323c6.6,29,32.9,49.3,62.7,48.3c34.6,0,78.8-25.8,78.8-52.5s-26.6-56.3-60.8-62.6 C55.2,243.8,24,245.6,42.9,323z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} d="M393.8,257c2.2-2.5,2.7-6.1,0.9-9.1l-74-135.3c-11.9-21.7-37.2-37.9-56.3-36.2c-8.8,0.3-16.5,6.1-19.3,14.5
	c-1.4,4,0.6,8.3,4.5,9.9c0.1,0,0.2,0.1,0.3,0.1c3.9,1.3,8-0.9,9.2-4.7c0.4-1.1,1.4-3.9,6.7-4.4c18,1.7,33.8,12.5,42.1,28.5
	l67.7,123.8l-19.2-1.1c-1.9-0.3-3.8-0.5-5.7-0.4c-60.1,0-105.1,12.4-126.5,34.9c-5.7,5.6-9.7,12.5-11.8,20.1h-17.6
	c-2-7.5-6-14.5-11.7-20c-21.5-22.5-66.5-34.9-126.5-34.9c-1.8-0.1-3.6,0-5.3,0.3c-0.1,0-0.2,0-0.3,0l-19.4,1.2l67.6-123.7
	c8.3-16.1,24.1-26.8,42.1-28.5c5.4,0.5,6.4,3.3,6.7,4.4c0,0.2,0.1,0.3,0.1,0.4c1.5,3.7,5.6,5.6,9.4,4.2c4-1.5,6-5.9,4.5-9.9
	c-2.8-8.3-10.5-14.1-19.3-14.5c-19.2-1.7-44.4,14.5-56.3,36.2L12.5,248c-0.7,1.2-1.1,2.5-1.1,3.9c0,1.9,0.7,3.7,1.8,5l18.2,53.4
	c0.3,0.8,0.6,1.5,1.1,2.1c10.5,29.1,27.2,50.1,47.9,59.7c10.8,4.9,22.5,7.4,34.3,7.3c18,0,35.7-5.3,50.9-15.2
	c17.8-11.4,29-30.4,30.6-51.2h15c1.6,20.8,12.9,39.7,30.6,51.1c15,10,32.7,15.3,50.7,15.3c11.9,0.1,23.6-2.4,34.5-7.3
	c21.9-10.3,39.4-33.3,49.8-65.3L393.8,257z M377,260.2c-0.1-0.2-0.1-0.4-0.2-0.6h0.4L377,260.2z M29.9,259.6h0.7
	c-0.1,0.3-0.3,0.7-0.4,1L29.9,259.6z M157.2,351c-21,13.9-47.6,16.5-70.9,6.8c-26.4-12.3-38.9-44.7-43.9-62.8
	c-3.3-10.5-2.4-21.9,2.6-31.8c2.7-3.6,7-5.6,11.5-5.3c55.3,0,97.5,11.1,115.9,30.2c5.4,5.1,8.5,12.1,8.8,19.5
	C181.2,325.2,172.1,341.6,157.2,351z M320.8,358c-23.3,9.7-49.9,7.2-70.9-6.8c-15-9.4-24-25.8-24-43.4c0.2-7.4,3.4-14.4,8.7-19.4
	c18.4-19.3,60.7-30.3,116-30.3c4.5-0.3,8.8,1.7,11.5,5.3c5.1,9.8,6,21.3,2.6,31.8C359.7,313.4,347.2,345.6,320.8,358z"/>
	
</svg>







    )
}

export default Imgcolor 