import React  , {useState} from "react";
import {useSelector ,  useDispatch} from 'react-redux';
import axios from 'axios';
import { nanoid } from '@reduxjs/toolkit';

import { queryClient } from '../../state/queryClient';
import { addEltHistory } from "../../state/reactquery";
import {modifyDisplaymodal} from '../../state/redux';

import { threeyearsafter} from "../../utils/utils_dates";
import { modifyonelead } from "../../utils/actions_lead";

import FormulaireContrat from "./FormulaireContrat";
import ButtonSimple from "../Buttons/ButtonSimple";

import croixclose from '../../images/croixclose.svg';

import './CardValidContrat.scss';
function CardNewContratGestion(){
    const dispatch = useDispatch() ;
    const ficheclient = useSelector(state=>state.lead.leadactu);
    const [datacontrat, setDatacontrat] = useState({"nom_titulaire":ficheclient.nom,"prenom_titulaire":ficheclient.prenom});
    
    const validcontrat = async()=>{
            let contrat={...datacontrat,'id_contrat':nanoid(9)  ,'email': ficheclient.email,'id_lead': ficheclient.id,'id_client': ficheclient.id_client ,'commercial':ficheclient.commercial,
                        'date_effet': (new Date(datacontrat.date_effet)).toLocaleDateString('fr-FR')   , courtier:ficheclient.courtier } ;

            const leadupdate =  {'commercial':ficheclient.commercial,'email':ficheclient.email,'formulesigne_compagnie' : contrat.compagnie , 'formulesigne_gamme' : contrat.gamme ,
                                'formulesigne_formule' : contrat.formule ,'date_effet' : (new Date(datacontrat.date_effet)).toLocaleDateString('fr-FR')   ,'num_contrat':contrat.num_contrat , 'formulesigne':'XXX_'+contrat.prix, 
                                'date_rdvrelance':threeyearsafter(ficheclient.date_lastmodif),'documents': 1 ,'statut_client':'gagne','hot':0 } ;//'recyclage' : 2 , 
            modifyonelead({...leadupdate}) ;   
            addEltHistory('leadgagne', `<div class='horoline1' > <span className=horobold'>Lead passé en Gagné !</span> </div>` )    
            Object.keys(leadupdate).map(key=>(leadupdate[key]==="" ||leadupdate[key]===null || leadupdate[key]===undefined )&& delete leadupdate[key]);
            await axios.post(`/clients/updateclients/`,{id:ficheclient.id,...ficheclient,...leadupdate });
            await axios.post(`/contrats/createcontrat/`,contrat);
            queryClient.invalidateQueries({ queryKey: ['contrats',ficheclient?.email,ficheclient?.courtier]}) ;
            dispatch(modifyDisplaymodal(null)) ;
     }


    return( <div className="container_formulaire"> 
                <div id='cardpassgagne'  className='cardpassgagne'>
                        <div className="cardpassgagneclose" ><img src={croixclose}  alt="imgclose" onClick={()=>{dispatch(modifyDisplaymodal(null))}} /></div>
                        <div className='cardpassgagne_top cardpassgagne_top_debut cardpassgagne_top_titre' >
                           Enregistrer Nouveau Contrat
                        </div><br/>
                        <FormulaireContrat datacontrat={datacontrat} setDatacontrat={setDatacontrat} />  
                        <div className='cardpassgagne_buttons'>
                        <ButtonSimple  texte="Continuer"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'   onclick={validcontrat}  
                                                      disable={(!datacontrat['compagnie'] || !datacontrat['formule'] || !datacontrat['prix'] || !datacontrat['num_contrat']|| !datacontrat['date_effet'])}  />
                        </div>
                </div>
            </div>)
}



export default CardNewContratGestion