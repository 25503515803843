import React, { useEffect, useState }  from "react";
import { useSelector , useDispatch} from 'react-redux';
import axios from "axios";
import Handlebars from "handlebars";


import { useUser } from "../../state/reactquerycabinets";
import {defineEcranclientshowsendmail ,defineObjet,defineMessage,defineTypeMail} from "../../state/redux";

import { radinpopup } from "../../utils/radin";
import { useDownloadDevis , devistolink} from "../../hooks/useDownloadDevis";
import { useDevis } from '../../state/reactquery';
import { downloaddevis } from "../../utils/utils_devis";

import ButtonSend from "../../Components/Buttons/ButtonSend";
import CardFormuleDevis from "../../Components/CardsFormule/CardFormuleDevis";
import ImgPrevention from "../../images/images_postes/ImgPrevention" ;
import ImgOrthodontie from "../../images/images_postes/ImgOrthodontie";
import ImgImplant from "../../images/images_postes/ImgImplant";
import ImgHospi from "../../images/images_postes/ImgHospi";
import ImgProtheses from "../../images/images_postes/ImgProtheses";
import ImgSpecialistes from "../../images/images_postes/ImgSpecialistes";
import ImgLentilles from "../../images/images_postes/ImgLentilles";
import ImgLunettes from "../../images/images_postes/ImgLunettes";

import './FormulesDevis.scss';
const classNames = require('classnames');
 const listecol=[{image:<ImgHospi color='gris' />,titre:'Hospitalisation',liste:["Honoraires","Chambre Particulière"]},
                {image :<ImgSpecialistes color='gris' /> ,titre:'Consultations',liste:["Généralistes DPTAM","Généralistes non DPTAM","Spécialistes DPTAM","Spécialistes non DPTAM","Médicaments non remb."]},
                {image :<ImgLunettes color='gris' /> ,titre:'Lunettes',liste:["Prise en charge"]},
                {image :<ImgLentilles color='gris' /> ,titre:'Lentilles',liste:["Prise en charge"]},
                {image :<ImgProtheses color='gris' /> ,titre:'Soins dentaires',liste:["Détartrage","Prothèse"]},
                {image :<ImgImplant color='gris' /> ,titre:'Implants Dentaires', liste:["Implants"]},
                {image:<ImgOrthodontie color='gris' /> ,titre:'Orthodontie',liste:["Orthodontie"]},
                {image:<ImgPrevention color='gris' /> ,titre:'Prévention',liste:["Consultations"]}]  ;

const BlockIntitulesPostes = ({index,titre,image,children})=>{ 
                                        return(<div key={index} className="col1block">
                                                        <div className="col1block_divimage" >{image}</div>
                                                        <div  className="col1block_texte">
                                                                <div className="col1block_titre">{titre}</div>
                                                                {children}
                                                        </div>
                                                </div>) }

function FormulesDevis({setShow}){
     const dispatch = useDispatch();
//      const  downloaddevis = useDownloadDevis();
     const {data: user} = useUser(localStorage.getItem('token'))  ;

     const devisformules = useSelector(state=>state.formules.devisformules) ;
     const lead = useSelector(state=>state.lead.leadactu) ;
    
     const [loading , setLoading ] = useState(false);
     const [loadingdownload  , setLoadingdownload ] = useState(false);



     const numwithspaces =(num) =>{if(!num){return }
        return  num.replace('+33','0').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')  ; 
        }
     const usertelephone = numwithspaces(user.telephone)
     
        const devistomessagerie = async () => {
                if(user?.showtarifs!==1){radinpopup() ; return }
                setLoading(true);
                const res = await axios.post(`/emailgestion/gettemplate`, { 'groupe': 'htmllibre', 'type': 'templatefrontdevis' }, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
                await downloaddevis(devisformules);
                const template = Handlebars.compile(res.data.htmlbody);
                const messagefront = template({ lead, user, usertelephone });
                setShow('rightselect3') ; 
                dispatch(defineMessage(messagefront));
                dispatch(defineEcranclientshowsendmail(true));
                dispatch(defineObjet(`Cabinet ${user.nom_courtier} : Proposition de mutuelle`));
                dispatch(defineTypeMail('envoidevis'));
                setLoading(false);
        }

   const telechargedevis = async()=>{if(user?.showtarifs!==1){radinpopup() ; return }
                                     setLoadingdownload(true);
                                     const pdfdata = await downloaddevis(devisformules); 
                                     devistolink(pdfdata)
                                     setLoadingdownload(false);}

        return (<div className="formulesdevis_container">
                        {devisformules.length > 0 && <div className="formulesdevis" >
                                <div className="formulesdevis_firstcol" >
                                        <div className="formulesdevis_top" >
                                                <ButtonSend texte="Envoyer le devis"
                                                        color='color_orange'
                                                        image='imagesenddevis'
                                                        loading={loading}
                                                        onclick={devistomessagerie} />

                                                <ButtonSend texte="Télécharger"
                                                        color='color_blanc_orange'
                                                        image='imagedownloaddevis'
                                                        loading={loadingdownload}
                                                        onclick={telechargedevis} />
                                        </div>
                                        {listecol.map((l, index) => (<BlockIntitulesPostes key={index} index={index} image={l.image} titre={l.titre} >
                                                {l.liste.map((t, i) => (<div key={t + i } className={classNames('col1block_line', { "col1block_line_bold": (t === 'Généralistes DPTAM' || t === 'Spécialistes DPTAM') })}  >
                                                                                {t}
                                                                        </div>))}
                                                                        </BlockIntitulesPostes>))}

                                </div>
                                {devisformules.map((formule, index) => <CardFormuleDevis formule={formule} last={index === devisformules.length - 1} />)}

                        </div>}
            </div>)
}



   

export default FormulesDevis

