import React ,{  useState }from "react";
import { useSelector} from "react-redux";

import { useContrats } from "../../state/reactquery";
import { useUser} from "../../state/reactquerycabinets";

import ListeFormules from "../../Ecrans/EcranFicheClient/ListeFormules";
import ContratWinFormuleExterne from "./ContratWinFormuleExterne";
import CardFormuleContrat from '../../Components/Contrats/CardFormuleContrat';
import ContratWinAlert from "./ContratWinAlert";


import './ContratWinFormule.scss' ; 
function ContratWinFormule(){ 
   const [displayformexterne, setDisplayformexterne] = useState(false) ;

   const lead = useSelector(state=>state.lead.leadactu) ;

   const {data: user} = useUser(localStorage.getItem('token'))  ;
   const {data: contrats} = useContrats(lead?.email,user?.courtier) ; 

   const formuleenregistree = contrats?.filter(c=>c.id_contrat===lead.id_contrat)?.[0] ;

    return(<div data-testid='contratwin2' className='contratwin2' >
             
              <> {formuleenregistree && <CardFormuleContrat key={1}  contrat={formuleenregistree}  ecran='win' />} 
                  {!displayformexterne ?<><div className="contratwin2_top">
                                              <div>Vous souhaitez faire souscrire une formule 
                                                  <span> qui n’est pas encore sur Mutuello ?</span>
                                              </div>
                                              <div className="contratwin2_top_btn" onClick={()=>{setDisplayformexterne(true)}} > 
                                                  Saisir la formule
                                              </div>
                                          </div>
                                          <ListeFormules  typeliste='souscription' />
                                        </>:<ContratWinFormuleExterne  setDisplayformexterne={setDisplayformexterne} />}</>
            
            </div> )
}

export default ContratWinFormule