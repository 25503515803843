import React  , { useEffect , useState} from "react";
import { useSelector , useDispatch } from "react-redux";
import axios from "axios";

import { useUser } from "../../state/reactquerycabinets";
import {  useContrats } from "../../state/reactquery";
import { defineComgestion , modifyDisplaymodal } from "../../state/redux";
 
import { soustitres } from "../../utils/utils_comgestion";

import ComGestionBottom from "./ComGestionBottom";
import ComGestionChoice from "./ComGestionChoice";
import ComGestionDocu from "./ComGestionDocu";
import ComGestionFin from "./ComGestionFin";
import ComGestionFormulaire from "./ComGestionFormulaire";
import ComGestionMail from "./ComGestionMail";

import croixclose from '../../images/croixclose.svg';


import './ComGestion.scss';
const classNames = require('classnames');
function ComGestion(){
    const dispatch = useDispatch() ;  

    const ficheclient = useSelector(state=>state.lead.leadactu);
    const comgestion = useSelector(state=>state.comgestion.value);

    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const {data: contrats} = useContrats(ficheclient?.email,ficheclient?.courtier) ; 
    const lastcontrat =  contrats?.sort((a,b)=> new Date(b.date_insert) - new Date(a.date_insert))?.[0]; 

    const [etape, setEtape] = useState('type_ticket') ;
    const [listeetapes, setListeetapes] = useState(['type_ticket','formulaire','documents','fin']) ;
    const [attachments, setAttachments] = useState([]);
    const [disablecontinue, setDisablecontinue] = useState(false);
    const [listegestions, setListegestions]= useState({});
    
    const noauth_decalageeffet =    user?.role ==="COMMERCIAL" || user?.role ==="MANAGER" ;

    async function fetchDataGestion() { 
        const res = await axios.get(`/emailgestion/gettypesgestion`,{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
        noauth_decalageeffet && (delete res.data?.['decalageeffet'] )  ; 
        setListegestions(res.data)
    } 

    const databegin = {'courtier': ficheclient.courtier, 
                        'nom_souscripteur' : ficheclient.nom,
                        'prenom_souscripteur' : ficheclient.prenom,
                        'iban_soucripteur' : ficheclient.iban1,
                        'id_client' : ficheclient.id_client,
                        'id_lead' :  ficheclient.id ,
                        'email_client' :  ficheclient.email,
                        'commercial' :  ficheclient.commercial,
                        'num_contrat' : lastcontrat?.num_contrat,
    }

    useEffect(() => { fetchDataGestion();  }, []); 

    useEffect(()=>{ dispatch(defineComgestion({...databegin})) },[lastcontrat,ficheclient]);

    const titre = etape==='type_ticket'?"Création de ticket":listegestions[comgestion.type_gestion]?.type_affichage;
    return(<div  className="containergray">
            <div className={classNames("comgestion",{'pbot':etape!=='type_ticket'})} >
            <div className="modifcontratclose" ><img src={croixclose}  alt="imgclose" onClick={()=>{dispatch(modifyDisplaymodal(null))  }}  />       </div>

                <div className="comgestion_titre" >{titre}</div>
                <div className="comgestion_soustitre" >{soustitres[etape]}</div>
                <div className="comgestion_btngrp" >

                    {etape==='type_ticket' && <ComGestionChoice listegestions={listegestions}   setListeetapes={setListeetapes}  setEtape={setEtape}  />  }                  
                    {etape==='formulaire' &&  <ComGestionFormulaire setDisablecontinue={setDisablecontinue} />  }
                    {etape==='documents' && <ComGestionDocu listegestions={listegestions}  setDisablecontinue={setDisablecontinue} 
                                                            attachments={attachments} setAttachments={setAttachments} />    }
                    {etape==='nonliste' && <ComGestionMail setDisablecontinue={setDisablecontinue}  />    }
                    {etape==='fin' && <ComGestionFin attachments={attachments}   />    }

                </div>
                {etape!=='type_ticket' && <ComGestionBottom attachments={attachments}   
                                                            listeetapes={listeetapes} 
                                                            etape={etape} 
                                                            setEtape={setEtape} 
                                                            disablecontinue={disablecontinue}  
                                                            setDisablecontinue={setDisablecontinue} />}
            </div>
    </div>);
}


export default ComGestion