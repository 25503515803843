import React , {useState} from "react";


import {useUser,useUsers,useEquipes,useEquipesManagers } from "../../../state/reactquerycabinets";


import AlertePopupDelete from "./AlertePopupDelete";
import AjoutModifEquipe from "./AjoutModifEquipe";

import './Equipes.scss' ;
function Equipes(){
    const {data:user} = useUser(localStorage.getItem('token'))  ;
    const {data:users} = useUsers(user?.courtier) ;
    const {data:equipes} = useEquipes(user?.courtier)  ;

    const listeidmanagers = users?.filter(u=> u.role==='MANAGER' || u.role==='ADMIN')?.map(x=>x.id) ;
    const {data:equipes_managers} = useEquipesManagers(listeidmanagers)  ;

    const [showajoutequipe , setShowajoutequipe] = useState(false) ; 
    const [showmodifequipe , setShowmodifequipe] = useState(false) ; 
    const [showavert , setShowavert] = useState(false) ; 
    const [equipeactu, setEquipeactu] = useState(null) ; 
    const [typeaddmodif, setTypeaddmodif] = useState(null) ; 

    const managers = users?.filter(x=>x.role === "MANAGER" || x.role === "ADMIN")  ; 

    const ajoutequipe = () =>{setEquipeactu({});setShowajoutequipe(true);setTypeaddmodif('add')}

    const modifyequipe = (equipe)=>{setEquipeactu({...equipe});setShowmodifequipe(true);setTypeaddmodif('modif') } 

    const deleteequipe = (equipe) =>{setEquipeactu({...equipe})  ; setShowavert(true) ; } 

    const line1 =()=>(<div className="parametrage_tablo_line1" >
                            <div>Utilisateur</div>
                            <div>Description</div>
                            <div>
                                <div className="first">Manager</div>  
                                {managers?.map(x=><div className="second">{x.firstname}</div>)} 
                            </div>
                            <div></div>
                            <div></div>
                        </div>)
 
    const lineequipe = (equipe,managers) =>(<div className="parametrage_tablo_line" >
                                                <div>{equipe.nom}</div>
                                                <div>{equipe.description}</div>
                                                <div>
                                                    <div className="first"></div>   
                                                    {managers?.map(man =><div className={equipes_managers?.[equipe?.id]?.has(man?.id)  ? 'ismanager':'nomanager'}></div>)}
                                                </div>
                                                <div  onClick={()=>deleteequipe(equipe)} ></div>
                                                <div  onClick={()=>modifyequipe(equipe)} ></div>
                                            </div> );

    return(<>{showavert && <AlertePopupDelete equipe={equipeactu} setShowavert={setShowavert}  />}

             {(showajoutequipe || showmodifequipe) && <AjoutModifEquipe setShowajoutequipe={setShowajoutequipe}  
                                                                        setShowmodifequipe={setShowmodifequipe}  
                                                                        equipeactu={equipeactu} 
                                                                        typeaddmodif={typeaddmodif}  />}

            <div className="parametres_titre  divider" >Gestion de vos équipes et vendeurs</div>
            <div  className="parametrage_soustitre" >Utilisateurs</div>
            <div className="parametrage_para" >Gérer vos utilisateurs, les droits d’accès ainsi que l’équipe</div>
            <div className="parametrage_tablo" >
                {line1()}
                {equipes?.map(eq=>lineequipe(eq,managers))}
            </div>
            <div className="ajouteruser" onClick={ajoutequipe} >Ajouter une equipe</div>
           </>) ;
}

export default Equipes



