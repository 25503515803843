
import React from 'react';
import {  useSelector , useDispatch} from 'react-redux'; 

import { modifyChampsLeadmemo } from '../../state/redux';

import {listejsonall } from '../../utils/leadlistechamps';


import TitleInput from './TitleInput';
import Gender from './Gender';
import DateNaissance from './DateNaissance';
import Regime from './Regime';
import DateAdhesion from '../DateAdhesion/DateAdhesion';


function Infos({id,ecran,nbenfants,setNbenfants}){
    const dispatch = useDispatch()
    const leadmemo  = useSelector(state=>state.lead.leadmemo);

    const inversion=()=>{
        const newdatasleads= {};
        ["civilite","nom","prenom","regime","date_naissance","numsecu","organisme"].forEach(champ=> newdatasleads[listejsonall['souscripteur'][champ]] = leadmemo[listejsonall['conjoint'][champ]]||'' );
        ["civilite","nom","prenom","regime","date_naissance","numsecu","organisme"].forEach(champ=> newdatasleads[listejsonall['conjoint'][champ]] = leadmemo[listejsonall['souscripteur'][champ]]||'' );
        dispatch(modifyChampsLeadmemo({...newdatasleads}))
    }
    
    const listenfants= ['enfant1','enfant2','enfant3','enfant4','enfant5'];
    const lestedesennfants = [];
    listenfants.forEach(x=>lestedesennfants.push({civilite: leadmemo[listejsonall[x]['civilite']],date_naissance: leadmemo[listejsonall[x]['date_naissance']],
                                                                                                            nom: leadmemo[listejsonall[x]['nom']],num_secu: leadmemo[listejsonall[x]['num_secu']],
                                                                                                            organisme: leadmemo[listejsonall[x]['organisme']],prenom: leadmemo[listejsonall[x]['prenom']],regime: leadmemo[listejsonall[x]['regime']]}));

    const delenfant = ()=>{
        const numeroenfant = parseInt(id.replace('enfant','')) -1; 
         const newdatasleads= {civilite_enf5:undefined,date_naissance_enf5: undefined,nom_enf5:undefined, prenom_enf5:undefined , organisme_enf5:undefined ,regime_enf5:undefined  };
        for(let i= numeroenfant ; i<4; i++){
            ["civilite","nom", "prenom","regime","date_naissance", "numsecu","organisme"].map(champ=>{newdatasleads[listejsonall[listenfants[i]][champ]] = lestedesennfants[i+1][champ]} );
        }
        dispatch(modifyChampsLeadmemo({...newdatasleads})) ;
        setNbenfants(nbenfants-1);
      }

    return(<div className='infos'>
              <div className="firstline">{listejsonall[id]['titre']}
                     {((id==='souscripteur'||id==='conjoint') && leadmemo.regime_conjoint  && ecran!=='souscription' ) && <div className='btninversion' onClick={inversion}></div>}
                     {(id!=='souscripteur'&& id!=='conjoint'  && ecran!=='souscription' ) && <div className='btndelchild' onClick={delenfant}>X</div>}
                </div> 
                <div className='groupinfos commun' >
                    <Gender  id={id}  />
                    <TitleInput  id={id} nameinput="nom"  ecran={ecran}   /> 
                    <TitleInput  id={id}  nameinput="prenom" ecran={ecran}    /> 
                    <DateNaissance  id={id}  ecran={ecran}   />
                    <Regime  id={id}  ecran={ecran}  />
                    <TitleInput  id={id}    nameinput="numsecu"   ecran={ecran}  /> 
                    <TitleInput  id={id}    nameinput="organisme"  ecran={ecran}   /> <br/>
                    { id==="souscripteur"  && <><div className='formulairedateeffet'>
                                                    <div className='formulairedateeffet_titre' > Date d'effet </div>  
                                                    <DateAdhesion  formulaire={true}  />   
                                                </div>
                                                <TitleInput  id={id}    nameinput="codepostal_naissance"   ecran={ecran}  /> 
                                                <TitleInput  id={id}    nameinput="ville_naissance"   ecran={ecran}  /> </>}

                    
     
                </div> 
      </div>)

}



export default Infos