

import React  , {useState} from 'react';
import { useSelector,  useDispatch} from "react-redux";

import { modifyDisplaymodal} from '../../state/redux';
import PopupReassigner from '../../Components/Popup/PopupReassigner';

import ButtonSimple from '../../Components/Buttons/ButtonSimple';
import ListeContrats from '../../Components/Contrats/ListeContrats';

import { listestatus } from '../../utils/listeprogression';

import './Contrats.scss';

const classNames = require('classnames');
function Contrats({ecran}){
  const dispatch= useDispatch();
  const [showpopupreassigner, setShowpopupreassigner] = useState(false);

  const lead =  useSelector(state=>state.lead.leadactu);

    return(<div id='contrats' className='contratsgestion' >
      {showpopupreassigner && <PopupReassigner setShowpopupreassigner={setShowpopupreassigner} listidchangecom={[lead.id]}  setListidchange={()=>{return }}   /> }

                <div className='contratcommercial'  >Origine: {lead.origine}  </div>
                <div className='contratcommercial'  >Campagne: {lead.campagne} </div>
                <div className='contratcommercial'  >Commercial: {lead.commercial?.toUpperCase()}  </div>
                

                {(lead?.statut_client?.split('_'))?.map((y,i)=> (listestatus[y] &&<span  key={i} className={classNames('minibadge',{"minibadgeechec":lead?.statut_client?.includes('echec')},{"minibadgegagne":lead?.statut_client?.includes('gagne')})}> {listestatus[y]} </span> )) }
                
                <div className='displayflex' >
                    <ButtonSimple  texte="Nouveau Contrat" containerbouton="mbb10"  color='bouton_color_orange ' size='bouton_size_normal'   onclick={()=>{dispatch(modifyDisplaymodal('mod_gestnewcontrat')) } }    disable={false}  /> 
                    <ButtonSimple  texte="Ticket" containerbouton="mbb10"  color='bouton_color_violet' size='bouton_size_normal'   onclick={()=>{dispatch(modifyDisplaymodal('mod_comgestion'))  }   }    disable={false}  /> 
                    <div className="reassigner"  onClick={()=>{setShowpopupreassigner(true)}} >Ré-assigner</div>
                </div>

                <h3>Liste des contrats</h3>

                <div id="listecontrats" className="liste_contrats" >
                   <ListeContrats ecran={ecran}  />
                </div>
          </div>)

}

export default  Contrats

