import React  from "react";

import { useGestionmails } from "../../state/reactquery";


import EcranEmails from "./EcranEmails";

function  EcranEmailsGestion(){
  
    
    const {data: emails} = useGestionmails() ; 

    return(<EcranEmails emails={emails} type='gestion' />)
}

export default EcranEmailsGestion