import React , {useEffect, useState , useRef } from 'react';
import { useDispatch , useSelector} from 'react-redux';

import {   modifyDisplaymodal ,initialiseLeadactu,modifyEcran  } from '../../state/redux';

import { searchclient } from '../../utils/axios_functions';

import TabloGestion from './TabloGestion';

import './GestionRecherche.scss';
var filtre = /^[a-zA-Z -àâäéèêëïîôöùûüÿç]+$/;
function GestionRecherche(){
  const dispatch = useDispatch();
  const clients = useSelector(state=>state.datagestion.clients);

  const [value, setValue ]= useState('');
  const [resultsearch , setResultsearch] = useState([]);
  const inputReference = useRef(null);
  
  useEffect(() => { inputReference.current.focus();}, [])

  const recherche =async (value)=>{
    const leadsfound =await searchclient(value?.trim()) ;
   setResultsearch([...leadsfound]) ; 
  }

  const findclient =(client,val)=>{
    let liste_nom = client.nom ? client.nom.indexOf(val)!==-1 : false ; 
    let liste_email = client.email ? client.email.indexOf(val)!==-1 : false;
    return (liste_nom||  liste_email ) 
  }

  const handleChange =async (e)=>{ 
    setValue(e.target.value);
    if(e.target.value.length===0 ){  setResultsearch([])}
    else if(e.target.value.length<4){setResultsearch([...clients].filter(client=> findclient(client,e.target.value)) );}
  }

  const seachLead = async (e) => {
    if (e.key === 'Enter') { 
          if(!filtre.test(e.target.value)){return   }
          const leadsfound = await searchclient(e.target.value?.trim()) ;
          setResultsearch([...leadsfound]) ;
    }
  }


    useEffect(()=>{
        if(value.length<4){return }
        const getData = setTimeout(() => {recherche(value?.trim());}, 2000);  
        return () => clearTimeout(getData)
    },[value]);
      
  const clickline =async(id)=>{
      const exist_client = [...resultsearch].filter(x=>x.id===id)[0] ;dispatch(initialiseLeadactu(exist_client));dispatch(modifyEcran('ficheclientgestion')) ; 
      dispatch(modifyDisplaymodal(null)) 
  }




    return(<div className='containergray' >
                <div className='gestionsearch_content' >
                    <div className='search_content_top'> 
                        <div> 
                          <input ref={inputReference} 
                                  id="inputsearch"     
                                  type="text"  
                                  value={value} 
                                  onChange={handleChange} 
                                  onKeyDown={seachLead}   />  
                          </div>
                        <div className='search_close' onClick={()=>{ dispatch(modifyDisplaymodal(null))   }}   >  </div>
                    </div>
                    <div className='gestiontablesearch_container'  >
                      {resultsearch.length >0 && <TabloGestion id='tabsearch' clickline={clickline} datas={resultsearch} />}
                    </div>
                </div>
            </div> )
}

export default GestionRecherche
