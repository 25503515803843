import axios from 'axios';


import { queryClient } from '../state/queryClient';


export const changestatuticket = async(ticket)=>{
    await axios({method:'POST',url:`/tickets/changestatuticket`,data:ticket,headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
    queryClient.invalidateQueries({queryKey: ['tickets']}) ;
}



export const createticket = (lead, id_user, postmail, dataticket)=>{
    const ticket =     {"ticket_tags":dataticket?.ticket_tags,
                         "ticket_resume":dataticket?.ticket_resume,
                        "id_user":id_user, "postmail":postmail,
                        "nom_seller":"Sellam ", // si ticket cree par commercial user.lastname 
                        "prenom_seller":"Simon", // si ticket cree par commercial user.firstname 
                        "courtier":lead.courtier,
                        "id_lead":lead.id,
                        "nom":lead.nom||'',
                        "prenom":lead.prenom||'',
                        "email":lead.email, 
                    }
    axios({method:'POST',url:`/tickets/createticket`,data:ticket,headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
}