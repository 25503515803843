import RingoverSDK from "ringover-sdk";
import { findclientfromtel } from './phone';
import { batch } from 'react-redux';



import { store  ,modifyQualiflead,modifyDisplaymodal,modifyEtattel,
         initialiseLeadactu,  initialiseLeadappelant , modifyEcran ,
		 allFormules , defineDevisFormules ,filterFormules} from '../state/redux';

import { getallformules } from './calculstarifs';
import { modifyonelead } from "./actions_lead";

const options = {   type: "fixed",// "fixed", "relative", "absolute"
					size: "small",
					container: null,
					position: {top: null,bottom:"50px",left: null,right:"50px"},
					border: false,// true, false
					animation: true,// true, false
					backgroundColor: "transparent",
					trayicon: false};
 
// Create instance
export const simpleSDK = new RingoverSDK(options);

// Check iframe status
// simpleSDK.dial("+33179757575");	
// simpleSDK.checkStatus();

const handleringing = (e)=>{

	e.data.direction==='out'  && store.dispatch(modifyEtattel('appel'));
	const nb = store.getState().lead.leadactu.nbappels || 0 ;
	e.data.direction==='out' && modifyonelead({'nbappels': nb+1}) ;
	e.data.direction==='in' && findclientfromtel(e.data.from);
}


export const deccrocher = ()=>{ 
	store.dispatch(modifyQualiflead((store.getState().lead.leadappelant.id && store.getState().lead.leadappelant!=='gagne')?false:true));  
	store.dispatch(allFormules([]));  
	store.dispatch(defineDevisFormules([])); 
	store.dispatch(filterFormules({'type':'liste', 'liste':[]})); 
	store.dispatch(modifyEtattel('appel')) ;

	batch( () => {store.getState().lead.leadappelant.id && store.dispatch(initialiseLeadactu( store.getState().lead.leadappelant));  
				   store.getState().lead.leadappelant.id && store.dispatch(modifyEcran('ficheclient'));  });
	setTimeout(()=>{ store.getState().lead.leadappelant.id &&  store.dispatch(getallformules());}, 1000) ;
	setTimeout(()=>{store.dispatch(initialiseLeadappelant({})) ; }, 2000) ;
}



const handleanswered= (e)=>{
	const conditiontelappelent = store.getState().lead.leadappelant.telephone===e.data.from || store.getState().lead.leadappelant.telephone2===e.data.from ;
	(e.data.direction==='in' && conditiontelappelent)  && deccrocher() ; 
}

const handlehangup = (e)=>{ 
	store.dispatch(modifyEtattel('normal')) ;
	const ecran = store.getState().display.ecran ; 
	const conditiontellead = store.getState().lead.leadactu.telephone===e.data.from || store.getState().lead.leadactu.telephone2===e.data.from ||
							store.getState().lead.leadactu.telephone===e.data.to || store.getState().lead.leadactu.telephone2===e.data.to;
	 (ecran==='ficheclient' &&  conditiontellead ) && store.dispatch(modifyDisplaymodal('mod_qualiflead')) ;


}


export const initialiseringover =async ()=>{
    await simpleSDK.generate();
	simpleSDK.on('changePage',    (e) => console.log('changePage',e.data));
	simpleSDK.on('dialerReady',   (e) => console.log('dialerReady',e.data));
	simpleSDK.on('login',         (e) => console.log('login',e.data)); 
	simpleSDK.on('ringingCall',   (e) =>{ console.log('ringingCall'); handleringing(e)});
	simpleSDK.on('answeredCall',  (e) =>{ console.log('answeredCall'); handleanswered(e) });
	simpleSDK.on('hangupCall',    (e) => { console.log('hangupCall'); handlehangup(e) });
    // document.getElementById("email").value = "michael@mutuello.com";
    // document.getElementById("pwd").value = "Coucou@26";
    // document.getElementsByClassName("login_submit")[0].click();

}