
import React  , {useEffect}  from 'react';

import { useDispatch , useSelector } from 'react-redux'; 

import { modifyChampsLeadmemo } from '../../state/redux';
import { listejsonall} from '../../utils/leadlistechamps';

import './Gender.scss';
const classNames = require('classnames'); 
function Gender({id}){
   
    const dispatch = useDispatch() ; 
    const leadmemo  = useSelector(state=>state.lead.leadmemo);
   
    useEffect(()=>{ leadmemo[listejsonall[id]['civilite']]===undefined && dispatch(modifyChampsLeadmemo({[listejsonall[id]['civilite']]:'M'}))},[dispatch,id,leadmemo]);

    const clickgenderbutton=(e)=>{  dispatch(modifyChampsLeadmemo({[listejsonall[id]['civilite']]:e.target.name}))}

    return ( <div className="groupgender" > 
                <div  className="groupgender_buttons" >
                    <button id={id} name="M"  className={classNames('button button_gender mr ', {'button_gender_selected': leadmemo[listejsonall[id]['civilite']]==='M'})} onClick={clickgenderbutton}>M.</button>
                    <button name="Mme"  className={classNames('button button_gender', {'button_gender_selected': leadmemo[listejsonall[id]['civilite']]!=='M'})} onClick={clickgenderbutton}>Mme</button>
                </div>
             </div>)

}

export default  Gender
