import React, { useState }  from "react";

import Courtiers from "./Courtiers";

import FormCourtier from "../../Components/EcranParametres/Coordonnees/FormCourtier";
import Compagnies from "./Compagnies";

import './EcranSuperAdmin.scss'  ; 


function EcranSuperAdmin(){
    const [choice, setChoice] = useState('Infos Courtier') ;
    const [actucourtier , setActucourtier] = useState({}) ;


   return(<div className="ecransuperadmin">
                <div className="ecransuperadmin_titre">Dashboard Super-Admin</div>
                <Courtiers actucourtier= {actucourtier}  setActucourtier= {setActucourtier}  /><br/>
                <div className="ecransuperadmin_menu"  >
                    {['Infos Courtier','Compagnies']?.map(choix=>(<div id={choix}  
                                                                key={choix} 
                                                                className={choice===choix?"selected":""} 
                                                                onClick={()=>setChoice(choix)} >
                                                                    {choix}
                                                          </div>))}
                </div>
                {choice ==='Infos Courtier'?<FormCourtier courtier={actucourtier}  />:
                                            <Compagnies actucourtier= {actucourtier}  setActucourtier= {setActucourtier}  /> }
               
        </div>) 
}



export default EcranSuperAdmin