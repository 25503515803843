import React  , {useState,useEffect} from "react";
import { useSelector  } from "react-redux";
import { nanoid } from '@reduxjs/toolkit';
import axios from "axios";


import { useAddDocuments, useDocuments} from "../../state/reactquery";
import { queryClient } from "../../state/queryClient";

import { modifyonelead   } from '../../utils/actions_lead';
import { threeyearsafter } from "../../utils/utils_dates";

import uploadcheck  from '../../images/uploadcheck.svg'; 
import uploaddel from '../../images/uploaddel.svg'; 

import './Upload.scss';

const nomsdocuments ={'cni':"Pièce d’identité",'attestationro':"Attestation  R.O", 'ribfirst':"RIB Prélèv.",'ribsecond':"RIB Remb.",
                      'kbis':"KBIS",'resiliation':"Lettre de résil.",'signature':"Contrat Signé",'devis':"Devis",'conseil':"Fiche conseil",
                      'autre':"Autre" }

const test_fichiers = (liste)=>{return ['cni','attestationro','ribfirst','signature'].every(elt=>[...Object.keys(liste).filter(x=>liste[x].length >0)].includes(elt) )} 

const jsonliste ={ 'cni':[],'attestationro':[], 'ribfirst':[], 'ribsecond':[], 'kbis':[],'resiliation':[],'signature':[],'devis':[],'conseil':[],'autre':[] }

const orderdocuments = (liste) =>{  if(!liste){ return }
    let jsonliste ={'cni':[],'attestationro':[], 'ribfirst':[],'ribsecond':[],'kbis':[],'resiliation':[],'signature':[],'devis':[],'conseil':[],'autre':[]}
    for(let doc of liste ){ 
      jsonliste.hasOwnProperty(doc.type_file)?(jsonliste[doc.type_file]=[...jsonliste[doc.type_file],doc]):
      doc.type_file==='ficcontrat' ? (jsonliste['signature']=[...jsonliste['signature'],doc]):
                                      (jsonliste['autre']=[...jsonliste['autre'],doc])  ;
    }
    return jsonliste
}

const classNames = require('classnames');
function UploadFile({listedocs,typefile}){
  const ficheclient = useSelector(state=>state.lead.leadactu);
  const addDocument = useAddDocuments(ficheclient?.id) ;

  const [progress, setProgress] = useState(0);

   const sign_fic = ficheclient?.sign_fic ;
   const sign_bulletin = ficheclient?.sign_bulletin ;

  const uploadfileins3 = async (e)=>{ 

      const nomfichier = typefile+(nanoid(5)).toString() ;
 
      const testfichiersobligatoire = ['cni','attestationro','ribfirst'].every(elt=>[...new Set([...Object.keys(listedocs).filter(x=>listedocs[x].length >0), typefile])].includes(elt)) ;
     
      const c_gagne = (ficheclient.statut_client?.includes('gagne') &&  typefile==='signature') || (testfichiersobligatoire && sign_fic+sign_bulletin===4 );
      const c_attentesignature = testfichiersobligatoire && sign_fic+sign_bulletin!==4;
      const c_devisenvoye = typefile==='devis' && !ficheclient.statut_client?.includes('gagne') && !ficheclient.statut_client?.includes('attente');


      if(c_gagne){modifyonelead({'sign_docs':2, 'statut_client':'gagne', 'date_rdvrelance':threeyearsafter(ficheclient.date_lastmodif),'hot':0})}
      else if(c_attentesignature){modifyonelead({'sign_docs':2, 'statut_client':ficheclient.statut_client?.includes('rdv')?"attente_signature_rdv":ficheclient.statut_client?.includes('relance')?"attente_signature_relance":'attente_signature'})}
      else if(c_devisenvoye){modifyonelead({'statut_client':'devisenvoye'})}

      const data = new FormData();
      data.append('datajson', JSON.stringify({courtier:ficheclient?.courtier,id_client:ficheclient['id_client'] ,id_lead:ficheclient['id'] ,type_file:typefile,commercial:ficheclient?.commercial,nomfichier}) )
       data.append('file',e.target.files[0],`/${nomfichier}.${e.target.files[0].name.split('.')[1]}`);

      const newdoc = await axios.post(`/files/addclientfile/`, data ,{  headers:  { 'Accept': 'application/json',"Content-Type": "multipart/form-data"},
                                          onUploadProgress:(p)=>{   const percent= Math.round((p.loaded*100)/p.total) ; setProgress(percent); percent===100 && setTimeout(()=>{setProgress(0)},3000);  } })

      setTimeout(()=>{ addDocument.mutate(newdoc.data); document.getElementsByName(typefile)[0].value= null ;  },200); 
  }

  const delfile = async(e)=>{await axios({method:'POST',url:`/files/deleteclientfile`,data: {'url':e.target.id}}) ;
                            queryClient.invalidateQueries({ queryKey: ['documents',ficheclient.id]}) ;
                            let newliste = {...listedocs}  ; 
                            newliste[typefile] = listedocs[typefile].filter(x=>x!==e.target.id); 
                            const newstatutclient = ficheclient.statut_client?.contratsigne===1 && ficheclient.statut_client?.includes('rdv')?"attente_documents_rdv":
                                                    ficheclient.statut_client?.contratsigne===1 &&  ficheclient.statut_client?.includes('relance')?"attente_documents_relance":
                                                    ficheclient.statut_client ;
                        
                            !test_fichiers(newliste)&&  modifyonelead({'documents': 0,'sign_docs':0,'statut_client':newstatutclient}) ;
                          }

  useEffect(()=>{
    document.getElementById(typefile) && document.getElementById(typefile+typefile).addEventListener('dragover', () => {  document.getElementById(typefile).classList.add('hover') });
    document.getElementById(typefile) && document.getElementById(typefile+typefile).addEventListener('dragleave', () => {  document.getElementById(typefile).classList.remove('hover') });
  });          

   const testnodelete = !['signature','conseil','resiliation'].includes(typefile) ;

   return(<div id={typefile}  className={"uploadvalidcontrat "+((listedocs?.[typefile]?.length >0)?' uploaded':' uploadwait') } >
                <input id={typefile+typefile}   className={classNames("uploadvalidcontrat_input",{"little":(listedocs[typefile]&& listedocs[typefile].length >0)})} type="file" name={typefile} onChange={uploadfileins3} />
                <div className="uploadvalidcontrat_2">
                      <span className={"uploadvalidcontrat_2_top "+((listedocs[typefile]&& listedocs[typefile].length >0 )?' uploaded':' uploadwait')} >{nomsdocuments[typefile]}</span>
                      {(listedocs[typefile]&& listedocs[typefile].length >0 ) &&  <img  src={uploadcheck} alt='imgcheck' />}<br/> 
                      
                      {listedocs[typefile] && listedocs[typefile].map((doc,i)=>{ return <div  className="validcontrat_linkdoc" key={i}>
                                                                                          <a  className="uploadcontratlink" href={doc.url} rel="noopener noreferrer"   target="_blank">   {doc?.nomfichier}</a>   
                                                                                         {testnodelete && <img id={doc?.url} className="uploaddel" src={uploaddel} alt='imgdel'  onClick={delfile} />  }
                                                                                  </div>})}                       
                  </div>
                <div className={"uploadvalidcontrat_progress_container "+((listedocs[typefile]&& listedocs[typefile].length >0 )?' uploaded':' uploadwait')} >
                   <div className={"uploadvalidcontrat_progress lenght"+progress} > </div>
                </div>
          </div>)
}

function Upload(){

      const ficheclient = useSelector(state=>state.lead.leadactu);
      const {data: documents} = useDocuments(ficheclient?.id) ;
      const listedocs =documents?orderdocuments(documents):jsonliste; 

      const nomsdocs = nomsdocuments ; 
      return(<>{Object.keys(nomsdocs).map(x=>(<UploadFile  listedocs={listedocs} key={x} typefile={x} />))}</>);
}

export default Upload

