import React from "react"
import { useSelector ,useDispatch } from 'react-redux';

import {   defineFormuleShow} from '../../state/redux';
import { useUser } from "../../state/reactquerycabinets";


import AjoutDevis from "./AjoutDevis"

import AccessToDocs from "./AccessToDocs";

import { isclassblur } from "../../utils/radin";

import { logocompagnie } from '../../utils/logocompagnie';

import './CardFormuleDetail.scss' ;

function formatprix(prix){ return prix?   (Math.round(prix*100)/100).toString().split('.').join(","):'0'}
const classNames = require('classnames');

const listepostes =['hospi','consults_specialistes','prevention','optique_lunettes','optique_lentilles','dentaire_prothese','dentaire_implant','dentaire_orthodontie']

function CardFormuleDetail(){

   const {data: user} = useUser(localStorage.getItem('token'))  ;
   
   const dispatch= useDispatch() ;
   const formuleshow= useSelector(state=>state.formules.formuleshow) ;

    const BlockDetail = ({index,cle,titre,nb,children})=>{
        
        return(<div  key={cle} className={classNames(`formuledetailsblock imagepostedetail ${listepostes[index]}`,
                                                        {'last':titre==='Prévention' || titre==='Orthodontie'},
                                                        {'bigpadding': titre ==='Consultations'} 
                                                        
                                                        )}>
                        <div className="formuledetailsblock_divimage" > </div>

                        <div className='containerjaugedetail' > 

                {[1,2,3,4,5].map((x,i)=>{return  (<div key={x}  className={classNames('containtimagenivos_nivo',{'bleu': i>=5-nb})}  ></div>) })}


        </div>
                        <div>  <div className="formuledetailsblock_texte">{titre}</div>  {children} </div>
                </div>)
    }

    const lineinfos =(texte1,texte2)=>{  
          const fontbold = texte1=== "Généralistes DPTAM" ||texte1=== "Spécialistes DPTAM";
        return   <div className="formuledetailslineinfo" >
                                 <div  className={classNames("formuledetailslineinfo_line1",{"formuledetailslineinfo_line1_bold": fontbold}  )}>{texte1}</div>
                                 <div  className={classNames("formuledetailslineinfo_line2",{"formuledetailslineinfo_line2_bold": fontbold}  )}>{texte2} </div>
                  </div>
   } 
   
   const listedatas =(form)=>{
    return  [{nb: form?.hospi  ,  titre :'Hospitalisation' , liste:["Honoraires","Chambre Particulière"], listeinfo:[form?.hospi_honoraire,form?.hospi_chambre]},
            {nb: form?.consults  , titre :'Consultations' , liste:["Généralistes DPTAM","Généralistes non DPTAM","Spécialistes DPTAM","Spécialistes non DPTAM","Médicaments non remb."], listeinfo:[form?.consults_generalistes_p,form?.consults_generalistes_p_non_dptam,form?.consults_specialistes_p,form?.consults_specialistes_p_non_dptam,form?.consults_pharma_nr]},
            {nb: form?.prevention  , titre :'Prévention' , liste:["Consultations"], listeinfo:[form?.prevention_consult]},
            {nb: form?.optique_lunettes_nb ,  titre :'Lunettes'    , liste:["Prise en charge"], listeinfo:[form?.optique_lunettes]},
            {nb: form?.optique_lentilles_nb  ,  titre :'Lentilles'    , liste:["Prise en charge"], listeinfo:[form?.optique_lentilles]},
            {nb: form?.dentaire_prothese_nb  , titre :'Soins dentaires' , liste:["Détartrage","Prothèse"], listeinfo:[form?.dentaire_detartrage_p,form?.dentaire_prothese_p]},
            {nb: form?.dentaire_implant_nb  , titre :'Implants Dentaires', liste:["Implants"], listeinfo:[form?.dentaire_implant]},
            {nb: form?.dentaire_orthodontie_nb  , titre :'Orthodontie' , liste:["Orthodontie"], listeinfo:[form?.dentaire_orthodontie_p]},
        ]
        }  

     return(<div  className={classNames("detailformule_container")}  >
        {/* <div  className={classNames("detailformule_container",{'nonvisible': formuleshow===null},{'visible': formuleshow!==null})}  > */}

                <div className="retourformules" onClick={()=>{dispatch(defineFormuleShow(null)) }}  >Retour aux formules</div>
                 <div  className="detailformule">
                     <div  className="detailformule_top">
                         <div className="detailformule_top_1">
                         <img src={logocompagnie(formuleshow?.compagnie?.toUpperCase())}  width='70' alt='logoCompagnie'/>
                         </div>  
                         <div className="detailformule_top_2">
                                 <div className="nomgamme" >{formuleshow?.gamme}</div>    
                                 <div className={`formulenom ${isclassblur()}`}>{formuleshow?.nom_formule}</div> 
                         </div>  
                         <div className="detailformule_top_3">
                                 <div className={`prix ${isclassblur()}`} >{formatprix(formuleshow?.prix)}€</div>      
                                 <div className="parmois" >par mois</div>  
                         </div>  
                          <div  className="detailformule_top_4">  <AccessToDocs nom={formuleshow?.compagnie+formuleshow?.gamme}  /> </div> 
                         <div className="detailformule_top_5">
                                     <AjoutDevis  formule={formuleshow}  />
                          </div>  
                     </div>
                     <div  className="detailformule_bottom">
                            <div  className="borderright">
                           { formuleshow && (listedatas(formuleshow)).map((x,i)=>(i<3 && <BlockDetail index={i} cle={100+i} nb={x.nb} titre={x.titre}   >
                                                                     {x.liste.map((y,index)=>lineinfos(y,x.listeinfo[index]))}
                                                         </BlockDetail>) )}
                              </div>
                              <div>
                              {formuleshow &&  (listedatas(formuleshow)).map((x,i)=>(i>=3 && <BlockDetail index={i}  cle={500+i} nb={x.nb} titre={x.titre}   >
                                                                     {x.liste.map((y,index)=>lineinfos(y,x.listeinfo[index]))}
                                                         </BlockDetail>) )}
                             </div>
                         </div>

                         </div>  
             </div>)

}

export default CardFormuleDetail