
import React   from 'react';

import TitleInput from './TitleInput';
 import TitleInputAdresse  from './TitleInputAdresse'; 
import MiniDepartements from './MiniDepartements';



import './Infos.scss';
function InfosSouscripteur({ecran}){
    
    return(<div className='infossouscripteur' >
                <div className='lineinfossouscripteur'>
                    <TitleInput  id="souscripteur"  nameinput="email" ecran={ecran}    />
                    <TitleInput  id="souscripteur"  nameinput="email2"  ecran={ecran}   />
                    <TitleInput  id="souscripteur"  nameinput="telephone"   ecran={ecran}  />
                    <TitleInput  id="souscripteur"  nameinput="telephone2"   ecran={ecran}  />
        
                </div>
                <div className='lineinfossouscripteur'>
                <TitleInputAdresse     ecran={ecran}    /><TitleInput  id="souscripteur"  nameinput="complement_adresse"  ecran={ecran}   /> 
                </div>
              {ecran!=='souscription' &&  <MiniDepartements  />}
                <div  className='lineinfossouscripteur'>
                    <TitleInput  id="souscripteur"  nameinput="iban1" ecran={ecran}    /> 
                    <TitleInput  id="souscripteur" nameinput="bic1"   ecran={ecran}    /> 
                </div>
                <div  className='lineinfossouscripteur'>
                    <TitleInput  id="souscripteur" nameinput="iban2"  ecran={ecran}     /> 
                    <TitleInput  id="souscripteur"  nameinput="bic2"  ecran={ecran}     /> 
                </div>      
         </div>)
}

export default InfosSouscripteur