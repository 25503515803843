
import React , { useState} from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider , useGoogleLogin } from '@react-oauth/google';


import { useUser } from '../state/reactquerycabinets';

import { handlelogout,  getrefreshtokenandsave, loginmailpass} from '../utils/utils_login';

import {allLeads,initialiseLeadactu,initialiseLeadmemoConjoint , initialiseLeadMemoEnfants,defineCampagnes, 
        initialiseLeadmemo,defineDevisFormules,allFormules , modifyEcran} from '../state/redux';
        


import Mutuello_Logo  from '../images/logoMutuelloLogin.svg'
import ButtonSimple from '../Components/Buttons/ButtonSimple';

import './Login.scss';

const LoginButton = ()=>{
    const dispatch = useDispatch() ;
    let navigate = useNavigate();
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    // useEffect(()=>{  
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //     window.onbeforeunload = function (e) {e.preventDefault()  ;return false;}
    // })

    const login = useGoogleLogin({onSuccess: async(response) => { 
                                                const reftoken = await getrefreshtokenandsave(response); 
                                                reftoken && navigate('/');
                                                dispatch(allLeads([]));
                                                dispatch(defineCampagnes([]));
                                                dispatch(initialiseLeadactu({}));
                                                dispatch(initialiseLeadmemoConjoint({}));
                                                dispatch(initialiseLeadMemoEnfants([]));
                                                dispatch(initialiseLeadmemo({})) ;
                                                dispatch(defineDevisFormules([])); 
                                                dispatch(allFormules([])); 
                                                if(user?.role==="GESTION"){dispatch(modifyEcran('ecrangestionleads'));}  
                                                else{dispatch(modifyEcran('dashboard'));}  
                                            },
                                  onError :(response) => {   console.log(response) },
                                  flow: 'auth-code', 
                                  prompt: 'consent', 
                                  scope: 'openid email profile  https://www.googleapis.com/auth/calendar '});           
    


      return(<div className='loginbutton' onClick={login}  >
                    <div className='loginbutton_img'></div><div  className='loginbutton_txt'>Connexion avec Google Calendar</div>
             </div> )
  }
  

    const LoginPage = ()=>{
        let navigate = useNavigate();
        const dispatch = useDispatch() ;
        const [userdata, setUserdata] = useState({email:'', password:''})
        const [errorpass, setErrorpass] = useState(false)
        const {data: user} = useUser(localStorage.getItem('token'))  ;

        const changeuserdata = (e)=>{ setUserdata({...userdata, [e.target.id]:e.target.value  })  ; }

        const onkeydown = (e)=>{  e.key==='Enter' && document?.getElementById('boutonlog')?.click()   }

        const loginemailpass = async()=>{ const log= await loginmailpass(userdata)  ;
                                         if(log){   dispatch(allLeads([]));
                                                    dispatch(defineCampagnes([]));
                                                    dispatch(initialiseLeadactu({}));
                                                    dispatch(initialiseLeadmemoConjoint({}));
                                                    dispatch(initialiseLeadMemoEnfants([]));
                                                    dispatch(initialiseLeadmemo({})) ;
                                                    dispatch(defineDevisFormules([])); 
                                                    dispatch(allFormules([])); 
                                                    setErrorpass(false) ;
                                                    if(user?.role==="GESTION"){dispatch(modifyEcran('ecrangestionleads'));}  
                                                    else{dispatch(modifyEcran('dashboard'));}  
                                                    navigate('/')  }
                                        else{setErrorpass(true) ;}  ;}
    

        return( <div className="loginpage">
                    <div  className="loginpage_left">
                        <div  className="loginpage_left_container">
                            <div  className='loginpage_leftlogo' > <img alt='logo' src={Mutuello_Logo} height="55" /> </div>
                            <div  className='loginpage_lefthello' >Hello !</div>
                            <div  className='loginpage_lefttxt' >Bienvenue sur votre console Mutuello</div>
                            <GoogleOAuthProvider  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} >
                                    <LoginButton  />
                            </GoogleOAuthProvider>
                            {user?.['email'] ? (<div className='loginfo'>You logged in as {user['email']} <span onClick={handlelogout}>Log Out</span> </div>):<></>}
                            <div className='loginseparate'  ><span className='linethrough' ></span><span> OU</span> <span className='linethrough' ></span></div>
                            <div className='loginpage_titleinput' >Email</div>
                            <input   id="email" className='loginpage_input'  placeholder='Enter your email'  value={userdata.email}  onChange={changeuserdata}  />
                            <div className='loginpage_titleinput' >Password</div>
                            <input type="password" id="password" className='loginpage_input'  value={userdata.password} onChange={changeuserdata}  onKeyDown={onkeydown}  /><br/>
                            <ButtonSimple id='boutonlog'
                                          texte="Se connecter"  
                                          containerbouton='fullbuton'  
                                          color='bouton_color_orange' 
                                          size='bouton_size_medium'   
                                          onclick={loginemailpass}   
                                          disable={(!userdata['email'] || !userdata['password'] )} /> 
                            {errorpass && <div className='errormdp' >Mot de passe et/ou email invalide</div>}
                        </div>
                    </div>
                    <div   className="loginpage_right"></div>
            </div>)
    }


export default LoginPage

