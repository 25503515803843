import React from "react";
import { useDispatch ,useSelector } from "react-redux";

import { defineComgestion } from "../../state/redux";


function ComGestionChoice({listegestions,setListeetapes, setEtape}){
    const dispatch = useDispatch () ;

    const comgestion = useSelector(state=>state.comgestion.value) 

    const clickbtncomgestion = (cle)=>{ 
            cle==='nonliste'?setEtape('nonliste') :setEtape('formulaire')  ;
            ['gestecom','parrainage','cartetierspayant']?.includes(cle)?setListeetapes(['type_ticket','formulaire','fin']):
            cle==='nonliste'?setListeetapes(['type_ticket','nonliste','documents','fin']):setListeetapes(['type_ticket','formulaire','documents','fin']);
            dispatch(defineComgestion({...comgestion, 'type_gestion': cle})) ; 
    } 

return(<>{Object.keys(listegestions).length>0 && Object.keys(listegestions).sort((a,b)=>listegestions[a]?.ordre -  listegestions[b]?.ordre)
                                                                           .map((cle)=>(<div id={cle} key={cle} className="comgestion_btn" onClick={()=>clickbtncomgestion(cle)}>{listegestions[cle]?.type_affichage}</div>))}
</>) ;

}



export default ComGestionChoice;