import axios from "axios";

import { queryClient }  from '../state/queryClient';

import { store , allLeads , defineCampagnes} from "../state/redux";


  

export const getallleads = () => async () => {
  store.dispatch(allLeads([]));
  store.dispatch(defineCampagnes([]));
    try {
      const response = await axios({method:'POST',
                                    url:`/clients/findallclients`,
                                    headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`}});
      if(response.error){localStorage.removeItem('token')  ; }
      let leads = [...response.data.leads] ; 
      let campagnes = [...response.data.campagnes]
  
      store.dispatch(allLeads([...leads]));
      store.dispatch(defineCampagnes([...campagnes]));
    }catch(err){ store.dispatch(allLeads([]));  }
};
  

  export const writenote = (id,note) => {   
    axios.post(`/memory/writenote/`,{id,note},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
  } 
    
    
  export const getnotebyid =async () => {
    const lead = store.getState().lead.leadactu;
    let note; 
    try { const response = await axios.post( `/memory/getnotebyid`,{id:lead.id });
          note= response.data;  return note
      }catch(err){ note =''  ;   return note }
};
    
      


export const searchclient = async(data)=>{
  const user= queryClient.getQueryData(['user',localStorage.getItem('token')])  ;

   const response = await axios.post(`/clients/searchclient/`,{data },{headers:{'Authorization':`Bearer ${localStorage.getItem('token')}`}}) ;
   return response?.data ;
}


export const createclient =  async (lead) => { 
    const reqrecord   =await   axios.post(  `/clients/createclient/`,lead  );
    return  {...reqrecord.data}
}

export const updateclients =async (lead) => { 
  const reqrecord   =await   axios.post(  `/clients/updateclients/`,lead  ); 
  return  {...reqrecord.data}
}

