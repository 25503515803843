import React from "react";
import { useDispatch } from "react-redux";


import './PopupRadinConfirm.scss';


function PopupRadinConfirm(){
const dispatch = useDispatch() ;
return(<div  className="containergray">
    <div  className="popupradinconfirm"  >
    <div  className="popupradinconfirm_titre"  >Demande envoyée !</div>
    <div  className="popupradinconfirm_texte"  >Un conseiller Mutuello vous contacte au plus vite pour vous faire passer sur la formule<span> Mutuello Expert</span>.</div>


        </div>
        </div>)
}

export default PopupRadinConfirm;

