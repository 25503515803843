import React,  {useState} from "react";
import axios from 'axios' ; 
import { useDispatch , useSelector} from "react-redux";

import { modifyDisplaymodal,modifyDisplaymodalCalendar, modifyQualiflead, modifyEtattel , modifyEcran } from '../../state/redux';

import { addEltHistory } from "../../state/reactquery";
import { useUser } from "../../state/reactquerycabinets";

import {modifystatus_nrp,modifystatus_fauxnum} from "../../utils/actions_modifstatuts";
import { modifyonelead  } from "../../utils/actions_lead";
import { rdvgagne } from "../../utils/actions_gagne";
import {telephone } from "../../utils/twilio";
import {finappel } from "../../utils/phone";

import ButtonImgTel from "../../Components/Buttons/ButtonImgTel";
import Phone from "../../Components/Telephone/Phone";
import ButtonSouscrire from "../Buttons/ButtonSouscrire";

import threepoints from '../../images/threepoints.svg';
import telephonietoclient from '../../images/telephonietoclient.svg';

import './Telephonie.scss'
const classNames = require('classnames');
function Telephonie(){
    const dispatch = useDispatch();
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const lead = useSelector(state=>state.lead.leadactu);
    const eventtochange = useSelector(state=>state.calendar.eventtochange);
    const  ecran = useSelector(state=>state.display.ecran) ;
    const displaymodal = useSelector(state=>state.display.displaymodal);

    const { etat} = useSelector(state=>state.tel);

    const [showboutons,setShowboutons] = useState(false) ;
    const [muted, setMuted] = useState(false);

    const relancerdv = ()=>{dispatch(dispatch(modifyDisplaymodalCalendar('mod_calendarelance')))  }    
    const prendrerdv = ()=>{dispatch(modifyDisplaymodalCalendar('mod_calendarrdv')) }

    const maintientcontrat = () =>{
        axios.post(  `/contrats/maintiencontrat/`,{email: lead.email} );
        modifyonelead({'statut_client': 'gagne', 'hot': 0}) ;  // 'recyclage' : 2 , 
        rdvgagne(lead.id); 
        addEltHistory('qualiflead', `<div class='horoline1' >Recyclage: Maintient du contrat </div>`)  
        dispatch(modifyQualiflead(true));
        dispatch(modifyDisplaymodal(null));
    }

    const handlebtnechec = ()=>{ dispatch(modifyDisplaymodal('mod_echecs'));  finappel()}
    // const handlebtnechec = ()=>{ dispatch(modifyDisplaymodal('mod_echecs'));}
    const raccroche_nrp =  ()=>{finappel() ; modifystatus_nrp();lead.qualiflead===false &&  dispatch(modifyDisplaymodal('mod_qualiflead'))}
    const raccroche_fauxnum =  ()=>{finappel() ; modifystatus_fauxnum();lead.qualiflead===false &&  dispatch(modifyDisplaymodal('mod_qualiflead'))}
    

    const tooglemute =() =>{  
        if (telephone.getTelephone().connexion===null ){return }
         setMuted(muted => !muted)
         telephone.getTelephone().connexion.isMuted() ?   telephone.getTelephone().connexion.mute(false) : telephone.getTelephone().connexion.mute(true)   }
    return( <div className="telephonie">
                {(ecran !== 'ficheclient' && !showboutons) && <div className="threepoints" onClick={() => { setShowboutons(true) }}  > <img src={threepoints} alt='threepoints' /> </div>}
                {((ecran !== 'ficheclient' && showboutons) || ecran === 'ficheclient') && <>
                    <ButtonSouscrire  /> 
                    {user.showgestion === 1 && <ButtonImgTel texte="Gestion" type='bouton_gestion' onclick={() => {dispatch(modifyDisplaymodal('mod_comgestion')) }} />}
                    {lead.recyclage === 2 && <ButtonImgTel texte="Maintien" type='bouton_maintien' onclick={maintientcontrat} />}
                    <ButtonImgTel texte={(eventtochange.id && lead.statut_client?.includes('relance')) ? "Modifier Relance" : "Relancer"} type='bouton_relance' onclick={relancerdv} />
                    <ButtonImgTel texte={(eventtochange.id && lead.statut_client?.includes('rdv')) ? "Modifier Rdv" : "Rdv"} type='bouton_rdv' onclick={prendrerdv} />
                    <ButtonImgTel texte="NRP" type='bouton_nrp' onclick={raccroche_nrp} />
                    <ButtonImgTel texte="Fx Num" type='bouton_fauxnum' onclick={raccroche_fauxnum} />
                    <ButtonImgTel texte="Echec" type='bouton_echec' onclick={handlebtnechec} /></>}
           { user.type_tel === 'twilio' &&   <>  <div className="telephonietextes" >
                    <div className="telephonienom" >{lead.nom??''} {lead.prenom??''}</div>
                    <div className="telephoniestatus" >{etat === 'appel' ? 'Appel en cours' : etat === 'sonnerie' ? 'Appel rentrant' : 'En pause'}</div>
                </div>
                <div className={classNames("silence", { "ismuted": muted === true }, { "nomuted": muted === false })} onClick={tooglemute} ></div></>}
                { ecran !=='ficheclient'  &&   <div className="telephonietoclient" onClick={()=>{ dispatch(modifyEcran('ficheclient'));dispatch(modifyEtattel('normal')) }}  > <img src={telephonietoclient} alt='telephonietoclient' />  </div>}
                <Phone />
                

            </div> )
}



export default Telephonie