import axios from 'axios';
import { batch } from 'react-redux';

import { addEltHistory } from '../state/reactquery';
import { store,allLeads ,initialiseLeadactu, modifyChampsLeadactu,modifyEcran, modifyPopupmultitel ,
         setFileattentes, modifyDisablebuttons , modifyDisplaymodal ,
         defineFormuleShow , defineEcranclientselectleft ,seteventtochange ,initialiseLeadmemoConjoint,
         defineMessage,defineTypeMail,defineObjet,  defineAttachments} from '../state/redux';

import { getallformules } from './calculstarifs';
import { handlephone } from './phone';
import { champsobligatoiresignature_client } from './leadlistechamps';
import { searchrdvleadgooglecalendar } from './actions_calendar';
import { memoenfantsfromlead } from './memoenfants';

export const popup= (div_id)=>{
        document.getElementById(div_id).classList.remove('no');document.getElementById(div_id).classList.add('yes');
        setTimeout(()=>{
            document.getElementById(div_id)?.classList?.remove('yes'); document.getElementById(div_id)?.classList?.add('no');
        },3000)
    }


export const alertpbcontrat = (nom,prenom,email)=>{
    document.getElementById('divalertcontrattext').innerHTML =`<span>${nom}  ${prenom}</span>   <br/>  ${email}</>` ; 
    setTimeout(()=>{   document.getElementById('divalertcontrat').classList.remove('divalertnone')},200);
    setTimeout(()=>{ document.getElementById('divalertcontrat').classList.add('divalertnone')},8000);  
}

export const modifyoneleadfront = (newlead)=>{

    const leads = store.getState().leads.leads;
    const othersleads = leads.filter(x=>x.id!==newlead.id);
   
    store.dispatch(modifyChampsLeadactu({...newlead}));
    store.dispatch(allLeads([...othersleads,newlead]));
}


export const modifyonelead = (champsleadchanged)=>{
    const lead = store.getState().lead.leadactu;
    const newlead = {...lead, ...champsleadchanged};

    const leads = store.getState().leads.leads;
    const othersleads = leads.filter(x=>x.id!==lead.id);
   
    store.dispatch(modifyChampsLeadactu({...newlead}));
    store.dispatch(allLeads([...othersleads,newlead])); 
    axios.post(  `/clients/updateclients`,{...newlead});


}

export const modifyoneleadechec = async(lead, champsleadchanged)=>{
    const leads = store.getState().leads.leads;
    const newlead = {...lead, ...champsleadchanged};

    const othersleads = leads.filter(x=>x.id!==lead.id);
    
     await  axios.post(  `/clients/updateclients`,{...newlead});
     batch( () => {
            store.dispatch(modifyChampsLeadactu({...champsleadchanged}));
            store.dispatch(allLeads([...othersleads])); });
}


export const  modifyleadformulaire = async(memofiche)=>{ 
    const leads = store.getState().leads.leads;
    
    const testchampssignature = champsobligatoiresignature_client(memofiche)?.every(x=> memofiche[x]!==null  &&  memofiche[x]!==undefined ) ; 
    const newlead =testchampssignature ?{...memofiche,'sign_coord':2 }: {...memofiche, 'sign_coord':0 } ;
    const othersleads = leads.filter(x=>x.id!==memofiche.id);
    store.dispatch(initialiseLeadactu({...newlead}));
    store.dispatch(allLeads([...othersleads,newlead]));

   await  axios.post(`/clients/updateclients`,{...newlead});
}





export const searchandinitialiseclient = async(idlead,phonenow)=>{
    store.dispatch(defineMessage(''));
    store.dispatch(defineTypeMail(''));
    store.dispatch(defineObjet(''));
    store.dispatch(defineAttachments([]));
    store.dispatch(modifyPopupmultitel(false))  ; 
    store.dispatch(modifyDisablebuttons(false)) ;
    store.dispatch(defineEcranclientselectleft('leftselect1')) ;
    store.dispatch(defineFormuleShow(null));
    const reqclient = await  axios.post(`/clients/searchclientbyid`,{'id' :idlead });
    if(!reqclient?.data?.id){ store.dispatch(modifyEcran('dashboard')); }
    store.dispatch(initialiseLeadactu({...reqclient.data})); 
    store.dispatch(initialiseLeadmemoConjoint({"civilite_conjoint":reqclient.data.civilite_conjoint, "nom_conjoint":reqclient.data.nom_conjoint, "prenom_conjoint":reqclient.data.prenom_conjoint, "regime_conjoint":reqclient.data.regime_conjoint, "date_naissance_conjoint":reqclient.data.date_naissance_conjoint, "numsecu_conjoint":reqclient.data.numsecu_conjoint, "organisme_conjoint":reqclient.data.organisme_conjoint}))
    memoenfantsfromlead(reqclient.data) ;
    let ev =await searchrdvleadgooglecalendar(idlead) ;
    batch( () => { store.dispatch(modifyDisplaymodal(null))  ; 
                    store.dispatch(getallformules());
                    store.dispatch(modifyEcran('ficheclient'))  ; 
                    store.dispatch(seteventtochange(ev))  ;
                phonenow===true && ((reqclient?.data?.telephone2!==undefined && reqclient?.data?.telephone2!==null ) ? store.dispatch(modifyPopupmultitel(true))   :   handlephone(reqclient?.data?.telephone) ) ; 
                });
}

export const phonefromlead =async (e,datas)=>{
    const lead =  datas.filter(x=>x.id === e.target.closest('tr').id)[0];
    const allids = datas.map(x=>x.id) ;
    const indexlead = allids?.indexOf(lead.id);
    store.dispatch(setFileattentes([...allids.filter((id,index)=>index>=indexlead)])) ;
    await searchandinitialiseclient(lead?.id,true) ;
}


export const demarrersequence = async(datas)=>{
    store.dispatch(setFileattentes([...datas.map(x=>x.id)]))
   await searchandinitialiseclient(datas?.[0]?.id,true) ;
}




export const phonefromleadinjoignables =async (e)=>{
    const allleads = store.getState().leads.leads;

    const leadinjoignables= allleads.filter(x=>x.statut_client?.includes('nrp')).sort((a,b)=>new Date(new Date(b.date_lastmodif) - new Date(a.date_lastmodif)));
    const lead =  leadinjoignables.filter(x=>x.id === e.target.closest('tr').id)[0]; 
    const listidall = leadinjoignables.map(x=>x.id);
    const indexlead = listidall.indexOf(lead.id);
    const listidafter = listidall.filter((x,i)=> i>=indexlead);
    store.dispatch(setFileattentes([...listidafter]))
    searchandinitialiseclient(lead?.id,true)

}


export const modifyhot =  (lead)=>{
    // DEV
    addEltHistory('qualiflead', `<div class='horoline1' >Qualification du prospect en <span className=horobold'>${lead.hot===0?'Lead Chaud':'Passage a froid'} </span>  </div>`)  

    const newlead = {...lead, hot: lead.hot===0?1:0};
    const leads = store.getState().leads.leads;
    const othersleads = leads.filter(x=>x.id!==lead.id);

    store.dispatch(modifyChampsLeadactu({...newlead}));
    store.dispatch(allLeads([...othersleads,newlead]));
    axios.post(  `/clients/updateclients`,{...newlead});
}


