import React , { useState}  from "react" ;
import axios from "axios";

import { useUser } from "../../../state/reactquerycabinets";
import { queryClient } from '../../../state/queryClient';


import { changeGrouperoleAuth } from "../utils";
 
import './TabloCompagnieGammes.scss';

const ROLES = ['COMMERCIAL','MANAGER','GESTION','ADMIN'];
const classNames = require('classnames');

function GammeTarif({index,nom_compagnie ,nom_gamme,type_tarif,appellation,gamme_mini , compagnie_mini, actuauths , setActuauths}){
     const {data: user} = useUser(localStorage.getItem('token'))  ;

     const [libelle , setLibelle] = useState(appellation)  ;

     const changegreoupeauth = async(ROLE)=>{      
          const newauths = await changeGrouperoleAuth(actuauths,compagnie_mini,gamme_mini,type_tarif,ROLE) ;
          setActuauths(newauths);
          queryClient.invalidateQueries({ queryKey: ['courtier']})  ;

     }

     const registerappellation=async()=>{
     let copyauths=  {...actuauths} ;
     copyauths[compagnie_mini]['gammes'][gamme_mini]['tarifs'][type_tarif]['appellation']= libelle ;
     setActuauths(copyauths);
     await axios.post(`/cabinetscompagnies/updateauthcompagnies_courtier`,{'courtier':user?.['courtier'] , 'autorisation':copyauths  },{headers:{'Accept':'application/json','Content-Type':'application/json'} }); 
     queryClient.invalidateQueries({ queryKey: ['courtier']})  ;

     }

     
     const authrole = (ROLE)=> actuauths[compagnie_mini]['gammes']?.[gamme_mini]['tarifs'][type_tarif]['autorisations']?.[ROLE]  ;
     
     return(<>{actuauths?<div className="tablogammes_tablo_line" >
                              <div>{index===0 && nom_compagnie}</div>
                              <div>{index===0 && nom_gamme}</div>
                              <div>{type_tarif}</div>
                              <div>
                                   <input type='text' className="tablogammes_tablo_input"
                                              value={libelle}  
                                              onChange={(e)=>{setLibelle(e.target.value)}} 
                                              onBlur={registerappellation} />
                              </div>
                              {ROLES.map(ROLE=>(<div className={classNames({"isselected":authrole(ROLE)===1}, {"noselected":authrole(ROLE)!==1})}
                                                     onClick={()=>{changegreoupeauth(ROLE)}}>
                                                </div>))} 
                 </div>:<></>}</>);




}

export default GammeTarif;



