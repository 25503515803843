import React from "react";


import './ButtonStyle.scss' ;
function ButtonImgTop({texte, type,onclick}){

    return(<div className={"boutonbase boutonimgtop "+type }  onClick={onclick}  >  <div className="interieurbouton" >  {texte}</div></div>)
}


export default ButtonImgTop