import React , {useState}  from "react";
import axios from "axios";


import { queryClient } from '../../../state/queryClient';
import { useEquipes , useUser , useUsers } from "../../../state/reactquerycabinets";


import ButtonSimple from "../../Buttons/ButtonSimple";


import './AjoutUtilisateur.scss' ;

const listeattributs ={'Conseiller':'COMMERCIAL','Manager':'MANAGER','Gestion':'GESTION' ,'Admin':'ADMIN' }
const classNames = require('classnames');
function ModifyUtilisateur({setShowmodifuser , utilisateur}){
    const {data:user} = useUser(localStorage.getItem('token'))  ;
    const {data:equipes} = useEquipes(user?.courtier)  ;
    const {data:users} = useUsers(user?.courtier) ;
    const listeidmanagers = users?.filter(u=>u.role ==='MANAGER')?.map(x=>x.id) ;

    const [newuser, setNewuser] = useState(utilisateur) ;

    const [id_equipe, setId_equipe] = useState(null)  ; 


    const handlechange = (e)=>{    setNewuser({...newuser, [e.target.id]: e.target.value})};

    const annuler = ()=>{setShowmodifuser(false)};

    const modifier =async ()=>{
        const miniprenom =  newuser.firstname?.toLowerCase()?.replace(/\s/g,'').replace(/([éèëê])/g,"e").replace(/([ô])/g,"o") ; 
        const newuser_complete ={...newuser,'nom':miniprenom ,'postmail':miniprenom+'.'+(user?.courtier)+'@mutuello.email'   }
        await axios.post(`/cabinets/modifyuser/`,{'user':newuser_complete,id_equipe});
        queryClient.invalidateQueries({ queryKey: ['equipes', user?.courtier]})  ;
        queryClient.invalidateQueries({ queryKey: ['users', user?.courtier]})  ;
        queryClient.invalidateQueries({ queryKey: ['equipes_managers',listeidmanagers]})  ;
        setShowmodifuser(false)
    };

    const toogleattribut = (att)=>{
        let copynewuser ={...newuser,'role':listeattributs[att]} ;
        // Object.keys(listeattributs)?.filter(r=>r!==att)?.map(x=>listeattributs[x]).forEach(r=>copynewuser[r]=0)
        setNewuser(copynewuser);                         
    }

    const grpattribut = (att)=>(<div key={att} className="utilisateurajoutmodif_role" > 
                                    <div className={classNames({"ismanager":newuser['role']===listeattributs[att]},{"notmanager":newuser['role']!==listeattributs[att]})} onClick={()=>toogleattribut(att)}></div  >
                                    <div className="utilisateurajoutmodif_role_texte"  > {att}</div>
                                </div> );
    
    const defineequipe = (e)=>{ setNewuser({...newuser,'id_equipe':parseInt(e.target.value)});  }

    const defineequipemanager = (e)=>{ setId_equipe(e.target.value);  }
    
    const changeshowinfos = ()=>{setNewuser({...newuser,'showinfos':newuser.showinfos===0?1:0});   }
    const changeshoworigine= ()=>{setNewuser({...newuser,'showorigine':newuser.showorigine===0?1:0});   }
    const changeshowgestion = ()=>{setNewuser({...newuser,'showgestion':newuser.showgestion===0?1:0});   }

    return(<div className="containergray">
             <div  className="utilisateurajoutmodif parametresmodal">
             <div className="equipeajoutmodif_titre mb30">Modifier un utilisateur</div>
             <div className="equipeajoutmodif_soustitre">Détails de l’utilisateur</div>
                <div className="groupeinput">
                            <div className="containerlabelinput" >
                                <div className="inputtextelabel">Nom</div>
                                <input id='lastname' type="text" onChange={handlechange} value={newuser.lastname} />
                            </div>
                            <div className="containerlabelinput" >
                                <div className="inputtextelabel">Prénom</div>
                                <input id='firstname' type="text"  onChange={handlechange} value={newuser.firstname} />
                            </div>
                            <div className="containerlabelinput" >
                                <div className="inputtextelabel">Email</div>
                                <input id='email' type="text"  onChange={handlechange} value={newuser.email} />
                            </div>
                            <div className="containerlabelinput" >
                                <div className="inputtextelabel">Téléphone</div>
                                <input id='telephone' type="text"  onChange={handlechange} value={newuser.telephone} />
                            </div>
                    </div>          
            <div className="equipeajoutmodif_infosorigine" >
                <div className={classNames({'yes':newuser.showinfos===1} ,{'no':newuser.showinfos!==1} )} onClick={changeshowinfos}> Masq. des infos critiques </div>    
                <div className={classNames({'yes':newuser.showorigine===1} ,{'no':newuser.showorigine!==1} )} onClick={changeshoworigine}> Aff. origine des leads </div> 
                <div className={classNames({'yes':newuser.showgestion===1} ,{'no':newuser.showgestion!==1} )} onClick={changeshowgestion}> Aff. de la gestion </div> 
            </div>
             <div className="equipeajoutmodif_soustitre">Attributs</div>
             <div className="listeattibuts" >
             {grpattribut('Conseiller')}{grpattribut('Manager')}
             </div>
             <div className="listeselect">
             {newuser['role']==='COMMERCIAL' ? <select id='toto' value={newuser?.id_equipe||''} className='selectlisteequipes' onChange={defineequipe} >
                                                <option  value={null} >Equipe</option>
                                                {equipes?.map((y)=><option key={y.id}  value={y.id} >{y.nom}</option>)}
                                        </select>:<div  className='placevide' ></div>}

               {newuser['role']==='MANAGER'  ? <select id='toto'   value={newuser.equipe||'Sélectionner équipe'} className='selectlisteequipes' onChange={defineequipemanager} >
                                                    <option  value={null} >Sélectionner équipe</option>
                                                    {equipes?.map((y)=><option key={y.id} value={y.id}  >{y.nom}</option>)}
                                            </select>:<div  className='placevide' ></div>}
            </div>
            <div className="listeattibuts" >
             {grpattribut('Gestion')}{grpattribut('Admin')} 
            </div><br/><br/>
             <div className="groupeboutons">
                    <ButtonSimple  texte="Retour" containerbouton='fullbuton white'   color='bouton_color_blancgris  noborder' size='bouton_size_normal'  onclick={annuler}    />   
                    <ButtonSimple  texte="Modifier" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' disable={!newuser.firstname} onclick={modifier}  />    
             </div>
        </div>
    </div>)

}

export default ModifyUtilisateur  ;

