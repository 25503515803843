import React ,{useState } from "react";
import { useSelector , useDispatch} from "react-redux";

import { modifyDisplaymodal , defineEquipementchange} from "../../state/redux";

import ListeContrats from "../../Components/Contrats/ListeContrats";
import ListeEquipements from "../../Components/Contrats/ListeEquipements";
import ButtonSimple from "../../Components/Buttons/ButtonSimple";


import './InfosContrat.scss'

const classNames = require('classnames');
function InfosContrat(){
   const dispatch = useDispatch() ;

   const [choiceType, setChoiceType] = useState('sante')

   const clikchoicetype = (e)=>{setChoiceType(e.target.id)}

   const diplayformequipement = ()=>{dispatch(modifyDisplaymodal('mod_equipement'));dispatch(defineEquipementchange({}))}
    
    return ( <div id='infoscontrat' className='infoscontrat' >
                   <div className="infoscontrat_top" >
                         <div className="infoscontrat_top_choice" >
                            <div id='sante' onClick={clikchoicetype}  className={classNames({"selected":choiceType==='sante'})}  >Contrats santé</div>
                            <div id='equipement' onClick={clikchoicetype}  className={classNames({"selected":choiceType==='equipement'})} >Autres équipements</div>
                         </div>
                         <div>
                            <ButtonSimple  texte="Ajouter un équipement"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'   onclick={diplayformequipement}    disable={false}  />
                         </div>
                   </div>
                    <div id="listecontrats" className="listecontrats" >
                       {choiceType==='sante' ? <ListeContrats  /> : <ListeEquipements  />}
                    </div>
            </div>)
}



export default InfosContrat