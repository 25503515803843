import React from 'react';


import './PopupALertPbContrat.scss'
function PopupALertPbContrat(){

        return(<div id="divalertcontrat" className="divalertnone"  > 
                        <div  className="divalertcontrat" >
                                <div  className="divalertcontrat_top">Un contrat a change de statut</div>
                                <div  className="divalertcontrat_bottom"> 
                                        <div className="divalertcontrat_bottom_left"  id='divalertcontrattext'></div>
                                       </div>
                        </div>
                </div>)
}


export default PopupALertPbContrat;

