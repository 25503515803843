
import React from 'react';

import './Img.scss'
const classNames = require('classnames'); 
function Imgcolor(props){
    const orange = (props.color==='orange' && props.selected!==true);
    const gris = (props.color==='gris' && props.selected!==true);
    
    return (




<svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 447.9 447.9" >


<rect id="Rectangle_1484" x="32.6" y="51.1" className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true})}  width="339.2" height="354.4"/>
<path id="Tracé_707" className={classNames({'svgorange1':orange},{'svggris1':gris},{'svgcolor1_selected':props.selected===true})}   d="M281.6,333h-35.4v-86.3h-88.7V333h-35.4V135.5h35.4v84.9h88.6v-84.9h35.4V333H281.6z"/>
<path id="Tracé_708" className={classNames({'svgorange1':orange },{'svggris1':gris},{'svgcolor1_selected':props.selected===true})}  d="M372.1,413.2H31.6c-4-0.1-7.2-3.4-7.2-7.5l0,0V49.8c-0.1-4,3.1-7.4,7.1-7.5l0,0h340.7
	c4,0.1,7.2,3.4,7.2,7.4l0,0v355.9C379.4,409.8,376.2,413.1,372.1,413.2z M38.7,398.2H365v-341H38.7V398.2z"/>
				
</svg>


    )
}

export default Imgcolor 
