


export const   deleteauth=(copycoutierauth,compagnie_mini,gamme_mini,t)=>{

    delete copycoutierauth[compagnie_mini]?.['gammes']?.[gamme_mini]?.['tarifs'][t]  ;

    if(Object.keys(copycoutierauth[compagnie_mini]?.['gammes']?.[gamme_mini]['tarifs'])?.length===0){
      delete copycoutierauth[compagnie_mini]?.['gammes'][gamme_mini]; 
    }
    if(Object.keys(copycoutierauth[compagnie_mini]?.['gammes'])?.length===0){
        delete copycoutierauth[compagnie_mini]; 
      }

    return copycoutierauth ;
}


function getOrCreate(obj,key,defaultValue){
    if(!obj.hasOwnProperty(key)){ obj[key]=  defaultValue }
    return obj[key]
}

export const createauthnew=(nom_compagnie,nom_gamme,copycoutierauth,compagnie_mini,gamme_mini,t)=>{

    getOrCreate(
        getOrCreate(
            getOrCreate(
                getOrCreate(
                    getOrCreate(copycoutierauth,compagnie_mini,{'nom_compagnie':nom_compagnie}),
                'gammes',gamme_mini),
            'nom_gamme',nom_gamme),
        'tarifs',{}),
    t,{appellation:t?.toUpperCase(),autorisations: { ADMIN: 1, COMMERCIAL: 1, MANAGER: 1, GESTION: 1 }}) ;

    return copycoutierauth
}

export const createauth=(nom_compagnie,nom_gamme,copycoutierauth,compagnie_mini,gamme_mini,t)=>{

    const endjsonauth = {appellation:t?.toUpperCase(),autorisations: { ADMIN: 1, COMMERCIAL: 1, MANAGER: 1, GESTION: 1 }}


    if(!copycoutierauth[compagnie_mini]){
        copycoutierauth[compagnie_mini]={'nom_compagnie':nom_compagnie,'gammes':{[gamme_mini]:{'nom_gamme':nom_gamme,'tarifs':{[t]:endjsonauth }  }   }}
    }else if(!copycoutierauth[compagnie_mini]?.['gammes']){
        copycoutierauth[compagnie_mini]['gammes']  = {[gamme_mini] :{'nom_gamme':nom_gamme,'tarifs':{[t]:endjsonauth}}}
    }else if(!copycoutierauth[compagnie_mini]?.['gammes']?.[gamme_mini]){
        copycoutierauth[compagnie_mini]['gammes'][gamme_mini] = {'nom_gamme':nom_gamme,'tarifs':{[t]:endjsonauth}}
    }else if(!copycoutierauth[compagnie_mini]?.['gammes']?.[gamme_mini]?.['tarifs']){
        copycoutierauth[compagnie_mini]['gammes'][gamme_mini]['tarifs']={[t]:endjsonauth}
    }else{copycoutierauth[compagnie_mini]['gammes'][gamme_mini]['tarifs'][t]=endjsonauth;}

    return copycoutierauth
}

// {
//     "asaf": {
//         "gammes": {
//             "osalysv3": {
//                 "tarifs": {
//                     "normal": {
//                         "appellation": "NORMAL",
//                         "autorisations": {
//                             "ADMIN": 0,
//                             "GESTION": 0,
//                             "MANAGER": 0,
//                             "COMMERCIAL": 0
//                         }
//                     },
//                     "lowcost": {
//                         "appellation": "LOWCOST",
//                         "autorisations": {
//                             "ADMIN": 0,
//                             "GESTION": 0,
//                             "MANAGER": 0,
//                             "COMMERCIAL": 0
//                         }
//                     }
//                 },
//                 "nom_gamme": "Osalys V3"
//             }
//         },
//         "nom_compagnie": "ASAF AFPS"
//     },
//     "alptis": {
//         "gammes": {
//             "santeselect": {
//                 "tarifs": {
//                     "normal": {
//                         "appellation": "NORMAL",
//                         "autorisations": {
//                             "ADMIN": 0,
//                             "GESTION": 0,
//                             "MANAGER": 0,
//                             "COMMERCIAL": 0
//                         }
//                     }
//                 },
//                 "nom_gamme": "Santé Select"
//             }
//         },
//         "nom_compagnie": "Alptis"
//     },
//     "neoliane": {
//         "gammes": {
//             "tonik": {
//                 "tarifs": {
//                     "lineaire": {
//                         "appellation": "Ne la vendez pas ",
//                         "autorisations": {
//                             "ADMIN": 0,
//                             "GESTION": 0,
//                             "MANAGER": 0,
//                             "COMMERCIAL": 0
//                         }
//                     }
//                 },
//                 "nom_gamme": "Tonik"
//             },
//             "optima": {
//                 "tarifs": {
//                     "lowcost": {
//                         "appellation": "LOWCOST",
//                         "autorisations": {
//                             "ADMIN": 0,
//                             "GESTION": 0,
//                             "MANAGER": 0,
//                             "COMMERCIAL": 0
//                         }
//                     }
//                 },
//                 "nom_gamme": "Optima"
//             },
//             "altosante": {
//                 "tarifs": {
//                     "normal": {
//                         "appellation": "NORMAL",
//                         "autorisations": {
//                             "ADMIN": 0,
//                             "GESTION": 0,
//                             "MANAGER": 0,
//                             "COMMERCIAL": 0
//                         }
//                     }
//                 },
//                 "nom_gamme": "AltoSanté"
//             },
//             "performance": {
//                 "tarifs": {
//                     "normal": {
//                         "appellation": "NORMAL",
//                         "autorisations": {
//                             "ADMIN": 0,
//                             "GESTION": 0,
//                             "MANAGER": 0,
//                             "COMMERCIAL": 0
//                         }
//                     }
//                 },
//                 "nom_gamme": "Performance"
//             }
//         },
//         "nom_compagnie": "Néoliane"
//     },
//     "fma": {
//         "nom_compagnie": "FMA",
//         "gammes": {
//             "vitalia": {
//                 "nom_gamme": "Vitalia",
//                 "tarifs": {
//                     "normal": {
//                         "appellation": "NORMAL",
//                         "autorisations": {
//                             "ADMIN": 0,
//                             "COMMERCIAL": 0,
//                             "MANAGER": 0,
//                             "GESTION": 0
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }