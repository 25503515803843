import React , {useEffect, useState} from 'react';
import { useDispatch , useSelector} from 'react-redux';


import { modifyDisplaymodal } from '../state/redux';

import { searchclient  } from '../utils/axios_functions';
import { phonefromlead } from '../utils/actions_lead';

import TableLeads from './TabLeads/TableLeads';

import './Recherche.scss';
var filtre = /^[a-zA-Z -àâäéèêëïîôöùûüÿç]+$/;
function Recherche(){
  const dispatch = useDispatch();
  
  const clients= useSelector(state=>state.leads.leads);
  const [value, setValue ]= useState('');
  const [leadssearch,setLeadssearch]= useState([]);
  const recherche =async (value)=>{
    const leadsfound =await searchclient(value?.trim()) ;
    setLeadssearch([...leadsfound]) ;
  }

  const findclient =(client,val)=>{
    let liste_nom = client?.nom ? client?.nom?.toLowerCase()?.indexOf(val?.toLowerCase())!==-1 : false ; 
    let liste_prenom = client?.nom ? client?.prenom?.toLowerCase()?.indexOf(val?.toLowerCase())!==-1 : false ; 
    let liste_email = client?.email ? client?.email?.toLowerCase()?.indexOf(val?.toLowerCase())!==-1 : false;
    return (liste_nom|| liste_prenom||  liste_email ) 
  }  

  const handleChange = async(e)=>{ 
    setValue(e.target.value);
    if(e.target.value.length===0 ){setLeadssearch([]);
    }else if(e.target.value.length<4){
      setLeadssearch([...clients].filter(client=> findclient(client,e.target.value.trim())));
    }
  }
  
  const seachLead = async (e) => {
    if(!filtre.test(e.target.value)){return   }
    if (e.key === 'Enter') { recherche(e.target.value?.trim()) ;}
  }
  
    useEffect(()=>{ document.getElementById('inpusearch').focus() },[]);

    useEffect(()=>{
      if(value.length<4){return }
      const getData = setTimeout(() => {recherche(value?.trim());}, 2000);  
      return () => clearTimeout(getData)
  },[value]);

    return( <div className='search_container' >
                <div className='search_content' >
                    <div className='search_content_top'> 
                        <div> 
                            <input id="inpusearch" 
                                  type="text"
                                  value={value} 
                                  onChange={handleChange} 
                                   onKeyDown={seachLead} 
                                  />  
                        </div>
                        <div className='search_close' onClick={()=>{dispatch(modifyDisplaymodal(null))}} ></div>
                    </div>
                    <div className='tablesearch_container'>
                        <TableLeads datas={leadssearch} id="searchtable" titre="notitre" clicktel={phonefromlead}  />
                    </div>
                </div>
            </div> );
}


export default Recherche

