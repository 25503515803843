import React , {useState, useEffect , useRef} from "react";
import { useSelector } from 'react-redux';


import {  listejsonall} from "../../utils/leadlistechamps";
import { validedatenaissance} from "../../utils/validations";
import { dateconvert } from "../../utils/utils_dates";



import './DateNaissance.scss' ;

const classNames = require('classnames');
function DateNaissance({id,ecran }) {
      const elementRef = useRef();

      const fichememo  = useSelector(state=>state.lead.leadmemo);

      const [val, setVal] = useState(fichememo[listejsonall[id]['date_naissance']]?dateconvert(fichememo[listejsonall[id]['date_naissance']]):"")

      useEffect(()=>{
            setVal(dateconvert(fichememo[listejsonall[id]['date_naissance']]))
      },[fichememo[listejsonall[id]['date_naissance']]])

      const copytoclipboard =()=>{ 
            let date =  !(elementRef.current.value||elementRef.current.placeholder) ? "": 
                        (new Date(elementRef.current.value||elementRef.current.placeholder)).toLocaleDateString('fr-FR') ;
            navigator.clipboard.writeText(date);
      }

      const handblur =(e)=>{ validedatenaissance(e.target.value,id) ; }

      const changevalueinput =(e)=>{setVal(e.target.value)  ;}


      return(<div className="container_datedenaissanceinput" > 
                        <div className="datenaissance_label" >
                              Date de naissance
                              {ecran==='souscription'&&<span className='grouptitleinput_containerinput_labelobliged'>*</span>}
                        </div>
                        <input  type="date"  ref={elementRef}  className={classNames("datedenaissanceinput")}  disabled={ecran==='souscription' } 
                                pattern="d{2}/d{2}/d{4}"     onBlur={handblur} onChange={changevalueinput} value={val}  />
                        <div  className='logocopy' onClick={copytoclipboard}  ></div>
            </div>) ; 
}

export default DateNaissance


