
//import djerbaredux from "./utils/djerbaredux";
import {configureStore, createSlice }  from "@reduxjs/toolkit";





const calendarSlice = createSlice({
  name : "calendar" ,
  initialState : {eventtochange:{},listcalendarevents:[]},
  reducers :{seteventtochange: (state, action)=>{ return  {...state,eventtochange :action.payload} },
              allcalendarevents : (state, action)=>{return  {...state,listcalendarevents: action.payload} }, }
});

const leadSlice = createSlice({
    name : "lead" ,
    initialState :{  leadactu:{},leadmemo:{} , memoconjoint:{} ,memoenfants:{},  leadappelant :{}, qualiflead : true },
    reducers :{   initialiseLeadactu : (state, action)=>{ return  {...state,leadactu :action.payload} },

                  modifyChampsLeadactu : (state, action)=>{  return  {...state,leadactu :{...state.leadactu,...action.payload}} },

                  deleteChampsLeadactu : (state, action)=>{const copylead = {...state.leadactu};  action.payload.map(x =>  delete copylead[x]) ;
                                                         return {...state, leadactu : copylead}  },
                  
                  initialiseLeadappelant : (state, action)=>{  return  {...state,leadappelant :action.payload} },
                 
                  initialiseLeadmemo : (state, action)=>{ const leed =   {...action.payload};
                                  return  {...state,leadmemo :leed} },
                  
                  modifyChampsLeadmemo : (state, action)=>{  return  {...state,leadmemo :{...state.leadmemo,...action.payload}} },

                  deleteChampsLeadmemo : (state, action)=>{
                    const copylead = {...state.leadmemo}; action.payload.map(x => delete copylead[x]) ;
                    return {...state, leadmemo : copylead} }, 

                  initialiseLeadmemoConjoint : (state, action)=>{ const conjoint =   {...action.payload};
                                  Object.keys(conjoint).map(key=>(conjoint[key]==="" ||conjoint[key]===null )&& delete conjoint[key]   )     ;
                                return  {...state,memoconjoint :conjoint} },
                  
                  initialiseLeadMemoEnfants : (state, action)=>{ return  {...state,memoenfants :action.payload} },

                  modifyQualiflead: (state, action)=>{  return  {...state,qualiflead :action.payload} },
    }
});

export const leadsSlice = createSlice({
    name: "leads",
    initialState: { leads: []  , commerciaux:[] ,  fileattentes:[] , campagnes:[] , leadsfiltres :null ,choiceliste:"newleads" ,
                   filtrescom :{'status':null, 'interaction' :null ,'campagne':null , 'hot':0 ,'date1':(new Date((new Date()).setMonth((new Date()).getMonth()-6))).setHours(0,0,0),'date2':(new Date()).setHours(0,0,0)} ,
                   filtresmanager :{'selectonecom' : 'all'  ,'selectstatut': 'all' ,'date1':(new Date((new Date()).setDate(1))).setHours(0,0,0) ,'date2':(new Date()).setHours(23,59,0) , 'commerciauxffiches':[]}} ,
    reducers: {allLeads: (state, action) => { state.leads=action.payload   ; },
                defineCampagnes: (state, action) => { state.campagnes=action.payload   ; },
                addlead: (state, action) => {  state.leads=[...state.leads,action.payload] } ,
                deletelead :(state,action)=>{state.leads = state.leads.filter(x=>x.id!==action.payload)},
                setFileattentes : (state, action) => { state.fileattentes=action.payload   ; },
                setLeadsfiltres: (state, action) => { state.leadsfiltres=action.payload   ; },
                setFiltrescom: (state, action) => { state.filtrescom=action.payload   ; },
                setFiltresmanager: (state, action) => { state.filtresmanager=action.payload   ; },
                setChoiceliste: (state, action) => { state.choiceliste=action.payload   ; },
              }
});


  export const formulesSlice = createSlice({
    name: "formules",
    initialState: {corell:'',datas:[],formulesaffichees:[],allformulesshow:false,formuleshow:null,favorisshow:false, 
                    devisformules:[], listecompagnies:[], listecompagniesaffichees:[]},
    reducers: { setCorell: (state, action) => {state.corell = action.payload;},
                allFormules: (state, action) => {state.datas = action.payload;},
                defineFormuleShow: (state, action) => {state.formuleshow = action.payload;},
                defineFavorisShow: (state, action) => {state.favorisshow = action.payload;},
                defineAllFormulesShow: (state, action) => {state.allformulesshow = action.payload;},
     
                filterFormules: (state, action) => {
                  if(action.payload.type==='all'){   // 
                    return { ...state,formulesaffichees :  [...new Set(state.datas)].sort((a,b)=>a.prix-b.prix)};
                  }else if(action.payload.type==='liste'){  
                    return  { ...state,formulesaffichees:  [...new Set(state.datas)].filter(x=>action.payload.liste.indexOf(x.id)!==-1).sort((a,b)=>a.prix-b.prix)}
                  }else if(action.payload.type==='empty'){  
                  return  { ...state,formulesaffichees:  []}
                }
                },
                defineListeCompagnies: (state, action) => {state.listecompagnies = [...new Set(action.payload)];},
                defineListeCompagniesAffichees: (state, action) => {state.listecompagniesaffichees = action.payload;},

                defineDevisFormules: (state, action) => {state.devisformules = action.payload;},
                addDevisformule: (state, action) => {
                  state.devisformules = [...state.devisformules,action.payload];},
                deleteDevisformule: (state, action) => {state.devisformules = state.devisformules.filter(f=>f.id!==action.payload)   ;},
    }
  });

  
  const displaySlice = createSlice({
    name : "display" ,
    initialState : {screenvalidcontrat : false , popupmultitel :false, 
                    ecran:window.location.hostname==='localhost'?'ecrangestion':'dashboard',
                    disablebuttons : false,notifnew :{}  , displaycalleft:false,displaymodal : null ,
                    displaymodalcalendar : null ,
                    ecranclientselectleft :'leftselect1' , ecranclientshowsendmail:false}  , 
    reducers :{ modifyEcran: (state, action)=>{state.ecran= action.payload},
                modifyDisplaymodal: (state, action)=>{state.displaymodal = action.payload}, 
                modifyDisplaymodalCalendar: (state, action)=>{state.displaymodalcalendar = action.payload}, 
                modifyDisplaycalleft: (state, action)=>{state.displaycalleft = action.payload}, 
                modifyPopupmultitel: (state, action)=>{state.popupmultitel= action.payload},
                modifyDisablebuttons: (state, action)=>{state.disablebuttons= action.payload},
                defineNotifnew : (state, action)=>{state.notifnew = action.payload},
                defineEcranclientselectleft : (state, action)=>{state.ecranclientselectleft = action.payload},
                defineEcranclientshowsendmail : (state, action)=>{state.ecranclientshowsendmail = action.payload},
              }
  });


  const telSlice = createSlice({
    name : "tel" ,
    initialState :{ messagetel: '', etat:'normal' , ringfirst: false}, 
    reducers :{modifyMessagetel : (state, action)=>{state.messagetel = action.payload},
              modifyEtattel: (state, action)=>{state.etat = action.payload}  ,
              modifyRingfirst: (state, action)=>{state.ringfirst = action.payload}  ,
            } ,
              
  });


  const datagestionSlice = createSlice({
    name : "datagestion" ,
    initialState : {leads:[], datasleads:{} ,clients:[] , allstats :{}  ,  infoscourtier :{}  , dataformcourtier:{} },
    reducers :{  getAllLeads : (state, action)=>{ state.leads=action.payload },
                  getInfoscourtier : (state, action)=>{ state.infoscourtier=action.payload },
                  getDatasLeads : (state, action)=>{ state.datasleads=action.payload },
                  getAllClients : (state, action)=>{ state.clients=action.payload },
                  getAllStats: (state, action)=>{  return  {...state,allstats :{...action.payload}}} ,
                  changeDataformcourtier: (state, action)=>{ state.dataformcourtier=action.payload },
                
                }
});

const requeteSlice = createSlice({
  name: "firstrequest",
  initialState : {calendar: true,gleap: true} ,
  reducers: {defineCalendar : (state, action)=>{ state.calendar=action.payload }, 
              defineGleap : (state, action)=>{ state.gleap=action.payload }}
});

const contratchangeSlice = createSlice({
  name:"contratchange",
  initialState : {value:{}} ,
  reducers: {defineContratchange : (state, action)=>{  state.value=action.payload } }
}); 

const equipementchangeSlice = createSlice({
  name:"equipementchange",
  initialState : {value:{}} ,
  reducers: {defineEquipementchange : (state, action)=>{  state.value=action.payload } }
})



const comgestionSlice = createSlice({
  name:"comgestion",
  initialState : {value:{}} ,
  reducers: {defineComgestion: (state, action)=>{  state.value=action.payload } }
})

const emailSlice = createSlice({
  name:"email",
  initialState : {attachments:[],objet:'',message:'',typemail:'' } ,
  reducers: {defineAttachments: (state, action)=>{  state.attachments=action.payload } ,
            defineObjet: (state, action)=>{  state.objet=action.payload } ,
            defineMessage: (state, action)=>{  state.message=action.payload } ,
            defineTypeMail: (state, action)=>{  state.typemail=action.payload } ,
          }
});

const courtierSlice = createSlice({
  name:"courtier",
  initialState : {jsongammesbulletins:[]} ,
  reducers: {defineJsongammesbulletins: (state, action)=>{  state.jsongammesbulletins=action.payload } ,
          }
});

const initialcategories ={"choiceleadsrecus":[],"choicenontraites":[],"choicenrp":[],"choiceechec":[] ,
                          "choiceencours":[],"choicedevis":[],"choicecontratsenattente":[],"choicegagnes":[],
                          "choicefauxnumeros":[],"choicefauxnumerosdoublons":[]}
const initialfilters = {'origines':'allorigines','campagnes':'allcamps','commerciaux':[]}
const gestionSlice = createSlice({
  name : "gestion" ,
  initialState : {leads:[],  affLeads:[],  affLeads:[],affFxNum:[] , categories:initialcategories, filters:initialfilters  ,  fxnumeros:[],choiceliste:"choiceleadsrecus" ,typetablo:"gestion" , typedate :"date_origine" },
  reducers :{defineleads: (state, action)=>{ return  {...state,leads :action.payload} },
  defineaffLeads : (state, action)=>{return  {...state,affLeads: action.payload} }, 
  defineaffFxNum : (state, action)=>{return  {...state,affFxNum: action.payload} }, 
            defineCategories : (state, action)=>{return  {...state,categories: action.payload} }, 
            defineFilters : (state, action)=>{return  {...state,filters: action.payload} }, 
              definefxnumeros: (state, action)=>{ return  {...state,fxnumeros :action.payload} },
              definechoiceliste : (state, action)=>{return  {...state,choiceliste: action.payload} }, 
              definetypetablo : (state, action)=>{return  {...state,typetablo: action.payload} }, 
              definetypedate : (state, action)=>{return  {...state,typedate: action.payload} }, 
            }
});


export const {defineJsongammesbulletins} = courtierSlice.actions ;

export const {defineAttachments,defineObjet,defineMessage,defineTypeMail} = emailSlice.actions ;

export const {defineCalendar, defineGleap   } = requeteSlice.actions ;

export const {getAllLeads,getDatasLeads,getAllClients,getAllStats,  getInfoscourtier , changeDataformcourtier
             } = datagestionSlice.actions ;

export const { defineListedocs, deleteChampsLeadactu,modifyChampsLeadactu,   initialiseLeadappelant, 
                initialiseLeadactu,deleteChampsLeadmemo,modifyChampsLeadmemo,initialiseLeadmemo, 
                modifyQualiflead , initialiseLeadmemoConjoint ,initialiseLeadMemoEnfants }= leadSlice.actions;

export const {allLeads,defineCampagnes, addlead,deletelead,  setFileattentes , 
              setLeadsfiltres, setFiltrescom ,setChoiceliste ,setFiltresmanager}= leadsSlice.actions ;

export const {setCorell,allFormules,defineFormuleShow,filterFormules,
              defineListeCompagnies,defineListeCompagniesAffichees,
              defineDevisFormules,addDevisformule,deleteDevisformule,
              defineFavorisShow , defineAllFormulesShow} = formulesSlice.actions;

 export const {allcalendarevents, seteventtochange } = calendarSlice.actions;

 export const {modifyEcran ,
                modifyPopupmultitel, 
               modifyDisablebuttons , 
               defineNotifnew ,  
                modifyDisplaycalleft , 
                 modifyDisplaymodal ,
                 modifyDisplaymodalCalendar,
                 defineEcranclientselectleft,
                 defineEcranclientshowsendmail} = displaySlice.actions;

 export const { modifyMessagetel  , modifyEtattel ,modifyRingfirst } = telSlice.actions;

 export const {defineContratchange} = contratchangeSlice.actions;
 export const {defineEquipementchange} = equipementchangeSlice.actions;
 
 export const {defineComgestion} = comgestionSlice.actions;

 export const {defineleads, defineaffLeads , definefxnumeros , definechoiceliste,
  definetypetablo,definetypedate , defineCategories , defineFilters , defineaffFxNum} = gestionSlice.actions;

export const store = configureStore({
    reducer :{   gestion: gestionSlice.reducer,
                email: emailSlice.reducer,
                lead : leadSlice.reducer ,
                leads : leadsSlice.reducer  , 
                formules : formulesSlice.reducer , 
                display : displaySlice.reducer,
                tel : telSlice.reducer,
                datagestion:datagestionSlice.reducer ,
                calendar:calendarSlice.reducer  ,  
                firstrequest : requeteSlice.reducer,
                contratchange: contratchangeSlice.reducer,
                equipementchange: equipementchangeSlice.reducer,
                comgestion: comgestionSlice.reducer,
                courtier: courtierSlice.reducer,
} ,
    middleware:  (getDefaultMiddleware) =>  getDefaultMiddleware({serializableCheck: false }), 
})