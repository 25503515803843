import React  from "react";



import { logocompagnie } from '../utils/logocompagnie';


import './FiltresCompagnies.scss'
const classNames = require('classnames');

function FiltresCompagnies({listenomscompagnies,listecompagnies,listecompagniesaffichees,clickboxcompagnies,clickCompagnie}){

     return( <div className="groupeimgcompagnies">
                   <div className={'casevalidall '+(listecompagniesaffichees?.length >0 ?'validate':'')}  onClick={clickboxcompagnies}></div>  
                   <div className="groupeimgcompagnies">
                   {[...listecompagnies]?.sort()?.map((comp)=>(<div className="container_imgcompagnies"  id={comp} key={comp}    onClick={clickCompagnie}> 
                                                       <div className={classNames('compagnievu',{'compagnievu_selected':listecompagniesaffichees.indexOf(comp)===-1})} ></div>
                                                       <img src={logocompagnie(listenomscompagnies?.[listecompagnies?.indexOf(comp)]?.toUpperCase())}  width='44' alt='logoCompagnie'/>
                                                 </div>)  )}

                   </div>

         </div>)
}


export default FiltresCompagnies;

