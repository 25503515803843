import React  from "react";
import { useDispatch , useSelector} from "react-redux";
import Draggable from 'react-draggable';

import {modifyEtattel   } from '../../state/redux';

import { deccrocher , rejectcall} from "../../utils/twilio";

import './Telephonie.scss'
 
function TelephonieDraggable(){
    const nodeRef = React.useRef(null);
    const dispatch = useDispatch();
    const leadappelant = useSelector(state=>state.lead.leadappelant);

    const { etat , messagetel} = useSelector(state=>state.tel);

    return( <Draggable nodeRef={nodeRef} >
                  <div className="telephoniedraggable" ref={nodeRef}>
                        <div className="telephonie">
                            <div className="telephonietextes" >
                                <div className="telephonienom" >{leadappelant.nom??''} {leadappelant.prenom??''}</div>
                                {messagetel  &&  <div className="telephonienom" >{messagetel}</div> }
                                <div className="telephoniestatus" >{etat === 'appel' ? 'Appel en cours' : etat === 'sonnerie' ? 'Appel rentrant' : 'En pause'}</div>
                            </div>
                         <div className="telephoniebtns" >
                            <div className="telephoniebtns_repondre" onClick={()=>{deccrocher();}} >Répondre</div>
                            <div className="telephoniebtns_reject" onClick={()=>{rejectcall();  dispatch(modifyEtattel('normal')) ;  }}  >Messagerie</div>
                        </div>  
                        </div> 
                    </div>
            </Draggable>)
}



export default TelephonieDraggable