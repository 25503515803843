
import axios from "axios";


import { queryClient }  from '../state/queryClient';

import meilleurassu from '../images/origines/meilleurassu.svg';
import gads from '../images/origines/gads.svg';
import fb from '../images/origines/fb.svg';
import crm from '../images/origines/crm.png';
import inconnu from '../images/origines/inconnu.png';
import recyclage from '../images/recyclage.svg';
import comparadise from '../images/origines/comparadise.png';



export const imagesorigine=(origine) =>{
    return origine ==="meilleureassurance.com"?meilleurassu:
            origine === "fb"?fb:
            origine === "gads"? gads :
            origine ===  "crm"?crm :
            origine ===  "comparadise"?comparadise :
            origine === "recyclage"?recyclage : inconnu}

export const champsgestion = {'gestion_remboursement' :  'Pour un remboursement en attente ou une demande de prise en charge'  , 
                                'gestion_impaye' :  'Pour une cotisation impayée',   
                                'gestion_questioncontrat' :  'Pour une question sur votre contrat',   
                                'gestion_changecontrat' :  'Pour changer de contrat',   
                                'gestion_parrainage' :  'Pour parrainer un ami',   
                                'gestion_autre' :  'Pour toute autre demande',   
                                'gestion_partenaire' :  'Vous êtes partenaire ou fournisseur',   
                                'gestion_redirectionleadnoseller' :  'Redirection nouveau lead non rattaché à un vendeur',   
                                'gestion_redirectionclientnoseller' :  'Redirection client dont le vendeur n’est pas identifié'}
                             
                
export const  ordergroupe = (groupe)=>{

    let ordgroupe = [];
    let admin = groupe.filter(x=>x.role === "ADMIN" &&  x.DEV!==1 )[0]
    let managers = groupe.filter(x=>x['role']==='MANAGER').sort(x=>x.prenom)
    let fakeusers = groupe.filter(x=>x['id']<0);

    for(let fakeuser of fakeusers){  ordgroupe.push(fakeuser);}

    ordgroupe.push(admin) ;
 
    groupe.filter(x=>x.id_manager===admin.id).forEach(x=>ordgroupe.push(x));


    for(let manager of managers){
          ordgroupe.push(manager);
          groupe.filter(x=>x.id_manager===manager.id).forEach(x=>ordgroupe.push(x));    }

    let others = groupe.filter(x=> !([...ordgroupe.map(y=>y.id)].includes(x.id)))

    others.forEach(x=>ordgroupe.push(x));

    return ordgroupe
}

    export   const deleteuser = (id) =>{
        try {axios.post(`/users/deleteuser`,{id:id}, {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}});
             queryClient.invalidateQueries({ queryKey: ['users']}) ;   }
        catch(err){console.log('ERR',err) ;}
    }


 export  const sendinfoscoutier = async (data) =>{
    try {
        await axios.post(`/courtier/sendinfoscoutier`,data, {headers: { 'Accept': 'application/json', "Content-Type": "multipart/form-data"} }) 
        queryClient.invalidateQueries({ queryKey: ['courtier']})
    }
    catch(err){console.log('ERR',err) ;}
}

export  const createcoutier = async (data) =>{
     try {await axios.post(`/courtier/create`,data, {headers: { 'Accept': 'application/json', "Content-Type": "multipart/form-data"} }) ;
          }
     catch(err){console.log('ERR',err) ;}
     queryClient.invalidateQueries({ queryKey: ['courtiers']})
 }
 



