import axios from "axios";
import { batch } from 'react-redux' ;

import { queryClient } from '../state/queryClient';
 

import { store, setCorell ,allFormules,filterFormules,  defineListeCompagnies,
         defineListeCompagniesAffichees , modifyDisablebuttons , defineDevisFormules} from "../state/redux";
import { registerdevisfromformules } from "./utils_devis";
import { radintarifs } from "./radin";

import { dateeffetavanttoday , dateconvert  } from "./utils_dates";

const verifages =(lead,age_max_adulte,age_min_adulte,age_max_enfant)=>{  
    for(let i=0; i<listedatesadultes(lead).length ; i++){
        if(calculage(lead[listedatesadultes(lead)[i]],lead['date_effet']) > age_max_adulte){return false}
        if(calculage(lead[listedatesadultes(lead)[i]],lead['date_effet']) < age_min_adulte){return false}
    }
    for(let i=0; i<listedatesenfants(lead).length ; i++){ if(calculage(lead[listedatesenfants(lead)[i]],lead['date_effet']) > age_max_enfant){return false}}
    return true
}


const calculage = (date_naissance,date_effet)=>{
    return Math.abs(new Date(new Date(dateconvert(date_effet))-new Date(dateconvert(date_naissance)).getTime()).getUTCFullYear()-1970);
}

const listedatesadultes =(lead)=>{return ['date_naissance','date_naissance_conjoint'].filter(x=>lead[x]!==undefined && lead[x]!==null);  }
const listedatesenfants=(lead)=>{return ['date_naissance_enf1','date_naissance_enf2','date_naissance_enf3','date_naissance_enf4','date_naissance_enf5'].filter(x=>lead[x]!==undefined  && lead[x]!==null);}


async function gettarifsfromcompagnie(dispatch,getState,compagnie_mini,lead,corell){

    if(!verifages(lead,120,18,26) ){ return [] }

    try { const response = await axios({method:'POST', 'url' : `/${compagnie_mini}/calcul`,data:{...lead  }  , 'Authorization': `Bearer ${localStorage.getItem('token')}` });
   
      if( response.data?.length=== 0 || corell!==getState().formules.corell){   return   }
     const formulesactus = [...getState().formules.datas] ;
     const nom_compagnie = response.data?.[0]?.['compagnie']  ;

      batch( () => {  dispatch(allFormules([...formulesactus, ...response.data?.filter(x=>x.prix!==0 && x.prix!==undefined && x.prix!==null )]));
                      dispatch(filterFormules({'type':'all'})); 
                      const listecompagnies = store.getState().formules.listecompagnies;
                      dispatch(defineListeCompagnies([...listecompagnies,nom_compagnie].sort()));
                      const formules_actus_noms = store.getState().formules.listecompagniesaffichees ;
                      dispatch(defineListeCompagniesAffichees([...formules_actus_noms,nom_compagnie].sort()));
            });
    } catch (err) {  return [] }

}

export const getallformules = (myvar) =>  async(dispatch,getState) => {  
    const lead = {...getState().lead.leadactu} ;
    const user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
    batch(()=> {  dispatch(defineDevisFormules([])); 
                    dispatch(allFormules([])); 
                    dispatch(filterFormules({'type':'empty'})); 
                    dispatch(defineListeCompagnies([])) ; 
                    dispatch(defineListeCompagniesAffichees([])) ;  });  
            
    if(!lead.date_naissance || !lead.date_effet || !lead.num_departement || isNaN(new Date(dateconvert(lead.date_effet))) || dateeffetavanttoday(lead.date_effet)){return []}

    const corell = Math.random() ;

     dispatch(setCorell(corell)) ; 
     dispatch(modifyDisablebuttons(true)) ;
     setTimeout(()=>{dispatch(modifyDisablebuttons(false))}, 6000)  ;
     if(user?.showtarifs===0){radintarifs() ; return }

    await Promise.all(user.compagnies?.map((compagnie_mini)=>gettarifsfromcompagnie(dispatch,getState, compagnie_mini,lead,corell) ));
    dispatch(modifyDisablebuttons(false)) ;
    const formules = store.getState().formules.datas ;
    const ids_formules= formules.map(x=>x.id?.toString());
    const devis= queryClient.getQueryData(['devis',lead.id]);
    const mesdevisid = [devis?.id_1,devis?.id_2,devis?.id_3].filter(x=>x!==undefined && x!==null)?.filter(x=>ids_formules.indexOf(x)!==-1);   

    console.log('myvar' , myvar) ; 
    if(myvar==='recalcul'){
        console.log(myvar)
        const devisformules=formules.filter(f=>mesdevisid?.includes(f.id)) ; 
        registerdevisfromformules(lead,devisformules);
        dispatch(defineDevisFormules(devisformules));
    }






 };

