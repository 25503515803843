import React from "react";
import axios from "axios";

import { queryClient } from '../../../state/queryClient';
import {useUser,useUsers } from "../../../state/reactquerycabinets";

import ButtonSimple from "../../../Components/Buttons/ButtonSimple";

import './AlertePopupDelete.scss'
function AlertePopupDelete({equipe, setShowavert}){
    const {data:user} = useUser(localStorage.getItem('token'))  ;
    const {data:users} = useUsers(user?.courtier) ;
    const listeidmanagers = users?.filter(u=>u.role==='MANAGER')?.map(x=>x.id) ;

    const annuler = ()=>{setShowavert(false)}

    const deleteequipe = async()=>{
        await axios.post(`/cabinets/deleteequipe/`,{'id':equipe.id});
        queryClient.invalidateQueries({ queryKey: ['equipes', user?.courtier]})  ;
        queryClient.invalidateQueries({ queryKey: ['equipes_managers',listeidmanagers]})  ;
        setShowavert(false) ; 
    }
   
  
    return (<div  className="alertepopupdelete">
        <div>Êtes vous bien sûr ?</div><div> Vous vous apprêtez à supprimer </div><div>l’equipe de <span>{equipe?.nom}</span></div>
        <div>
            <ButtonSimple  texte="Annuler" containerbouton='fullbuton white' color='bouton_color_blancgris noborder' size='bouton_size_normal' onclick={annuler}    />   
            <ButtonSimple  texte="Confirmer"  containerbouton='fullbuton' color='bouton_color_orange' size='bouton_size_normal' onclick={deleteequipe}   />      
        </div>


    </div>)
}


export default AlertePopupDelete