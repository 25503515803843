import React , {useState , useEffect } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';


import { sendinfoscoutier ,createcoutier} from '../../../utils/utils_courtier';
import { useCourtier } from '../../../state/reactquerycabinets';
import { changeDataformcourtier } from '../../../state/redux';


import UploadFile from './UploadFile';
import ButtonSimple from '../../../Components/Buttons/ButtonSimple';



import './FormCourtier.scss';
const classNames = require('classnames');

function FormCourtier({courtier}){
   const dispatch = useDispatch()  ; 

    const  dataformcourtier=  useSelector(state=>state.datagestion.dataformcourtier);

    const [images,setImages] = useState({})
    const disable = false ; 

    useEffect(()=>{dispatch(changeDataformcourtier(courtier)) },[courtier])

    const changedata = (e)=>{ dispatch(changeDataformcourtier({...dataformcourtier, [e.target.id]: e.target.value}))   }
  
    const  enregistre = ()=>{  
        const copydataformcourtier ={...dataformcourtier}
    //    setTimeout(()=>{   document.getElementById('popupdatacourtiersaved').classList.add('display')},200);
    //    setTimeout(()=>{ document.getElementById('popupdatacourtiersaved').classList.remove('display')},3000);
    if(!dataformcourtier.denominationsociale && dataformcourtier.nomcommercial){copydataformcourtier['denominationsociale'] =dataformcourtier.nomcommercial }
    if(dataformcourtier.nomcommercial){copydataformcourtier['nom_cabinet'] =dataformcourtier.nomcommercial ;
                                        copydataformcourtier['nomdomaine'] =dataformcourtier.nomcommercial.replace(/([éèëê])/g,"e").replace(/([^a-zA-Z0-9])/g,"")?.toLowerCase(); }


        let  data= new FormData();
        Object.keys(copydataformcourtier).forEach(key=>{copydataformcourtier?.[key]  &&  data.append(key, copydataformcourtier?.[key]) }) ;


        for (let i = 0; i < Object.keys(images).length; i++) {  
                if(Object.keys(images).length===0){ return }
                let image = Object.values(images)[i] ;
                let nameimage =  Object.keys(images)[i]+(nanoid(5)).toString() +'.'+image.name.split('.')[1] ;
                data.delete(Object.keys(images)[i]) ;
                data.append(Object.keys(images)[i],`https://${process.env.REACT_APP_AWS_NAMEBUCKET}.s3.eu-west-3.amazonaws.com/courtiers/logos/${copydataformcourtier['nomdomaine']}/${nameimage}`) ; 
                data.append("images", image, nameimage) ;
        }
        dataformcourtier.id_courtier ? sendinfoscoutier(data) : createcoutier(data); 
        setImages({});
    }
           
      return(<  div className='formcourtier'>

              {/* SECTION CABINET */}
              <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                        <div className="h1" >Votre cabinet</div>  
                    </div>  
                    <div className="coordonnes_section_right" >
                             <div className="paramslabel" >Nom commercial</div>
                             <input  id="nomcommercial" value={dataformcourtier?.nomcommercial||''} className="paramsinput"  onChange={changedata}   />
                             <div className="paramslabel" >Slogan</div>
                             <textarea  id="slogan" value={dataformcourtier?.slogan||''}    className="paramsinput ht68 "  onChange={changedata}   />
                             <div className="paramslabel" >Nom de domaine sur Mutuello</div>
                            <span className='nomdomaine' ><input  id="nomdomaine" value={dataformcourtier?.nomdomaine||''}   className={classNames("paramsinput paramsinput_disabled")}  onChange={changedata}   /></span>   
                   </div>  
              </div>
                {/* SECTION ADMINISTRATEUR */}
                <div className="coordonnes_section" >
                <div className="coordonnes_section_left" >
                        <div className="h1" >Administrateur</div>  
                        <div className="h2" >Utilisateur principal disposant de tous les accès</div>
                    </div>  
                    <div className="coordonnes_section_right" >
                        <div className="paramslabel wd249" >Nom</div>
                        <div className="paramslabel wd248" >Prénom</div>
                        <input  id="adminnom" value={dataformcourtier?.adminnom||''}   className="paramsinput wd249"  onChange={changedata}   />
                        <input  id="adminprenom" value={dataformcourtier?.adminprenom||''}  className="paramsinput wd248"  onChange={changedata}   />  
                        <div className="paramslabel" >Email</div>
                        <input  id="adminemail" value={dataformcourtier?.adminemail||''}  className="paramsinput "  onChange={changedata}   />
                   </div>  
              </div>
               {/* SECTION ADRESSE */}
              <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                        <div className="h1" >Siège social</div>  
                    </div>  
                    <div className="coordonnes_section_right" >
                        <div className="paramslabel" >Adresse</div>
                        <input  id="adresse" value={dataformcourtier?.adresse||''}   className="paramsinput"  onChange={changedata}   />
                        <div className="paramslabel" >Complément d’adresse</div>
                        <input  id="complementadresse" value={dataformcourtier?.complementadresse||''}  className="paramsinput "  onChange={changedata}   />
                        <div className="paramslabel wd180" >Code postal</div>
                        <div className="paramslabel wd316" >Ville</div>
                        <input  id="codepostal" value={dataformcourtier?.codepostal||''}  className="paramsinput wd180"  onChange={changedata}   />
                        <input  id="ville" value={dataformcourtier?.ville||''} className="paramsinput wd316"  onChange={changedata}   />          
                   </div>  
              </div>    
               {/* SECTION INFOS LEGALES */}
              <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                        <div className="h1" >Informations légales</div>  
                        <div className="h2" >Ces informations seront reprises sur les mentions légales ainsi que certains documents émis auprès des clients</div>
                    </div>  
                    <div className="coordonnes_section_right" >
                        <div className="paramslabel wd249" >Dénomination sociale</div>
                        <div className="paramslabel wd248" >Forme sociale (SAS, SARL...)</div>
                        <input  id="denominationsociale" value={dataformcourtier?.denominationsociale||''}   className="paramsinput wd249"  onChange={changedata}   />
                        <input  id="formesociale" value={dataformcourtier?.formesociale||''}  className="paramsinput wd248"  onChange={changedata}   />  

                        <div className="paramslabel wd249" >Montant du capital</div>
                        <div className="paramslabel wd248" >Code APE</div>
                        <input  id="capital" value={dataformcourtier?.capital||''}   className="paramsinput wd249"  onChange={changedata}   />
                        <input  id="codeape" value={dataformcourtier?.codeape||''}  className="paramsinput wd248"  onChange={changedata}   />
                        
                        
                        <div className="paramslabel wd249" >Numéro RCS</div>
                        <div className="paramslabel wd248" >Ville RCS</div>
                        <input  id="numrcs" value={dataformcourtier?.numrcs||''}   className="paramsinput wd249"  onChange={changedata}   />
                        <input  id="villercs" value={dataformcourtier?.villercs||''}   className="paramsinput wd248"  onChange={changedata}   />

                        <div className="paramslabel wd249" >Immatriculation ORIAS</div>
                        <div className="paramslabel wd248" >Numéro CNIL</div>
                        <input  id="orias" value={dataformcourtier?.orias||''} className="paramsinput wd249"  onChange={changedata}   />
                        <input  id="numcnil"  value={dataformcourtier?.numcnil??''}   className="paramsinput wd248"  onChange={changedata}   />

                        <div className="paramslabel wd249" >Compagnie RC Pro</div>
                        <div className="paramslabel wd248" >Numéro RC Pro</div>
                        <input  id="comprcpro"  value={dataformcourtier?.comprcpro||''}  className="paramsinput wd249"  onChange={changedata}   />
                        <input  id="numrcpro"  value={dataformcourtier?.numrcpro||''}  className="paramsinput wd248"  onChange={changedata}   />
                   </div>
              </div>
               {/* SECTION NUMERO RESPONSABLE PUBLICATION AVANT NUM CABINET */}
               <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                             <div className="h1" >Responsable de la publication</div>  
                             <div className="h2" >Cette information sera également affichée sur les mentions légales du site.</div> 
                    </div>  
                    <div  > <div style={{display:'flex'}}>      
                     <div className="paramslabel wd249" >Nom</div>
                        <div className="paramslabel wd248" > Prénom</div></div>
                        <div style={{display:'flex', marginBottom:'10px'}}>
                        <input  id="respcom_nom" value={dataformcourtier?.respcom_nom||''}  className="paramsinput wd249"  onChange={changedata}   />
                        <input  id="respcom_prenom" value={dataformcourtier?.respcom_prenom||''}   className="paramsinput wd248"  onChange={changedata}   />
                        </div>
                   </div>
              </div>
               {/* SECTION NUMERO CABINET */}
                  <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                             <div className="h1" >Numéro du cabinet</div>  
                             <div className="h2 mb10" >Dans l’idéal le numéro publié est le même que le numéro technique.</div>
                             <div className="h2" >S’assurer que le numéro publié soit redirigé vers le numéro technique ou qu’il soit porté chez Mutuello.</div>  
                    </div>  
                    <div  > <div style={{display:'flex'}}>      
                     <div className="paramslabel wd249" >Numéro publié</div>
                        <div className="paramslabel wd248" >Numéro technique</div></div>
                        <div style={{display:'flex', marginBottom:'10px'}}>
                        <input  id="numero_publie" value={dataformcourtier?.numero_publie||''}  className="paramsinput wd249"  onChange={changedata}   />
                        <input  id="numero_technique" value={dataformcourtier?.numero_technique||''}   className="paramsinput wd248"  onChange={changedata}   />
                        </div>
                   </div>
              </div>
                {/* SECTION COORDONNEES INTERNET */}
                <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                        <div className="h1" >Coordonnées Internet</div>  
                    </div>  
                    <div className="coordonnes_section_right" >
                             <div className="paramslabel" >Votre site internet</div>
                             <input  id="site_internet" value={dataformcourtier?.site_internet||''} className="paramsinput"  onChange={changedata}   />
                              <div className="paramslabel" >Email du cabinet</div>
                             <input  id="email_cabinet" value={dataformcourtier?.email_cabinet||''} className="paramsinput"  onChange={changedata}   />
   
                   </div>  
              </div>
             {/* SECTIONS LOGO */}
              <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                        <div className="h1" >Logo format horizontal</div>  
                        <div className="h2" >Sera utilisé dans les emails et dans vos documents. Privilégiez une image en .png transparent.</div>  
                    </div>  
                    <div className="coordonnes_section_right" >
                            <div className="divimage" >{dataformcourtier?.['logo'] &&   <img src={dataformcourtier?.['logo']}  width="150"  alt="imagelogo" />}</div>
                            <UploadFile typefile='logo'  images={images}  setImages={setImages}    />
                    </div>
              </div>
              <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                        <div className="h1" >Logo format carré</div>  
                        <div className="h2" >Sera utilisé dans les emails et dans vos documents. Privilégiez une image en .png transparent.</div>  
                    </div>  
                    <div className="coordonnes_section_right" >
                    <div className="divimage" >{dataformcourtier?.['minilogo'] &&  <img   src={dataformcourtier?.['minilogo']}   width="30"  height="30"   alt="imagelogo" />  }   </div>
                  
                            <UploadFile typefile='minilogo'  images={images}  setImages={setImages}    />
                    </div>
              </div>
               {/* SECTIONS PAIEMENT */}
                 {/* <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" >
                        <div className="h1" >Paiement</div>  
                        <div className="h2" >Paiement sécurisé avec Stripe</div>  
                    </div>  
                    <div className="coordonnes_section_right flexright" >
                        <ButtonSimple  texte="Procéder au paiement"  
                                        color='bouton_color_orange' 
                                        size='bouton_size_normal'  
                                        onclick={()=>{console.log('paiement')}}   />                    
                    </div>
                </div> */}

               {/* SECTIONS BOUTON CONFIRMER*/}
              <div className="coordonnes_section" >
                    <div className="coordonnes_section_left" ></div>  
                    <div className="coordonnes_section_right" >
                            <div className="divleftbutton" ></div>
                            <ButtonSimple  texte="Confirmer"   color={disable?'bouton_color_disable':'bouton_color_orange'} size='bouton_size_normal'  onclick={enregistre}   />       
                    </div>
              </div>
 
      </div>)
  }
  
  
  export default  FormCourtier

