import React  , {useEffect} from 'react';
import { useDispatch , useSelector} from 'react-redux';

import { defineComgestion } from "../../state/redux";



function ComGestionMail({setDisablecontinue}){

    const dispatch = useDispatch() ;
    const comgestion = useSelector(state=>state.comgestion.value) ;

    const testchamps = ['nonliste_titre','nonliste_message']?.every(champ=>![null, undefined,''].includes(comgestion[champ]?.trim())) ;

    useEffect(()=>{setDisablecontinue(!testchamps)},[testchamps])

    const handlechange = (e) =>{ dispatch(defineComgestion({...comgestion,[e.target.id]: e.target.value}))}

    return(<><input id='nonliste_titre' className='comgestion_messagetitre' placeholder='Objet' value={comgestion['nonliste_titre']}  onChange={handlechange}  />
            <textarea id='nonliste_message' placeholder='Message' className='comgestion_messagecontent' value={comgestion['nonliste_message']} onChange={handlechange}   /></>)
}


export default ComGestionMail 