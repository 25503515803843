import React from "react";


import './Jauge.scss'

const classNames = require('classnames');
function Jauge({nb}){

return ( <div className='containerjauge' > 

                {[1,2,3,4,5].map((x,i)=>{return  (<div key={x} 
                className={classNames('containtimagenivos_nivo',{'bleu': i>=5-nb})}  ></div>) })}


        </div> )
}

export default Jauge 



