import React ,{ useState , useEffect}from 'react';
import { useSelector , useDispatch} from 'react-redux';


import { queryClient } from '../../state/queryClient';
import {  addEltHistory } from '../../state/reactquery';
import { useUser  } from '../../state/reactquerycabinets';

import { defineEcranclientshowsendmail ,defineAttachments,defineObjet,defineMessage} from "../../state/redux";

import { sendemail } from '../../utils/email';
import { modifyonelead } from '../../utils/actions_lead';
import { valideemail } from '../../utils/validations';
import { actionenvoimaildevis } from '../../utils/actions_devis';

import ButtonSend from '../Buttons/ButtonSend';
import croixclose from '../../images/croixclose.svg'; 
import uploaddel from '../../images/uploaddel.svg'; 

import './SendMail.scss';

const classNames = require('classnames');
const max_size_attachements = 100000000 ;

function SendMail(){
    const dispatch  = useDispatch() ; 
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const leadactu  = useSelector(state=>state.lead.leadactu);
    // const devisformules = useSelector(state=>state.formules.devisformules) ;
    const {attachments,objet,message,typemail} = useSelector(state=>state.email);
    

    const [email2, setEmail2] = useState('') ;
    const [totalsize, settotalsize] = useState(0)
    const [loading , setLoading ] = useState(false);

    const disable = objet==='' || message==='' || totalsize>max_size_attachements || (email2.length >0 && !valideemail(email2)) ;

    useEffect(()=>{settotalsize([...attachments].map(x=>x.size).reduce((acc,x)=>acc+x,0))}, [attachments])

    const handleFileChange = (e) => {dispatch(defineAttachments([...attachments,...e.target.files]))};
    
  const envoyermessage = async () => {
      setLoading(true);
      typemail !== 'envoidevis' && await addEltHistory('email', `<div class='horoline1' > Email <span  className='horolink' >${objet} </span> envoyé. </div>`)
      const url = await sendemail({ lead: leadactu, email: leadactu.email, email2, objet, message, attachments });
      if (typemail === 'envoidevis') { actionenvoimaildevis(url) }
      setLoading(false);
      dispatch(defineAttachments([]));
      dispatch(defineObjet(''));
      dispatch(defineMessage(''));
      queryClient.invalidateQueries({ queryKey: ['clientmails', user['postmail'], leadactu.email] });
      queryClient.invalidateQueries({ queryKey: ['listmails'] });
      if (leadactu.statut_client === '' || leadactu.statut_client === undefined) { modifyonelead({ statut_client: 'contact' }); }

      dispatch(defineEcranclientshowsendmail(false));
  }

    const deletefilefromliste = (e)=>{ if(typemail==='envoidevis'){ return }
      dispatch(defineAttachments([...attachments].filter(x=>x.name!==e.target.id))) }
    
    return(<div className={classNames('sendmail_container')}   >
                    <div className='sendmail_top' > Nouveau Message à {leadactu.nom}  {leadactu.prenom}   
                              <span>({leadactu.email})</span>
                               <img   src={croixclose}  onClick={()=>{ dispatch(defineEcranclientshowsendmail(false))  }} alt="croixclose"   /> 
                    </div>
                    
                    <input  className='sendmail_input'    placeholder='Objet'  value={objet}  onChange={(e)=>{dispatch(defineObjet(e.target.value))}}   />
                    <input  className='sendmail_input'    placeholder="Copie" value={email2}  onChange={(e)=>{setEmail2(e.target.value)}}  />
                    <textarea className='sendmail_textarea'  value={message}  onChange={(e)=>{dispatch(defineMessage(e.target.value)) }}   />

                    <div className='sendmail_bottom'>
                              <div >
                            { totalsize>max_size_attachements &&  <div className='messagefilesbig' >Le fichier ne doit pas excéder 4mo</div>}
                                     <div className='containerlistenamefiles'>
                                    {[...attachments].map((x, index)=>(<div  key={index}  className='divfilenameinliste' >
                                                                     <div className='filenameinliste' id={x.name} key={x.name}   >{x.name}</div>
                                                                      <img id={x.name} className="uploaddel" src={uploaddel} alt='imgdel'  onClick={deletefilefromliste} />
                                                              </div>))}
                                    </div> 
                              </div>
                              <div className='sendmail_bottom_right'>  
                                    {/* <div className="attachements_jauge" ><div className={classNames("attachements_jauge_interne",{"error":totalsize>max_size_attachements})} style={{'width':totalsize>max_size_attachements?'70px':`${totalsize * 70 / max_size_attachements}px`}}></div></div>  */}
                                    <div className="inputmessageattachement_container">
                                        <input type="file"  disabled={typemail==='envoidevisXX'} multiple onChange={handleFileChange} />
                                    </div>
                                    <ButtonSend texte="Envoyer" 
                                                color={disable?'color_disable':'color_blue'}
                                                size='bouton_size_normal' 
                                                image='imagemail' 
                                                loading={loading}
                                                onclick={envoyermessage} /> 

                              </div>
                    </div>
              </div>)
}


export default SendMail