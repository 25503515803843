
import React ,  { useEffect, useState }   from "react" ;
import { useSelector , useDispatch} from "react-redux";

import { initialiseLeadmemo , modifyDisplaymodal } from "../../state/redux";


import ContratWinFormule from './ContratWinFormule';
import ContratWinDocsClient from './ContratWinDocsClient';
import ContraWinResiliation from './ContraWinResiliation';
import Formulaire from "../Formulaire/Formulaire";
import ContratWinFicContrat from "./ContratWinFicContrat";


import croixclose from '../../images/croixclose.svg';

import './ContratWin.scss';
const classNames = require('classnames');

const liste_etapes = {
     'sign_coord':{'name':'Coordonnées','component':<Formulaire ecran='souscription' />},
        'sign_formule':{'name':'Choix de la formule','component':<ContratWinFormule />},
        'sign_docs':{'name':'Documents','component':<ContratWinDocsClient />},
        'sign_ficbulletin':{'name':'Signature du bulletin et FIC','component':<ContratWinFicContrat />},
        'sign_resil':{'name':'Résiliation de l’ancien contrat','component':<ContraWinResiliation />}
}

function ContratWin(){
    const dispatch = useDispatch() ;
    const lead = useSelector(state=>state.lead.leadactu) ;
    const [etape, setEtape] = useState('sign_coord') ;

    const nbetapes = ['sign_coord','sign_formule','sign_docs','sign_bulletin'].filter(x=>lead[x]===2).length ;

    const defineetape = (e)=>{setEtape(e.target.id)}
    
    useEffect(()=>{ dispatch(initialiseLeadmemo(lead)) },[]) ; 

    const valideetape =(et)=>{

        return et!=='sign_ficbulletin'?  lead[et]===2 : (lead.sign_bulletin===2 && lead.sign_fic===2  )
    }
    return(<div className="containergray"> 
                <div   className="contratwin"  >
                        <div className="contratwin_top">
                            <div className="contratwinclose" ><img src={croixclose}  alt="imgclose" onClick={()=>{dispatch(modifyDisplaymodal(null))}}  /></div>
                            <div className="contratwin_top_texte">Souscrire un contrat</div>
                            <div  className="contratwin_top_avancement">
                                <div  style={{height:'100%',width: `${nbetapes*100/4}%`,background:'#FF8001'}} > </div>  
                            </div>
                        </div>
                        <div   className="contratwin_content"  >
                            <div   className="contratwin_left"  >
                                {Object.keys(liste_etapes).map(et=>(<div  className={classNames("contratwin_divchoice",{'choiced':etape===et})} key ={et} id ={et} data-testid={et} onClick={defineetape} >
                                                                            <div className={classNames("contratwin_divchoice_texte",{'underline':valideetape(et)})} >{liste_etapes[et]?.name}</div>
                                                                            {et==='sign_resil' && <div  className="contratwin_divchoice_optionnel" >Optionnel</div>  }
                                                                            <div className={classNames("contratwin_divchoice_valid",{'yes':valideetape(et)},{'no':!valideetape(et)  } )}  ></div> 
                                                                </div>))}
                            </div>
                            <div   className="contratwin_right"  > {liste_etapes?.[etape]?.['component']}  </div>
                        </div>
                        </div> 
             </div>);
                    
}

export default ContratWin ; 