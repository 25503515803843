import React, { useState , useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";


import { useUser, useEquipes , useUsers} from "../../state/reactquerycabinets";
import { defineleads,definefxnumeros,defineaffLeads,definechoiceliste,defineFilters, defineaffFxNum} from "../../state/redux";


import { useLeadsFxNumeros , useCommerciaux} from "../../state/reactquerygestion";
import { useEquipesManagers } from "../../state/reactquerycabinets";


import { filtersleads } from "../../utils/gestion";
import CalendarTwoPickers from "../../Components/Calendar/CalendarTwoPickers";

import './EcranGestionFiltersSearch.scss';

const dateactu = new Date() ;
const datedemain =  new Date(Date.now()+24*60*60*1000) ;
const diff = (new Date()).getDate() - (new Date()).getDay()  ;
const datedim = (new Date(new Date().setDate(diff))).setHours(0,0,0) ;
const datedimanche = new Date(datedim) ;
const datedimanchepassee = (new Date(datedim - 7*24*60*60*1000)) ;

function formadate(date){
    return date.getFullYear()+'-'+(date.getMonth()+1).toString().padStart(2,'0')+'-'+(date.getDate()).toString().padStart(2,'0');
}

function demain(madate){
    const datedemain =  new Date((new Date(madate)).getTime()+24*60*60*1000);
     return datedemain.getFullYear()+'-'+(datedemain.getMonth()+1).toString().padStart(2,'0')+'-'+(datedemain.getDate()).toString().padStart(2,'0');
}

function firstdaymonth(i){
    return dateactu.getFullYear()+'-'+(dateactu.getMonth()+i).toString().padStart(2,'0')+'-01';
}

const classNames = require('classnames');
function EcranGestionFiltersSearch(){
    const dispatch = useDispatch() ;

    const {typedate,leads ,fxnumeros, filters} = useSelector(state=>state.gestion);

    const [choicedate, setchoicedate] =  useState('today') ;
    const [choiceorigine, setchoiceorigine] =  useState('allorigines') ;
    const [choicecomp, setchoicecomp] =  useState('allcamps') ;
    const [choicegroupe, setchoicegroupe] =  useState('allcommerciaux') ;
    const [displaytwocalendars, setDisplaytwocalendars] =  useState(false) ;
    const [dates,setDates] =  useState({date1: null , date2:null}) ;
    const [gosearch,setGosearch] =  useState(false) ;
    const [origines, setOrigines] =  useState([]) ;
    const [showorigines, setShoworigines] =  useState(false) ;
    const [campagnes, setCampagnes] =  useState([]) ;
    const [showcampagnes, setShowcampagnes] =  useState(false) ;
    const [showcommerciaux, setShowcommerciaux] =  useState(false) ;
    const [showequipes, setShowequipes] =  useState(false) ;

   const {data:user} = useUser(localStorage.getItem('token'))  ;
   const {data:equipes} = useEquipes(user?.courtier)  ;
   const {data:users} = useUsers(user?.courtier) ;
   const {data:commerciaux, isLoading : isLoadingcommerciaux} = useCommerciaux() ;
   const listeidadminsmanagers = users?.filter(u=>u.role==='ADMIN' || u.role==='MANAGER')?.map(x=>x.id)||[] ;
   const {data:equipes_managers} = useEquipesManagers(listeidadminsmanagers)  ;
   
   const {data:todaydate_origine,isLoading:isLoadingtodaydate_origine} = useLeadsFxNumeros(formadate(dateactu),formadate(datedemain),'date_origine',!!commerciaux) ;
   const {data:lastweekdate_origine,isLoading:isLoadinglastweekdate_origine } = useLeadsFxNumeros(formadate(datedimanchepassee),formadate(datedimanche),'date_origine',choicedate==="lastweek" && typedate==='date_origine') ;
   const {data:thismonthdate_origine,isLoading:isLoadingthismonthdate_origine } = useLeadsFxNumeros(firstdaymonth(1),firstdaymonth(2),'date_origine',choicedate==="thismonth" && typedate==='date_origine') ;
   const {data:lastmonthdate_origine,isLoading: isLoadinglastmonthdate_origine} = useLeadsFxNumeros(firstdaymonth(0),firstdaymonth(1),'date_origine',choicedate==="lastmonth" && typedate==='date_origine') ;
   
   const {data:todaydate_lastmodif,isLoading: isLoadingtodaydate_lastmodif} = useLeadsFxNumeros(formadate(dateactu),formadate(datedemain),'date_lastmodif',choicedate==="today" && typedate==='date_lastmodif') ;
   const {data:lastweekdate_lastmodif,isLoading:isLoadinglastweekdate_lastmodif} = useLeadsFxNumeros(formadate(datedimanchepassee),formadate(datedimanche),'date_lastmodif',choicedate==="lastweek" && typedate==='date_lastmodif') ;
   const {data:thismonthdate_lastmodif,isLoading:isLoadingthismonthdate_lastmodif} = useLeadsFxNumeros(firstdaymonth(1),firstdaymonth(2),'date_lastmodif',choicedate==="thismonth" && typedate==='date_lastmodif') ;
   const {data:lastmonthdate_lastmodif,isLoading:isLoadinglastmonthdate_lastmodif} = useLeadsFxNumeros(firstdaymonth(0),firstdaymonth(1),'date_lastmodif',choicedate==="lastmonth" && typedate==='date_lastmodif') ;
   
   const {data:datasfromcalendar,isLoading:isLoadingdatasfromcalendar } = useLeadsFxNumeros(dates.date1,demain(dates.date2),typedate,gosearch) ;


    let jsonequipes ={} ;
    if(!!equipes && !!users && !!equipes_managers && !!equipes_managers){ 
        for(let eq of [...equipes]){
            let usersequipes = [...users].filter(x=>x.id_equipe === eq.id) ; 
            if(equipes_managers[eq.id]){
                const id_managers_equipe = Array.from(equipes_managers[eq.id]) ;
                users.filter(u=>id_managers_equipe?.includes(u.id))?.forEach(x=>usersequipes.push(x))  ;
                jsonequipes[eq.id] = [...usersequipes?.map(x=>x.nom)]
            
            }

    }}

    useEffect(()=>{
        if(isLoadingcommerciaux || isLoadingtodaydate_origine){ console.log('RETURN') ; return }

        dispatch(defineFilters({...filters, 'commerciaux': commerciaux})) ;
        const aff= filtersleads(todaydate_origine?.leads,{...filters, 'commerciaux': commerciaux}) ;
        dispatch(defineaffLeads(aff)); 
    },[isLoadingcommerciaux,isLoadingtodaydate_origine])



   const dataspreload = {todaydate_origine,lastweekdate_origine,thismonthdate_origine,lastmonthdate_origine,
                         todaydate_lastmodif,lastweekdate_lastmodif,thismonthdate_lastmodif,lastmonthdate_lastmodif}

   const isLoading = {todaydate_origine:isLoadingtodaydate_origine,lastweekdate_origine:isLoadinglastweekdate_origine,
                      thismonthdate_origine:isLoadingthismonthdate_origine,lastmonthdate_origine:isLoadinglastmonthdate_origine,
                      todaydate_lastmodif:isLoadingtodaydate_lastmodif,lastweekdate_lastmodif:isLoadinglastweekdate_lastmodif,
                       thismonthdate_lastmodif:isLoadingthismonthdate_lastmodif,lastmonthdate_lastmodif:isLoadinglastmonthdate_lastmodif}

    

    function handleresultquerry(result){
        dispatch(defineleads(result?.['leads']));
        dispatch(definefxnumeros(result?.['fauxnumeros']));
        setOrigines([...new Set(result?.['leads']?.map(x=>x.origine))]) ;
        setCampagnes([...new Set(result?.['leads']?.map(x=>x.campagne?.split('/')?.[0]?.trim()))]) ; 
        const aff= filtersleads(result?.['leads'],filters) ;
        const affFx= filtersleads(result?.['fauxnumeros'],filters) ;
        dispatch(defineaffFxNum(affFx)); 
        dispatch(defineaffLeads(aff)); 
        dispatch(definechoiceliste("choiceleadsrecus")) ;
        setDisplaytwocalendars(false) ; 
    }

    useEffect(()=>{(!isLoadingdatasfromcalendar && gosearch) && handleresultquerry(datasfromcalendar);},[gosearch,isLoadingdatasfromcalendar]);
   
    useEffect(()=>{
        (!isLoading[choicedate+typedate] && choicedate!=='choicefromcal') && handleresultquerry(dataspreload?.[choicedate+typedate]);
    },[isLoading[choicedate+typedate],typedate,choicedate,filters?.commerciaux])  ;


    const clickchoicedate = (e)=>{ 
        setchoicedate(e.target.id) ;
       e.target.id==='choicefromcal' && setDisplaytwocalendars(!displaytwocalendars)
    }  

    function changeaffleadsbyfilters(newfilters){
        const aff= filtersleads(leads,newfilters) ;
        dispatch(defineaffLeads(aff)); 
        const afffx= filtersleads(fxnumeros,newfilters) ;
        dispatch(defineaffFxNum(afffx)); 
 
        dispatch(defineFilters(newfilters)); 
    }

    const clickchoiceorigine = (e)=>{
        if(e.target.id==='allorigines'){changeaffleadsbyfilters({...filters,'origines':'allorigines'}) }
        setchoiceorigine(e.target.id) ; 
        e.target.id==='selectorigine' ? setShoworigines(!showorigines): setShoworigines(false)  ;
     } 

    const clickchoicecomp = (e)=>{ 
        if(e.target.id==='allcamps'){changeaffleadsbyfilters({...filters, 'campagnes':'allcamps'})   }
        setchoicecomp(e.target.id);  
        e.target.id==='selectcamp' ? setShowcampagnes(!showcampagnes)  : setShowcampagnes(false)  ;
    }  

    const clickchoicegroupe = (e)=>{
        setchoicegroupe(e.target.id);
        e.target.id==='allcommerciaux' && changeaffleadsbyfilters({...filters,'commerciaux':users.map(x=>x.nom)}) ;
        e.target.id==='selectcommercial' ? setShowcommerciaux(!showcommerciaux)  : setShowcommerciaux(false)  ;
        e.target.id==='selectequipe' ? setShowequipes(!showequipes)  : setShowequipes(false)  ;
    }  

    const filterbyorigine = (e)=>{ changeaffleadsbyfilters({...filters, 'origines':e.target.id}) ;setShoworigines(false) }  ;

    const filterbycampagne = (e)=>{changeaffleadsbyfilters({...filters,'campagnes':e.target.id}); setShowcampagnes(false) }  ;

    const filterbycommercial = (e)=>{changeaffleadsbyfilters({...filters,'commerciaux':[e.target.id]}); setShowcommerciaux(false) }  ;

    const filterbyequipe = (eq) =>{
        changeaffleadsbyfilters({...filters,'commerciaux':jsonequipes[eq.id]}); setShowequipes(false) }

    return(<div className="ecrangestionfilterssearch">    
        <div className="grpbtnstimes">
            <button id="today"  onClick={clickchoicedate} className={classNames("btnchoice",{'first':true},{'last':false},{'selected':choicedate==="today"})} >Ajourd.</button>
            <button id="lastweek" onClick={clickchoicedate} className={classNames("btnchoice",{'first':false},{'last':false},{'selected':choicedate==="lastweek"})} >Semaine Prec.</button>
            <button id="thismonth" onClick={clickchoicedate} className={classNames("btnchoice",{'first':false},{'last':false},{'selected':choicedate==="thismonth"})} >Ce mois-ci</button>
            <button id="lastmonth" onClick={clickchoicedate} className={classNames("btnchoice",{'first':false},{'last':false},{'selected':choicedate==="lastmonth"})} >Mois dern.</button>
            <button id="choicefromcal" onClick={clickchoicedate} className={classNames("btnchoice",{'first':false},{'last':true},{'selected':choicedate==="choicefromcal"})} >Choisir dates</button>
            {displaytwocalendars &&  <div className="containertwocalendars" >  <CalendarTwoPickers filtres={dates} setFiltres={setDates} setDisplaytwocalendars={setDisplaytwocalendars}   setGosearch={setGosearch} /> </div>   }
        </div>
        <div className="container_origines" >
           {showorigines && <div className="origines" >{origines.map(o=><div className="origine"   key={o} id={o} onClick={filterbyorigine}  >{o} </div> )}</div>}
            <button id="allorigines" onClick={clickchoiceorigine}  className={classNames("btnchoice",{'first':true},{'last':false},{'selected':choiceorigine==="allorigines"})} >Toutes orig.</button>
            <button id="selectorigine" onClick={clickchoiceorigine} className={classNames("btnchoice",{'first':false},{'last':true},{'selected':choiceorigine==="selectorigine"})} >{filters.origines==='allorigines' ?'Choisir orig.':filters.origines}</button>
        </div>
        <div className="container_origines" >
        {showcampagnes && <div className="origines" >{campagnes.map(o=><div className="origine"   key={o} id={o} onClick={filterbycampagne}  >{o} </div> )}</div>}
            <button id="allcamps" onClick={clickchoicecomp} className={classNames("btnchoice",{'first':true},{'last':false},{'selected':choicecomp==="allcamps"})} >Toutes camp.</button>
            <button id="selectcamp" onClick={clickchoicecomp}  className={classNames("btnchoice",{'first':false},{'last':true},{'selected':choicecomp==="selectcamp"})} > {filters.campagnes==='allcamps' ?'Choisir camp.':filters.campagnes}</button>                                                                                                                                
        </div>
        <div className="container_origines">
        {showcommerciaux && <div className="origines" >{[...commerciaux]?.sort().map(o=><div className="origine"   key={o} id={o} onClick={filterbycommercial}  >{o} </div> )}</div>}
        {showequipes && <div className="divequipes" >{equipes?.map(eq=><div className="origine"   key={eq.id} id={eq.id} onClick={()=>filterbyequipe(eq)}  >{eq.nom} </div> )}</div>}


{user?.role ==='MANAGER'?<>

    <button id="allcommerciaux"  onClick={clickchoicegroupe} className={classNames("btnchoice",{'first':true},{'last':false},{'selected':choicegroupe==="allcommerciaux"})} >Equipe</button>
            <button id="selectcommercial"   onClick={clickchoicegroupe}  className={classNames("btnchoice",{'first':false},{'last':true},{'selected':choicegroupe==="selectcommercial"})} >{filters.commerciaux?.length!==1?'Par conseiller':filters.commerciaux} </button>                                                               

</>:<><button id="allcommerciaux"  onClick={clickchoicegroupe} className={classNames("btnchoice",{'first':true},{'last':false},{'selected':choicegroupe==="allcommerciaux"})} >Tout le cabin.</button>
                    <button id="selectequipe"  onClick={clickchoicegroupe}  className={classNames("btnchoice",{'first':false},{'last':false},{'selected':choicegroupe==="selectequipe"})} >Par équipe</button>
                    <button id="selectcommercial"   onClick={clickchoicegroupe}  className={classNames("btnchoice",{'first':false},{'last':true},{'selected':choicegroupe==="selectcommercial"})} >{filters.commerciaux?.length!==1?'Par conseiller':filters.commerciaux} </button>                                                               </>}
                                                                                
        </div>
    </div>)
}

export default EcranGestionFiltersSearch ; 