import React , {useState} from "react";
import axios from "axios";
import { useSelector , useDispatch } from "react-redux";

import { defineleads , defineaffLeads} from "../../state/redux";
import { useCommerciaux } from "../../state/reactquerygestion";

import { filtersleads } from "../../utils/gestion";
import { modifyoneleadfront } from "../../utils/actions_lead";

import Boulettes from "../../Components/ContratWin/Boulettes";
import ButtonSimple from "../../Components/Buttons/ButtonSimple";

import croixclose from '../../images/croixclose.svg';


import './PopupReassigner.scss' ;

function PopupReassigner({setShowpopupreassigner,listidchangecom,setListidchange}){
    const dispatch = useDispatch() ;
    const {data:commerciaux} = useCommerciaux() ;
    const lead =  useSelector(state=>state.lead.leadactu);

    const {leads,affLeads,filters} = useSelector(state=>state.gestion);

    console.log('PopupReassigner',listidchangecom) ;  

    const [etape, setEtape] = useState(1) ;
    const [newcommercial, setNewcommercial] = useState(null) ;



    async  function  changeoneleadcommercial(idlead){
        await axios.post(`/clients/commercialchange/`,{idlead,commercial_new:newcommercial},{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }}
    );

        
        if(idlead===lead.id){
            modifyoneleadfront({...lead, commercial:newcommercial})
        }

    }

    const reattribution= () =>{
        listidchangecom.forEach((id)=>changeoneleadcommercial(id));
        const otherleads = leads.filter(x=>!listidchangecom.includes(x.id));
        const leadschange = leads.filter(x=>listidchangecom.includes(x.id)).map(x=>{return {...x,'commercial':newcommercial}})
        dispatch(defineleads([...otherleads,...leadschange]));
        const otherleadsaff = affLeads.filter(x=>!listidchangecom.includes(x.id));
        const leadschangeaff = affLeads.filter(x=>listidchangecom.includes(x.id)).map(x=>{return {...x,'commercial':newcommercial}}) ;
        const aff= filtersleads([...otherleadsaff,...leadschangeaff],filters) ;
        dispatch(defineaffLeads(aff)); 
        setListidchange([]) ;
        setShowpopupreassigner(null) ; 
        
    }







    const recule = ()=>{setEtape(1)}
    const avance = ()=>{ etape===1? setEtape(2) : reattribution() }

    const defineewcommercial = (e)=>{ e.target.value!=='Commercial'  && setNewcommercial(e.target.value) ;}

    return(<div  className="containergray">
               <div className="ecrangestionreassigner">
               <div className="cardresilitationclose" ><img src={croixclose}  alt="imgclose" onClick={()=> setShowpopupreassigner(null) }  /></div>

                <div className="ecrangestionreassigner_titre center" >Réaffectation</div>
                <Boulettes total={3} etape={etape} />
             { etape===1 ? <> 
                <div className="ecrangestionreassigner_titre left" >A qui souhaitez-vous réaffecter ces {listidchangecom?.length||0} leads ?</div>  

             <div className="ecrangestionreassigner_texte">Nom de l’utilisateur</div>
                <select     className='selectcom' onChange={defineewcommercial} >
                                        <option className='selectcom_option' value={undefined} >Commercial</option>
                                        {commerciaux?.map((x,i)=><option key={i}  value={x} >{x?.toUpperCase()}</option>)}
                 </select><br/><br/>
                <div className="ecrangestionreassigner_btns" >
                    <ButtonSimple  texte="Continuer" containerbouton='fullbutonX'  color='bouton_color_orange' size='bouton_size_normal' disable={newcommercial===null} onclick={avance}  />
                </div></>:<>
                <div className="ecrangestionreassigner_titre center"  >Souhaitez-vous réellement réaffecter ces {listidchangecom?.length||0} leads à {newcommercial?.toUpperCase()} ?</div>
                <div  className="ecrangestionreassigner_texte center" >Cette opération est irréversible</div><br/><br/>
                <div className="ecrangestionreassigner_btns" >
                    <ButtonSimple  texte="Retour"  color='bouton_color_blancgris' size='bouton_size_normal' disable={newcommercial===null} onclick={recule}  />
                    <ButtonSimple  texte="Confirmer"  color='bouton_color_orange' size='bouton_size_normal' disable={newcommercial===null} onclick={avance}  />
                </div></>}
               </div>
           </div>)
}


export default PopupReassigner