import axios from "axios";
import { queryClient } from '../state/queryClient';
import { store , defineAttachments} from "../state/redux";


export const registerdevisfromformules=async(lead,devisformules)=>{
    if(devisformules?.length===0){ return }
    const devis= queryClient.getQueryData(['devis',lead.id]);

    let datadevis = {'id_lead': lead.id, 'id_client': lead.id_client ,  
                    'id_s' :devis?.id_s, 'compagnie_s' :devis?.compagnie_s,
                    'gamme_s' :devis?.gamme_s, 'nom_formule_s':devis?.nom_formule_s,
                    'prix_s':devis?.prix_s } ;

    datadevis['id_1'] =devisformules[0]?.id; datadevis['compagnie_1'] =devisformules[0]?.compagnie ; 
    datadevis['gamme_1'] =devisformules[0]?.gamme; datadevis['nom_formule_1']=devisformules[0]?.nom_formule ; 
    datadevis['prix_1']=devisformules[0]?.prix ; 
    datadevis['id_2'] =devisformules[1]?.id; datadevis['compagnie_2'] =devisformules[1]?.compagnie ; 
    datadevis['gamme_2'] =devisformules[1]?.gamme; datadevis['nom_formule_2']=devisformules[1]?.nom_formule ; 
    datadevis['prix_2']=devisformules[1]?.prix ; 
    datadevis['id_3'] =devisformules[2]?.id; datadevis['compagnie_3'] =devisformules[2]?.compagnie ; 
    datadevis['gamme_3'] =devisformules[2]?.gamme; datadevis['nom_formule_3']=devisformules[2]?.nom_formule ;
    datadevis['prix_3']=devisformules[2]?.prix ; 

    await axios({ method: 'POST', url: `/devis/registerdevis`, data: {...datadevis}, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });

}

export const  devistoformules =(devis)=>{
    let mesformules =  [] ; 
    let forms ={id:devis.id_s,compagnie:devis.compagnie_s, gamme:devis.gamme_s,nom_formule:devis.nom_formule_s,prix:devis.prix_s }
    let form1 ={id:devis.id_1,compagnie:devis.compagnie_1, gamme:devis.gamme_1,nom_formule:devis.nom_formule_1,prix:devis.prix_1 }
    let form2 ={id:devis.id_2,compagnie:devis.compagnie_2, gamme:devis.gamme_2,nom_formule:devis.nom_formule_2,prix:devis.prix_2 }
    let form3 ={id:devis.id_3,compagnie:devis.compagnie_3, gamme:devis.gamme_3,nom_formule:devis.nom_formule_3,prix:devis.prix_3 }

    mesformules.push(forms)  ;

    (JSON.stringify(forms)!==JSON.stringify(form1)  && form1.compagnie) && mesformules.push(form1)  ;
    (JSON.stringify(forms)!==JSON.stringify(form2)  && form2.compagnie) && mesformules.push(form2)  ;
    (JSON.stringify(forms)!==JSON.stringify(form3)  && form3.compagnie && mesformules.length<3) && mesformules.push(form3)  ;

    return mesformules
}



export const downloaddevis =async (devisformules)=>{
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
    const lead = store.getState().lead.leadactu;
    const response = await  axios({url: '/devis/downloaddevis', 
        method: 'post',
        data:{user,lead,sol1:devisformules[0],sol2:devisformules[1],sol3:devisformules[2]},
       responseType:'blob',}) ;

       const pdfData= new Blob([response.data], {type:'application/pdf'}) ; 
       var file = new File([pdfData], `devis${(new Date()).getTime()}.pdf`,{type:'application/pdf'});
       store.dispatch(defineAttachments([file]));      
       return pdfData

}


