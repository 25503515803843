export const listmodes = {"mail":"Par email, envoyé par le client","nomandat":"En recommandé, envoyé par le client",
                   "mandat":"En recommandé, avec mandat"}

export const listmotifs = {'echeance':'A échéance','augmentation':'Augmentation','chatel':'Loi Châtel','modifrisque':'Modif. du risque',
                    'ria':'Infra-annuelle','retractation':'Rétractation'}

export const listchampsclient ={'nom_titulaire':'Nom du titulaire','prenom_titulaire':'Prénom du titulaire',
                                'numsecu_titulaire': 'Numéro de sécu du titulaire'}

export const listchampsadressecompagnie ={'compagnie':'Nom de la compagnie','adresse_compagnie':'Adresse de la compagnie', 'ville_compagnie':'Ville','complement_adresse_compagnie':"Complément d'adresse",
                                            'codepostal_compagnie':'Code postal'}

export const champemailcompagnie = {'compagnie':'Nom de la compagnie',"email_compagnie" : "Email de la compagnie"}

export const champs_augmentation={'num_anciencontrat':'N. Ancien Contrat','date_resile':'Date de résiliation'}

export const champs_echeance={'num_anciencontrat':'N. Ancien Contrat','date_resile':'Date de résiliation'}

export const champs_chatel={'num_anciencontrat':'N. Ancien Contrat','date_resile':'Date de résiliation'}

export const champs_ria={'num_anciencontrat':'N. Ancien Contrat'}

export const champs_retractation={'num_anciencontrat':'N. Ancien Contrat','date_signature':'Date de signature'}

export const champs_modifrisque={'num_anciencontrat':'N. Ancien Contrat','date_resile':'Date de résiliation'}

export const champresiliation = (objet,type) =>{
    let listdeschamps =type==="mail"? {...listchampsclient,...champemailcompagnie}:{...listchampsclient,...listchampsadressecompagnie};
    switch(objet){
        case 'echeance': listdeschamps ={...listdeschamps,...champs_echeance}; break
        case 'augmentation': listdeschamps ={...listdeschamps,...champs_augmentation}; break
        case 'chatel': listdeschamps ={...listdeschamps,...champs_chatel}; break
        case 'modifrisque': listdeschamps ={...listdeschamps,...champs_modifrisque}; break
        case 'ria': listdeschamps ={...listdeschamps,...champs_ria}; break
        case 'retractation': listdeschamps ={...listdeschamps,...champs_retractation}; break
        default: listdeschamps ={...listdeschamps,...champs_echeance};
    }
    return listdeschamps
}

export const datasresilinitial = (lead,lastcontrat)=> {
    return {"adresse_titulaire":lead.adresse?.split(lead.code_postal)?.[0]?.replace(lead.ville,'').replace(/([,])/g,"") , 
            "adresse_complement_titulaire":lead.complement_adresse||'' ,                           
            "commercial":lead.commercial,
            "civilite_titulaire":lead.civilite , 
            "courtier":lead.courtier, 
            "code_postal_titulaire":lead.code_postal , 
            "civilite":lead.civilite,
            "complement_adresse":lead.complement_adresse,
            'compagnie' : lastcontrat?.compagnie ,
            "email":lead.email,
            "id_lead":lead.id,
            "id_client":lead.id_client,
            'nom_titulaire':lastcontrat?.nom_titulaire||lead.nom,
            'numsecu_titulaire': lead.numsecu ,
            'objet':'echeance',
            'prenom_titulaire':lastcontrat?.prenom_titulaire||lead.prenom ,
            "signe":0,
            'type_resil':'A échéance',
            'type' :"mail",
            "url":null  ,
            "ville_titulaire":lead.ville,} 
};


export const line1 =(type)=> {return type==="mail"?<>Par <span>email</span>, envoyé par le client</>:
                                     type==="mandat"?<>En recommandé, <span>avec mandat</span></>:
                                             <>En recommandé, <span>envoyé par le client</span></>} ;

export const line2 =(objet)=> {return  objet==="echeance"?<>Contrat à <span>échéance</span></>:
                                        objet==="augmentation"?<>Pour cause d'<span>augmentation</span></>:
                                        objet==="chatel"?<>Par la loi <span>chatel</span></>:
                                        objet==="modifrisque"?<>Pour cause de <span>modification de risque</span></>:
                                                              <>Résiliation <span>Infra-annuelle</span></>};

export const textefin =(datasresil)=> { return <>{line1(datasresil['type'])}<br/>
                                            {line2(datasresil['objet'])}<br/>
                                            {datasresil['compagnie']&& datasresil['compagnie'] }<br/>
                                            Contrat {datasresil['num_anciencontrat']&&datasresil['num_anciencontrat']}
                                         </>};

export const messagefin = (type)=> {return  type==="mail"?<>S’assurer qu’il envoie bien la lettre par email ou dans l'espace client de sa compagnie.  </>:
                                           type==="mandat"?<>Après signature, vous recevrez une notification avec la lettre de résiliation</>:
                                                            <>S’assurer qu’il envoie bien la lettre  en recommandé</>}

export  const titrecontent =(etape)=> { return etape===0?'Quel mode de résiliation ?':
                                                etape===1?'Quel motif de résiliation ?':
                                                etape===2?'Détails du contrat': 
                                                etape===3?'Coordonnées de la compagnie':'' }  ;

export const titrefin = (type,lead)=> {return type==="mandat"? `Une demande de signature  pour le mandat vient d’être envoyée à ${lead.nom} ${lead.prenom} ` : 
                                                  type==="nomandat"? "Le modèle de lettre de résiliation vient d’être envoyé ":
                                                                    "La lettre de résiliation vient d’être envoyée par email" };
