import React from "react";
import { useSelector , useDispatch} from "react-redux";

import {  deccrocher}  from '../../utils/twilio'
import {finappel,  handlephone}  from '../../utils/phone'

import { modifyPopupmultitel , modifyDisplaymodal} from '../../state/redux';
import { useUser } from "../../state/reactquerycabinets";



import './Phone.scss';
const classNames = require('classnames');
function Phone(){
    
    const dispatch = useDispatch();
    const { etat} = useSelector(state=>state.tel);
    const qualiflead  = useSelector(state=>state.lead.qualiflead)
   const ficheclient = useSelector(state=>state.lead.leadactu);
   const {data: user} = useUser(localStorage.getItem('token'))  ;

    const telephone =()=>{ ficheclient.telephone2?dispatch(modifyPopupmultitel(true)):handlephone(ficheclient.telephone);
                           setTimeout(()=>{dispatch(modifyPopupmultitel(false))},3500);
    }

    const clicktelephone = ()=>{ 
      etat==='normal'?  telephone() : etat==='sonnerie'? deccrocher() : finappel()  ;
                               ( etat==='appel' && qualiflead===false) &&  dispatch(modifyDisplaymodal('mod_qualiflead')) ; 
                            }
    
    return(<div className={classNames("phone",{'telanimation':etat==='sonnerie'},{'telrouge':etat==='appel'})} onClick={clicktelephone}></div>)

 }


export default Phone



