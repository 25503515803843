import React  from "react";
import { useSelector } from "react-redux";


import {  useEquipements } from "../../state/reactquery";

import CardEquipement from "../Equipement/CardEquipement";


import './ListeContrats.scss';
function ListeEquipements(){
   const ficheclient = useSelector(state=>state.lead.leadactu);

   const {data: equipements} = useEquipements(ficheclient?.id); 

   return(<>{equipements?.map(equipement=><CardEquipement key={equipement.id} equipement={equipement} /> )}</>);
}

export default ListeEquipements ;