
import React from 'react';
import './Img.scss'
const classNames = require('classnames'); 
function Imgcolor(props){
	const orange = (props.color==='orange' && props.selected!==true);
    const gris = (props.color==='gris' && props.selected!==true);
    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 447.9 447.9" >

<path id="Tracé_709" className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true},{'svgcolor0_noselect':props.selected!==true &&  props.color!=='orange'})} 
      d="M390.9,296.2c0.8,35.6-27.5,65.1-63.1,65.9c-34.8,0-79.3-35.1-79.3-71.6s26.3-85.3,61.2-85.3S390.9,259.8,390.9,296.2z"/>
<g>
	<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} d="M364.7,277.3c0-0.9-0.1-1.7-0.1-2.5c-1.3-29.9-26.7-53-56.6-51.7s-53,26.7-51.7,56.6c1.2,27,22,48.4,48,51.4
		c0,0.1,0,0.1,0,0.2c-1.1,45.8-39,82.2-84.9,81.2c-46.2,0.1-84-36.7-85.3-82.9v-50.5c27.6-2,53.5-15.1,71.5-36.3
		c42.4-50.2,42.8-123.4,1.1-173.9c1.9-3.1,3.1-6.8,3-10.8c0-0.3,0-0.6,0-0.9c-0.5-11.1-9.9-19.7-21-19.2s-19.7,9.9-19.2,21
		s9.9,19.7,21,19.2c1.1,0,2.1-0.2,3.1-0.4c0.2,0.3,0.4,0.5,0.6,0.8c37.3,44.3,37.3,109,0,153.2c-3.4,4.1-7.2,7.9-11.4,11.4
		c-16.2,13.4-35.8,20-55.2,20c-0.6-0.1-1.2-0.1-1.8-0.1c-0.1,0-0.2,0-0.4,0c-24.3-0.6-48.1-11.2-64.8-31.3
		c-37.3-44.3-37.3-109,0-153.2c0.2-0.2,0.3-0.4,0.4-0.6c1.1,0.1,2.2,0.2,3.4,0.2c10.9-0.3,19.5-9.3,19.2-20.1c0-0.3,0-0.6,0-0.9
		c-0.5-11.1-9.9-19.7-21-19.2s-19.7,9.9-19.2,21c0.2,4.1,1.6,7.8,3.8,10.9C6.4,120.4,7.1,193,49.2,242.8
		c17.6,20.7,42.5,33.5,69.3,36.1v50.7c0.1,26.3,10.8,51.6,29.8,69.9c18.9,18.7,44.5,29.3,71.1,29.3c54.6,0.8,99.5-42.8,100.5-97.6
		c0-0.1,0-0.3,0-0.4C345.7,326.7,365.3,304.1,364.7,277.3z M349.1,278.9c-0.9,20.9-18.5,37.1-39.4,36.2s-37.1-18.5-36.2-39.4
		s18.5-37.1,39.4-36.2c20.4,0.4,36.6,17.3,36.2,37.8C349.1,277.8,349.1,278.4,349.1,278.9z"/>
	<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} d="M311.2,259.7c-8.8,0.4-15.7,7.9-15.3,16.8c0.4,8.8,7.9,15.7,16.8,15.3c8.7-0.2,15.5-7.4,15.3-16.1
		c0-0.2,0-0.5,0-0.7C327.6,266.2,320.1,259.3,311.2,259.7z"/>
</g>


	
</svg>


    )
}

export default Imgcolor 