import React , {useEffect, useState  }   from 'react';
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate  } from "react-router-dom";
import axios from 'axios';
import Gleap from 'gleap';




import { modifyEcran ,defineGleap } from '../state/redux';
import { useUser } from '../state/reactquerycabinets';


import {socketio,  initialiseSocket } from '../utils/socket';
import { initialisetwilio }  from '../utils/twilio';
import { formatdatenotif } from '../utils/utils_dates';
import { verifieversion } from '../utils/verifversion';
import { findCourtierCompagniesDatas } from '../utils/compagnies';
import { initialiseringover } from '../utils/ringover';
import { initialiseaircall } from '../utils/aircall';

import EcranParametres from '../Ecrans/EcranParametres/EcranParametres';

import EcranFicheClient  from "../Ecrans/EcranFicheClient/EcranFicheClient";
import EcranDev from "../Ecrans/EcranDev/EcranDev";
import EcranDev2 from "../Ecrans/EcranDev2/EcranDev2";
import Yoan from "../Ecrans/Yoan/Yoan";
import EcranGestionLeads from "../Ecrans/EcranGestionLeads/EcranGestionLeads";
import EcranGestion from "../Ecrans/EcranGestion/EcranGestion";
import EcranGestionOneClient from '../Ecrans/EcranGestionOneClient/EcranGestionOneClient';
import GestionRecherche    from '../Components/Gestion/GestionRecherche';
import EcranTickets from "../Ecrans/EcranTickets/EcranTickets";
import EcranEmails from "../Ecrans/EcranEmails/EcranEmails";
import EcranDashboard from '../Ecrans/EcranDashboard/EcranDashboard';
import EcranFormulaire from '../Components/Formulaire/EcranFormulaire';
import EcranEmailsGestion from '../Ecrans/EcranEmails/EcranEmailsGestion';
import EcranEmailsClients from "../Ecrans/EcranEmails/EcranEmailsClients";
import EcranSuperAdmin from "../Ecrans/EcranSuperAdmin/EcranSuperAdmin";




import Popup from '../Components/Popup/Popup';
import PopupRadin from '../Components/Popup/PopupRadin';
import PopupRadinConfirm from '../Components/Popup/PopupRadinConfirm';
import PopupAvertissementSignature from '../Components/Popup/PopupAvertissementSignature';
import PopupAvertissementResil from '../Components/Popup/PopupAvertissementResil';
import PopupALertPbContrat from '../Components/Popup/PopupAlertPbContrat';

import AfterCall from '../Ecrans/EcranFicheClient/AfterCall';
import CardChangeEmail  from '../Components/CardChangeEmail';
import ChangeMdp from '../Components/ChangeMdp';
import ComGestion from '../Components/ComGestion/ComGestion';
import ContratWin from '../Components/ContratWin/ContratWin';
import Calendar from '../Components/Calendar/Calendar';
import CardNewContratGestion from '../Components/Contrats/CardNewContratGestion';
import Echecs from '../Ecrans/EcranFicheClient/Echecs';
import ListEvents from '../Components/Calendar/ListEvents';
import Notifs from '../Components/Notifs/Notifs';
import ModifContrat from '../Components/Contrats/ModifContrat';
import Recherche from '../Components/Recherche';
import Sidebar from "../Components/Sidebar";
import TelephonieDraggable from '../Components/Telephone/TelephonieDraggable';
import FormEquipement from '../Components/Equipement/FormEquipement';

import './Application.scss';

const Ecrans={'dashboard':<EcranDashboard />,
            'ficheclient':<EcranFicheClient />,
            'ecrantickets':<EcranTickets />,
            'params':<EcranParametres />,
            'yoan': <Yoan />,
            'dev': <EcranDev />,
            'dev2': <EcranDev2 />,
            'ecranemails':<EcranEmailsClients />,
            'ecranemailsgestion':<EcranEmailsGestion  />,
            'ecrangestionleads':<EcranGestionLeads />,
            'ecrangestion':<EcranGestion />,
            'ficheclientgestion':<EcranGestionOneClient /> ,
            'ecransuperadmin':<EcranSuperAdmin /> 
        }

function Application(){ 

    const dispatch = useDispatch();
    const navigate = useNavigate();

    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    
    axios.interceptors.response.use(res => { return res;},
                                    err => {  if(err?.response?.status===403){  
                                                localStorage.clear() ;
                                                navigate('/login') }
                                            return {status:err?.response?.status}});

    const gleap = useSelector(state=>state.firstrequest.gleap)  ;
    const notifnew = useSelector(state=>state.display.notifnew)  ;
    const { etat , messagetel} = useSelector(state=>state.tel);
    const listcalendarevents= useSelector(state => state.calendar.listcalendarevents);
    const [displaynotifs , setDisplaynotifs] = useState(false) ;
    const [alertrdv , setAlertrdv ] = useState(null)
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const {ecran ,  displaymodal , displaymodalcalendar}= useSelector(state=>state.display);


    const demain =  (new Date((new Date()).getTime()+24*60*60*1000)).setHours(0,0,0)  ;
    const rdvsjour = listcalendarevents?.filter(x=>  (new Date(x.startDatetime) >new Date()) && (new Date(x.startDatetime) < demain ) )
    const firstrendezvous = rdvsjour.sort((a,b)=> new Date(a.startDatetime) - new Date(b.startDatetime))?.[0] ;
    const tempsnextrrdv_ms =( new Date(firstrendezvous?.startDatetime)  - new Date())/ 1000;
    const nbhours = Math.floor(tempsnextrrdv_ms /3600) ; 
    const nbminutes = Math.floor((tempsnextrrdv_ms%3600)/60)?.toString().padStart(2,'0') ;
    const temps = nbhours>0?nbhours+'h'+nbminutes+'min':nbminutes+'min'; 

function nexrdv(){
    if(rdvsjour?.length===0){return }
    firstrendezvous.id && setAlertrdv(`Votre prochain RDV est dans ${temps} avec ${firstrendezvous.summary} `)
  
    setTimeout(()=>{nexrdv()}, 60*1000 )     
}

useEffect(()=>{nexrdv()},[listcalendarevents]) ;


    // useEffect(()=>{
    //     window.history.pushState(null, document.title, window.location.href);
    //     window.addEventListener('popstate', function (event){
    //         console.log(event)
    //         window.history.pushState(null, document.title,  window.location.href);
    //     });
    //   // if(window.location.hostname==='localhost'){ window.onbeforeunload = function (e) {e.preventDefault()  ;return false;} }
    // }) ;


    window.addEventListener('click', (event) => { 
        (displaynotifs && (event.target.id!=='notifsbell' && !([...event.target.classList].includes('delnotif')) ))&& setDisplaynotifs(false) ;
     });

    useEffect(()=>{  if(user?.role==="GESTION"  ){dispatch(modifyEcran('ecrangestionleads')) ;  }
                    //  if(user?.roles?.includes("DEV")){dispatch(modifyEcran('dev')) ;  }
                    },[socketio,user,dispatch] );


  useEffect(()=>{  if(!localStorage.getItem('token')){  navigate('/login') ;  }
                   else if(!user?.["courtier"]){  return  ;}
                   else{    verifieversion() ;
                            initialiseSocket();
                            user.type_tel === 'twilio' && initialisetwilio() ;
                            user.type_tel === 'ringover' && initialiseringover() ;
                            findCourtierCompagniesDatas() ;}
                  },[user?.["courtier"]] );
                      

    useEffect(()=>{
        // if(!user?.['email'] ){return }
        if(!user?.['email'] || !gleap ){return }
        dispatch(defineGleap(false)) ; 
        Gleap.initialize("1SWQbVOLkiuU39dxBILcn1XrflnzIzJn");
        Gleap.setLanguage("fr"); 
        Gleap.identify(user['email'], { name: user['firstname'],email: user['email'], phone: user['telephone'] , customData:{'lastname': user['lastname'], 'courtier' :user['courtier']   } }) ;
        Gleap.registerCustomAction((customAction) => {
                if (customAction.name === "VISITE_GUIDEE") {
                  document.getElementById("startdemo").click() }
              });
    },[user,gleap,dispatch])


    useEffect(()=>{ user?.['type_tel']==='aircall' &&  initialiseaircall();  },[user])


    const divnotifpopup = (notif)=>{ return( <div   id='notif_popup'  className='notif_popup' >
                                                <div className='notif_date' >{formatdatenotif(notif?.date_notif ?? new Date())}   {notif?.id} </div>
                                                <div className='notif_texte' dangerouslySetInnerHTML={{__html: notif?.notification}} />     
                                        </div>)
    }

    return(<div  translate="no" className="administration">
      {alertrdv&& <div style={{'position':'fixed','right':'10px','zIndex':10000,'width':'280px' , 'color':'#62A2FF','height':'20px','lineHeight':'10px',  'fontSize':'11px','fontWeight':'bold'}} > {alertrdv}</div>}
                      {user?.authenticate?<> <Popup   id="popupsignature"  />
                                             <Popup   id="popupsendmail"  />
                                              <Popup   id="popupdevis"  />
                                               <PopupAvertissementSignature /> 
                                               <PopupAvertissementResil  />
                                               <PopupALertPbContrat />
                                                {messagetel && <TelephonieDraggable  />}
                                                {displaymodal==='mod_popuradin' && <PopupRadin />}
                                                {displaymodal==='mod_popuradinconfirm' && <PopupRadinConfirm />}
                                                {displaymodal==='mod_qualiflead' && <AfterCall />}
                                                {displaymodalcalendar==='mod_calendarrdv' && <Calendar  type='rdv' />}
                                                {displaymodalcalendar==='mod_calendarelance' && <Calendar  type='relance' />}
                                                {displaymodal==='mod_changemail' && <CardChangeEmail  />}
                                                {displaymodal==='mod_gestnewcontrat' && <CardNewContratGestion /> }
                                                {displaymodal==='mod_changemdp' && <ChangeMdp  />}
                                                {displaymodal==='mod_comgestion' &&  <ComGestion  />   }
                                                {displaymodal==='mod_contratwin' && <ContratWin  />  }
                                                {displaymodal==='mod_infos' && <EcranFormulaire  />}
                                                {displaymodal==='mod_echecs' && <Echecs  />}
                                                {displaymodal==='mod_searchgestion' && <GestionRecherche /> }
                                                {displaymodal==='mod_modifcontrat' && <ModifContrat  />}
                                                {displaymodal==='mod_equipement' && <FormEquipement  />}
                                                {displaymodal==='mod_search' && <Recherche /> }
                                                {displaymodal==='mod_searchgestion' && <GestionRecherche /> }
                                                <Sidebar  setDisplaynotifs={setDisplaynotifs}   displaynotifs={displaynotifs} />
                                                {ecran!=='dashboard' && <ListEvents position="moveleft" />}
                                                {Ecrans[ecran || 'dashboard']}
                                          </>:<></>}
                            {notifnew?.id && divnotifpopup(notifnew)}
                            {displaynotifs && <Notifs />}
                            {user?.type_tel === 'aircall' &&  <div id='aircallphone' ></div> }

             </div>)
}

export default Application


