import React , { useState , useEffect} from "react";
import { useDispatch , useSelector } from "react-redux";
import axios from "axios";

import { useContrats } from "../../state/reactquery";
import { modifyDisplaymodal } from "../../state/redux";

import { dateconvert } from "../../utils/utils_dates";
import ButtonSimple from "../Buttons/ButtonSimple";

import './JeResilie.scss';

const listchampsclient ={'lastname':'Nom du titulaire','firstname':'Prénom du titulaire',
                         'email':'Email du titulaire',
                        //  'phone':'Numero de telephone',
                          'contractNumber': 'Numéro de contrat','address': 'Adresse du titulaire',
                           'addressComplement': 'Complement d adresse','postCode': 'Code Postal',
                           'country':'FRANCE'}

                           const classNames = require('classnames'); 
function JeResile(){
    const dispatch = useDispatch() ; 
    const ficheclient = useSelector(state=>state.lead.leadactu);
    const [files, setFiles] = useState([]);
    const {data: contrats} = useContrats(ficheclient?.email,ficheclient?.courtier) ; 
    const lastcontrat =  contrats?.sort((a,b)=> new Date(b.date_insert) - new Date(a.date_insert))?.[0]; 

    const initsubscriber = {"gender": (ficheclient.civilite==='M'||ficheclient.civilite==='M.')?'Monsieur':'Madame'  ,
                            "lastname":ficheclient.nom,
                            "firstname":ficheclient.prenom,
                            "address":ficheclient.adresse,
                            "addressComplement": ficheclient.complement_adresse,
                            "postCode":ficheclient.code_postal,
                            "city": ficheclient.ville,
                            "email": ficheclient.email, 
                            "country": "FRANCE" } 

    const initcontract = { "kindOfContract": 6,
                            "cancelReason":  0 ,
                            "contractNumber":   lastcontrat?.num_contrat||"" ,
                             "echeancePrincipale": dateconvert(ficheclient.date_effet)||(new Date()).toLocaleDateString("fr-FR") }

    const [subscriber, setSubscriber] = useState(initsubscriber) ;
    const [contrat, setContrat] = useState(initcontract) ;
    const [recipient, setRecipient] = useState(initsubscriber) ;
    const [listecompagnies, setListecompagnies] =useState(['April','Cegema']) ;
    const [listemotifs, setListemotifs ]= useState(['Ria','Demission']) ;

    async function fetchDataGestion() { 
        const res = await axios.get(`/jeresilie/getresiledatas`,{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
        setListecompagnies(res.data?.liste_compagnies) ;
        setListemotifs(res.data?.liste_motifs) ;} 

    
    useEffect(() => { fetchDataGestion();  }, []); 


    const changechmap =(e)=>{e.target.id==='contractNumber'?setContrat({...contrat,[e.target.id]: e.target.value })  :
                                                            setSubscriber({...subscriber,[e.target.id]: e.target.value }) }
    const grplabelinput = (x,label)=>( <div key={x}  className={"containerlabelinputresil "  +((x==='complement_adresse_compagnie')?'deuxtiers':'tiers') } >
                                            <div className="inputtextelabel">{label}</div>
                                            <input id={x}  value={x==="contractNumber"?contrat[x] :subscriber[x]||''}  
                                                    type={x==='date_resile'|| x==='date_signature' ?"date":"text"} 
                                                    onChange={changechmap}
                                                    className="inputretractation"  />
                                        </div>) ;

    const changeSelectMotif =(e)=>{ setContrat({...contrat, "cancelReason": e.target.value})}
    const changeSelectCompagnie =(e)=>{ console.log(recipient, e.target.value) ;  setRecipient(e.target.value)}

    const handleFile = (e) => {  setFiles(files=>[...files,...e.target.files]);};
    const clickgenderbutton = (e) => {  setSubscriber({...subscriber, 'civilite': e.target.value})};

    const envoiresiliation = ()=>{console.log('Envoi') ;
        const data = new FormData() ;
        data.append('subscriber', JSON.stringify(subscriber)) ; 
        data.append('contrat', JSON.stringify({...contrat,"cancelReason":parseInt(contrat?.cancelReason)})) ; 
        console.log('CONTRAT' , contrat , JSON.stringify(contrat))   ; 
        data.append('recipient',recipient) ; 
        for (let i = 0; i < files.length; i++) {
                const nomfichier = files[i].name.split('.')[0] ;
                data.append('file',files[i],`/${nomfichier}.${files[i].name.split('.')[1]}`)
        }
        axios.post( `/jeresilie/sendLetter/`,data, {headers: { 'Accept': 'application/json', "Content-Type": "multipart/form-data"} })
    }


return(<div  className="containergray">
            <div  className="jeresilie">
            <div className="comgestion_titre" >Resiliation avec JeResilie.com</div><br/>
            <div className="comgestion_btngrp" >

                <div  className="containerlabelinput"  >
                    <div className="inputtextelabel">Compagnies</div>
                        {listecompagnies?.length >0 && <select value={recipient} className='selectcompgamme' onChange={changeSelectCompagnie} >
                                                                {listecompagnies?.sort((a,b)=>a.name?.toUpperCase() < b.name?.toUpperCase()  ?-1:1)?.map((y)=><option key={y.id} id={y.id} value={y.id} >{y.name}</option>)}
                                                    </select>}
                </div> 
                <div  className="containerlabelinput"  >
                    <div className="inputtextelabel">Motifs de resiliation</div>
                        {listemotifs?.length >0 && <select value={contrat.cancelReason||''} className='selectcompgamme' onChange={changeSelectMotif} >
                                                                {listemotifs?.sort((a,b)=>a.name?.toUpperCase() < b.name?.toUpperCase()  ?-1:1)?.map((y)=><option key={y.id} id={y.id} value={y.id} >{y.name}</option>)}
                                                    </select>}
                </div> 
                <div className="gendergroup" > 
                <div  className="gendergroup_buttons" >
                    <button id='genger' name="M"  className={classNames('button button_gender mr ', {'button_gender_selected': ficheclient['civilite']==='M'})} onClick={clickgenderbutton}>M.</button>
                    <button name="Mme"  className={classNames('button button_gender', {'button_gender_selected': ficheclient['civilite']!=='M'})} onClick={clickgenderbutton}>Mme</button>
                </div>
             </div>
                {Object.keys(listchampsclient).map(x=>( grplabelinput(x,listchampsclient[x]) )) }
                            
                  <div className="contratwindevis_upload">
        
                        <input type='file' multiple  id='uploaddevis' className="uploadvalidcontrat_input" 
                                 onChange={handleFile} />
                  </div>
                  <div className="equipement_boutons">
                      <ButtonSimple  texte="Fermer" containerbouton='fullbuton white' color='bouton_color_blancgris noborder' size='bouton_size_normal' onclick={()=>{ dispatch(modifyDisplaymodal(null)) }}    />   
                      <ButtonSimple  texte="Envoyer" containerbouton='fullbuton' color='bouton_color_orange' size='bouton_size_normal' onclick={envoiresiliation}   />      
                </div>     
             
                
                </div></div>

</div>)
}


export default JeResile