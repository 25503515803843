
import React from "react" ;





function ContratWinAlert({etape,noformule,nocoords}){

    const titre = etape==='sign_ficbulletin'?"à l'intégration du bulletin de signature":
                  etape==='etape_formule'?"au choix de la formule":"";


    return( <div className="alertwinred">
                        <h1>Vous ne pouvez pas accéder {titre}.</h1>
                          <ul>
                            {nocoords && <li>Les coordonnées  sont incomplètes</li>}
                            {noformule && <li>Aucune formule n'a été sélectionnée</li>}
                          </ul>
                      </div>)
}


export default ContratWinAlert