import React, { useEffect }   from 'react';

import { useDispatch , useSelector } from "react-redux";

import {definechoiceliste, defineCategories} from "../../state/redux";



import './EcranGestionFilterLeads.scss';

function finddoublons(leads){
        const emailSet =  new Set();const emaildoublons =[] ;
        for(let lead of leads){
            emailSet.has(lead.email) && emaildoublons.push(lead.email) ;
            emailSet.add(lead.email)
        }
        const doublons =  leads.filter(l=>emaildoublons.includes(l.email))
        return {nb:doublons.length , doublons }
 }
    

const classNames = require('classnames');
function EcranGestionFilterLeads(){
    const dispatch = useDispatch() ;
    const {affFxNum=[],affLeads =[],choiceliste} = useSelector(state=>state.gestion);


    const leadsrecus = affLeads ; //?.sort((a,b)=>new Date(new Date(b?.date_reception) - new Date(a?.date_reception)));
    const nontraites = affLeads.filter(x=>x.statut_client==='nontraite');
    const nrps = affLeads.filter(x=>x.statut_client?.includes('contact_nrp')   );
    const echecs = affLeads.filter(x=>x.statut_client?.includes('echec') );
    const encours = affLeads.filter(x=>x.statut_client==='contact_relance' || x.statut_client==='contact_rdv'  );
    const devis = affLeads.filter(x=>x.statut_client?.includes('devisenvoye'));
    const signatures = affLeads.filter(x=>x.statut_client?.includes('signature'));
    const gagnes = affLeads.filter(x=>x.statut_client?.includes('gagne'));
    const doublons = finddoublons(affLeads)?.doublons?.sort((b,a)=>{return  b.email > a.email ? 1:b.email  < a.email?-1 :0   }) ; 
    const contratsenattente = affLeads.filter(x=>x.sign_fic!==0);
    
    const jsonleads = {"choiceleadsrecus":leadsrecus,"choicenontraites":nontraites,"choicenrp":nrps,"choiceechec":echecs ,
                        "choiceencours":encours,"choicedevis":devis,"choicecontratsenattente":contratsenattente,"choicegagnes":gagnes,
                        "choicefauxnumeros":affFxNum,"choicefauxnumerosdoublons":[...doublons,...affFxNum]}

    useEffect(()=>{dispatch(defineCategories(jsonleads));},[affLeads]);

    const choiceleads = (e)=>{  dispatch(definechoiceliste(e.target.id)) ;}

    const choice =(id,titre,nb)=>( <div  id={id} className={classNames(`ecrangestionfilterleads_btn ${id}`,{"selected":choiceliste===id})} onClick={choiceleads} >
                                        <div className={classNames("ecrangestionfilterleads_btn_titre",{"selected":choiceliste===id})}>{titre}</div>
                                        <div className={classNames("ecrangestionfilterleads_btn_nb",{"selected":choiceliste===id})}>{nb}</div>
                                   </div>)

    return(<div className="ecrangestionfilterleads">
                {choice("choiceleadsrecus","Leads recus",jsonleads["choiceleadsrecus"].length)}
                {choice("choicenontraites","Non traites",jsonleads["choicenontraites"].length)}
                {choice("choicenrp","NRP",jsonleads["choicenrp"].length)}
                {choice("choiceechec","Echec",jsonleads["choiceechec"].length)}
                {choice("choiceencours","En cours",jsonleads["choiceencours"].length)}
                {choice("choicedevis","Devis envoyes",jsonleads["choicedevis"].length)}
                {choice("choicecontratsenattente","Contrat en attente",jsonleads["choicecontratsenattente"].length )}
                {choice("choicegagnes","Gagnes",jsonleads["choicegagnes"].length)} 
                {choice("choicefauxnumeros","Faux numéros",jsonleads["choicefauxnumeros"].length )}
                {choice("choicefauxnumerosdoublons","Doublons",finddoublons(affLeads)?.nb)}
        </div>)
}

export default EcranGestionFilterLeads