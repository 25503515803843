import React  ,{ useState }  from "react";


import FiltresBesoins from "./FiltresBesoins";
import FiltresCompagniesTarifs from "../../Components/FiltresCompagniesTarifs";
import DateAdhesion from "../../Components/DateAdhesion/DateAdhesion";


import './GroupFiltres.scss';

function GroupFiltres(){
    const [dispfiltrecomp, setDispfiltrecomp ] = useState(false)
    const [dispfiltrebesoins, setDispfiltrebesoins ] = useState(false)

  
    return(<div className="container_filtresformules">

                <div className="filtresformules" >
                    <div  className="filtresformules_datedisplays">
                        <DateAdhesion formulaire={false} setDispfiltrecomp={setDispfiltrecomp} dispfiltrecomp={dispfiltrecomp} dispfiltrebesoins={dispfiltrebesoins} setDispfiltrebesoins={setDispfiltrebesoins}  />  
                        <div>
                            <button className="openclosefiltres first" onClick={()=>{setDispfiltrecomp(!dispfiltrecomp)}} >Compagnies</button>
                            <button  className="openclosefiltres" onClick={()=>{setDispfiltrebesoins(!dispfiltrebesoins)}}>Besoins</button>
                        </div>
                    </div>

                    { dispfiltrecomp   &&  <FiltresCompagniesTarifs   /> }
                    {dispfiltrebesoins &&   <FiltresBesoins   />    }
                </div>

            </div>)
}


export default GroupFiltres;

