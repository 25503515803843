import React, {useState }from "react";
import { useSelector , useDispatch } from "react-redux";

import { modifyDisplaymodal } from "../../state/redux";
import { queryClient } from '../../state/queryClient';
import { useUser } from "../../state/reactquerycabinets";


import { sendticket } from "../../utils/utils_comgestion";

import ButtonSimple from "../Buttons/ButtonSimple";


import './ComGestion.scss';

function ComGestionBottom({attachments,listeetapes,etape, setEtape , disablecontinue }){
     const {data: user} = useUser(localStorage.getItem('token')) ;
    const dispatch = useDispatch() ;
    const comgestion = useSelector(state=>state.comgestion.value) ;
    const leadactu  = useSelector(state=>state.lead.leadactu);
    const [loading , setLoading] = useState(false) ;
    const [alert , setAlert] = useState(false) ;

    const retour = ()=> {etape==='nonliste'?setEtape('type_ticket'):setEtape(listeetapes[listeetapes.indexOf(etape)-1]);}
    const avance = ()=>{etape==='documents'?setEtape('fin'):setEtape(listeetapes[listeetapes.indexOf(etape)+1]);}

    const send = async()=>{ setLoading(true) ;
                               const rep = await sendticket(comgestion, attachments);   
                               if(rep.status!==200){setAlert(true);
                                                    setLoading(false) ;
                                                    setTimeout(()=>{dispatch(modifyDisplaymodal(null)) },3000) ;
                               }else{ dispatch(modifyDisplaymodal(null))   ; }   
                               queryClient.invalidateQueries({ queryKey: ['listmails']})  ;
                               queryClient.invalidateQueries({ queryKey: ['listgestionmails']})  ;
                               queryClient.invalidateQueries({ queryKey: ['clientmails', user['postmail'], leadactu.email] });
   }


     
    return(<div className='comgestion_buttons'>
     {loading && <div className="loadingwait"></div> }
     {alert && <div className="alerterror">Une erreur est servenue. Veuillez contacter l'assistance. </div>}
                <ButtonSimple  texte="Retour" containerbouton='fullbuton white'   color='bouton_color_blancgris  noborder' size='bouton_size_normal'  onclick={retour}    />   
               {etape!=='fin' && <ButtonSimple  texte="Continuer" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' disable={disablecontinue} onclick={avance}  /> }   
               {etape==='fin' && <ButtonSimple  texte="Envoyer" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' disable={disablecontinue} onclick={send}  />    }
            </div>)
}


export default ComGestionBottom