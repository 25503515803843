import React, { useEffect } from "react";
import { useSelector } from "react-redux";


import uploaddel from '../../images/uploaddel.svg'; 



const classNames = require('classnames');
function  ComGestionDocu({listegestions,attachments,setAttachments, setDisablecontinue}) {
    const comgestion = useSelector(state=>state.comgestion.value) ;
    
    const texte = listegestions[comgestion['type_gestion']]?.texteupload ;


    useEffect(()=>{ setDisablecontinue(!(texte===undefined || texte===null)&&!(attachments?.length>=1) ) ; },[attachments]) ;

    const handleFileChange = (e) => {  setAttachments(attachments=>[...attachments,...e.target.files]);};

    const deletefilefromliste = (e)=>{ setAttachments(attachments=>[...attachments].filter(x=>x.name!==e.target.id)) }

    
    return(<>{texte && <div  className="comgestion_docsobligatoire">{texte}</div>}
                                <div className={classNames("comgestion_docs",{'noupload':attachments.length===0},{'upload':attachments.length!==0})} >
                                                <input  id='filecontrat'  className="comgestion_docs_input" type='file' multiple onChange={handleFileChange}   />
                                </div> 
                                <div className="containtlistefilecomgestion">
                                    {[...attachments].map((x, index)=>(<div  key={index}  className='divfilenameinliste' >
                                                                            <div className='filenameinliste' id={x.name} key={x.name}  onClick={deletefilefromliste}  >{x.name}</div>
                                                                            <img id={x.name} className="uploaddel" src={uploaddel} alt='imgdel'  onClick={deletefilefromliste} />
                                                                    </div>))}
                        </div></>)

}

export default ComGestionDocu ;