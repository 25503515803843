import React , { useState }   from "react";

import { useSelector } from "react-redux";
import { useNotes , addOneNote } from "../../state/reactquery";
import ButtonSimple from "../Buttons/ButtonSimple";
import { formatdatenotif , comparedates } from "../../utils/utils_dates";
import './Notes.scss'


function Notes(){

    const ficheclient = useSelector(state=>state.lead.leadactu);
    const {data: notes} = useNotes(ficheclient?.id) ; 
    const [displayaddnote, setDisplayaddnote]  = useState(false) ;
    const [note, setNote]  = useState('');

    const enregistrernote = () =>{
        note.length !==0 && addOneNote(ficheclient?.id,note) ; 
       setNote('') ;
       setDisplayaddnote(false)
    }
// HENNER 1/2/3<br /><br />LE 12/3 MESSAGE LAISSE
    return( <div className="containernotes" >

                {displayaddnote && <div className="textarea_container" > 
                                        <div className="addnote_titre" > Nouvelle Note  </div> 
                                              <textarea className='addnote'  value={note}  onChange={(e)=>{setNote(e.target.value)}}   />
                                        
                                    </div>}
               <div className="listenotes" >
                { notes?.length > 0 &&  notes.sort((a,b)=> comparedates(a,b))
                                             .map((note)=>(<div key={note.id_note}  className="manote">
                                                                    <div className="manote_date" >{formatdatenotif(note.date_note)}</div>
                                                                    <div className="manote_texte"  dangerouslySetInnerHTML={{ __html: note.note}}  >{}</div>
                                                            </div>))}
               </div> 
                <div className="listenotes_bottom">
                    {displayaddnote ?  <ButtonSimple  texte="Enregistrer"   color='bouton_color_orange' size='bouton_size_normal'  onclick={enregistrernote}   />:
                                        <ButtonSimple  texte="Ajouter une note"   color='bouton_color_orange' size='bouton_size_normal'  onclick={()=>{setDisplayaddnote(true)}}   />}
                </div>
            </div> )
}



export default  Notes