import React, { useState}  from 'react';
import { useDispatch} from 'react-redux';

import {   defineFormuleShow} from '../../state/redux';

import { logocompagnie } from '../../utils/logocompagnie';

import { useUser } from "../../state/reactquerycabinets";

import { isclassblur } from '../../utils/radin';

import FormuleDetail from '../Contrats/FormuleDetail';
import CardFormuleJauge from './CardFormuleJauges';
import AjoutDevis from './AjoutDevis'
import Check from '../Check';


import './CardFormule.scss'

function formatprix(prix){ return prix?   prix.toFixed(2).replace('.',','):'0'}


const CardFormule = ({formule,typeliste,choicedformule, setChoicedformule})=>{

     const dispatch= useDispatch()
     const logo  = logocompagnie(formule?.compagnie?.toUpperCase());
     const c_appellation = formule.appellation && formule.appellation?.trim()?.length !==0 ;
     const etiquette = formule.type_tarif?.includes('lin') && c_appellation ?<div className='etiquette_container'><span className='etiquette lineaire'>{formule.appellation}</span></div>:
                        formule.type_tarif==='precompte30' && c_appellation ?<div className='etiquette_container'><span className='etiquette lowcost'>{formule.appellation}</span></div>:<></>
        
     const {hospi,consults,optique_lunettes_nb,optique_lentilles_nb,dentaire_prothese_nb ,dentaire_implant_nb, dentaire_orthodontie_nb, prevention } = formule ; 
     const [showFormuleDetail,setShowFormuleDetail] = useState(false);
     const showformule = (e)=>{ 

               if( e.target.closest('.cardformule_last')===null && e.target.closest('.cardformulecheckformulesigne')===null){
                    dispatch(defineFormuleShow({...formule})) 
               }
     }

    const changeformulesigne = ()=>{choicedformule?.id===formule?.id?setShowFormuleDetail(false):setShowFormuleDetail(true); 
                                    choicedformule?.id===formule?.id?setChoicedformule({}):setChoicedformule(formule);}

    
    return (<div className="cardformule"    >
                <div className="cardformuletop" id ={formule?.id} onClick={showformule}>
                    { typeliste === 'souscription' && <Check checked={formule?.id===choicedformule?.id } onclick={changeformulesigne}  />}
                      <div className='cardformuleimage' ><img src={logo}  width='70' alt='logoCompagnie'/></div>
                      <div className="nomformule" >              
                                   <span className="nomformule_span1">{formule?.gamme} </span>
                                   <span className={`nomformule_span2 ${isclassblur()}`}> { formule?.nom_formule +(formule.kdc? ' ( KDC ) ':'') } </span>     
                                   {etiquette}
                       </div>
                         <CardFormuleJauge  hospi  consults optique_lunettes_nb optique_lentilles_nb
                                            dentaire_prothese_nb dentaire_implant_nb  dentaire_orthodontie_nb 
                                            prevention 
                          />
                        <div  className="cardformuleright" > 
                                   <span className={`cardformuleright_span1 ${isclassblur}`}  >{formatprix(formule?.prix)}€</span>
                                   <span className='cardformuleright_span2' >par mois</span>
                       </div> 
                     { typeliste === 'ficheclient' && <div className='cardformule_last'>
                                                            <AjoutDevis  formule={formule}  />
                                             </div>}
                </div>
                {((formule?.id===choicedformule?.id && showFormuleDetail) && typeliste === 'souscription') && 
                                                  <div className="cardformulebottom">
                                                       <FormuleDetail setShowFormuleDetail={setShowFormuleDetail} 
                                                                      choicedformule ={choicedformule} 
                                                                      setChoicedformule ={setChoicedformule}    />
                                                  </div>} 
          </div>)       
             
}

export default CardFormule