import React from "react";
import { useSelector } from "react-redux";

import { useUser } from "../../state/reactquerycabinets";

import { convertdateAA } from "../../utils/utils_dates";
import { searchandinitialiseclient } from "../../utils/actions_lead";
import { raccrocher_twilio , phone} from "../../utils/twilio";


import btntickettoclient from '../../images/btntickettoclient.svg';

import './Ticket.scss';
const classNames = require('classnames');
function Ticket({ticket , clickticket}){
    const {data: user} = useUser(localStorage.getItem('token'))  ;   
    const { etat } = useSelector(state=>state.tel);
     
    const handlephone = ()=>{etat==='normal'?  phone(ticket['tel_from']) : raccrocher_twilio() }
    
    const clicktoclient = async()=>{searchandinitialiseclient(ticket?.id_lead,false)}

    const onDragStart = (event, ticket) => { // return
        event.dataTransfer.setData("idticket",ticket?.id );
        event.dataTransfer.setData("statusticket",ticket?.statut); 
      }

    return(<div className="ticket"  id={ticket?.id}  draggable onDragStart = {(event) => onDragStart(event,ticket)} >
                <div className="ticket_top">
                    <div className="ticket_top_left" >{ticket['date_ticket'] && convertdateAA(ticket['date_ticket'])}</div>
                    <div>  {ticket?.id_lead && <img src={btntickettoclient} alt="icon" heigth="40" width="40" onClick={clicktoclient} />} </div>
                    {(user?.role==="ADMIN" ) &&     <div className={classNames("phone",{'telanimation':etat==='sonnerie'},{'telrouge':etat==='appel'})} onClick={handlephone}> </div>  }  
                </div>
                <div id={ticket['id']} className="ticketclick"   onClick={clickticket}  >
                    <div className="ticket_detail" >
                        {(ticket['nom'] || ticket['prenom']) && <div className="ticket_detail_line1" > {ticket['nom'] ? ticket['nom'] : ''}  {ticket['prenom'] ? ticket['prenom'] : ''} </div>}
                        {(ticket['tel_from']) && <div className="ticket_detail_line" >    {ticket['tel_from']}   </div>}
                        {(ticket['email']) && <div className="ticket_detail_line" > {ticket['email']}  </div>}
                    </div>
                    {ticket['ticket_categorie'] &&       <><div className="ticket_categorie" >{ticket['ticket_categorie']}</div><br /></>}
                    {ticket?.['ticket_tags'] && ticket?.['ticket_tags'].split(',').map((x, i) => (<div key={i} className="ticket_tag" >{x}</div>))}
                </div>
            </div> )
}

export default Ticket