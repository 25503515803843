
import React from 'react';
import './Img.scss'
const classNames = require('classnames'); 
function Imgcolor(props){
	const orange = (props.color==='orange' && props.selected!==true);
    const gris = (props.color==='gris' && props.selected!==true);

    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 447.9 447.9" >
<path id="Tracé_734" className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true},{'svgcolor0_noselect':props.selected!==true &&  props.color!=='orange'})} 
 d="M374.8,299.2c1,47.4-36.5,86.6-83.9,87.7c-46.3,0-105.4-46.8-105.4-95.3s35-113.5,81.4-113.5  S374.8,250.8,374.8,299.2z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} 
      d="M157.8,126.5c25.9-0.6,46.5-22.1,45.9-48c0-0.7,0-1.4,0-2.1c-1.2-26.5-23.6-47.1-50.1-45.9
	c-26.5,1.2-47.1,23.6-45.9,50.1S131.3,127.7,157.8,126.5z M122,77c0.8-19,16.9-33.6,35.8-32.8c18.5,0.5,33.2,15.8,32.7,34.4
	c0,0.5,0,1,0,1.5c-0.8,18.9-16.9,33.5-35.8,32.7S121.2,95.9,122,77z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})}d="M361.5,335.2h-63.1l-26.8-66c-3.3-14.2-16.3-24.1-30.9-23.6l-9.4,0.8c8.6-6.2,12-17.2,8.4-27.1L213,145.5
	c-5-10.4-15.2-17.2-26.7-18h-67.8c-11.8,0.9-21.2,10.2-22.2,22L74.8,258.2c0,0.3-0.1,0.7-0.1,1l-31,2.6c-9.9,0.8-17.6,9.1-17.6,19
	v68.4c0,0.8,0.1,1.6,0.4,2.4s0.7,1.5,1.2,2.1c0.7,0.8,1.6,1.4,2.6,1.8c0.7,0.3,1.3,0.4,2,0.4l203.5,1.8h0.1c3.5-0.1,6.3-2.9,6.5-6.4
	c0.3-3.8-2.6-7.1-6.4-7.3l-197-1.8v-12.4l208.7-2.8l15,37c3.1,10,12.6,16.8,23.1,16.6h75.5c11.9-0.3,21.5-9.8,21.9-21.9
	C383.7,346.2,374,335.7,361.5,335.2z M108.8,152.1c0.5-2.6,2.6-11,9.3-11l67.7-0.2c5.9,0.6,11.3,4,14.4,9.1l26.8,73.8
	c1.7,4.6-0.4,9.6-4.8,11.6c0,0,0,0.1-0.1,0.1c-2.1,0.9-4.5,0.8-6.6-0.2s-3.7-2.8-4.5-5l-17.2-47.1c-0.9-2.7-3.5-4.6-6.3-4.5
	c-2.9,0.1-5.3,2.1-6.1,4.8L160.7,251l-0.2,0.9l-72.6,6L108.8,152.1z M210.1,248l-35.4,2.9l13.6-44.3l10.5,28.7
	C200.7,240.9,204.8,245.4,210.1,248z M361.4,366.9h-75.5c-6.5,0-8.9-3-11.1-8.3l-16.7-41.2c-1-2.5-3.4-4.2-6.1-4.2L38.9,316v-35.4
	c0.2-3,2.6-5.3,5.5-5.3l197.2-16.2c8.4-0.1,15.7,5.8,17.3,14c0.1,0.3,0.2,0.6,0.3,0.9l28.7,70.7c1,2.5,3.4,4.2,6.1,4.2h67.4
	c4.6,0.2,8.3,3.9,8.5,8.5C370.2,362.4,366.4,366.6,361.4,366.9z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} d="M335.2,332.4c25.9-0.6,46.5-22.1,45.9-48c0-0.7,0-1.4,0-2.1c-1.2-26.5-23.6-47.1-50.1-45.9
	s-47.1,23.6-45.9,50.1S308.7,333.6,335.2,332.4z M299.4,282.9c0.8-18.9,16.9-33.6,35.8-32.7c18.5,0.4,33.2,15.8,32.8,34.3
	c0,0.5,0,1-0.1,1.5c-0.8,18.9-16.9,33.6-35.8,32.7C313.2,317.9,298.5,301.8,299.4,282.9z"/>
	
</svg>









    )
}

export default Imgcolor 