import React from 'react';
import { useDispatch  } from 'react-redux';

import { defineEquipementchange , modifyDisplaymodal} from '../../state/redux';

import ButtonSimple from '../Buttons/ButtonSimple';

import './CardEquipement.scss';

function formatprix(prix){  return prix?   parseFloat(prix)?.toFixed(2).replace('.',','):''}

const CardEquipement = ({equipement})=>{
        const dispatch = useDispatch() ;

        const voir = () =>{dispatch(defineEquipementchange({...equipement})) ; dispatch(modifyDisplaymodal('mod_equipement'));} 

        return (<div className="cardequipement"   >
                        <div>{equipement?.compagnie}</div>
                        <div className="cardequipement_formule" >
                                <div><div className='cardequipement_badge'>{equipement.categorie}</div>     </div>         
                                <div ><span className='cardequipement_texte1'> Numéro contrat : {equipement.num_contrat} </span></div>
                                <div ><span className='cardequipement_texte1'> Date d’effet :</span> <span className='cardequipement_texte2'>{equipement.date_effet}      </span>      </div>     
                                <div ><span className='cardequipement_texte1'> Date d’échéance :</span><span className='cardequipement_texte2'>{equipement.date_signature}   </span></div> 
                        </div>
                        <div className='cardequipement_prix'>
                                <div className='cardequipement_prix'>{formatprix(equipement.prix)}€</div>
                                <div className='cardequipement_par'>par mois</div>
                        </div>
                        <div>
                         <ButtonSimple  texte="Voir"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'   onclick={voir}    disable={false}  />
                        </div>
                </div> )       
             
}

export default CardEquipement