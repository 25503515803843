import axios from "axios";
import { nanoid } from '@reduxjs/toolkit' ; 

import { queryClient } from '../state/queryClient';
import { store } from "../state/redux";
import { convertdate } from "./utils_dates";
import { modifyonelead } from "./actions_lead";




export const enregistrerformuleinterneMemo =async (choicedformule,contrat)=>{ 
    const lead = store.getState().lead.leadactu; 
    const devis = queryClient.getQueryData(['devis',lead?.id]);
    const {id,compagnie,gamme,nom_formule,prix} = choicedformule ;
    
   let datadevis  = {id_client:lead.id_client,id_lead:lead.id,
                    id_s:id,compagnie_s:compagnie, gamme_s:gamme,nom_formule_s:nom_formule,prix_s:prix,
                    id_1:id,compagnie_1:compagnie, gamme_1:gamme,nom_formule_1:nom_formule,prix_1:prix}

    let form1 ={id:devis.id_1,compagnie:devis.compagnie_1, gamme:devis.gamme_1,nom_formule:devis.nom_formule_1,prix:devis.prix_1 }
    let form2 ={id:devis.id_2,compagnie:devis.compagnie_2, gamme:devis.gamme_2,nom_formule:devis.nom_formule_2,prix:devis.prix_2 }
    let form3 ={id:devis.id_3,compagnie:devis.compagnie_3, gamme:devis.gamme_3,nom_formule:devis.nom_formule_3,prix:devis.prix_3 }
    let jsonforms ={[devis.id_1]:form1} ;
    devis.id_2 && (jsonforms[devis.id_2]=form2) ;
    devis.id_3 && (jsonforms[devis.id_3]=form3) ;

    delete jsonforms[datadevis['id_s']?.toString()] ;

    const listeidsautres =  Object.keys(jsonforms) ;

    let id_2 ,compagnie_2 , gamme_2, nom_formule_2 , prix_2 , id_3 ,compagnie_3 , gamme_3, nom_formule_3 , prix_3 ;

    listeidsautres[0] && ({id:id_2,compagnie:compagnie_2, gamme:gamme_2,nom_formule:nom_formule_2,prix:prix_2 }=jsonforms[listeidsautres[0]])
    listeidsautres[0] && (datadevis = {...datadevis,id_2 ,compagnie_2 , gamme_2, nom_formule_2 , prix_2 })

    listeidsautres[1] && ({id:id_3,compagnie:compagnie_3, gamme:gamme_3,nom_formule:nom_formule_3,prix:prix_3 }=jsonforms[listeidsautres[1]]);
    listeidsautres[1] && (datadevis = {...datadevis,id_3 ,compagnie_3 , gamme_3, nom_formule_3 , prix_3 });

   const req = await axios.post(`/contrats/createcontrat`,contrat,{headers:{'Accept':'application/json','Content-Type':'application/json'}});

   modifyonelead({'date_effet':lead.date_effet ,'sign_formule':2 ,'sign_fic':0,'sign_bulletin':0,'id_contrat':req.data.id_contrat });
   
  await  axios.post(`/devis/registerdevis`,datadevis,{headers:{'Accept':'application/json','Content-Type':'application/json'}});
  return req.data.id_contrat
}




export const enregistrerformuleinterne =async (choicedformule,contrat)=>{ 
    const lead = store.getState().lead.leadactu; 
    const devis = queryClient.getQueryData(['devis',lead?.id]);
    const {id,compagnie,gamme,nom_formule,prix} = choicedformule ;
    
   let datadevis  = {...devis,id_s:id,compagnie_s:compagnie, gamme_s:gamme,nom_formule_s:nom_formule,prix_s:prix,}

   const req = await axios.post(`/contrats/createcontrat`,contrat,{headers:{'Accept':'application/json','Content-Type':'application/json'}});

   modifyonelead({'date_effet':lead.date_effet ,'sign_formule':2 ,'sign_fic':0,'sign_bulletin':0,'id_contrat':req.data.id_contrat });
  await  axios.post(`/devis/registerdevis`,datadevis,{headers:{'Accept':'application/json','Content-Type':'application/json'}});
  return req.data.id_contrat
}



export const enregistrerformulelibreMemo = async (contrat)=>{
    const lead = store.getState().lead.leadactu; 
    const devis = queryClient.getQueryData(['devis',lead?.id]);
    
    let devisnew ={id_client:lead.id_client,id_lead:lead.id,
        'id_s' : undefined,'compagnie_s':contrat.compagnie,"gamme_s":contrat.gamme,"nom_formule_s":contrat.formule ,"prix_s":contrat.prix,
        'id_1' : undefined,'compagnie_1':contrat.compagnie,"gamme_1":contrat.gamme,"nom_formule_1":contrat.formule ,"prix_1":contrat.prix ,
        } ;

    let form1 ={id:devis.id_1,compagnie:devis.compagnie_1, gamme:devis.gamme_1,nom_formule:devis.nom_formule_1,prix:devis.prix_1 }
    let form2 ={id:devis.id_2,compagnie:devis.compagnie_2, gamme:devis.gamme_2,nom_formule:devis.nom_formule_2,prix:devis.prix_2 }
    let form3 ={id:devis.id_3,compagnie:devis.compagnie_3, gamme:devis.gamme_3,nom_formule:devis.nom_formule_3,prix:devis.prix_3 }
    let jsonforms ={[devis.id_1]:form1} ;
    devis.id_2 && (jsonforms[devis.id_2]=form2) ;
    devis.id_3 && (jsonforms[devis.id_3]=form3) ;

    const listeidsautres =  Object.keys(jsonforms) ;

    let id_2 ,compagnie_2 , gamme_2, nom_formule_2 , prix_2 , id_3 ,compagnie_3 , gamme_3, nom_formule_3 , prix_3 ;

    listeidsautres[0] && ({id:id_2,compagnie:compagnie_2, gamme:gamme_2,nom_formule:nom_formule_2,prix:prix_2 }=jsonforms[listeidsautres[0]])
    listeidsautres[0] && (devisnew = {...devisnew,id_2 ,compagnie_2 , gamme_2, nom_formule_2 , prix_2 })

    listeidsautres[1] && ({id:id_3,compagnie:compagnie_3, gamme:gamme_3,nom_formule:nom_formule_3,prix:prix_3 }=jsonforms[listeidsautres[1]]);
    listeidsautres[1] && (devisnew = {...devisnew,id_3 ,compagnie_3 , gamme_3, nom_formule_3 , prix_3 });

    const req = await axios({ method: 'POST', url: `/contrats/createcontrat`, data: {...contrat}, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
    
    await axios({ method: 'POST', url: `/devis/registerdevis`, data: devisnew, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
    modifyonelead({'sign_formule':2,'sign_fic':0,'sign_bulletin':0, 'id_contrat':req.data.id_contrat });

    return req.data.id_contrat
}


export const enregistrerformulelibre = async (contrat)=>{
    const lead = store.getState().lead.leadactu; 
    const devis = queryClient.getQueryData(['devis',lead?.id]);
  

    let devisnew ={...devis, 'id_s' : undefined,'compagnie_s':contrat.compagnie,"gamme_s":contrat.gamme,"nom_formule_s":contrat.formule ,"prix_s":contrat.prix, } ;

    const req = await axios({ method: 'POST', url: `/contrats/createcontrat`, data: {...contrat}, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });

    await axios({ method: 'POST', url: `/devis/registerdevis`, data: devisnew, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
    modifyonelead({'sign_formule':2,'sign_fic':0,'sign_bulletin':0, 'id_contrat':req.data.id_contrat });

    return req.data.id_contrat
}

export const sendfiledevis = async(ficheclient,fichier)=>{
    const data = new FormData();
    const nomfichier = `devis${(nanoid(5)).toString()}` ;
    data.append('datajson', JSON.stringify({commercial:ficheclient?.commercial,
                                            courtier:ficheclient?.courtier,
                                            id_client:ficheclient['id_client'],
                                            id_lead:ficheclient['id'],
                                            nomfichier:nomfichier, }) ) ;

    data.append('file',fichier,`/${nomfichier}.${fichier.name.split('.')[1]}`);
    const newdoc = await axios.post(`/files/addclientfiledevis/`, data ,{headers:{'Accept': 'application/json',"Content-Type": "multipart/form-data"}});
    axios.post(`/clients/updateclients/`,{...ficheclient, 'sign_devis':2 , statut_client: 'devisenvoye'});
    return newdoc
}



// A NE PAS DEVELOPPER POUR L INSTANT
export const postsignatureexterne = async({filecontrat,lead,formule,id_contrat})=>{
    const data = new FormData() ;
    data.append('file',filecontrat,`/${'signature'+(new Date()).toISOString().slice(0,16).replace(/\D+/g,'')}.${filecontrat.name.split('.')[1]}`);

    const req = await axios.post(`/signature/uploadfiletosignature/`,{lead,formule,id_contrat}); 
    modifyonelead({'sign_bulletin':1,'id_fic':req.data.id_fic })
}

export const sendlegalsdocs = async ({formule,lead})=>{  
      await axios.post(`/legaldocs/send/`,{formule,lead}); 
}


