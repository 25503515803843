import React from "react";
import { useSelector , useDispatch } from "react-redux";
import axios from "axios";


import { queryClient  } from '../../state/queryClient';
import { initialiseLeadmemo , modifyDisplaymodal} from "../../state/redux";
import { registerdevisfromformules } from "../../utils/utils_devis";

import './ButtonSouscrire.scss' ;


function ButtonSouscrire(){
    const dispatch = useDispatch() ;
    const devisformules = useSelector(state=>state.formules.devisformules) ;
    const lead = useSelector(state=>state.lead.leadactu) ;

    const nbetapes = ['sign_coord','sign_devis','sign_formule','sign_docs','sign_fic','sign_bulletin','sign_legaldocs'].filter(x=>lead[x]===2).length ;

    const click = async()=>{  
        await registerdevisfromformules(lead,devisformules)  ; 
        queryClient.invalidateQueries({ queryKey: ['devis',lead.id]}) ;
        dispatch(initialiseLeadmemo(lead)) ; 
       dispatch(modifyDisplaymodal('mod_contratwin')) ;
  }
 
    return(<div className="boutonsouscrire" onClick={click} >
                <div className="boutonsouscrire_texte" >{nbetapes===7?'Gagné':'Souscrire'} </div>
                <div  className="boutonsouscrire_avancement_container">
                     <div  style={{height:'100%',width: `${nbetapes*100/7}%`,background:'#12B76A'}}  >  </div>  
                </div>
            </div>);
}     


export default ButtonSouscrire