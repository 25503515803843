import React , {useState, useMemo} from 'react'  ;
import { useSelector } from 'react-redux';


import CardFormule from '../../Components/CardsFormule/CardFormule';

import { useDevis } from '../../state/reactquery';

import './ListeFormules.scss';

const classNames = require('classnames');
function  ListeFormules({typeliste}){
  const lead = useSelector(state=>state.lead.leadactu) ;
  const formuleshow= useSelector(state=>state.formules.formuleshow) ;
  const formulesaff= useSelector(state=>state.formules.formulesaffichees);
  const {data: devis} = useDevis(lead?.id) ; 
  const formules = useSelector(state=>state.formules.datas);
  const [choicedformule, setChoicedformule] = useState({}) ;
  const ids_datasformules= formules?.map(x=>x.id);
  const listidformules = [devis?.id_1,devis?.id_2,devis?.id_3]?.filter(x=>x!==undefined && x!==null)
                                                              ?.filter(x=>ids_datasformules.indexOf(x)!==-1);

                                                              
  const formulesdevis = formules.filter(f=>listidformules.includes(f.id));

  const formulesall = useMemo(() => {return [...new Set(formulesaff)].filter(x=>x.prix!==0 && x.prix!==null)}, [formulesaff]);
  const formulesaffichees =  typeliste==='souscription'   ? formulesdevis : formulesall ; 
  return(<div className={classNames('listeformules',typeliste)}>
    {/* <div className={classNames('listeformules',typeliste,{'nonvisible': (formuleshow!==null  && typeliste==='ficheclient')},{'visible': formuleshow===null})}> */}

            {formulesaffichees.length >0 ? formulesaffichees.map(f=><CardFormule key={f.id}
                                                                                formule={f} 
                                                                                typeliste={typeliste} 
                                                                                choicedformule={choicedformule} 
                                                                                setChoicedformule={setChoicedformule}/>)
                                     : <span>Pas de formule.</span>}

        </div>)
}



export default ListeFormules ;

