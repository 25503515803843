import React   from 'react';
import { useSelector} from 'react-redux';



import FicheClientTop from './FicheClientTop';
import FicheClientBottom from './FicheClientBottom';
import PopupMultiTel from '../../Components/Popup/PopupMultiTel';

import './EcranFicheClient.scss';
function EcranFichierClient(){

 
    const popupmultitel = useSelector(state=>state.display.popupmultitel ) ;

    return(<div className="ficheclient">
                {popupmultitel && <PopupMultiTel />}
                <FicheClientTop />
                <FicheClientBottom />
            </div>);
}


export default EcranFichierClient


