import React from "react";


import './ButtonStyle.scss' ;
const classNames = require('classnames');
function ButtonSimple({id,texte,color, size, onclick,containerbouton,disable}){

    return(<div  id={id}  className={classNames(containerbouton , size  ,{'boutondisable':disable })}   onClick={onclick}  >
                <div id={id+'int'}  className={classNames(`boutonbase boutonsimple ${color} ${size}` ,{'boutondisable':disable })}   > {texte}</div>
            </div>)
}     


export default ButtonSimple