

import './MiniTablo.scss'  ; 
const convertdate = (date)=>{ return (new Date(date)).toLocaleString("fr-FR",{timeZone:"Europe/Paris"})}
function MiniTablo({origine,stats={},leads=[]}){


    const csv = ()=>{
        let csvString = [["Date Reception","Email","Telephone","Campagne","Commercial"]] ;
        leads.forEach(item=>csvString.push([convertdate(item.date_origine),item.email,item.telephone,item.campagne,item.commercial]));
        var hiddenElement = document.createElement('a');  
        hiddenElement.href='data:text/csv;charset=utf-8,'+encodeURI(csvString.map(line => line.join(",")).join("\n"));hiddenElement.target='_blank';hiddenElement.download =`${origin}.csv`;  
        hiddenElement.click(); 
    }
    
    const campagnes = stats.listcamp ; 
    return(<div>{Object.keys(stats)?.length > 0 && <table className="rowleadstable">
               <tbody>
                    <tr> <td className='nomcompintab'  colspan={campagnes.length+1}>{origine?.toUpperCase()}</td> </tr>
                    <tr className="thead"><th>Conseiller</th>  {campagnes?.map(camp => <th key={camp} >  {camp?.toUpperCase()}  </th>)} </tr>
                
                        {Object.keys(stats?.['jsonstats']).map((conseiller, index) => <tr className="rowleadsweek" key={index}>
                            <td>{conseiller}</td>
                            {campagnes?.map(camp => <th key={camp} >{stats?.['jsonstats'][conseiller][camp] || 0}</th>)}
                        </tr>)}
                    <tr>
                        <td className='nomcompintab'  colspan={campagnes.length+1}>
                            <button className='bouton rouge' onClick={csv} >CSV</button>
                        </td>
                    </tr>
                </tbody>
            </table>}
            </div>)
}


export default MiniTablo  ; 