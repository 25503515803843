

import React  ,{useState} from "react";
import { useDispatch , useSelector} from "react-redux";
import axios from "axios";

import {  modifyDisplaymodal  , modifyChampsLeadactu ,modifyChampsLeadmemo, allLeads} from "../state/redux";

import './CardChangeEmail.scss' ;

var reg_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const classNames = require('classnames');
function CardChangeEmail(){
    const dispatch = useDispatch()  ;
    const ficheclient = useSelector(state=>state.lead.leadactu);
    const leads = useSelector(state=>state.leads.leads);
    const [confirmmodif,setConfirmmodif ]  = useState(false)
    const [newemail,setNewemail ]  = useState('')


    const confirmchangeemail = async() =>{
   
         await axios({method:'POST',url:`/clients/updateemail`,data:{email:ficheclient.email,newemail,courtier:ficheclient.courtier},headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  } });

        const newlead = {...ficheclient, email : newemail};
        const othersleads = leads.filter(x=>x.id!==ficheclient.id);
       
        dispatch(modifyChampsLeadactu({email : newemail}));
        dispatch(modifyChampsLeadmemo({email : newemail}));
       dispatch(allLeads([...othersleads,newlead]));
       dispatch(modifyDisplaymodal(null)) 
    }

    return(<div className="containergray zindexbig">
               <div className="cardchangeemail" >
                  <div className="cardchangeemail_titre">Souhaitez-vous vraiment modifier l’email ?</div>
                    {  confirmmodif?<>  <div className="cardchangeemail_soustitre">Ancien email</div>
                                        <div className="cardchangeemail_input" >{ficheclient?.email}</div>
                                        <div className="cardchangeemail_soustitre">Nouvel email</div>
                                        <input className="cardchangeemail_input" value={newemail} onChange={(e)=>{setNewemail(e.target.value)}  } /><br/><br/>
                                        <div className={classNames("cardchangeemail_btnorange",{"boutondisable":!reg_email.test(newemail)} )} onClick={confirmchangeemail} >Confirmer</div>
                                        <div className="cardchangeemail_btnblanc" onClick={()=>{dispatch(modifyDisplaymodal(null)) }} >Annuler</div>
                                    </>  :
                                    <>  <div className="cardchangeemail_btnorange" onClick={()=>{setConfirmmodif(true)}} >Modifier</div>
                                        <div className="cardchangeemail_btnblanc" onClick={()=>{dispatch(modifyDisplaymodal(null)) }} >Annuler</div>
                                    </>}
                    </div>
               
            </div>)
}

export default CardChangeEmail