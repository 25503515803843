

import axios from 'axios' ;

import { queryClient } from '../state/queryClient';




export const modifyMailRead = (type,id)=>{
    axios({method:'POST',url :`/email/modifymailread`,data:{id}  ,headers:{'Accept':'application/json','Content-Type':'application/json'} });
    const othermails=  type==='user' ? queryClient.getQueryData(['listmails']).filter(x=>x.id!==id) :
                                    queryClient.getQueryData(['listgestionmails']).filter(x=>x.id!==id); 
    const mailchange = type==='user' ?queryClient.getQueryData(['listmails']).filter(x=>x.id===id)[0] :
                                    queryClient.getQueryData(['listgestionmails']).filter(x=>x.id===id)[0] ; 

    type==='user' && queryClient.setQueryData(['listmails'],[...othermails,{...mailchange, RecordType:'Open'}]);
    type==='gestion' && queryClient.setQueryData(['listgestionmails'],[...othermails,{...mailchange, RecordType:'Open'}]);

}



export const modifyMailFavori = (type, id,favori)=>{
  axios({method:'POST',url :`/email/modifymailfavori`,data:{id,favori}  ,headers:{'Accept':'application/json','Content-Type':'application/json'} });
  let  othermails ; let mailchange ;
  if(type==='user'){
      othermails=  queryClient.getQueryData(['listmails']).filter(x=>x.id!==id);
      mailchange =  queryClient.getQueryData(['listmails']).filter(x=>x.id===id)[0] ;
   }else{
    othermails=  queryClient.getQueryData(['listgestionmails']).filter(x=>x.id!==id);
    mailchange =  queryClient.getQueryData(['listgestionmails']).filter(x=>x.id===id)[0] ;
   }


  type==='user' && queryClient.setQueryData(['listmails'],[...othermails,{...mailchange, 'favori':favori}]);
  type='gestion' && queryClient.setQueryData(['listgestionmails'],[...othermails,{...mailchange, 'favori':favori}]);

}