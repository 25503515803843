import React from "react";


import envlop from '../../images/envlop.svg';
import signaturesend from '../../images/signaturesend.svg';
import devissend from '../../images/devissend.png';



import './Popup.scss'
function Popup({id}){
const images = {"popupsignature":signaturesend, "popupsendmail":envlop  , "popupdevis": devissend  }
const textes = {"popupsignature":"Le  lien signature a été bien envoyé !", "popupsendmail": 'Le  mail a été bien envoyé !'  , "popupdevis": " Le devis a été bien envoyé ! "  }

return(<div id={id}  className="popup no"  >
            <img  src={images[id]}  alt="signaturesend"  />
            <div className="popup_texte" >{textes[id]} </div>
        </div>)
}

export default Popup;


