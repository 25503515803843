import React from "react"; 
import { useSelector } from "react-redux";




import { verifleadgagne_arecycle } from '../../utils/utils_dates';
import { progressions_attentedocuments,progressions_attentesignature,progressions_echecs,progressions_relance , progressions_rdv} from '../../utils/listeprogression';

import TableLeads from "../../Components/TabLeads/TableLeads";

import './DashBoardLeads.scss' ;

function filtredatemois(date){
    let premierdumois =  (new Date((new Date()).getFullYear()+'-'+((new Date()).getMonth()+1)+'-01')).getTime() ;
    return (new Date(date)).getTime()  > premierdumois }


function DashBoardLeads(){

    const {leads , leadsfiltres,choiceliste}= useSelector(state=>state.leads);

    const leadstoday= leads.filter(x=>(x.statut_client==='nontraite')).sort((a,b)=>new Date(new Date(b.date_reception) - new Date(a.date_reception)));
    const leadsrecycle = leads.filter(x=>(verifleadgagne_arecycle(x) )).sort((a,b)=>new Date(new Date(b.date_reception) - new Date(a.date_reception)));
    const leadsrdv= leads.filter(x=>(progressions_rdv.includes(x.statut_client))).sort((a,b)=>new Date(a.date_rdvrelance) -new Date(b.date_rdvrelance) )   ;
    const leadsrelances= leads.filter(x=>((progressions_relance.includes(x.statut_client) &&  x.statut_client!=="gagne") || x.statut_client==="devisenvoye")).sort((a,b)=>new Date(new Date(b.date_rdvrelance) - new Date(a.date_rdvrelance)));
    const leadinjoignables= leads.filter(x=> x.statut_client ==='contact_nrp' || x.statut_client ==='devisenvoye_nrp').sort((a,b)=>new Date(new Date(b.date_lastmodif) - new Date(a.date_lastmodif)));
    const leadswaitsign= leads.filter(x=> progressions_attentesignature.includes(x.statut_client)).sort((a,b)=>new Date(new Date(a.date_lastmodif) - new Date(b.date_lastmodif))); 
    const leadwaitdocs= leads.filter(x=>progressions_attentedocuments.includes(x.statut_client)).sort((a,b)=>new Date(new Date(a.date_lastmodif) - new Date(b.date_lastmodif)));
    const leadshot= leads.filter(x=>x.hot===1&& !progressions_echecs.includes(x.statut_client)  ).sort((a,b)=>new Date(new Date(b.date_lastmodif) - new Date(a.date_lastmodif)));
    const leadsgagnes= leads.filter(x=>x.statut_client==="gagne").filter(x=>filtredatemois(x.date_lastmodif)).sort((a,b)=>new Date(new Date(a.date_lastmodif) - new Date(b.date_lastmodif)));
    const leadsimpayes=  leads.filter(x=>(x?.statut_client?.includes('impaye')));

    
    return(<div className="dashboardleads">  
                {choiceliste ==="newleads" &&  <TableLeads datas={[...leadstoday,...leadsrecycle]} id="newleads" titre="Nouveaux Leads"   />}
                {choiceliste ==="rdv" &&  <TableLeads datas={leadsrdv} id="rdv" titre="RDV à venir"   />}
                {choiceliste ==="relances" &&  <TableLeads datas={leadsrelances} id="relances"  titre="A relancer"    />}
                {choiceliste ==="injoignables" &&  <TableLeads datas={leadinjoignables} id="injoignables"  titre="NRP"   />}
                {choiceliste ==="waitsign" &&  <TableLeads datas={leadswaitsign} id="waitsign" titre="Signatures en attente"  />}
                {choiceliste ==="waitdocs"  &&   <TableLeads datas={leadwaitdocs} id="waitdocs" titre="Documents en attente"  />}
                {choiceliste ==="hot"  &&  <TableLeads datas={leadshot} id="hot" titre="Leads Chauds"  />}
                {choiceliste ==="leadsgagnes" &&  <TableLeads datas={leadsgagnes} id="leadsgagnes" titre="Leads Gagnes"   />}
                {choiceliste ==='impayes' &&  <TableLeads datas={leadsimpayes} id="impayes" titre="Impayés"   />}
                {choiceliste ==='customchoice' &&  <TableLeads datas={leadsfiltres} id="customchoice" titre="Mes Filtres"   />}
            </div>)
}


export default DashBoardLeads