import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useDevis } from '../../state/reactquery';
import { addDevisformule , deleteDevisformule } from '../../state/redux';

import './AjoutDevis.scss';
const classNames = require('classnames'); 

function AjoutDevis({formule={}}){
        const dispatch = useDispatch() ;
        const disablebuttons = useSelector(state=>state.display.disablebuttons);
        const lead = useSelector(state=>state.lead.leadactu) ;
        const devisformules = useSelector(state=>state.formules.devisformules) ;
        const {data: devis} = useDevis(lead?.id) ; 

        const ajoutdevis = ()=>{
                if(!devisformules?.map(f=>f.id)?.includes(formule?.id) && devisformules?.length<3  ){
                  dispatch(addDevisformule(formule))
                }else{   dispatch(deleteDevisformule(formule?.id))}
        }

        const selected =  devisformules?.map(f=>f.id)?.includes(formule?.id) ;
                                      
        return(<div className={classNames("ajoutdevis",{'disablebuttons': disablebuttons && devis})} onClick={ajoutdevis} >
                        <svg width="20" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path className={classNames("svgdevis", {"selected":selected}, {"noselected": !selected} )}  
                                d="M17.3651 2.84172C16.9395 2.41589 16.4341 2.0781 15.8779 1.84763C15.3217 1.61716 14.7255 1.49854 14.1235 1.49854C13.5214 1.49854 12.9252 1.61716 12.369 1.84763C11.8128 2.0781 11.3074 2.41589 10.8818 2.84172L9.99847 3.72506L9.11514 2.84172C8.25539 1.98198 7.08933 1.49898 5.87347 1.49898C4.65761 1.49898 3.49155 1.98198 2.6318 2.84172C1.77206 3.70147 1.28906 4.86753 1.28906 6.08339C1.28906 7.29925 1.77206 8.46531 2.6318 9.32506L3.51514 10.2084L9.99847 16.6917L16.4818 10.2084L17.3651 9.32506C17.791 8.89943 18.1288 8.39407 18.3592 7.83785C18.5897 7.28164 18.7083 6.68546 18.7083 6.08339C18.7083 5.48132 18.5897 4.88514 18.3592 4.32893C18.1288 3.77271 17.791 3.26735 17.3651 2.84172Z"
                                strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                </div>)

}

export default AjoutDevis

