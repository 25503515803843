import React , {useState } from 'react';
import { useSelector , useDispatch} from 'react-redux';

import { defineEcranclientselectleft } from '../../state/redux';

import Client from './Client';
import Notes from '../../Components/Notes/Notes';
import Upload from '../../Components/UploadFiles/Upload';

import './FicheClientBottom.scss';

const leftchoices = {'leftselect1':"Coordonnées",'leftselect2' :"Docs",'leftselect3' :"Notes"}

const classNames = require('classnames');
function FicheClientBottomLeft(){
    const dispatch = useDispatch();
    const [leftselect, setLeftselect] = useState('leftselect1');
    const ecranclientselectleft = useSelector(state=>state.display.ecranclientselectleft);

    return(<div className="ficheclient_bottom_left">
                  <div className="ficheclient_bottom_left_top">
                    {Object.keys(leftchoices).map(id=>(<div key={id}   
                                                            id={id} 
                                                            className={classNames('case',{'selected':ecranclientselectleft===id})}  
                                                            onClick={(e)=>{dispatch(defineEcranclientselectleft(e.target.id))}}>
                                                                           {leftchoices[id]} 
                                                        </div>))  }
                  </div>
                  {ecranclientselectleft==='leftselect1' && <Client />}  
                  {ecranclientselectleft==='leftselect2' &&  <div className='contentupload'><Upload /></div>}
                  {ecranclientselectleft==='leftselect3' && <Notes />}
              </div>);
}


export default FicheClientBottomLeft