import React from "react";
import axios from 'axios' ; 
import { useDispatch , useSelector} from "react-redux";
import { modifyonelead } from "../../utils/actions_lead";
import { rdvgagne } from "../../utils/actions_gagne";
import { addEltHistory } from "../../state/reactquery";
import ButtonImgTop from "../../Components/Buttons/ButtonImgTop";

import {modifystatus_nrp,modifystatus_fauxnum} from "../../utils/actions_modifstatuts";

import { modifyDisplaymodal,modifyDisplaymodalCalendar, modifyQualiflead} from '../../state/redux';
import { finappel } from "../../utils/phone";


import './GroupBtnActionTel.scss';


function GroupBtnActionTel(){
    const dispatch = useDispatch();
    const lead = useSelector(state=>state.lead.leadactu);
    const eventtochange = useSelector(state=>state.calendar.eventtochange);
    const displaymodal = useSelector(state=>state.display.displaymodal);

    const relancerdv = ()=>{dispatch(dispatch(modifyDisplaymodalCalendar('mod_calendarelance')))   }    
    const prendrerdv = ()=>{dispatch(dispatch(modifyDisplaymodalCalendar('mod_calendarrdv'))) }

    const maintientcontrat = () =>{
        axios.post(  `/contrats/maintiencontrat/`,{email: lead.email} );
        modifyonelead({'statut_client':'gagne','hot':0}) ;  // 'recyclage' : 2 , 
        rdvgagne(lead.id); 
        // DEV
        addEltHistory('qualiflead', `<div class='horoline1'>Recyclage: Maintient du contrat </div>`)  

        dispatch(modifyQualiflead(true));
        dispatch(modifyDisplaymodal(null));
    }
    
    const handlebtnechec = ()=>{ dispatch(modifyDisplaymodal('mod_echecs'));}
    const raccroche_nrp =  ()=>{finappel() ; modifystatus_nrp();lead.qualiflead===false &&  dispatch(modifyDisplaymodal('mod_qualiflead'))}
    const raccroche_fauxnum =  ()=>{finappel() ; modifystatus_fauxnum();lead.qualiflead===false &&  dispatch(modifyDisplaymodal('mod_qualiflead'))}
    

    
    return( <div className="grpbtnactiontel" >
                     { lead.recyclage===2 &&  <ButtonImgTop  texte="Maintien"  type='bouton_maintien'  onclick={maintientcontrat} />  }
                        <ButtonImgTop  texte={(eventtochange.id && lead.statut_client?.includes('relance'))?"Modifier Relance":"Relancer"}  type='bouton_relance' onclick={relancerdv}  /> 
                        <ButtonImgTop  texte={(eventtochange.id && lead.statut_client?.includes('rdv'))?"Modifier Rdv":"Rdv"}    type='bouton_rdv' onclick={prendrerdv}  /> 
                        <ButtonImgTop  texte="NRP"  type='bouton_nrp'  onclick={raccroche_nrp}  /> 
                        <ButtonImgTop  texte="Fx Num"  type='bouton_fauxnum' onclick={raccroche_fauxnum} /> 
                        <ButtonImgTop  texte="Echec" type='bouton_echec' onclick={handlebtnechec}   />
                      
                </div>)
}



export default GroupBtnActionTel