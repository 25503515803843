import React , {useState, useEffect} from "react";
import { useSelector , useDispatch } from "react-redux";
import axios from "axios";

import { defineComgestion } from "../../state/redux";
import { useContrats } from "../../state/reactquery";

import { champsobligatoires} from "../../utils/utils_comgestion";


const toto ='' ;

function ComGestionFormulaire({setDisablecontinue}){
    const dispatch = useDispatch() ; 
    const comgestion = useSelector(state=>state.comgestion.value) ;
    const ficheclient = useSelector(state=>state.lead.leadactu);
    const {data: contrats} = useContrats(ficheclient?.email,ficheclient?.courtier) ; 
    const lastcontrat =  contrats?.sort((a,b)=> new Date(b.date_insert) -new Date(a.date_insert))?.[0]; 

    const [listecompagniess, setListecompagniess] = useState({}) ;

    const testchamps = champsobligatoires[comgestion['type_gestion']]?.every(champ=>![null, undefined,''].includes(comgestion[champ]?.trim())) ;
    const testformulaire = testchamps ;

    useEffect(()=>{setDisablecontinue(!testformulaire)},[testformulaire])   ;

    async function fetchCompagnies() { 
        const res = await axios.get(`/compagnies/getall`,{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
        setListecompagniess(res.data) ;
        const test  = res.data.find(x=>x.nom.toUpperCase().replace('-','').replace(' ','')=== lastcontrat?.compagnie?.toUpperCase()?.replace('-','')?.replace(' ',''));
        if(test.id){dispatch(defineComgestion({...comgestion,nom_compagnie:test.nom , email_compagnie:test.email}))}
    }    

   useEffect(() => {fetchCompagnies(); }, []); 


    const definecompagnie =(e)=>{
        const nom_compagnie = e.target.options[e.target.selectedIndex].value ;
        const email_compagnie = e.target.options[e.target.selectedIndex].id ;
        dispatch(defineComgestion({...comgestion,nom_compagnie , email_compagnie}));
        }
    const handlechange = (e) =>{ dispatch(defineComgestion({...comgestion,[e.target.id]: e.target.value}))}

   return(<> <div  className="containerlabelinput"  id="simon">
                <div className="inputtextelabel">Compagnie</div>
                      {listecompagniess?.length >0 && <select value={comgestion.nom_compagnie||''} className='selectcompgamme' onChange={definecompagnie} >
                                                            {listecompagniess?.map((y)=><option key={y.email} id={y.email} value={y.nom} >{y.nom}</option>)}
                                                </select>}
            </div> 
             <div  className="containerlabelinput" >
                <div className="inputtextelabel">Numero de Contrat</div>
                <input id='num_contrat' type="text"  autoComplete="off"     onChange={handlechange} value={comgestion.num_contrat}  />
            </div>
          
            {comgestion.type_gestion==='gestecom'&& <>
                                                <div  className="containerlabelinput" >
                                                    <div className="inputtextelabel">Montant du geste commercial</div>
                                                    <input id='montant_gestecom' type="text"  autoComplete="off"  onChange={handlechange} value={comgestion.montant_gestecom}  />
                                                </div></>}

            {comgestion.type_gestion==='parrainage'&& <><div  className="containerlabelinput" >
                                                            <div className="inputtextelabel">Nom et prénom du filleul</div>
                                                            <input id='nom_filleul' type="text"  autoComplete="off"   onChange={handlechange} value={comgestion.nom_filleul}  />
                                                        </div>
                                                    <div  className="containerlabelinput" >
                                                        <div className="inputtextelabel">Montant de la prime de parrainage</div> 
                                                        <input id='montant_parrainage' type="text"  autoComplete="off"  onChange={handlechange} value={comgestion.montant_parrainage}  />
                                                    </div></>}
                
            {comgestion.type_gestion==='ajoutbeneficiaire'&&<>
                                                    <div style={{'width':'100%'}} >Infomations sur le noubeau beneficiaire</div>
                                                        <div  className="containerlabelinput" >
                                                            <div className="inputtextelabel">Nom</div>
                                                            <input id='nom_newbenef' type="text"  autoComplete="off"   onChange={handlechange} value={comgestion.nom_newbenef}  />
                                                        </div>
                                                        <div  className="containerlabelinput" >
                                                            <div className="inputtextelabel">Prénom</div>
                                                            <input id='prenom_newbenef' type="text"  autoComplete="off"    onChange={handlechange} value={comgestion.prenom_newbenef}  />
                                                        </div>
                                                        <div  className="containerlabelinput" >
                                                            <div className="inputtextelabel">Date de naissance</div>
                                                            <input id='date_naissance_newbenef' type="date"  autoComplete="off"   onChange={handlechange} value={comgestion.date_naissance_newbenef}  />
                                                        </div>
                                                        <div  className="containerlabelinput" >
                                                            <div className="inputtextelabel">Numéro de Sécurite Social</div>
                                                            <input id='numsecu_newbenef' type="text"  autoComplete="off"    onChange={handlechange} value={comgestion.secu_newbenef}  />
                                                        </div>
                                                        <div  className="containerlabelinput" >
                                                            <div className="inputtextelabel">Organisme</div>
                                                            <input id='organisme_newbenef' type="text"  autoComplete="off"    onChange={handlechange} value={comgestion.organisme_newbenef}  />
                                                        </div>
                                                        <div  className="containerlabelinput" >
                                                            <div className="inputtextelabel">Date d'ajout du bénéficiaire</div>
                                                            <input id='date_ajout_newbnef' type="date"  autoComplete="off"    onChange={handlechange} value={comgestion.dateajout_newbnef}  />
                                                        </div>
                                                    </>}  

            {comgestion.type_gestion==='supprbeneficiaire'&&<>
                                                <div style={{'width':'100%'}} >Infomations sur le  beneficiaire</div>
                                                    <div  className="containerlabelinput" >
                                                        <div className="inputtextelabel">Nom</div>
                                                        <input id='nom_supprbenef' type="text"  autoComplete="off"     onChange={handlechange} value={comgestion.nom_supprbenef}  />
                                                    </div>
                                                    <div  className="containerlabelinput" >
                                                        <div className="inputtextelabel">Prenom</div>
                                                        <input id='prenom_supprbenef' type="text"  autoComplete="off"     onChange={handlechange} value={comgestion.prenom_supprbenef}  />
                                                    </div>
                                                    <div  className="containerlabelinput" >
                                                        <div className="inputtextelabel">Date de suppresion (Obligatoire)</div>
                                                        <input id='date_supprbenef' type="date"  autoComplete="off"     onChange={handlechange} value={comgestion.date_supprbenef}  />
                                                    </div> </>} 

                </>)
}


export default ComGestionFormulaire
