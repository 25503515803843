import React  from "react";

import { useAllmails } from "../../state/reactquery";


import EcranEmails from "./EcranEmails";

function  EcranEmailsClients(){
  
    
    const {data: emails} = useAllmails() ; 



 
    return(<EcranEmails emails={emails} type='user' />)
}

export default EcranEmailsClients