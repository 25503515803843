import React  from "react";
import { useSelector } from "react-redux";


import { useContrats } from "../../state/reactquery";
import {useUser} from "../../state/reactquerycabinets";

import CardFormuleContrat from '../../Components/Contrats/CardFormuleContrat';


import './ListeContrats.scss'

function ListeContrats({ecran}){

    const ficheclient = useSelector(state=>state.lead.leadactu);
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const {data: contrats} = useContrats(ficheclient?.email,user?.courtier) ; 

   

    const contratattentesignature = contrats?.filter(x=>x.etat_contrat==='attentesignature')?.sort((a,b)=>(new Date(b.date_insert)).getTime()  - (new Date(a.date_insert)).getTime()  );
    const contratsencours = contrats?.filter(x=>x.etat_contrat==='encours')?.sort((a,b)=>(new Date(b.date_insert)).getTime()  - (new Date(a.date_insert)).getTime()  );
    const contratsautres = contrats?.filter(x=>(x.etat_contrat!=='encours'&& x.etat_contrat!=='attentesignature'&& x.etat_contrat!=='projet'))?.sort((a,b)=>(new Date(b.date_insert)).getTime()  - (new Date(a.date_insert)).getTime()  );
    const contratsprojets = contrats?.filter(x=>  x.etat_contrat==='projet' )?.sort((a,b)=>(new Date(b.date_insert)).getTime()  - (new Date(a.date_insert)).getTime()  );


    return(<>{contratsprojets?.length >0 && <><div className="contrats_section_titre" >Contrat en projet</div>
                                                        {contratsprojets?.map(contrat=><CardFormuleContrat key={contrat.indexe}  contrat={contrat} ecran={ecran}  /> )} </>  } 

            {contratattentesignature?.length >0 && <><div className="contrats_section_titre" >Contrat en attente de signature</div>
                                                            {contratattentesignature?.map(contrat=><CardFormuleContrat key={contrat.indexe}  contrat={contrat} ecran={ecran}  /> )} </>  } 


            {contratsencours?.length >0 && <><div className="contrats_section_titre" >Contrat en cours</div>
                                                        {contratsencours?.map(contrat=><CardFormuleContrat key={contrat.indexe}  contrat={contrat} ecran={ecran}   /> )} </>  } 
            
            {contratsautres?.length >0 && <><div className="contrats_section_titre" >Résiliations contrats extérieurs</div>
                                                        {contratsautres?.map(contrat=><CardFormuleContrat key={contrat.indexe}  contrat={contrat} ecran={ecran}   /> )} </>  }
         </>)


}



export default ListeContrats