

import React  from 'react';

import Contrats from './Contrats';
import Famille from './Famille';
import Upload from '../../Components/UploadFiles/Upload';


import './EcranGestionOneClient.scss';

function EcranGestionOneClient(){

    return(<div className="clientsgestion">
                <div className='ficheclientcontainer' >
                      <Famille />
                      <Contrats  ecran='ecrangestiononeclient'  /> 
                    <div className='containerupload' ><Upload  /></div>  
                </div>
            </div>);

}

export default  EcranGestionOneClient ;

