import React from "react";


import  "./PopupAvertissementSignature.scss"
function PopupAvertissementResil(){
    return(<div id="popuparvertresil"  className="popuparvertsign">
        Veuillez renseigner  l'adresse du prospet
    </div>)
}


export default PopupAvertissementResil