import axios from "axios";
import { store ,  defineJsongammesbulletins,defineListeCompGammes ,defineGammesbulletins} from "../state/redux";

import { queryClient } from '../state/queryClient';






export const findCourtierCompagniesDatas = async()=>{ 
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
    let response = await axios({method:'POST',
                                url :`/compagnies/getCourtierCompagniesDatas`,
                                data:{'courtier':user.courtier},
                                headers:{ 'Accept': 'application/json',
                                        'Content-Type': 'application/json' ,
                                        'Authorization': `Bearer ${localStorage.getItem('token')}`}});
    
    const {jsongammesbulletins} = response.data ;

    store.dispatch(defineJsongammesbulletins(jsongammesbulletins)) ; 


}
