import React from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { modifyDisplaymodal } from "../../state/redux";
import {  useUser} from "../../state/reactquerycabinets";

import ButtonSimple from "../Buttons/ButtonSimple";
import './PopupRadin.scss';


function PopupRadin(){
    const dispatch = useDispatch() ;
    const {data: user} = useUser(localStorage.getItem('token'))  ;


    const demanderessai =async ()=>{
        const {lastname,firstname,nom_courtier} = user ;
        dispatch(modifyDisplaymodal('mod_popuradinconfirm'));
        try { await axios.post(  `/email/experttrial/`, {lastname,firstname,nom_courtier}, { headers: {"Content-Type": "multipart/form-data"}  , 'Authorization': `Bearer ${localStorage.getItem('token')}`});
        }catch (error) { console.error(error); }
        setTimeout(()=>{dispatch(modifyDisplaymodal(null));},5000)
    }

    return(<div  className="containergray">
                <div  className="popupradin"  >
                        <div className="popupradin_titre" >Vous souhaitez accéder à cette fonctionnalité ?</div>
                        <div className="popupradin_texte center">Cette fonctionnalité est incluse dans <span className="popupradin_texte_blue">Mutuello Expert</span> pour une gestion complète de vos dossiers.</div>
                        <div className="popupradin_middle">
                            <div className="popupradin_middle_1">
                                <div className="popupradin_texte  left"> Passez à <span className="popupradin_texte_blue">Mutuello Expert</span> et débloquez :</div>
                                <div className="popupradin_texte  left"><span className="popupradin_texte_blue">•</span> Le tarificateur en live pour des devis instantanés,</div>
                                <div className="popupradin_texte  left"><span className="popupradin_texte_blue">•</span> L'envoi des mandats de résiliation en quelques clics,</div>
                                <div className="popupradin_texte  left wd550"><span className="popupradin_texte_blue">•</span> L'automatisation de vos actes de gestion en 2 clics    directement vers les compagnies et vos clients.</div>
                            </div>
                        
                            <div className="popupradin_texte  center"><span className="popupradin_texte_orange"> Essai gratuit !</span> Profitez sans frais de toutes les fonctionnalités Expert pendant 30 jours. </div>
                            <div className="popupradin_texte  center">  Profitez-en pour découvrir toutes les fonctionnalités premium.</div>
                        </div>
                        <div className="popupradin_btns">
                        <ButtonSimple texte="Fermer"  containerbouton='fullbuton white'  color='bouton_color_blancgris noborder' size='bouton_size_normal'  onclick={()=>dispatch(modifyDisplaymodal(null)) }  /> 
                        <ButtonSimple texte="Demander un Essai Gratuit"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'   onclick={demanderessai}  /> 
                        </div>
                    </div>
                </div>)
}

export default PopupRadin;

