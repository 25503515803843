import React , {useState,useMemo} from "react";
import { useSelector , useDispatch } from 'react-redux'; 

import { addEltHistory  } from "../../state/reactquery";
import { modifyChampsLeadmemo,  modifyDisplaymodal ,initialiseLeadactu ,
         modifyEcran , allLeads} from '../../state/redux';


import { champsobligatoiresignature_client } from "../../utils/leadlistechamps";

import { listejsonall , champsverifs} from "../../utils/leadlistechamps";
import { createclient , updateclients  } from "../../utils/axios_functions";

import { getallformules } from '../../utils/calculstarifs';

import ButtonSimple from "../Buttons/ButtonSimple";
import Infos from './Infos';
import InfosSouscripteur from "./InfosSouscripteur";


import './Formulaire.scss';
const classNames = require('classnames'); 

const tabenfants = ['enfant1','enfant2','enfant3','enfant4','enfant5'] ;
const listechamps = ['regime','civilite','nom','prenom','date_naissance','numsecu','organisme'] ;
function Formulaire({ecran}){
    const dispatch = useDispatch()
    const ficheclient = useSelector(state=>state.lead.leadactu);
    const memofiche = useSelector(state=>state.lead.leadmemo);
    const memoconjoint = useSelector(state=>state.lead.memoconjoint);
    const memoenfants = useSelector(state=>state.lead.memoenfants);
    
    const leads = useSelector(state=>state.leads.leads);


    const nbenfs = useMemo(() => {return ['regime_enf1','regime_enf2','regime_enf3','regime_enf4','regime_enf5'].map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length}, [memofiche]);
    
    const [nbenfants, setNbenfants] = useState(nbenfs);
    const [nbadultes, setNbadultes] = useState(memofiche.date_naissance_conjoint?2:1);


    const changeNbadultes = (e)=>{  e.target.id==='ad1'? setNbadultes(1): setNbadultes(2) ;
                                    e.target.id==='ad1'&& dispatch(modifyChampsLeadmemo({'regime_conjoint': null,'civilite_conjoint': null,'nom_conjoint': null,'prenom_conjoint': null,'date_naissance_conjoint': null,'numsecu_conjoint':null,'organisme_conjoint':null} )) ;
                                    const newmemoficheclient= Object.values(memoconjoint).length===0 ?{"regime_conjoint":memofiche['regime'] || 'general' , "civilite_conjoint": 'M'} : memoconjoint ;
                                    e.target.id==='ad2'&& dispatch(modifyChampsLeadmemo(newmemoficheclient));
                                } 

    const changeNbenfants =(e)=>{
        const nbclick = parseInt((e.target.id).replace('enf',''));
        setNbenfants(nbclick) ; 
        let newenfants = {} ;
        if(nbclick===nbenfants){ return }
        else if(nbclick<nbenfants){
            for(let i=nbclick;i<5 ;i++){listechamps.forEach(champ=>newenfants[listejsonall[tabenfants[i]][champ]] = null)} 
        }
        else if(nbclick>nbenfants){
            for(let i=nbenfants+1;i<=nbclick;i++){
              if(memoenfants[i-1]){    newenfants={...newenfants, ...memoenfants[i-1]}}
              else{   newenfants[listejsonall[tabenfants[i-1]]['regime']]=memofiche['regime'];}
            }
            for(let  i=nbclick+1; i<6 ; i++){listechamps.forEach(champ=>newenfants[listejsonall[tabenfants[i-1]][champ]] = null)}
        }
       
        dispatch(modifyChampsLeadmemo(newenfants));
    }
                              

   
    const nbages = Object.values(listejsonall).map(x=>x.date_naissance).map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length ;
    //const nborganismes = Object.values(listejsonall).map(x=>x.organisme).map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length ;
    const nbnoms = Object.values(listejsonall).map(x=>x.nom).map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length ;
    const nbprenoms = Object.values(listejsonall).map(x=>x.prenom).map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length ;
    const nbnumsecus = Object.values(listejsonall).map(x=>x.numsecu).map(x=>memofiche[x]).filter(x=>x!==undefined && x!==null).length ;
   
    const disableclient = (nbages!== nbadultes + nbenfants) || (memofiche.num_departement === undefined )|| (memofiche.email === undefined) || (memofiche.date_effet=== undefined) || (memofiche.telephone=== undefined) ;
    //const disablesouscription = disableclient || (nbages!==nbnoms)|| (nbages!==nbprenoms)|| (nbages!==nbnumsecus) ; // || (nbages!==nborganismes)
    const disable =  ecran==='souscription'? false : disableclient ;
    const submit = async()=>{ let createdclient={}  ; 
        const testchampssignature = champsobligatoiresignature_client(memofiche)?.every(x=> memofiche[x]!==null  &&  memofiche[x]!==undefined ) ; 
        const ismodifyclient = JSON.stringify(champsverifs.map(x=>memofiche[x]))!==JSON.stringify(champsverifs.map(x=>ficheclient[x])) ;
        const isnewclient = memofiche.id===undefined ;
        const recalculformules = ismodifyclient || isnewclient ;
        const client_new =   {...memofiche,'need_hospi':0,'need_orthodontie':0,'need_protheses':0,'need_implant':0,'need_lentilles':0,'need_lunettes':0,'need_specialiste':0,'need_prevention':0,'statut_client':'nontraite'} ;
        const oldclient_modif = {...memofiche,"sign_formule":0} ;
        const newclient = isnewclient ? client_new : oldclient_modif ;
        newclient['sign_coord'] = testchampssignature ? 2 : 0 ;
 
        // isnewclient && (createdclient = await  createclient(newclient) );
        // const newleadfront = isnewclient ?createdclient: newclient ;
        // !isnewclient && updateclients(newclient) ;
        
        let newleadfront ={}
        if(isnewclient){newleadfront=await  createclient(newclient)}
        else{updateclients(newclient) ;newleadfront=newclient }

        const othersleads = leads.filter(x=>x.id!==newleadfront.id);
        dispatch(initialiseLeadactu({...newleadfront}));
        dispatch(allLeads([...othersleads,newleadfront]));
    
        isnewclient && addEltHistory('enterlead',`<div class='horoline1' >Création du lead sur le CRM.</div>`) ;
        if(ecran!=='souscription'){dispatch(modifyDisplaymodal(null)) ; dispatch(modifyEcran('ficheclient'))  ; }
    
        if(recalculformules) {dispatch(getallformules('recalcul')) ;}
    }

   return( <div className={classNames("monformulaire", ecran )}> 
                <div className={classNames("formulaire_content",ecran )}>
                    <Infos id="souscripteur"  ecran={ecran}   />
                    <InfosSouscripteur ecran={ecran}  />
                    {['conjoint','enfant1','enfant2','enfant3','enfant4','enfant5'].map(id=>(memofiche[listejsonall[id]['regime']]!==undefined && memofiche[listejsonall[id]['regime']]!==null  )&& <Infos id={id}  key={id} ecran={ecran}  nbenfants={nbenfants}  setNbenfants={setNbenfants} />)}
                </div>
                <div className={classNames("formulaire_bottom",ecran )}>
                    <div>
                        { ecran !== 'souscription' && <><span>Adultes</span>
                                                                {["ad1","ad2"].map((x,index)=>(<button id={x}  key={x}  className={classNames("btnnb", {"select": nbadultes===index+1}, {"noselect": nbadultes!==index+1} )} onClick={changeNbadultes}>{index+1}</button>))}
                                                                <span>Enfants</span>
                                                                {["enf0","enf1","enf2","enf3","enf4","enf5"].map((x,index)=>(<button id={x} key={x}  className={classNames("btnnb", {"select": nbenfants===index}, {"noselect": nbenfants!==index} )} onClick={changeNbenfants}>{index}</button>))}</> }
                    </div> 
                    <ButtonSimple  texte="Valider"   color={disable?'bouton_color_disable':'bouton_color_orange'} size='bouton_size_normal'  disable={disable}  onclick={submit}   /> 
                </div>
            </div>  )
}

export default Formulaire