import React, { useEffect ,useState   } from "react";
import {useDispatch,useSelector, batch} from 'react-redux';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { initialiseLeadactu ,modifyEcran,  modifyDisplaymodalCalendar,  
        seteventtochange , modifyDisablebuttons } from '../../state/redux';
        import { searchandinitialiseclient } from "../../utils/actions_lead";
import { useUser } from "../../state/reactquerycabinets";


import { listestatus } from "../../utils/listeprogression";
import { getallevents , orderevents} from "../../utils/actions_calendar";
import { getallformules } from '../../utils/calculstarifs';
import { formatdatefrance , dateheuremin} from "../../utils/utils_dates";

import './ListEvents.scss';

const rdvpro = (y)=>{  return   y.description? ((y.description.indexOf("#ID")) !==-1)  : false }
const cacherdv = (id_divlistejour)=>{ document.getElementById(id_divlistejour) && document.getElementById(id_divlistejour).classList.add('cache_rdvjour'); }
const togglecacherdv = (id_divlistejour)=>{ document.getElementById(id_divlistejour) && document.getElementById(id_divlistejour).classList.toggle('cache_rdvjour'); }
const classNames = require('classnames');

function ListEvents({position}){
   
  let navigate = useNavigate();
  const dispatch = useDispatch(); 
  const {data: user} = useUser(localStorage.getItem('token'))  ;
  const [listevents,setListevents] = useState({})
  const leads = useSelector(state=>state.leads.leads);

  const listcalendarevents= useSelector(state => state.calendar.listcalendarevents);
  const firstrequest= useSelector(state => state.firstrequest);
  const displaycalleft = useSelector(state=>state.display.displaycalleft) ;

  useEffect(()=>{ const liste = orderevents(listcalendarevents.filter(x=>(new Date(x.startDatetime)).getTime()  >= (new Date() ).getTime()-7*24*60*60*1000 ));
                     setListevents(liste) ; 
            },[listcalendarevents])

 useEffect(()=>{  Object.keys(listevents).forEach((x,i)=> { ((new Date(x)).setHours(0, 0, 0, 0)<(new Date()).setHours(0, 0, 0, 0)) && cacherdv('jour'+position+i) }); },[listevents])
  
  async function showclient(e){    searchandinitialiseclient(e.target.parentNode.id,false)  ;  }

  const modifevent =async  (e)=>{  let fiche ;  
      if(leads.filter(x=>x.id===e.target.parentNode.id)[0]){fiche = leads.filter(x=>x.id===e.target.parentNode.id)[0]}
      else{const response = await axios.post(`/clients/searchclientbyid/`,{id : e.target.parentNode.id} ,{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
      fiche = {...response.data}}
      dispatch(initialiseLeadactu(fiche)) ;  
      dispatch(seteventtochange(listcalendarevents.filter(x=>x.id===e.target.id)[0]));
      document.getElementById('detailrdv'+e.target.id)&& document.getElementById('detailrdv'+e.target.id).classList.remove('show') ;
      fiche &&   ( fiche.statut_client?.includes('rdv')? dispatch(modifyDisplaymodalCalendar('mod_calendarrdv'))  :  dispatch(modifyDisplaymodalCalendar('mod_calendarelance'))   );
  }

  useEffect(()=>{ firstrequest.calendar===true && dispatch(getallevents(navigate));},[dispatch,navigate,firstrequest.calendar,user]);

  const descriptif = (y)=>{ let statut_lead  =leads.filter(x=>x.id===y?.description?.split('#ID')?.[1])?.[0]?.statut_client ;
                             const spans = statut_lead?.split('_')?.map((y,i)=> (listestatus[y] &&<span  key={i} className={classNames('minibadge',{"minibadgeechec":statut_lead?.includes('echec')},{"minibadgegagne":statut_lead?.includes('gagne')})}> {listestatus[y]} </span> )) 
                             return statut_lead?.split('_')?.length>0?<div  className='calendarstatut'>{spans}</div> :<></>  }
                       
    const indic_hot = (y)=>{ let lead  =leads.filter(x=>x.id===y.description?.split('#ID')[1])[0] ;
                             return(((y?.description?.split('#ID')[1]) && lead?.hot===1 )&& <div className="calendarflamme"></div>) }
   const alertgooglecalendar = () =>{
      return  localStorage.getItem('google_auth_calendar')==='false'  ? <>Vous n'avez pas validé l'accès au calendrier lors de votre authentification</>   :<></>
   } 
    return (<div  id="listevents"  className={classNames(`listevents ${position}`, {'displaycal':(position==='moveleft' && displaycalleft===true ) } , {'nodisplaycal':(position==='moveleft' && displaycalleft===false) } ,   )} >

                 <div className="listevents_titre">
                          Votre planning
                          <div className="alerte_calendar" >{user?.calendar==='google'&&alertgooglecalendar()}</div>
                  </div>
                <div  className="listedesevents">
                {listevents &&  Object.keys(listevents).map((x,i)=> <div  id={'jour'+position+i}  key={i} className='cursorpointer'  >
                                                              <div  className='formatjour' onClick={()=>{togglecacherdv('jour'+position+i)}} >{formatdatefrance(x)}</div>
                                                              {listevents && listevents[x].map(y=> 
                                                                                                      <div  key={y.id} className={classNames('formatrdv',{'formatrdv_pro':rdvpro(y)},{'formatrdv_nopro':!rdvpro(y)})}>
                                                                                              <div className='formatrdv_heuremin'>{dateheuremin(y.startDatetime)} </div>
                                                                                              <div  className='summary'>
                                                                                                <div> {y.summary}</div>  
                                                                                                 {indic_hot(y) }
                                                                                                 {descriptif(y)}
                                                                                               </div>
                                                                                               <div className="calendaricons"   id={y.description? y.description.split('#ID')[1] : Math.random()}  >
                                                                                                  <div className="calendaricons_changecal" id={y.id} onClick={modifevent} ></div>
                                                                                                  <div className="calendaricons_tofiche" onClick={showclient}  ></div>
                                                                                               </div>
                                                                                 
                                                                                    </div>)}                             
                                                    </div> )}    
                  </div>      
              </div>)
}

export default ListEvents