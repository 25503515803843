import React from "react";


import './ButtonStyle.scss' ;
function ButtonImgLeft({id,texte, color, size,image, onclick, containerbouton }){

    return(<div  id={id} className={containerbouton}    onClick={onclick}  >
            <div className={"boutonbase boutonimgleft "+color+' '+size+' '+image  }  > <div className="interieurbouton" > {texte}</div></div>
    </div>
    )
}


export default ButtonImgLeft