import React , {useEffect, useState} from "react";
import axios from "axios";

import { useCourtiers } from "../../state/reactquerycabinets";

import ButtonImgLeft from "../../Components/Buttons/ButtonImgLeft";


function Courtiers({actucourtier , setActucourtier}){
    const {data:courtiers }= useCourtiers()

    useEffect(()=>{  setActucourtier(courtiers?.[0])  },[courtiers]) ;

    const clickchoice = (courtier)=>{ setActucourtier(courtier)}
      
    return(<div className="ecransuperadmin_grpmenu"  > 
                <div className="ecransuperadmin_menu"  >
                    {courtiers?.map(courtier=>(<div id={courtier.id_courtier}  
                                                    key={courtier.id_courtier} 
                                                    className={actucourtier?.id_courtier===courtier?.id_courtier?"selected":""} 
                                                    onClick={()=>clickchoice(courtier)} >
                                                        {courtier.nom_cabinet||'   '}
                                                </div>))}

                </div>  
                <div className="ecransuperadmin_addcourtier">
                      <ButtonImgLeft texte="Nouveau Courtier"   
                                      color='bouton_color_orange' 
                                      size='bouton_size_normal' 
                                      image='imageplus' 
                                      onclick={()=>{setActucourtier({})}}  /> 
                 </div>
            </div>)
}

export default Courtiers