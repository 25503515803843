import React , { useState } from "react";
import { useDispatch, useSelector} from "react-redux";

import { filterFormules, modifyChampsLeadactu } from '../../state/redux';
import {   defineFormuleShow} from '../../state/redux';

import djerbaredux from "../../utils/djerbaredux";

import './FiltresBesoins.scss';
const besoins = {'need_hospi':'Hospi','need_orthodontie':'Orthodontie','need_protheses':'Prothèses','need_implant':'Implants','need_lentilles':'Lentilles','need_lunettes':'Lunettes','need_specialiste':'Spécialistes','need_prevention':'Prévention'}

const classNames = require('classnames');
function FiltresBesoins(){
    const dispatch = useDispatch();
    
    const {need_hospi,need_orthodontie,need_protheses,need_lentilles,need_lunettes,need_prevention,need_implant,need_specialiste}= useSelector(state=>state.lead.leadactu)
    const [jsonliste , setJsonliste] = useState({need_hospi:need_hospi===1?1:0 ,need_orthodontie:need_orthodontie===1?1:0 ,need_protheses:need_protheses===1?1:0 ,
                                                need_lentilles:need_lentilles===1?1:0 ,need_lunettes:need_lunettes===1?1:0 ,need_prevention: need_prevention===1?1:0,
                                                need_implant:need_implant===1?1:0 ,need_specialiste:need_specialiste===1?1:0 })
    const [tooglebox, setTooglebox] = useState(false)

    const clickBesoin = (e)=>{
        dispatch(modifyChampsLeadactu( {[e.target.id]: jsonliste[e.target.id] !==1?1:0 }));
        setJsonliste({...jsonliste,[e.target.id]: jsonliste[e.target.id] !==1?1:0  })  ;
        dispatch(filterFormules({'type':'liste', 'liste':djerbaredux()}))
    }
    
    const clickallbesoin=async ()=>{ 
        let newneeds = {};
        Object.keys(besoins).forEach(bes=>newneeds[bes]=tooglebox?0:1) ;
        setJsonliste({...newneeds});  
         dispatch(modifyChampsLeadactu({...newneeds}));  
        dispatch(filterFormules({'type':tooglebox?'all':'liste', 'liste':tooglebox?null:djerbaredux()})) ;
        setTooglebox(tooglebox=>!tooglebox)  ; 
    }

    return(<div className="divfiltresbesoins">
                <div className={classNames('filtresnone',{'validate':tooglebox})}   onClick={clickallbesoin}></div>  
                {Object.keys(besoins).map((x,index)=><button id={x} key={x} className={classNames("btn btn_besoins",{'first':index===0},{'last':index===Object.keys(besoins)?.length-1},{'selected':jsonliste[x]===1})} onClick={clickBesoin}>{besoins[x]}</button>)}
            </div>)
}


export default FiltresBesoins;

