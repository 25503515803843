import { VERSION } from 'handlebars';
import { store } from '../state/redux';

//   const listehospis = [64,74,111,121,69,120]
   const p_hospi=(x,n) => {return x.hospi >=n }
   const p_orthodontie=(x,n) => {return x.dentaire_orthodontie_nb >=n }
   const p_implant=(x,n) => {return x.dentaire_implant_nb >=n }
   const p_prothese=(x,n) =>  {return x.dentaire_prothese_nb >=n }
   const p_lentilles=(x,n) => {return x.optique_lentilles_nb >=n }
   const p_lunettes=(x,n) => {return x.optique_lunettes_nb >=n }
   const p_prevention=(x,n) => {return x.prevention >=n }
   const p_specialistes=(x,n) => {return x.consults_specialistes_nb>=n }


 function djerbaredux  (){
        
        const alldatas = store.getState().formules.datas;
        if(alldatas.length===0){return []}

        const ficheclient = store.getState().lead.leadactu; 

        const listecompagnies = store.getState().formules.listecompagniesaffichees;

        const datas = alldatas.filter(x=>listecompagnies.indexOf(x.compagnie)!==-1)
   
        const [hospi,orthodontie,protheses,lentilles,lunettes,prevention,implant,specialistes]=['need_hospi','need_orthodontie','need_protheses','need_lentilles','need_lunettes','need_prevention','need_implant','need_specialiste'].map(x=>ficheclient[x]===1?true:false );

        const monfiltre = (formule) =>{
                const critere_hospi = hospi ?(formule.hospi>2 ): (formule.hospi<6) ;
                const critere_orthodontie = orthodontie ?(formule.dentaire_orthodontie_nb>2 ): (formule.dentaire_orthodontie_nb<6) ;
                const critere_protheses = protheses ?(formule.dentaire_prothese_nb >2 ): (formule.dentaire_prothese_nb <6) ;
                const critere_lentilles = lentilles ?(formule.optique_lentilles_nb>2 ): (formule.optique_lentilles_nb<6) ;
                const critere_lunettes = lunettes ?(formule.optique_lunettes_nb>2 ): (formule.optique_lunettes_nb<6) ;
                const critere_prevention = prevention ?(formule.prevention>2 ): (formule.prevention<6) ;
                const critere_implant = implant ?(formule.dentaire_implant_nb>2 ): (formule.dentaire_implant_nb<6) ;
                const critere_specialistes= specialistes ?(formule.consults_specialistes_nb>2 ): (formule.consults_specialistes_nb<6) ;
        
                return (critere_hospi && critere_orthodontie && critere_protheses &&  critere_lentilles && critere_lunettes  && critere_prevention  && critere_implant  && critere_specialistes  ) ? formule.id : undefined
        }
  
     return datas.map(form=>monfiltre(form)).filter(x=>x!==undefined)
}




export default djerbaredux

