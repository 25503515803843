import React, {useState, useEffect } from "react" ;
import axios from "axios";






import './DashboardFiles.scss';

const url_bucket = `https://${process.env.REACT_APP_AWS_NAMEBUCKET}.s3.eu-west-3.amazonaws.com`
const nomfichier=(compgamme) =>{return compgamme.replace(/([éèëê])/g,"e").replace(/([^a-zA-Z0-9])/g,""); }
const mininomcompagnie = (comp)=>{return comp.replace(/([éèëê])/g,"e").replace(/([^a-zA-Z0-9])/g,"")?.toLowerCase() ; }

function DashboardFiles(){

    const [compagnies, setCompagnies]=useState([]);
    const [resdata, setResdata]=useState({});
    const [logos, setLogos] = useState([]) ; 
    const [listeipids, setListeipids] = useState([]) ; 
    const [listenotices, setListenotices] = useState([]) ; 
    const [listeplaquettes, setListeplaquettes] = useState([]) ; 
    const [listebulletins, setListebulletins] = useState([]) ; 
    const [gammesSurco, setGammesSurco] = useState([]) ; 
    
    const [idloader,setIdloader] = useState(null) ; 
    const jsonSetList ={'ipids':setListeipids,'notices':setListenotices,'plaquettes':setListeplaquettes,'bulletins':setListebulletins}

    async function fetchLogos() {
        const res = await axios.post(`/filesnew/listdocsclientS3`,{'directory':'logoscompagnies'});
        setLogos(res.data.map(x=>x.replace('logoscompagnies/','')))  ;} 

    async function fetchDocs(typedoc) {
        const res = await axios.post(`/filesnew/listdocsclientS3`,{'directory':typedoc});
        jsonSetList[typedoc](res.data);} 

    async function fetchGammes() { 
        const res = await axios.get(`/gammesnew/getallgammesdocs`,{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
        setResdata(res.data); setCompagnies(Object.keys(res.data));
      } 

    async function fetchSurco() { 
      const res = await axios.get(`/gammesnew/getgammessurco`,{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
      setGammesSurco(res.data); 
    } 

  const uploadfileins3 = async (e)=>{
        const [typefile,namefile] = e.target.id.split('/');

        setIdloader(e.target.id);
        const data = new FormData();
        data.append('typefile',typefile) ; 
        data.append('file',e.target.files[0],namefile);
        await axios.post(`/files/addfilecompagnie/`,data,{headers:{'Accept':'application/json',"Content-Type":"multipart/form-data"}}) ;
        await fetchDocs(typefile);
        setIdloader(null);
    }

  const uploadlogoins3 = async (e)=>{
    const [namefile,file] = [e.target.id?.split('/')[1], e.target.files[0]];
    setIdloader(e.target.id);
    const data = new FormData();
    data.append('typefile','logoscompagnies') ; 
    data.append('file',file,namefile);
    await axios.post(`/files/addfilecompagnie/`,data,{headers:{'Accept':'application/json',"Content-Type":"multipart/form-data"}}) ;
    await fetchLogos();
    setIdloader(null);
  }

  useEffect(async()=>{await fetchGammes();fetchSurco() ; 
                       fetchLogos() ;fetchDocs('ipids');fetchDocs('notices');
                       fetchDocs('plaquettes');fetchDocs('bulletins'); },[]) ;

    const loaderimage =  (<div className="loaderdashfile"></div>) ;  
    const keyfile = (typefile,compgamme)=>(`${typefile}s/${typefile}${nomfichier(compgamme)}.pdf`);
    const lienverfichier =(typefile,compgamme)=>(<a className='hrefdashfile' href={`${url_bucket}/${typefile}s/${typefile}${nomfichier(compgamme)}.pdf`} target="blank">lien</a> )

    const deletefile = async (e)=>{
      await axios.post(`/files/deletefilecompagnie/`, {'typefile':e.target.dataset.name,'nomfile':e.target.id   } ,
                                                      {  headers:  { 'Accept': 'application/json',"Content-Type": "application/json"}}) ;

      e.target.dataset.name==='logoscompagnie'?fetchLogos() :  fetchDocs(`${e.target.dataset.name}s`) ;
    }

    const spanfile  = (typefile,compgamme) => {
      const fileinaws = eval(`liste${typefile}s`)?.includes(keyfile(typefile,compgamme)) ;
      return (<>{idloader === keyfile(typefile,compgamme) ?loaderimage:
                fileinaws ?<>{lienverfichier(typefile,compgamme)}<div  className="deldashfile"  id={`${typefile+nomfichier(compgamme)}.pdf`} data-name={typefile} onClick={deletefile} >X</div></>:
              <><label className='labelinputdashfile' htmlFor={ keyfile(typefile,compgamme)} >Upload</label>
                  <input id={keyfile(typefile,compgamme)} className='inputdashfile' type="file" onChange={uploadfileins3} />
              </>}</>)
    }

    const generebulletin = async(comp,gamme)=>{
      const lead ={  "id": "-sFCboQY2Yv2Gu_u55kJs","id_client": "PuuIGKyZzJ9WFPlTwfaNq","id_fic": null,"id_resil": null,"id_contrat": null,"date_origine": "2023-08-02T02:54:15.000Z","recyclage": 0,"statut_client": "nontraite",
                      "sign_coord": 0,"sign_devis": 0,"sign_formule": 0,"sign_docs": 0,"sign_fic": 0,"sign_bulletin": 0,"sign_resil": 0,"sign_legaldocs": 0,
                      "hot": 0,"nbappels": 0,"courtier": "fidelis","campagne": "secu / diane","commercial": "simon","origine": "meilleureassurance.com","type_aquisition": "lead acheté","date_reception": "2023-08-01T14:54:15.000Z",
                      "date_lastmodif": "2024-10-25T05:22:11.000Z","date_rdvrelance": null,"adresse": "6 rue de la Verte vallee","complement_adresse": null,
                      "code_postal": "79015","num_departement": "79","ville": "NIORT","besoins": null, "email": "alice-lejeune@orange.fr",
                      "email2": null,"iban1": null,"iban2": null,"bic1": null,"bic2": null,"telephone": "+337575757575","telephone2": null,
                      "contratsigne": 0,"documents": 0,"profession": "Employé du secteur privé","profession_conjoint": null,"date_effet": "29/11/2024","deja_assure": "Oui","civilite": "Madame","civilite_conjoint": null,
                      "civilite_enf1": 'M.',"civilite_enf2": null,"civilite_enf3": null,"civilite_enf4": null,"civilite_enf5": null,"nom": "Lejeune","nom_conjoint": null,"nom_enf1": 'Selleck',
                      "nom_enf2": null,"nom_enf3": null,"nom_enf4": null,"nom_enf5": null,"prenom": "Alice ","prenom_conjoint": null,"prenom_enf1": 'Tom',"prenom_enf2": null,
                      "prenom_enf3": null,"prenom_enf4": null,"prenom_enf5": null,"date_naissance": "12/03/1950","date_naissance_conjoint": null,"date_naissance_enf1": "19/07/2020","date_naissance_enf2": null,"date_naissance_enf3": null,
                      "date_naissance_enf4": null,"date_naissance_enf5": null,"regime": "independant","regime_conjoint": null,"regime_enf1": 'independant',"regime_enf2": null,"regime_enf3": null,"regime_enf4": null,
                      "regime_enf5": null,"numsecu": null,"numsecu_conjoint": null,"numsecu_enf1": null,"numsecu_enf2": null,"numsecu_enf3": null,"numsecu_enf4": null,"numsecu_enf5": null,
                      "organisme": null,"organisme_conjoint": null,"organisme_enf1": null,"organisme_enf2": null,"organisme_enf3": null,"organisme_enf4": null,"organisme_enf5": null,"need_hospi": 1,
                      "need_implant": 1,"need_lentilles": 1,"need_lunettes": 1,"need_orthodontie": 1,"need_prevention": 0,"need_protheses": 1,"need_specialiste": 1,"formulesigne_compagnie": null,
                      "formulesigne_gamme": null,"formulesigne_formule": null,"num_contrat": null,"formulesigne": null,"formuledevis1": null,"formuledevis2": null,"formuledevis3": null,"sourcedb": null,
                      "simon": "clientscopy","ville_naissance": null,"codepostal_naissance": null }

      const response = await  axios({url: '/ficbulletin/bulletindev/', 
                                    method: 'post',
                                    data:{lead:lead , formule:{'compagnie':comp,'gamme':gamme,'nom_formule':'Formule 2','prix':'172.60' }},
                                    headers:{'Accept':'application/json','Content-Type':'application/json','Authorization':`Bearer ${localStorage.getItem('token')}`},
                                    responseType:'blob'}) ;


       const pdfData= new Blob([response.data], {type:"application/pdf"}) ; 

       const a = document.createElement('a');
       a.href = window.URL.createObjectURL(pdfData);
       const nomfichier=  `contrat${(comp+gamme).replace(/([éèëê])/g,"e").replace(/([^a-zA-Z0-9])/g,"")+(new Date()).getTime()}.pdf`;

       a.setAttribute("download",nomfichier);
       a.setAttribute('target', '_blank');
       a.click()
       a.remove()
       setTimeout(() => window.URL.revokeObjectURL(a.href), 100)
 
    }
    const spanessai = (comp,gamme) => { // 
      const fileinaws = listebulletins?.includes(`bulletins/bulletin${nomfichier(comp+gamme)}.pdf`); 
      return (<>{fileinaws ?<><div  className="deldashfile"  onClick={()=>generebulletin(comp,gamme)} >Essai</div></>:
              <> </>}</>)
    }

    const imageorload = (comp)=>{
      const image = <><img src={`${url_bucket}/logoscompagnies/${mininomcompagnie(comp)}.png`} width="60" alt='imagecompagnie'  />
                      <div   className="dashboardfiles_delimage"  id={`${mininomcompagnie(comp)}.png`} data-name='logoscompagnie' onClick={deletefile} >X</div></> ;
      const uploadimage = <><label className='labelinputdashfile' htmlFor={`logoscompagnies/${mininomcompagnie(comp)}.png`} >Upload</label>
                              <input id={`logoscompagnies/${mininomcompagnie(comp)}.png`} className='inputdashfile' type="file" onChange={uploadlogoins3} /></>;
    return logos.includes(mininomcompagnie(comp)+'.png')?image:uploadimage ;
    }

    const spansurco = (compgam)=>{
     const needfile = gammesSurco?.includes(compgam) ;
     const fileinaws = listebulletins?.includes(`bulletins/bulletin${nomfichier(compgam)}_s.pdf`); 
     const linkaws = <><a className='hrefdashfile' href={`${url_bucket}/bulletins/bulletin${nomfichier(compgam)}_s.pdf`} target="blank">lien</a>
                      <div  className="deldashfile"  id={`bulletin${nomfichier(compgam)}_s.pdf`} data-name='bulletin'onClick={deletefile} >X</div>
                      </>;
     const inputupload =  <>
                              <label className='labelinputdashfile' htmlFor={`/bulletins/bulletin${nomfichier(compgam)}_s.pdf`} >Upload</label>
                              <input id={`bulletins/bulletin${nomfichier(compgam)}_s.pdf`} className='inputdashfile' type="file" onChange={uploadfileins3} />
                          </>
     return needfile?(fileinaws? linkaws:inputupload ):<></>  
    }

    return(<div  className="dashboardfiles_container">
              <div className="dashboardfiles_linetitres">
                  <div></div>
                  <div >
                    <div></div><div >Ipids</div><div >Notices</div><div>Plaquettes</div><div>Bulletins</div><div>Surco</div><div>Essai</div>
                  </div>
              </div>

              {compagnies?.map(comp=><div className="dashboardfiles">
                        
                                    <div className="dashboardfiles_compagnie">
                                        {comp}<br/>{imageorload(comp)}
                                     </div>
                                    <div>
                                      {resdata[comp]?.map(gamme=>
                                            <div className="dashboardfiles_line">
                                                <div className="dashboardfiles_gamme">{gamme}</div>
                                                <div className="dashboardfiles_detail">{spanfile('ipid',comp+gamme)}</div>
                                                <div className="dashboardfiles_detail">{spanfile('notice',comp+gamme)}</div>
                                                <div className="dashboardfiles_detail">{spanfile('plaquette',comp+gamme)}</div>
                                                <div className="dashboardfiles_detail">{spanfile('bulletin',comp+gamme)}</div>
                                                <div className="dashboardfiles_detail">{spansurco(comp+gamme)}</div>
                                                <div className="dashboardfiles_detail">{spanessai(comp,gamme)}</div>
                                            </div>
                                     )}</div>
                  </div>)}
        </div>)

}

export default DashboardFiles