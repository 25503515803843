
import axios from 'axios' ;
import { useQuery } from '@tanstack/react-query';


import { queryClient } from './queryClient';
import { ordergroupe } from "../utils/utils_courtier";


// COURTIER
const queryCourtiers= async()=>{  
  const response =await axios.get(`/courtier/getall`,{headers:{'Accept':'application/json','Content-Type':'application/json','Authorization':`Bearer ${localStorage.getItem('token')}`}});
  return response?.data || []
} 

export const useCourtiers = ()=>useQuery({ queryKey: ['courtiers'], queryFn:async () =>queryCourtiers() ,    staleTime: Infinity  });


// COURTIER
const queryCourtier= async()=>{  
  const  user = queryClient.getQueryData(['user',localStorage.getItem('token')]);
  const response =await axios.post(`/courtier/getinfoscourtier`,{'courtier':user?.['courtier']|| 'fidelis'},{headers:{'Accept':'application/json','Content-Type':'application/json'} }); 
  return response?.data || []
} 

export const useCourtier = ()=>useQuery({ queryKey: ['courtier'], queryFn:async () =>queryCourtier() ,    staleTime: Infinity  });

// EQUIPES
const queryEquipes= async(courtier)=>{  

    const response =await axios.post(`/cabinets/getcabinetequipes`,{'courtier':courtier},{headers:{'Accept':'application/json','Content-Type':'application/json'} }); 

    return  response.data 
} 

export const useEquipes = (courtier)=>useQuery({ queryKey: ['equipes', courtier], queryFn:async () =>await queryEquipes(courtier) ,    staleTime: Infinity });

// EQUIPES_MANAGERS
const queryEquipesManagers= async(listeidmanagers)=>{  if(!listeidmanagers) {return []}; 
  const response =await axios.post(`/cabinets/getequipesmanagers`,{'listeidmanagers':listeidmanagers},{headers:{'Accept':'application/json','Content-Type':'application/json'} }); 
  return response.data?.reduce( (accumulator, {id_equipe,id_manager}) => {
                                      const s =  accumulator[id_equipe];
                                      if(s){ s.add(id_manager) ;}
                                      else{accumulator[id_equipe] = new Set([id_manager])}
                                        return accumulator
                                  }, {}) ;
} 

export const useEquipesManagers = (listeidmanagers)=>useQuery({ queryKey: ['equipes_managers', listeidmanagers], queryFn:async () =>queryEquipesManagers(listeidmanagers) ,    staleTime: Infinity  });

//USERS CABINETS
const queryUsers= async()=>{  
    let response = await axios.get(`/cabinets/getusers`,{headers:{'Accept':'application/json','Content-Type':'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}`} });
    return ordergroupe(response.data)
} 
export const useUsers= (courtier)=>useQuery({ queryKey: ['users',courtier], queryFn: () =>queryUsers() ,    staleTime: Infinity  });


// USER
const queryUser= async(token)=>{  let user  ;
  if(!localStorage.getItem('token') )  {  return  { 'authenticate' : false}   ;}
 try{
  // const  response =  await axios({method:'GET',url:`/users/userauthenticate/`,headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  } });
 const  response =  await axios({method:'GET',url:`/users/userauthenticate/`,headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${token}`}   });
     user =  {...response.data , 'authenticate' : true,} ; 

    response.data.telephone && localStorage.setItem('teltwilio', response.data.telephone);

window.usetifulTags = {userId:response.data.id,segment:response.data.courtier,firstName:response.data.firstname,lastName:response.data.lastname,role:response.data.roles?.join(',')}                                 
 }catch(err){user =  { 'authenticate' : false}   }
 return user
} 

export const useUser = (token)=>useQuery({ queryKey: ['user',token], queryFn: () =>queryUser(token) ,    staleTime: Infinity    });






