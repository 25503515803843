import React , {useState, useEffect } from "react";
import { useMemo } from "react";
import {  useSelector , useDispatch} from 'react-redux';

import { setFileattentes } from "../../state/redux";

import { searchandinitialiseclient } from "../../utils/actions_lead";
import { progressions_nrp } from '../../utils/listeprogression';

import eclair from "../../images/eclair.svg";

import './Periodes.scss'

const listes = ['list1','list2','list3','list4','list5','list6'] ;
let periodesnames = ['Matin','Fin de matinée', 'Midi', 'Après-midi', "Fin d'après-midi", 'Soirée' ];
let intervalle = 2*60*60*1000;

function Periodes(){
  const dispatch = useDispatch();
   let [time1, time2, time3,time4]=[11,13,15,17].map(x=>(new Date()).setHours(x,0,0));

    const num_periode0 = useMemo(()=>{  let now = (new Date()).getTime(); 
                                        return  now<time1?1:now<time2?2:now<time3?3:now<time4?4:5  },[time1,time2,time3,time4]);
    const [num, setNum] = useState(num_periode0);

    const allleads = useSelector(state=>state.leads.leads);
    const leadinjoignables= allleads.filter(x=>progressions_nrp.includes(x.statut_client)).sort((a,b)=>new Date(new Date(b.date_lastmodif) - new Date(a.date_lastmodif)));
       
    const clicktocallist = async()=>{  
        const liste = num===1?newpartage['list1']:num===2?newpartage['list2']:num===3?newpartage['list3']:num===4?newpartage['list4']:newpartage['list5'] ; 
        const premierleadid =  allleads?.filter(x=>x.id===liste[0])?.[0]?.id;
        dispatch(setFileattentes([...liste]))
        searchandinitialiseclient(premierleadid,true)
     }

     const clickautreperiode  =async (e)=>{  
        const numero = parseInt(e.target.id) ; 
        const liste =    numero===1?newpartage['list1']:numero===2?newpartage['list2']:numero===3?newpartage['list3']:numero===4?newpartage['list4']:newpartage['list5'] ; 
        const premierleadid =  allleads?.filter(x=>x.id===liste[0])?.[0]?.id;
        dispatch(setFileattentes([...liste]))
        searchandinitialiseclient(premierleadid,true)  ; 
      }

      const partagenrp = (leads)=>{
             let list1=[] ; let list2=[] ; let list3=[] ; let list4=[] ; let list5=[] ; 
            for(let i=0; i<leads.length; i++ ){
                  let lead = leads[i] ; 
                  let heure= lead.date_rdvrelance? (new Date(lead.date_rdvrelance)).getHours() : (new Date(lead.date_lastmodif)).getHours() ;
                  heure<= 11 ? list1.push(lead['id']):
                  heure<= 13 ? list2.push(lead['id']):
                  heure<= 15 ? list3.push(lead['id']): 
                  heure<= 17 ? list4.push(lead['id']):
                              list5.push(lead['id']);
            }
            return {list1, list2, list3, list4, list5}
      }

   const newpartage = useMemo(()=> partagenrp(leadinjoignables),[leadinjoignables])

    useEffect(()=>{if(!(num>=5 || num===num_periode0)){
                      const interval = setInterval(() => { setNum(num=>num+1);}, intervalle);
                      return () => clearInterval(interval);
                    }
   },[num,num_periode0])

    useEffect(() => {const time_periode0 = num_periode0===1?time1:num_periode0===2?time2:num_periode0===3?time3:num_periode0===4?time4:(new Date()).setHours(22,0,0); 
                      setNum(num_periode0); 
                      const timer = setTimeout(() => {num_periode0 !==5 &&  setNum(num_periode0+1); }, time_periode0 - (new Date()).getTime());
                      return () => clearTimeout(timer);
  }, [num_periode0,time1,time2,time3,time4]);


    return(<div  className="periodes"> 
             <div className="periodes_now"  onClick={clicktocallist} >
                                {periodesnames[num-1]}
                                <img src={eclair}  width="14" height="14"   alt="eclair" />
                                 <span>{(newpartage[listes[num-1]]||[]).length} </span> 
            </div>
             <div className="periodes_groupe" >
                   {  [1,2,3,4,5,6].filter(x=>x>num).map(y=>( <div  key={y} className="periodes_autre"  > <div id={y}  className="periodes_autre_texte" onClick={clickautreperiode} > {periodesnames[y-1]} </div>  <span>{(newpartage[listes[y-1]]||[]).length}</span>  </div>  )) }
                   {  [1,2,3,4,5,6].filter(x=>x<num).map(y=>( <div  key={y} className="periodes_autre"  >   <div   id={y}  className="periodes_autre_texte" onClick={clickautreperiode} >  {periodesnames[y-1]}</div>   <span>{(newpartage[listes[y-1]]||[]).length}</span> </div>  )) }
            </div>
         </div>)
}


export default Periodes