
import React from "react" ;



import './Boulettes.scss' ; 

const classNames = require('classnames');
function Boulettes({total,etape}){


    return(
        <div  className={classNames('boulettes' , 'b'+total) } > 
            {[...Array(total)].map((x,i) =>i)?.map(x=><div key={x}  className={classNames('boulette', {'orange':etape>=x},{'grise':etape<x} ) } ></div>)}
         </div>
   )
}

export default Boulettes