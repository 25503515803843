import React , {useEffect, useState} from "react";

import croixclose from '../../images/croixclose.svg';
import './Mimi.scss' ;
const classNames = require('classnames');
function leadstojson(leads){
    if(!leads || leads.length===0){return {myjson:{}, jsoncamp:[]}}
    let myjson = {} ;
    let jsoncamp ={}

    for(let lead of leads){
        const {origine , campagne }= lead ;
        const [camp ,  com ] = campagne? (campagne?.split('/')?.map(x=>x?.trim())) : ['nocampagne', 'nocommercial']  ; 
        if(!myjson[origine]){ myjson[origine]={} ; jsoncamp[origine]=[]}
        if(!myjson[origine][com]){myjson[origine][com]={}}
        if(!myjson[origine][com][camp]){
            camp!=='crm' && (!jsoncamp[origine]?.includes(camp) &&   jsoncamp[origine]?.push(camp)) ;
            myjson[origine][com]={...myjson[origine][com],[camp]:1}
        }
        else{ myjson[origine][com][camp]=myjson[origine][com][camp]+1}
     
     }
     delete myjson['crm'] ; 
     
     return {myjson, jsoncamp}; 
}

function Mimi({leads , setShowpopupmimi}){

    const [jsonleads  , setJsonleads] = useState({}) ;
    const [jsoncampagnes  , setJsoncampagnes] = useState({}) ;
    const [origines, setOrigines ] = useState([]) ;
    const [choicedorigine , setChoicedorigine ]= useState('') ;

    
    useEffect(()=>{
        const {myjson, jsoncamp} = leadstojson(leads)  ;
        setJsonleads(myjson) ;
        setJsoncampagnes(jsoncamp) ;
        setOrigines(Object.keys(myjson)) ;
        setChoicedorigine(Object.keys(myjson)?.[0])
    
    },[])
    

      const tablo = (origine, jsoncoms)=>{ if(!origine || !jsoncoms){return<></>}
        let campagnes = jsoncampagnes[origine] ;
        const commerciaux = Object.keys(jsoncoms) ;
        
        return ( <table className="mimitable">
                    <tr> <td></td> {campagnes.map(c=><td className="mimitd">{c}</td>)} </tr>
                    {commerciaux.map(com=><tr> <td className="mimitd">{com}</td> {campagnes.map(c=><td className="mimitd">{jsoncoms[com][c]}</td>)} </tr>)}
                </table>);
    
      }


    return(<div  className="containergray">
               <div className="ecranmimi">
               <div className="cardresilitationclose" ><img src={croixclose}  alt="imgclose" onClick={()=>setShowpopupmimi(false) }  /></div>

               {origines?.map(o=><div key={o} className={classNames("mimibtn",{"selected":choicedorigine===o})}  onClick={()=>setChoicedorigine(o)} >{o}</div>)}<br/>
               {tablo(choicedorigine,jsonleads[choicedorigine])}
               </div>
           </div>)
}


export default Mimi