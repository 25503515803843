import React   from "react";


import { deleteNoAuthTarif ,  createNoAuthTarif } from "../utils";
import './TabloAuthsUtilisateur.scss' ;

const classNames = require('classnames');
function TabloAuthsUtilisateur({compagniesgammesaffichees,usernotarif, setUsernotarif}){

    const changeuserauths = (comp,gam,tarif) =>{
        const existNoAuthTarif = usernotarif?.[comp]?.[gam]?.[tarif]   ;
        const copy_usernotarif ={...usernotarif}

        let copyauth ; 
        if(existNoAuthTarif){ copyauth = deleteNoAuthTarif(copy_usernotarif,comp,gam,tarif);
        }else{ copyauth = createNoAuthTarif(copy_usernotarif,comp,gam,tarif);}
        setUsernotarif(copyauth) ;
    }

  
    const linetarif =(index,comp,nom_compagnie,gam,nom_gamme ,tarif, appellation)=>{
    const tarifinterdi = usernotarif?.[comp]?.[gam]?.[tarif] ; 

        return(<div className="tabloauthsutilisateurs_line">
                    <div>{index===0 && nom_compagnie}</div>
                    <div>{index===0 && nom_gamme}</div>
                    <div>{tarif}</div>
                    <div>{appellation}</div>
                    <div className={classNames({'isvalid':!tarifinterdi},{'notvalid':tarifinterdi})}
                         onClick={()=>changeuserauths(comp,gam,tarif)} ></div>
                </div>)
    }

    function divgamme (comp,nom_compagnie,gam,datagamme){
        const {tarifs , nom_gamme }= datagamme ; 
        return (<div className="tabloauthsutilisateurs_divgamme" >{Object.keys(tarifs).map((t,index)=>linetarif(index,comp,nom_compagnie,gam,nom_gamme ,t,tarifs[t]?.appellation))}</div>)
    }

    const grpCompagnie_gammes=(comp,compagniedatas)=>{
        const {gammes,nom_compagnie} = compagniedatas ;
        return(<>{Object.keys(gammes).map(gam=>divgamme(comp,nom_compagnie,gam,gammes[gam]))}</>)
    }

    return(<div className="tabloauthsutilisateurs">
             <div className="tabloauthsutilisateurs_line1" >
                 <div>Compagnie</div><div>Gamme</div><div>Tarification</div><div>Appelation</div><div>Affichage</div>
              </div>
             {Object.keys(compagniesgammesaffichees).sort().map(comp=>grpCompagnie_gammes(comp,compagniesgammesaffichees[comp]))}
    </div>)
}


export default TabloAuthsUtilisateur ;