
import React from 'react';
import './Img.scss'
const classNames = require('classnames'); 
function ImgImplant(props){
	const orange = (props.color==='orange' && props.selected!==true);
    const gris = (props.color==='gris' && props.selected!==true);
    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 447.9 447.9" >
<path className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true},{'svgcolor0_noselect':props.selected!==true &&  props.color!=='orange'})}
 d="M341.9,217.3c-43.7,85.9-76.7,86.3-136.3,86.3s-66.7-57.3-33.2-123.6c40-79,85.3-69.3,103.3-69.3C335.3,110.7,368.3,165.4,341.9,217.3z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})}
 d="M380.5,218h-33.9c5-13.2,8.9-27,10.4-40.4c3.6-33.6-9.6-57.9-39.4-72.3c-28.6-13.8-61.9-1.4-91.2,9.5
	c-14.8,5.6-28.9,10.8-40.1,11.7c-1.7-1.4-3.4-2.7-5-4c-2.6-2-5.1-4-7.5-5.8c-20.6-15.1-44.7-19.4-68-12.3
	c-22.1,6.8-36.4,17.2-43.9,32c-11.7,22.8-4.5,51.2,5.6,81.6H33.1c-3.9,0-7,3.1-7,7s3.1,7,7,7h39.2c0,0.1,0.1,0.2,0.1,0.3l1.1,3.1
	c3,8.8,8.4,18.1,13.6,27.2c6.5,11.3,13.3,23.1,13.4,30.5c0,2.5,0.1,5.1,0.1,7.8c0.3,23,0.7,54.7,7.5,81.6
	c8.6,34.1,25.6,52.4,50.7,54.3c0.1,0,0.3,0,0.5,0c1.5,0,3-0.5,4.2-1.4c13-10,10.5-30.2,7.8-51.6c-4.2-34.4-5.2-59.5,22-69
	c24-8.4,40.1-2.9,50.7,17.4c8.3,16,5.7,36.1,3.3,53.8c-0.5,3.6-0.9,7.1-1.3,10.5c-2.1,19.1,3.1,33.9,13.6,38.7c5,2.3,15.3,4,28.1-10
	c29.6-32.2,29.3-99.1,29.1-135c0-6,6.3-19.5,13.1-33.9c3.6-7.6,7.4-15.8,11.1-24.3h39.4c3.9,0,7.1-3.1,7.1-7
	C387.5,221.1,384.4,218,380.5,218z M74.3,142.6c5.7-11.2,17.3-19.3,35.5-24.9c24.4-7.5,43.7,1.4,55.6,10.2c2.2,1.6,4.5,3.5,7,5.5
	c2.2,1.8,4.6,3.7,7.2,5.7c0.1,0.1,0.2,0.1,0.3,0.2c10.6,8.1,24.6,16.9,44.4,20.5c3.8,0.7,7.4-1.8,8.1-5.6c0.7-3.8-1.8-7.4-5.6-8.1
	c-9.3-1.7-17.2-4.8-24-8.4c9-2.5,18.5-6,28.4-9.7c26.7-10,56.9-21.3,80.2-10c24.6,11.9,34.6,30.4,31.6,58.2
	c-1.4,13.6-5.9,28-11.4,41.8h-75.7v-15.6c0-14.7-12-26.7-26.7-26.7h-44.9c-14.7,0-26.7,12-26.7,26.7V218H82.3
	C72.4,188.7,64.6,161.6,74.3,142.6z M171.6,232h28.2v24.3c0,1.5,0.5,2.9,1.2,4h-16.7c-7,0-12.7-5.7-12.7-12.7V232z M199.8,218h-28.2
	v-15.5c0-7,5.7-12.7,12.7-12.7H201c-0.7,1.1-1.2,2.4-1.2,3.9V218z M213.8,256.3V232h28.1v15.6c0,7-5.7,12.7-12.7,12.7h-16.7
	C213.3,259.2,213.8,257.8,213.8,256.3z M213.8,218v-24.3c0-1.5-0.4-2.8-1.2-3.9h16.6c7,0,12.7,5.7,12.7,12.7V218H213.8z
	 M317.2,250.4c-8.4,17.9-14.4,30.8-14.4,39.9c0.2,34,0.5,97.3-25.4,125.4c-4.9,5.3-9.5,7.9-12,6.7c-3.3-1.5-7.1-10.1-5.5-24.5
	c0.4-3.3,0.8-6.6,1.3-10.2c2.6-19.8,5.6-42.2-4.8-62.1c-10-19-24.8-28.7-43.7-28.7c-7.4,0-15.4,1.5-24.1,4.4
	c-17.7,6.2-28.5,18.6-32.2,36.8c-3,15-0.9,32,1,47.1c1.8,14.9,3.7,30.4-0.8,37c-17-2.7-28.4-17-35.1-43.4c-6.4-25.4-6.7-56-7-78.4
	c0-2.7-0.1-5.3-0.1-7.8c-0.2-11-7.5-23.7-15.2-37.2c-4.6-7.9-9.3-16.2-12-23.4h70.4v15.5c0,14.7,12,26.7,26.7,26.7h44.9
	c14.7,0,26.7-11.9,26.7-26.7V232h69.8C322.8,238.4,319.9,244.6,317.2,250.4z"/>
	
</svg>







    )
}

export default ImgImplant 