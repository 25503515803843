export const champsverifs = ["num_departement","date_effet","date_naissance","regime",
                            "date_naissance_conjoint","regime_conjoint","date_naissance_enf1",
                            "regime_enf1","date_naissance_enf2","regime_enf2",
                            "date_naissance_enf3","regime_enf3","date_naissance_enf4",
                            "regime_enf4","date_naissance_enf5","regime_enf5"]

export const listejsonall ={"souscripteur":{"titre":"Le souscripteur","titre1":"SOUSCRIPTEUR","civilite":"civilite","nom": 'nom', "prenom": "prenom","regime": "regime", "date_naissance": "date_naissance", "numsecu": "numsecu", "telephone": 'telephone', "telephone2": 'telephone2', "telephone3": 'telephone3', "email":"email", "email2":"email2", 
                                                "iban1":"iban1", "iban2":"iban2", "bic1":"bic1", "bic2":"bic2", "organisme": "organisme","complement_adresse":"complement_adresse","ville_naissance":"ville_naissance","codepostal_naissance":"codepostal_naissance"},
                            "conjoint":{"titre":"Le conjoint","titre1":"VOTRE CONJOINT","civilite":"civilite_conjoint","nom": 'nom_conjoint', "prenom": "prenom_conjoint","regime": "regime_conjoint", "date_naissance": "date_naissance_conjoint", "numsecu": "numsecu_conjoint", "organisme": "organisme_conjoint"},
                            "enfant1":{"titre":"Enfant 1","titre1":"ENFANT 1","civilite":"civilite_enf1","nom": 'nom_enf1', "prenom": "prenom_enf1","regime": "regime_enf1","date_naissance": "date_naissance_enf1","numsecu": "numsecu_enf1", "organisme": "organisme_enf1"},
                            "enfant2":{"titre":"Enfant 2","titre1":"ENFANT 2","civilite":"civilite_enf2","nom": 'nom_enf2', "prenom": "prenom_enf2","regime": "regime_enf2","date_naissance": "date_naissance_enf2",  "numsecu": "numsecu_enf2", "organisme": "organisme_enf2"},
                            "enfant3":{"titre":"Enfant 3","titre1":"ENFANT 3","civilite":"civilite_enf3","nom": 'nom_enf3', "prenom": "prenom_enf3","regime": "regime_enf3","date_naissance": "date_naissance_enf3",  "numsecu": "numsecu_enf3", "organisme": "organisme_enf3"},
                            "enfant4":{"titre":"Enfant 4","titre1":"ENFANT 4","civilite":"civilite_enf4","nom": 'nom_enf4', "prenom": "prenom_enf4","regime": "regime_enf4","date_naissance": "date_naissance_enf4",  "numsecu": "numsecu_enf4", "organisme": "organisme_enf4"},
                            "enfant5":{"titre":"Enfant 5","titre1":"ENFANT 5","civilite":"civilite_enf5","nom": 'nom_enf5', "prenom": "prenom_enf5","regime": "regime_enf5","date_naissance": "date_naissance_enf5",  "numsecu": "numsecu_enf5", "organisme": "organisme_enf5"},
}

export const  champsobligatoiresignature =  ['nom','prenom','regime','date_naissance','numsecu','telephone','email','iban1','bic1','organisme',
                                             'nom_conjoint','prenom_conjoint','regime_conjoint','date_naissance_conjoint','numsecu_conjoint','organisme_conjoint',
                                             'nom_enf1','prenom_enf1','regime_enf1','date_naissance_enf1','numsecu_enf1','organisme_enf1',
                                             'nom_enf2','prenom_enf2','regime_enf2','date_naissance_enf2','numsecu_enf2','organisme_enf2',
                                             'nom_enf3','prenom_enf3','regime_enf3','date_naissance_enf3','numsecu_enf3','organisme_enf3',
                                             'nom_enf4','prenom_enf4','regime_enf4','date_naissance_enf4','numsecu_enf4','organisme_enf4',
                                             'nom_enf5','prenom_enf5','regime_enf5','date_naissance_enf5','numsecu_enf5','organisme_enf5'] ;
        
export const  champsdisablesignature =  ['regime','date_naissance','regime_conjoint','date_naissance_conjoint',
                                            'regime_enf1','date_naissance_enf1', 'regime_enf2','date_naissance_enf2',
                                            'regime_enf3','date_naissance_enf3','regime_enf4','date_naissance_enf4',
                                            'regime_enf5','date_naissance_enf5'] ;


export const  champsobligatoiresignature_client = (ficheclient)=>{
    let liste = ['nom','prenom','regime','date_naissance','numsecu','telephone','email','iban1','bic1','adresse'] ;
    ficheclient['iban2'] && liste.push('iban2','bic2') ;
    ficheclient['date_naissance_conjoint'] && liste.push('nom_conjoint','prenom_conjoint','regime_conjoint','date_naissance_conjoint','numsecu_conjoint') ;
    ficheclient['date_naissance_enf1'] && liste.push('nom_enf1','prenom_enf1','regime_enf1','date_naissance_enf1','numsecu_enf1') ;
    ficheclient['date_naissance_enf2'] && liste.push('nom_enf2','prenom_enf2','regime_enf2','date_naissance_enf2','numsecu_enf2') ;
    ficheclient['date_naissance_enf3'] && liste.push('nom_enf3','prenom_enf3','regime_enf3','date_naissance_enf3','numsecu_enf3') ;
    ficheclient['date_naissance_enf4'] && liste.push('nom_enf4','prenom_enf4','regime_enf4','date_naissance_enf4','numsecu_enf4') ;
    ficheclient['date_naissance_enf5'] && liste.push('nom_enf5','prenom_enf5','regime_enf5','date_naissance_enf5','numsecu_enf5') ;
    
    return liste
}
