import { store , seteventtochange} from '../state/redux';
import { addEltHistory } from '../state/reactquery';

import { modifyoneleadechec  } from './actions_lead';
import {tenmonthafter } from './utils_dates';
import { listeechecs } from './listeprogression';
import { deleterdvgooglecalendar , rdvcalendar} from './actions_calendar';


export const modifyechec = (newechec)=>{
    const lead = store.getState().lead.leadactu;
    if(lead.statut_client==='gagne')  return ; 

    const no_cmu_entreprise = (newechec!=='echec_cmu'&& newechec!=='echec_mutuelleentreprise') ;

    let myjson={'statut_client': newechec , 'hot': 0 ,  'recyclage': no_cmu_entreprise? 1:0 ,
                'date_rdvrelance': no_cmu_entreprise? tenmonthafter(lead.date_reception):null  };
 
   addEltHistory('qualiflead', `<div class='horoline1' >Qualification du prospect en <span className=horobold'>Echec ${listeechecs[newechec]} </span>  </div>`)  

    modifyoneleadechec(lead,{...myjson});

    // GESTION CALENDRIER
    let testregexid =  new RegExp(lead.id,'g');
    let listcalendarevents =  store.getState().calendar.listcalendarevents;
    let existerdv = listcalendarevents.filter(y=>y.description).filter(y=>y.description.match(testregexid));

    if(newechec==='echec_cmu'||newechec==='echec_mutuelleentreprise'){
        existerdv.length > 0 && deleterdvgooglecalendar(existerdv[0]['id']);
        store.dispatch(seteventtochange({})); 
    }else{ rdvcalendar(tenmonthafter(lead.date_lastmodif),'Relance Apres Echec'+newechec) }
}

