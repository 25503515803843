import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { modifyechec } from "../../utils/actions_echec";
import {  modifyDisplaymodal ,modifyQualiflead } from '../../state/redux';
import { listeechecs } from "../../utils/listeprogression";


import  iconechecgris from '../../images/iconechecgris.svg'
import croixclose from '../../images/croixclose.svg';


import './Echecs.scss';


function Echecs(){
    const dispatch = useDispatch();
    const qualiflead  = useSelector(state=>state.lead.qualiflead)

    const defineechec=(e)=>{modifyechec(e.target.id);dispatch(modifyQualiflead(true));  dispatch(modifyDisplaymodal(null))   }
    
    const closeaftercall =()=>{ !qualiflead?  dispatch(modifyDisplaymodal('mod_qualiflead')) : dispatch(modifyDisplaymodal(null)) ;   }

    return (<div className="containergray"> 
                <div className="divechecs" >
                     <img src={croixclose}  className="closeechecs" alt="imgclose" onClick={closeaftercall}  />
                    <img  src={iconechecgris}  width="48"  height="48"   alt="iconechecgris" /> 
                    <div className="divechecs_titre1" >Echec</div>
                    <div className="divechecs_titre2" >Veuillez indiquer la raison de l’échec</div>
                    { Object?.keys(listeechecs).map((x)=>(<button key={x} id={x}  className="divechecs_btn"  onClick={defineechec} >{listeechecs[x]}</button>)   ) }
                </div>
            </div>)
}


export default Echecs



