
import React , {useState } from "react" ;
import { useSelector } from "react-redux";
import axios from "axios";
import { nanoid } from '@reduxjs/toolkit';

import { addEltHistory } from "../../state/reactquery";
import { queryClient } from '../../state/queryClient';

import { modifyonelead } from "../../utils/actions_lead";

import './ContratWinFicContratUpload.scss' ; 
function ContratWinFicContratUploadOneFile(){

    const lead = useSelector(state=>state.lead.leadactu) ;

    const [loading, setLoading] = useState(false) ; 

    
    const senddocdejasigne= async (e) =>{ 
        setLoading(true)   ; 
        const filecontrat = e.target.files[0] ; 
        const nomfichier = `contrat${(nanoid(5)).toString()}` ;
        const data = new FormData() ;
        data.append('datajson', JSON.stringify({courtier:lead?.courtier,id_client:lead.id_client ,id_lead:lead.id ,type_file:'signature',commercial:lead?.commercial,nomfichier:`conseil${(nanoid(5)).toString()}` }) )
        data.append('file',filecontrat,`/${nomfichier}.${e.target.files[0].name.split('.')[1]}`);
        await axios.post(`/files/addclientfile/`, data ,{  headers:  { 'Accept': 'application/json',"Content-Type": "multipart/form-data"}  });
        const newlead = lead.sign_docs===2?{...lead,'sign_fic':2,'sign_bulletin':2,'statut_client':'gagne'}:{...lead,'sign_fic':2,'sign_bulletin':2,'statut_client':'attente_documents'}
        modifyonelead(newlead) ;   
        addEltHistory('documents',  `<div class='horoline1' > Fiche Conseil et bulletin signés à l’extérieur  importés.</div>`) ;
        setTimeout(()=>{document.getElementById('uploadficbulletin') && (document.getElementById('uploadficbulletin').value= null )  },1000); 
        queryClient.invalidateQueries({ queryKey: ['documents',lead?.id]}) ; 
        setLoading(false)   ; 
    }

    return( <div className='contratwin_box'>
                            <div className="contratwindevis_upload">
                                    <div className="contratwindevis_upload_texte"  >{loading ?<div className="loadingupload"></div> :'Fiche Conseil et Contrat signée'}</div>
                                    <input type='file' id='uploadficbulletin' className="uploadvalidcontrat_input"
                                            onChange={senddocdejasigne} />
                            </div>
            </div>);
}



export default ContratWinFicContratUploadOneFile



