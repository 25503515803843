import React , {useState} from "react";
import { useSelector , useDispatch} from "react-redux";

import { modifyDisplaymodal } from "../../state/redux";

import { handle_equipment , jsontypeequipement , listecategories , listetypesprix } from "./utils";

import ButtonSimple from "../../Components/Buttons/ButtonSimple";

import uploaddel from '../../images/uploaddel.svg'; 

import './FormEquipement.scss';

const transformdate1 = (date)=>{ return  date.split('/').reverse().join('-') ; }
const transformdate2 = (date)=>{ return  date.split('-').reverse().join('/') ; }
const formatter = new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });

function FormEquipement(){
    const dispatch = useDispatch()  ; 
    const equipementchange = useSelector(state=>state.equipementchange.value)  ;
    const { urls,...equipementactu} = equipementchange ;

    const ficheclient = useSelector(state=>state.lead.leadactu);
    const equipement_initial_vide = {id_lead: ficheclient.id, 
                                    id_client : ficheclient.id_client ,
                                    nom_titulaire: ficheclient.nom,
                                    prenom_titulaire: ficheclient.prenom,
                                    courtier: ficheclient.courtier , 
                                    commercial: ficheclient.commercial ,
                                    email: ficheclient.email ,
                                    categorie:listecategories[0] , 
                                    type:jsontypeequipement[listecategories[0]]?.[0],
                                    compagnie:'',
                                    prix:'',
                                    date_signature:formatter.format(new Date()),
                                    infos:'',
                                    date_effet:formatter.format(new Date()),
                                    num_contrat:'',
                                    typeprix: listetypesprix[0] }   
                                
    const isnewequipement = !equipementchange?.id ;
    const equipement_initial = isnewequipement ?  equipement_initial_vide : equipementactu; 

    const [equipement,setEquipement] = useState(equipement_initial) ;
    const [files, setFiles] = useState([]);

    const changeequipement = (e)=>{  
        const newvalue = e.target.type==='date'?{[e.target.id]:transformdate2(e.target.value)}:
                         e.target.id==='categorie'?{'categorie':e.target.value , 'type':jsontypeequipement[e.target.value][0] }:
                         {[e.target.id]:e.target.value} ;
        setEquipement({...equipement,...newvalue})
    }  

    const selectequipement =(id,titre,liste,size)=>{
        return (<div className={`containerlabelandselect ${size}`}  >
                    <div className="containerlabelandselect_label">{titre}</div>
                    <div className="containerlabelandselect_select" >
                        <select id={id} value={equipement[id]} className='selectcat'  onChange={changeequipement}  >
                            {liste.map(y => <option className='optioncat' key={y} value={y} >{y}</option>)}
                        </select>
                    </div>
                </div>);
    }

    const inputequipement = (id,titre,type,size)=>{
        return(<div className={`containerlabelandselect ${size}`} >
                    <div className="containerlabelandselect_label">{titre}</div>
                    <div id={id} className="containerlabelandselect_select" >
                        <input id={id} type={type}  onChange={changeequipement}
                                value={type==='date'?transformdate1(equipement[id]) :equipement[id] } 
                                pattern={type==='date'?"d{2}/d{2}/d{4}":null} />
                    </div>
                </div>)  ;
    }

    const handleFileChange = (e) => {  setFiles(files=>[...files,...e.target.files]);};
    
    const enregistrer = ()=>{ handle_equipment(equipement,files,isnewequipement) ;dispatch(modifyDisplaymodal(null)) ;  }

    const deletefilefroninput = (e)=>{setFiles([...files].filter(x=>x.name!==e.target.id)) }
    
    return(<div className="containergray"  >
             <div className="equipement">
                <div className="equipement_titre1">Equipement</div>
                <div className="equipement_titre2">Saisir les détails du contrat</div>
                <div className="equipement_formulaire">
                    {selectequipement('categorie','Catégorie d’équipement',listecategories,'half')}
                    {selectequipement('type','Type d’équipement',jsontypeequipement[equipement['categorie']],'half')}
                    {inputequipement('compagnie','Compagnie','text','large')} 
                    {inputequipement('prix','Prix','text','half')} 
                    {selectequipement('typeprix','Type de prix',listetypesprix,'half')}
                    {inputequipement('num_contrat','Numéro de contrat','text','third')} 
                    {inputequipement('date_effet','Date d’effet','date','third')} 
                    {inputequipement('date_signature','Date de signature','date','third')} 
                    {inputequipement('infos','Informations complémentaires','text','large')} 
                </div>
                <div className="equipement_files">
                    <div className="equipement_files_liste">
                        {[...files].map((x, index)=>(<div  key={index}  className='divfilenameinliste' >
                                                            <div className='filenameinliste' id={x.name} key={x.name}   >{x.name}</div>
                                                            <img id={x.name} className="uploaddel" src={uploaddel} alt='imgdel' onClick={deletefilefroninput}   />
                                                    </div>))}
                  </div>
                <input type="file" multiple onChange={handleFileChange} className="uploadvalidcontrat_input" />
                </div>
                <div className="equipement_listefiles">
                {urls?.split(',')?.map((link, index) => (<a key={index} className="messageattachementlinkX" href={link} target="blank" >{link?.split('/')?.reverse()[0]}</a>))}
                </div>
                <div className="equipement_boutons">
                      <ButtonSimple  texte="Fermer" containerbouton='fullbuton white' color='bouton_color_blancgris noborder' size='bouton_size_normal' onclick={()=>{ dispatch(modifyDisplaymodal(null)) }}    />   
                      <ButtonSimple  texte={isnewequipement?"Enregistrer":"Modifier"}  containerbouton='fullbuton' color='bouton_color_orange' size='bouton_size_normal' onclick={enregistrer}   />      
                </div>      
            </div>
            </div>)
}


export default FormEquipement

