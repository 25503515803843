import React from "react";


import './ButtonStyle.scss' ;
const classNames = require('classnames');

function Button({id,texte,color, size,onclick,  disable, loading}){
 
    return(<div id={id}  className={classNames(`boutonbase boutonsimple ${color} ${size}`,{'boutondisable':disable },{'loading': loading}  )}  onClick={onclick} >
                 {texte}
            </div>);
}     


export default Button