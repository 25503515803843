import React  , { useState,  useRef, useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux'; 

import {  modifyChampsLeadmemo ,  modifyDisplaymodal } from '../../state/redux';
import { useUser } from '../../state/reactquerycabinets';

import { validsecu, validtel, valideemail, validnomprenom , validiban , validtrue} from '../../utils/validations';

import { listejsonall , champsobligatoiresignature_client , champsdisablesignature} from '../../utils/leadlistechamps';


import './Infos.scss';
import './TitleInput.scss';

const listenameinput ={nom: 'Nom',prenom: 'Prénom', civilite:"Titre", regime:"Régime",date_naissance: "Date de Naissance",organisme: "Organisme",
                      numsecu:"N. de sécurite sociale",email:'Email' ,email2:'Second Email', telephone:'Téléphone 1', telephone2:'Téléphone 2',
                      complement_adresse:"Complément d'adresse",adresse:"Adresse ou Departement", iban1:"Iban prélèvement", 
                      bic1:"Bic prélèvement",iban2: "Iban remboursement",bic2:"Bic remboursement" ,codepostal_naissance:"Code Postal de Naissance",
                      ville_naissance:"Ville de Naissance"} ;

const validfunctions = {"prenom": validnomprenom, "nom": validnomprenom, "numsecu": validsecu , "telephone": validtel ,  "telephone2": validtel ,  "telephone3": validtel ,
                         "email": valideemail,"email2": valideemail, "iban1": validiban , "iban2": validiban , "organisme": validtrue , "bic1": validtrue , "bic2": validtrue  ,
                          'complement_adresse' :validtrue , 'ville_naissance' :validtrue  , 'codepostal_naissance' :validtrue   }
  
function hide(str){ 
  return  !str?'': str?.length<=8 ? str?.replace(/./gi, 'x'):
          str?.substring(0,str?.length-4).replace(/./gi, 'x')+str?.substring(str?.length-4,);}
                        
                        
const classNames = require('classnames'); 
function TitleInput({nameinput,id,ecran}){
  const elementRef = useRef();
  const dispatch = useDispatch() ; 
  const {data:user} = useUser(localStorage.getItem('token'))  ;

  const hideinfo = ["email","email2","telephone","telephone2","iban1","iban2"].includes(nameinput) && user.showinfos===0 && ecran  ==='ecranclient';

  const fichememo = useSelector(state=>state.lead.leadmemo);
  const lead = useSelector(state=>state.lead.leadactu);
    
    const [valid, setValid] = useState(( fichememo[listejsonall[id][nameinput]]!==null && fichememo[listejsonall[id][nameinput]]!==undefined) ? true : undefined)   
    const [focus, setFocus] = useState(false);
    const valeur =hideinfo ? hide(fichememo[listejsonall[id][nameinput]]) : fichememo[listejsonall[id][nameinput]];

    const [val, setVal] = useState(valeur||'');

    useEffect(()=>{setVal(valeur||'')},[fichememo[listejsonall[id][nameinput]]])
    const copytoclipboard =()=>{  navigator.clipboard.writeText(elementRef.current.value||elementRef.current.placeholder);}
    const changeemail = ()=>{dispatch(modifyDisplaymodal('mod_changemail')) }


    const formatIban = (val) => {  let formattedText = val.split(' ').join('');
                                    if (formattedText.length > 0) { formattedText = formattedText.match(new RegExp('.{1,4}', 'g')).join(' '); }
                                    return formattedText; }

    const handleBlur=(e)=>{setFocus(false);   
                              validfunctions[e.target.name]?validfunctions[e.target.name](e.target.value) ? setValid(true)  : setValid(false) : setValid(true) ;
                              (validfunctions[e.target.name]&&validfunctions[e.target.name](e.target.value)) && dispatch(modifyChampsLeadmemo( {[listejsonall[id][nameinput]] :(nameinput==='iban1' || nameinput==='iban2')?  formatIban(e.target.value) :  e.target.value })); 
                              (validfunctions[e.target.name]&&   !validfunctions[e.target.name](e.target.value)) && dispatch(modifyChampsLeadmemo({[listejsonall[id][nameinput]]: null }));  }
          

    const readonly = ecran==='souscription' ? champsdisablesignature?.includes(listejsonall[id][nameinput]):(listejsonall[id][nameinput]==='email' && lead.email )   ;

    return( <div className="grouptitleinput"> 
                    <div className={classNames("grouptitleinput_containerinput",nameinput,{"valid": valid===true},{"error": valid===false},{"focus": focus} ,{"obliged": ecran==='souscription' && champsobligatoiresignature_client(lead)?.includes(listejsonall[id][nameinput]) && !fichememo[listejsonall[id][nameinput]] } )}> 
                               <div className='grouptitleinput_containerinput_label' >
                                   {listenameinput[nameinput]}
                                   {(ecran==='souscription'&& champsobligatoiresignature_client(lead)?.includes(listejsonall[id][nameinput]))&&<span className='grouptitleinput_containerinput_labelobliged'>*</span>}
                              </div>
                                <input style={{fontSize: '16px'}}  ref={elementRef} value={val} name={nameinput} 
                                       placeholder={val} 
                                       onChange={(e)=>{ setVal(e.target.value)} } 
                                       autoComplete="off" 
                                       onFocus={()=>{(listejsonall[id][nameinput]==='email'&& lead.email)?changeemail() :setFocus(true)}} 
                                       onBlur={handleBlur} 
                                       readOnly={readonly} />

                                <div  className='logocopy' onClick={copytoclipboard}  ></div>
                    </div>
                   
            </div>)
}



export default TitleInput

