
import React  from "react";


import DashboardFiles from "./DashboardFiles";


import './EcranDev.scss'
function EcranDev(){

        return(<div className="ecrandev">
                    <h1>DEV!!!</h1>
                    <DashboardFiles  />

             </div>)
}

export default EcranDev