
import {  googleLogout } from '@react-oauth/google';
import axios from 'axios';


export const handlelogout =()=>{googleLogout();    localStorage.removeItem('token')  ;  }

// function saveavatartolocalstorage(url) {
//   if( window.location.hostname==='localhost'){return }
//     const xhr = new XMLHttpRequest();
//     xhr.open('GET', url); xhr.responseType = 'blob';  xhr.send();
//     xhr.onload = function() {
//       const reader = new FileReader();  reader.readAsDataURL(xhr.response);
//       reader.onloadend = function() { localStorage.setItem('imgavatar',reader.result) }
//     };
// }

export const getrefreshtokenandsave = async (response) => {
    localStorage.setItem('google_auth_calendar',response?.scope?.includes('calendar')? true : false)  ; 
    const  refreshtokenreq = await  axios.post(  `/googleapis/getrefreshtoken`,{code:response.code},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;

    if(refreshtokenreq?.data?.error){   return false } ;
    localStorage.setItem('token',refreshtokenreq?.data?.accessToken)  ; 
    localStorage.setItem('calendar','google')  ; 

    // saveavatartolocalstorage(refreshtokenreq.data.urlavatar);
  return true
}


export const loginmailpass =  async (userdata) => {
  const  refreshtokenreq = await  axios.post(  `/users/login`,{...userdata},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;
  localStorage.setItem('token',refreshtokenreq.data.accessToken) ;
  localStorage.setItem('calendar','mutuello')  ; 
  return refreshtokenreq.data.error ? false : true
}
   


