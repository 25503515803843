import axios from 'axios';
import { redirect } from "react-router-dom";

export async function verifieversion(){ 
    // let toto =await axios.get('https://frontcrm.s3.eu-west-3.amazonaws.com/version.txt' ,{headers: { 'Access-Control-Allow-Origin': '*'}}) ;
   // let toto =await axios.get('https://selchesibucket.s3.eu-west-3.amazonaws.com/docsclients/cabinettest/2023/clients/PLzlXpZGFJqX3n3xFka4I/signature202308311013.txt' ,{headers: { 'Access-Control-Allow-Origin': '*'}})
   let req = await   axios.post('/readversion')  ;
   if(req?.data?.error){  localStorage.clear() ; redirect("/login")  ; return  }
    
    let versionactu = JSON.parse(req?.data||"{}")?.['version']   ; 
    let versionapp = localStorage.getItem('version')  ;

    if(versionactu !== versionapp ){  localStorage.setItem('version',versionactu)   ;  window.location.reload(true)}

    setInterval(()=>{verifieversion()},4*60*60*1000) ;
     
    }
 