import React , {useState} from "react";
import axios from "axios";
import { useSelector , useDispatch } from "react-redux";

import { useUser} from '../../state/reactquerycabinets';

import { imagesorigine } from '../../utils/utils_courtier';
import { convertdateAA } from "../../utils/utils_dates";
import { listestatus } from "../../utils/listeprogression";

import { initialiseLeadactu , modifyEcran } from "../../state/redux";



import PopupReassigner from "../../Components/Popup/PopupReassigner";
import PopupChangeStatut from "../../Components/Popup/PopupChangeStatut";
import PopupSupprimer from "../../Components/Popup/PopupSupprimer";
import Mimi from "../../Components/Popup/Mimi";

import './EcranGestionListe.scss' ;



function finddoublons(leads){
    const emailSet =  new Set();const emaildoublons =[] ;
    for(let lead of leads){
        emailSet.has(lead.email) && emaildoublons.push(lead.email) ;
        emailSet.add(lead.email)
    }
    const doublons =  leads.filter(l=>emaildoublons.includes(l.email))
    return {nb:doublons.length , doublons }
}





const convertdate = (date)=>{ return (new Date(date)).toLocaleString("fr-FR",{timeZone:"Europe/Paris"})}

const classNames = require('classnames');
function EcranGestionListe(){
    const dispatch = useDispatch() ;
    const {data: user} = useUser(localStorage.getItem('token'))  ;

    const {  choiceliste , categories}= useSelector(state=>state.gestion);

    const [checkall, setCheckall]  = useState(false) ;  
    const [listidchange,setListidchange] = useState([]);
    const [showpopupreassigner, setShowpopupreassigner] = useState(false);
    const [showpopupsupprimer, setShowpopupsupprimer] = useState(false);
    const [showpopupchangestatut, setShowpopupchangestatut] = useState(false);
    const [showpopupmimi, setShowpopupmimi] = useState(false);


    const myliste = [...categories?.[choiceliste]]?.sort((a,b)=>new Date(new Date(b.date_origine) - new Date(a.date_origine)))??[] ; 

    const clickall = () =>{setCheckall(!checkall);const newlistid = checkall ?[]:myliste.map(x=>x.id);setListidchange(newlistid);}

    const clickcheckbox = (e) => {
        setListidchange((prevSelected) => {
                if (!prevSelected.includes(e.target.name)) {return [...prevSelected, e.target.name]; }
                else { return prevSelected.filter((current) => current !== e.target.name);}
        });
    };

    const exportcsv =(leads)=>{
        let csvString = [["Date Reception","Email","Telephone","Campagne","Commercial"]] ;
        leads.forEach(item=>csvString.push([convertdate(item.date_origine),item.email,item.telephone,item.campagne,item.commercial]));
        var hiddenElement = document.createElement('a');  
        hiddenElement.href='data:text/csv;charset=utf-8,'+encodeURI(csvString.map(line => line.join(",")).join("\n"));hiddenElement.target='_blank';hiddenElement.download =`${origin}.csv`;  
        hiddenElement.click(); 
    }

    const degrerole = user?.role ==="COMMERCIAL"?1:user?.role ==="MANAGER"?2:user?.role ==="GESTION"?3:user?.role ==="ADMIN"?4:5 ;

    const gotolead =async(id)=>{
        const reqclient = await  axios.post(`/clients/searchclientbyid`,{'id' :id});
        dispatch(initialiseLeadactu(reqclient.data));
        dispatch(modifyEcran('ficheclientgestion')) ; 

    }

    return(<>
    {showpopupmimi && <Mimi  leads={myliste}  setShowpopupmimi={setShowpopupmimi} />}
    {showpopupreassigner && <PopupReassigner setShowpopupreassigner={setShowpopupreassigner} listidchangecom={listidchange}  setListidchange={setListidchange}   /> }
    {showpopupsupprimer && <PopupSupprimer setShowpopupsupprimer={setShowpopupsupprimer} listidsuppression={listidchange} setListidchange={setListidchange}  /> }
    {showpopupchangestatut && <PopupChangeStatut setShowpopupchangestatut={setShowpopupchangestatut} listidchangestatut={listidchange}  setListidchange={setListidchange} /> }
     
              <div  className="gestionleads">
              <div  className="gestionleads_top">
              <div><input type="checkbox"  className={classNames("gestionleads_checkbox", { 'checked':checkall})}  onClick={clickall} /></div>
                  <div>Date de réception</div><div>Nom & Prénom</div>
                  <div>Email</div><div>Commercial</div><div>Origine</div>
                  <div>Campagne</div><div>Statut</div>
              </div>    
              <div  className="gestionleads_tablo">
                 {myliste?.length >0 &&  [...myliste].map(x=><div key={x.id} className="gestionleads_tablo_line">
                                            <div><input type="checkbox" name={x.id} className={classNames("gestionleads_checkbox", { 'checked': listidchange.includes(x.id) })}  onClick={clickcheckbox} /></div>
                                            <div>{convertdateAA(x.date_origine)}</div>
                                            <div>{x.nom} {x.prenom}  </div>
                                            <div className="cursorpointer" onClick={()=>gotolead(x.id)} >{x.email}</div>
                                            <div>{x.commercial}</div>
                                            <div><img height="30" width="30" src={imagesorigine(x.origine) } alt="imageorigine"  />  </div>
                                            <div>{x?.campagne?.split('/')?.[0]}</div>
                                            <div>
                                            {(x?.statut_client?.split('_'))?.map((y,i)=> (listestatus[y] &&<span  key={i} className={classNames('minibadge',{"minibadgeechec":x?.statut_client?.includes('echec')},{"minibadgegagne":x?.statut_client?.includes('gagne')})}> {listestatus[y]} </span> )) }                                             </div>  
                                        </div>) }
               </div>  
           </div>
           <div className="grpbtnflex">
                <div className="exportcsv"  onClick={()=>exportcsv(myliste)} >Exporter CSV</div>
                { degrerole >1  && <div className="reassigner"  onClick={()=>{listidchange?.length > 0 && setShowpopupreassigner(true)}} >Ré-assigner</div> }
                { degrerole >2  && <div className="reassigner"  onClick={()=>{listidchange?.length > 0 && setShowpopupsupprimer(true)}} >Suppression</div> }
                { degrerole >2  && <div className="reassigner"  onClick={()=>{listidchange?.length > 0 && setShowpopupchangestatut(true)}} >Modification Statut</div> }
                { ((degrerole >2 || user.email==='eric@groupefidelis.com') && user.courtier==='fidelis' ) && <div className="mimibtn"  onClick={()=>{setShowpopupmimi(true)}} >Mimi</div> }
           </div>
           

           </>)
}

export default EcranGestionListe ; 