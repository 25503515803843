
import React from 'react';
import './Img.scss'
const classNames = require('classnames'); 
function Imgcolor(props){
	const orange = (props.color==='orange' && props.selected!==true);
    const gris = (props.color==='gris' && props.selected!==true);
    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 447.9  447.9" >





<path id="Tracé_720_00000143601626392785109440000018056742640173966216_"
  className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true},{'svgcolor0_noselect':props.selected!==true &&  props.color!=='orange'})}
 d="M182.5,196.8 c14.2,24.3,28.4,36.3,55.6,35.1c32.1,0,54.4-15.3,59.3-40c7.4-39.6-19.8-92.7-56.9-144.6C204.8,89.2,163.5,151.5,182.5,196.8z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} d="M196.1,394.5c96.4,0,175.5-61.8,175.5-138.4c0,0,0-1.2,0-2.5c0-16.1-14.8-30.9-42-43.3c-3.7-1.2-7.4,0-8.7,3.7
	c-1.2,3.7,0,7.4,3.7,8.7c22.2,8.7,34.6,21,34.6,30.9c0,24.7-66.7,51.9-161.9,51.9S35.4,278.3,35.4,253.6c0-18.5,39.6-42,113.7-49.4
	c3.7,0,6.2-3.7,6.2-7.4s-3.7-6.2-7.4-6.2c-76.6,7.4-126.1,32.1-126.1,63c0,0,0,1.2,0,2.5C20.6,332.7,99.7,394.5,196.1,394.5z
	 M196.1,319.1c63,0,128.5-12.4,158.2-35.8c-16.1,56.9-80.3,98.9-158.2,98.9S54,338.9,37.9,283.3C67.6,306.8,133.1,319.1,196.1,319.1
	z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} d="M307.4,168.3c0-28.4-17.3-54.4-34.6-79.1c-9.9-14.8-19.8-28.4-24.7-42c-2.5-6.2-6.2-7.4-8.7-7.4
	s-6.2,1.2-8.7,6.2c-6.2,13.6-14.8,27.2-26,42c-17.3,26-35.8,51.9-35.8,79.1c0,38.3,30.9,69.2,69.2,69.2
	C276.5,238.8,307.4,206.7,307.4,168.3z M182.5,168.3c0-23.5,17.3-48.2,33.4-71.7c8.7-12.4,17.3-24.7,23.5-37.1
	c6.2,12.4,14.8,24.7,23.5,37.1c16.1,23.5,32.1,48.2,32.1,71.7c0,30.9-24.7,56.9-55.6,56.9C207.2,225.2,182.5,200.5,182.5,168.3z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} d="M245.6,210.4h1.2c18.5-4.9,32.1-22.2,33.4-40.8c0-3.7-2.5-6.2-6.2-6.2s-6.2,2.5-6.2,6.2
	c-1.2,13.6-9.9,26-23.5,29.7c-3.7,1.2-4.9,4.9-4.9,7.4C240.6,207.9,243.1,210.4,245.6,210.4z"/>

	
</svg>


    )
}

export default Imgcolor 