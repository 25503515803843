import React   from "react";
import axios from "axios";


import { deleteauth , createauth } from "./utils";


import './Tablo.scss';

const classNames = require('classnames');

const  line1 = ()=>(<div className="tablocourtiersauths_line1" >
                         <div></div><div>Compagnie</div><div>Gamme</div>
                         <div>Tarification</div><div>Affichage</div>
                    </div>);

function Tablo({allgammes,actucourtier,setActucourtier,listecompagniesaffichees}){

  const changeauthcomgamtarif =(compagnie_mini,gamme_mini,t)=>{
    const {gamme:nom_gamme,compagnie: nom_compagnie }= allgammes?.filter(g=>g.compagnie_mini === compagnie_mini && g.gamme_mini === gamme_mini )?.[0] ;

    const coutierauth=(typeof actucourtier['autorisation'] ==='string')? JSON.parse(actucourtier['autorisation']): {} ; 
    const copycoutierauth = {...coutierauth}  ;
    const auth_compgamtarif = copycoutierauth[compagnie_mini]?.['gammes']?.[gamme_mini]?.['tarifs'][t] ;
     
    let newcourtierauth ; 
    if(!auth_compgamtarif){ newcourtierauth =  createauth(nom_compagnie,nom_gamme,copycoutierauth,compagnie_mini,gamme_mini,t) ;}
    else{  newcourtierauth =  deleteauth(copycoutierauth,compagnie_mini,gamme_mini,t) ;}
    setActucourtier({...actucourtier,'autorisation':JSON.stringify(newcourtierauth)}) ;
 
    axios.post(`/courtier/updateauthtarifs`,
                {id_courtier:actucourtier.id_courtier ,autorisation: newcourtierauth},
                {headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});

  }
    
    const line = (index,compagnie,compagnie_mini,gamme, gamme_mini,t)=>{
        const coutierauth=(typeof actucourtier?.['autorisation'] ==='string')? JSON.parse(actucourtier?.['autorisation']): {} ; 

        const authline = coutierauth?.[compagnie_mini]?.['gammes']?.[gamme_mini]?.['tarifs']?.[t] ;

        return(<div className="tablocourtiersauths_line">
                  <div></div>
                  <div>{index===0 && compagnie}</div>
                  <div>{index===0 && gamme}</div>
                  <div>{t}</div>
                  <div className={classNames({'isselected':authline},{'notvalid':!authline})}
                        onClick={()=>changeauthcomgamtarif(compagnie_mini,gamme_mini,t)} >
                  </div>
              </div>)
    }

    function divgamme(compagnie,gam){
        const {compagnie_mini,gamme, gamme_mini, type_tarif} = gam ;
        return (<div className="tablocourtiersauths_divgamme" >
                  {type_tarif?.split(',')?.map((t,index)=>line(index,compagnie,compagnie_mini,gamme, gamme_mini,t))}
                </div>);
    }

    const grpCompagnie_gammes=(compagnie,gammes)=>(<>{gammes.map(gam=>divgamme(compagnie,gam))}</>);
  

    return(<div className="tablocourtiersauths">
              {line1()}
              {listecompagniesaffichees.map(compagnie=>grpCompagnie_gammes(compagnie,allgammes.filter(ag=>ag.compagnie===compagnie)))}
          </div>)
}




export default Tablo;