import React from 'react';


import './CardFormuleJauges.scss' ;
const classNames = require('classnames');

const listepostes =['hospi','consults_specialistes','optique_lunettes','optique_lentilles','dentaire_prothese','dentaire_implant','dentaire_orthodontie','prevention']

const Mapetitejauge =({nb,j})=>{return(<div className={`containtimagenivos imageposte ${listepostes[j]} `}   >
                                <div className='containerjauge' > 
                                  {[0,1,2,3,4].map((i)=>{return  (<div key={i}  className={classNames('containtimagenivos_nivo',{'bleu': i>=5-nb})}></div>) })}
                                  </div>
                                  </div>)}

const  CardFormuleJauge = ({hospi,consults,optique_lunettes_nb,optique_lentilles_nb,dentaire_prothese_nb,dentaire_implant_nb,dentaire_orthodontie_nb,prevention})=>{

const listnb_formule = [hospi,consults,optique_lunettes_nb,optique_lentilles_nb,dentaire_prothese_nb,dentaire_implant_nb,dentaire_orthodontie_nb,prevention]


return(<div className='cardformulejauge'> 
            {[1,2,3,4,5,6,7,8].map((x,j)=>{return(<Mapetitejauge  key={x} j={j} nb={listnb_formule[j]} />)})}
        </div>)

}

export default  CardFormuleJauge ;


