
import  React  ,{ useEffect} from 'react';

import DashTemplates from './DashTemplates';
import Editor from '../../Editor/Editor';
import Editor2 from '../../Editor/Editor2';
import JeResile from '../../Components/JeResilie/JeResilie';
import { initialiseaircall , phonesend} from "../../utils/aircall";
import ContratWinFicContrat from '../../Components/ContratWin/ContratWinFicContrat';


import './EcranDev2.scss' ;
function EcranDev2(){

// useEffect(()=>{initialiseaircall()},[])

const essaitelephone=()=>{
    phonesend('+33756921355')
}

        return(<div className="ecrandevff">
            <h1>DEV2</h1>
            {/* <button  onClick={essaitelephone}>Cal!!!</button> */}

            {/* <div id='aircallphone' ></div> */}

            <JeResile  />
{/* <DashTemplates /> */}
{/* <Editor />   
<Editor2 />    */}

             </div>)
}

export default EcranDev2