import React from 'react';
import { useDispatch  } from 'react-redux';

import { defineContratchange ,modifyDisplaymodal} from '../../state/redux';
import { deleteContrat } from '../../state/reactquery';
import { useUser } from '../../state/reactquerycabinets';

import { logocompagnie } from '../../utils/logocompagnie';

import lowcost from '../../images/lowcost.svg';
import lineaire from '../../images/lineaire.svg';

import './CardFormuleContrat.scss';

function formatprix(prix){  return prix?   parseFloat(prix)?.toFixed(2).replace('.',','):''}

const classNames = require('classnames');

const CardFormuleContrat = ({contrat,ecran})=>{
      const dispatch= useDispatch();
      const logo  = logocompagnie(contrat?.compagnie?.toUpperCase());

      const {data: user} = useUser(localStorage.getItem('token'))  ;
      const degrerole = user?.role ==="COMMERCIAL"?1:user?.role ==="MANAGER"?2:user?.role ==="GESTION"?3:user?.role ==="ADMIN"?4:5 ;
      const clickcard = (e) =>{  
        if(ecran==='win'){return   }
        if(e.target.classList.value==='deletecontrat'){  deleteContrat(contrat.indexe) }
        else{dispatch(defineContratchange(contrat))  ;dispatch(modifyDisplaymodal('mod_modifcontrat'))}
      }

        return (<div className={classNames("cardcontrat",{'cardcontrat_attentesignature':contrat?.etat_contrat==='attentesignature' } ,{'noaction':(  degrerole >2   && contrat?.etat_contrat==='attentesignature' ) }     )} onClick={clickcard}  >
                        <div className='cardcontrat_image' >
                        {logo ? <img src={logo}  width='70' alt='logoCompagnie'/> : <div className='pasdelogo' ><div>{contrat?.compagnie}</div></div>}
                        </div>
                        <div className="cardcontrat_formule" >              
                                <div className="cardcontrat_formule_line1"> {contrat.gamme} </div>
                                <div className="cardcontrat_formule_line2"> {contrat.formule}</div>     
                                {contrat?.lowcost===1 &&<div><img height="20"  src={lowcost} alt='logolowcost'    /></div> }
                                {contrat?.lineaire===1 &&<span><img height="20"  src={lineaire} alt='logolowcost'    /></span> }
                        </div>
                        <div  className="cardcontrat_details" > 
                        {(ecran==='ecrangestiononeclient' && contrat.commercial )&&<div className='cardcontrat_details_titulaire'>Commercial: {contrat.commercial?.toUpperCase()}</div>}
                        {(contrat.nom_titulaire || contrat.prenom_titulaire) && <div className='cardcontrat_details_titulaire'><span className='lightgray'>{contrat.nom_titulaire||''} {contrat.prenom_titulaire||''} </span> </div>}
                                <div className='cardcontrat_details_numcontrat' >Contrat nº {contrat.num_contrat?contrat.num_contrat:''}</div>  
                        {contrat.date_signature && <div className='cardcontrat_details_dates' ><span className='graybold' >Date de signature: </span>{contrat.date_signature && <span className='lightgray' >{new Date(contrat.date_signature).toLocaleDateString('fr-FR') }</span>}   </div>  }
                        {contrat.date_effet &&  <div className='cardcontrat_details_dates' ><span className='graybold' >Date d'adhesion: </span>{contrat.date_effet && <span className='lightgray' >{contrat.date_effet}</span>}   </div>       }
                                {( degrerole >2  && contrat.date_fincontrat)&&<div className='cardcontrat_details_dates' ><span className='graybold' >Date fin  contrat :</span><span className='lightgray' >{new Date(contrat.date_fincontrat).toLocaleDateString('fr-FR')}</span></div>}     
                        </div>
                        <div className='cardcontrat_prix'>
                                <div className='cardcontrat_prix_line1'>{formatprix(contrat.prix)}€</div>
                                <div className='cardcontrat_prix_line2'>par mois</div>
                        </div>
                        {(contrat.etat_contrat!=='projet' &&  degrerole >2 )&&  <div className='indiccontrat' >{contrat.etat_contrat}</div>}
                        {(contrat.etat_contrat!=='projet' &&  degrerole >2  )&&  <div className='deletecontrat' >X</div>}
                </div> )       
             
}

export default CardFormuleContrat