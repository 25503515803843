
import React , { useState , useEffect }  from "react";
import { useSelector  } from "react-redux";
import axios from "axios";

import { useDevis } from "../../state/reactquery";

import Check from "../Check";
import ContratWinFicSend from "./ContratWinFicSend";
import ContratWinFicContratSend from "./ContratWinFicContratSend";
import ContratWinFicContratUploadOneFile from "./ContratWinFicContratUploadOneFile";
import ContratWinFicContratUploadTwoFiles from "./ContratWinFicContratUploadTwoFiles";

import ContratWinAlert from "./ContratWinAlert";


import './ContratWinFicContrat.scss' 
const nombulletin  =(compagnie , gamme) =>{
    return(`bulletin`+compagnie+gamme).replace(/(['])/g,"").replace(/(["’ \-+])/g,"").replace(/([é])/g,"e").replace(/([è])/g,"e")+'.pdf' ;
  }
function ContratWinFicContrat(){
    const [loadingpage, setLoadingpage] = useState(true)
        const lead = useSelector(state=>state.lead.leadactu) ;
  
        const {data: devis} = useDevis(lead?.id) ; 

        const [bulletinexiste, setBulletinexiste] = useState(true) ;
        const [choice, setChoice] = useState(1) ; 

        const alert = lead.sign_coord!==2  || lead.sign_formule!==2  ;
        

        async function fetchBulletinsAndTest() {
            const res = await axios.post(`/filesnew/listdocsclientS3`,{'directory':'bulletins'});
            const test =res.data?.map(x=>x?.replace('bulletins/',''))?.includes(nombulletin(devis?.compagnie_s,devis?.gamme_s)) ;
            setBulletinexiste(test?true : false ) ;
            setChoice(test?1:0) ;
            setLoadingpage(false) ;
        } 

            useEffect(()=>{  fetchBulletinsAndTest();     },[devis?.compagnie_s]) ;

    
        return(<>{alert ? <ContratWinAlert etape='etape_bulletin'
                                            nocoords = {lead.sign_coord!==2} 
                                            noformule ={lead.sign_formule!==2}
                                             />:<>
         <>{loadingpage?<></>  :
                <> 
                <div className="contratwin_left_titre" >Envoi et signature de la fiche Info & Conseils et du contrat et des documents légaux</div>
                <div className='ficheconseil_textetop'>Pour être en conformité avec l’article L521-4 du Code des assurances, vous devez faire signer cette fiche par le client.</div>
                <div>
                {!bulletinexiste && <div className="bulletinfic_choix"><Check checked={choice===0} onclick={()=>setChoice(0)}  /><div><span>Signer la Fiche Conseil</span> avec Yousign</div> </div>}
                {bulletinexiste && <div className="bulletinfic_choix"><Check checked={choice===1} onclick={()=>setChoice(1)}  /><div><span>Signer Bulletin + FIC</span> avec Yousign</div> </div>}
                    <div className="bulletinfic_choix"><Check checked={choice===2} onclick={()=>setChoice(2)} /><div><span>Bulletin + FIC  déjà signé</span> en un seul document</div></div>
                    <div className="bulletinfic_choix"><Check checked={choice===3} onclick={()=>setChoice(3)} /><div><span>Bulletin + FIC déjà signés</span> en deux documents séparés </div></div>
                </div>
                {choice===0?<ContratWinFicSend />:
                choice===1?<ContratWinFicContratSend />:
                choice===2?<ContratWinFicContratUploadOneFile />:
                            <ContratWinFicContratUploadTwoFiles />}
            </>  }</></>}</>
            
        );
}

export default ContratWinFicContrat ;

