

import axios from 'axios';

import { useSelector , useDispatch} from 'react-redux';

import { useUser } from '../state/reactquerycabinets';

import { defineAttachments } from '../state/redux';



export  function useDownloadDevis(){
        const dispatch = useDispatch() ;
        const lead = useSelector(state=>state.lead.leadactu);
        const devisformules = useSelector(state=>state.formules.devisformules);
        const {data: user} = useUser(localStorage.getItem('token'))  

        async function download(){
                // let datadevis = {'id_lead': lead.id, 'id_client': lead.id_client} ;
                //                         datadevis['id_1'] =devisformules[0]?.id; datadevis['compagnie_1'] =devisformules[0]?.compagnie ; 
                //                         datadevis['gamme_1'] =devisformules[0]?.gamme; datadevis['nom_formule_1']=devisformules[0]?.nom_formule ; 
                //                         datadevis['id_2'] =devisformules[1]?.id; datadevis['compagnie_2'] =devisformules[1]?.compagnie ; 
                //                         datadevis['gamme_2'] =devisformules[1]?.gamme; datadevis['nom_formule_2']=devisformules[1]?.nom_formule ; 
                //                         datadevis['id_3'] =devisformules[2]?.id; datadevis['compagnie_3'] =devisformules[2]?.compagnie ; 
                //                         datadevis['gamme_3'] =devisformules[2]?.gamme; datadevis['nom_formule_3']=devisformules[2]?.nom_formule ;
                                        
                                const response = await  axios({url: '/devis/downloaddevis', 
                                                                method: 'post',
                                                                data:{user,lead,sol1:devisformules[0],sol2:devisformules[1],sol3:devisformules[2]},
                                                               responseType:'blob',}) ;

                                const pdfData= new Blob([response.data], {type:'application/pdf'}) ; 
                                var file = new File([pdfData], `devis${(new Date()).getTime()}.pdf`,{type:'application/pdf'});
                                dispatch(defineAttachments([file]));      
                                return pdfData
                                } 

                 return download

}

export  function devistolink(pdfData){
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(pdfData);
        a.setAttribute("download", `devis${(new Date()).getTime()}.pdf`);
        a.setAttribute('target', '_blank');
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(a.href), 100) ;
 }

