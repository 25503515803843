import { batch } from 'react-redux';
import axios from 'axios';

import { store,modifyDisplaymodal ,modifyQualiflead} from '../state/redux';
import { addEltHistory } from '../state/reactquery';
import { modifyonelead } from './actions_lead';


import { progressions_contact , progressions_devisenvoye, progressions_attentedocuments , progressions_attentesignature } from './listeprogression';

export const modifystatus_fauxnum= ()=>{
    const lead = store.getState().lead.leadactu;

    axios.post(  `/gestion/fauxnumero`,{'email': lead.email, 'telephone': lead.telephone,
                                        'date_origine':lead.date_origine ,'courtier': lead.courtier, 
                                        'origine':lead.origine,'campagne':lead.campagne,
                                        'commercial':lead.commercial });
    addEltHistory('qualiflead', `<div class='horoline1' >Qualification du prospect en <span class='horobold'>Faux numéro</span>  </div>`) ;

    batch( () => {  store.dispatch(modifyQualiflead(true));
                    store.dispatch(modifyDisplaymodal(null));

                    modifyonelead({'statut_client': 'echec_fauxnum'}) ;
                    });
                    
}


export const modifystatus_nrp= ()=>{
    const heureactu = (new Date()).getHours() ;
    const heurenew = heureactu<15? heureactu+4: heureactu<17? 10:12 ; 
    let demain =new Date((new Date((new Date().getTime()+24*60*60*1000)).setHours(heurenew+2,0,0,0)));    
    const lead = store.getState().lead.leadactu;

    const newstatutclient = lead.statut_client?.includes('gagne')? lead.statut_client : 
                            ( progressions_contact.includes(lead.statut_client)?'contact_nrp':progressions_devisenvoye.includes(lead.statut_client)?'devisenvoye_nrp':
                        progressions_attentedocuments.includes(lead.statut_client)?'attente_documents_nrp':progressions_attentesignature.includes(lead.statut_client)?'attente_signature_nrp':'contact_nrp');  

        addEltHistory('qualiflead', `<div class='horoline1' >Qualification du prospect en <span className=horobold'>Ne répond pas</span> </div>`) 
        batch( () => { store.dispatch(modifyQualiflead(true));
                        store.dispatch(modifyDisplaymodal(null));
                        modifyonelead({ 'date_rdvrelance':demain.toISOString(), 'statut_client':newstatutclient });
                        });            
  
}
