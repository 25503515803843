import React from "react";

import './AccessToDocs.scss'
function    AccessToDocs({nom}){

   const nomdoc = nom? nom.replace(/([éèëê])/g,"e").replace(/([^a-zA-Z0-9])/g,"") :'' ;
    const url_bucket = `https://${process.env.REACT_APP_AWS_NAMEBUCKET}.s3.eu-west-3.amazonaws.com` ;

    return(<>{nom ? <div className="accesstodocs">
                        <a href={`${url_bucket}/plaquettes/plaquette${nomdoc}.pdf`} target="blank">
                            <div className="linedocument" >Prestations</div> 
                        </a>
                        <div className="docdivider" ></div> 
                        <a href={`${url_bucket}/notices/notice${nomdoc}.pdf`} target="blank">
                            <div className="linedocument" > CG </div>
                        </a>
           </div>:<></> }</>  )
}


export default AccessToDocs


