


import React , {useEffect, useState} from "react";
import axios from "axios";


import { queryClient } from '../../../state/queryClient';
import {useUser,useUsers,useEquipes,useEquipesManagers } from "../../../state/reactquerycabinets";

import ButtonSimple from "../../Buttons/ButtonSimple";
import AjoutUtilisateur from "./AjoutUtilisateur";
import ModifyUtilisateur from "./ModifyUtilisateur";
import ModifyAuthsUtilisateur from "./ModifyAuthsUtilisateur";

import './Utilisateurs.scss';

const classNames = require('classnames');

const  line1 = ()=>(<div className="utilisateurs_tablo_line1" >
                        <div>Utilisateur</div><div>Email</div><div>Téléphone</div>
                        <div>Masquage</div><div>Origine</div><div>Équipe</div>
                        <div>Conseiller</div><div>Manager</div><div>Gestion</div>
                        <div>Admin</div><div></div><div></div><div></div>
                    </div>);



function Utilisateurs(){
    const {data:user} = useUser(localStorage.getItem('token'))  ;

    const {data:users} = useUsers(user?.courtier) ;
    const {data:equipes} = useEquipes(user?.courtier)  ;
    const listeidadmins = users?.filter(u=>u.role==='ADMIN')?.map(x=>x.id)||[] ;
    const listeidmanagers = users?.filter(u=>u.role==='MANAGER')?.map(x=>x.id)||[] ;
    
    const {data:equipes_managers} = useEquipesManagers([...listeidmanagers,...listeidadmins])  ;

    const [showajoutuser, setShowajoutuser] = useState(false) ;
    const [showmodifuser, setShowmodifuser] = useState(false) ;
    const [showmodifauthsuser, setShowmodifauthsuser] = useState(false) ;
    const [useractu, setUseractu]= useState({}) ;
    const [utilisateurs, setUtilisateurs ]= useState([]) ;
    const [showpopupavertdelete, setShowpopupavertdelete]= useState(false) ;


    function ordreutulisateurs(users){
        let tablo =[] ; 
        for(let eq of equipes){
            if(equipes_managers?.[eq.id]){
                const id_managers_equipe = Array.from(equipes_managers[eq.id]) ;
                users.filter(u=>id_managers_equipe?.includes(u.id) && !tablo.map(x=>x.id)?.includes(u.id))?.forEach(x=>tablo.push(x))  ;
                users.filter(u=>u.role!=='ADMIN' && u.role!=='MANAGER' && u.id_equipe===eq.id )?.forEach(x=>tablo.push(x))  ;
            }
        }
        users.filter(u=> !tablo.map(x=>x.id)?.includes(u.id))?.forEach(x=>tablo.push(x))  ;
        return tablo
    }
    


    useEffect(()=>{
        if(!equipes){setUtilisateurs([]) ; }
        else{const usersordre = ordreutulisateurs(users); setUtilisateurs(usersordre);}
    },[equipes,users,equipes_managers]) ;


    const deleteuser=async()=>{
        await axios.post(`/users/deleteuser`,{'id':useractu.id});
        queryClient.invalidateQueries({ queryKey: ['equipes', useractu?.courtier]})  ;
        queryClient.invalidateQueries({ queryKey: ['users', useractu?.courtier]})  ;
        setShowpopupavertdelete(false) ; 
    }

    const popup = ()=>(<div className='popupavertissement' >
        <div className='popupavertissement_titre'>Êtes vous bien sûr ?</div>
        <div className='popupavertissement_texte'> Vous vous apprêtez à supprimer {useractu.firstname} {useractu.lastname}.</div>
        <div className='popupavertissement_btns'>
                <ButtonSimple  texte="Annuler" containerbouton='fullbuton white' color='bouton_color_blancgris noborder' size='bouton_size_normal' onclick={()=>{setShowpopupavertdelete(false)}}    />   
                <ButtonSimple  texte="Confirmer"  containerbouton='fullbuton' color='bouton_color_orange' size='bouton_size_normal' onclick={()=>{deleteuser(useractu)}}   />      
        </div>
</div>) ;
    const showdeleteuser=async(user)=>{setUseractu(user); setShowpopupavertdelete(user)}
   
    const ajouteutilisateur=()=>{setShowajoutuser(true)}

    const modifyuser=(user)=>{setUseractu(user); setShowmodifuser(true)}

    const changeauthuser = (user)=>{setUseractu(user); setShowmodifauthsuser(true)}

    const  line = (user)=>(<div id={user.id}  className={classNames("utilisateurs_tablo_line",{"utilisateurs_tablo_line_gray":(user.role === 'MANAGER' || user.role == 'ADMIN')})} >
                                <div >{user.lastname} {user.firstname}</div>
                                <div>{user.email}</div>
                                <div>{user.telephone}</div>
                                <div className={classNames({'yes':user.masquage===1},{'yes':user.masquage!==1})} ></div>
                                <div className={classNames({'yes':user.showorigine!==1},{'yes':user.showorigine===1})} ></div>
                                <div>{equipes.filter(eq=>eq.id===user.id_equipe)?.[0]?.['nom']}</div>
                                <div className={classNames({'isrole':user.role === 'COMMERCIAL'},{'notrole':user.role !== 'COMMERCIAL'})}></div>
                                <div className={classNames({'isrole':user.role === 'MANAGER'},{'notrole':user.role !== 'MANAGER'})}></div>
                                <div className={classNames({'isrole':user.role === 'GESTION'},{'notrole':user.role !== 'GESTION'})}></div>
                                <div className={classNames({'isrole':user.role === 'ADMIN'},{'notrole':user.role !== 'ADMIN'})}></div>
                                <div onClick={()=>showdeleteuser(user)}></div> 
                                <div onClick={()=>changeauthuser(user)}></div>
                                <div onClick={()=>modifyuser(user)}></div>
                            </div>);

    return( <>  {showmodifauthsuser &&  <ModifyAuthsUtilisateur setShowmodifauthsuser={setShowmodifauthsuser}  utilisateur={useractu} />}
                {showmodifuser &&  <ModifyUtilisateur setShowmodifuser={setShowmodifuser} utilisateur={useractu} /> }
                { showajoutuser &&  <AjoutUtilisateur setShowajoutuser={setShowajoutuser}  /> }
                {showpopupavertdelete && popup(useractu)}
                <div className="utilisateurs_tablo">
                    {line1()}
                    <div className="utilisateurs_tablo_lines">
                        {utilisateurs?.map(u=>line(u))}
                    </div>
                </div>
                <div  className="ajouteruser" onClick={ajouteutilisateur} >Ajouter un utilisateur</div>
            </> ) ;
}



export default Utilisateurs



