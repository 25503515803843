import axios from 'axios';

import { store,  modifyDisplaymodal} from '../state/redux';
import { queryClient  } from '../state/queryClient';
 

import { progressions_gagne } from './listeprogression';
import { modifyonelead , popup} from './actions_lead';
import { testdateafter } from './utils_dates';
import { addEltHistory } from '../state/reactquery';
import { registerdevisfromformules } from './utils_devis';



export const actionenvoimaildevis = async(url)=>{
        const devisformules = store.getState().formules.devisformules ;
        const lead = store.getState().lead.leadactu; 

        const newstatutclient = progressions_gagne.includes(lead.statut_client)?lead.statut_client:
                                lead.statut_client?.includes('rdv')?'devisenvoye_rdv':
                                lead.statut_client?.includes('relance')?'devisenvoye_relance':
                                'devisenvoye' ;
                                
        modifyonelead({'statut_client':newstatutclient,'sign_devis' : 2 });

        await registerdevisfromformules(lead,devisformules)  ; 

        // AJOUT DANS L HISTORIQUE
        const formules =  [devisformules[0],devisformules[1],devisformules[2]].filter(x=>x!==undefined);
         const listedesformules = formules.map(formule=>{return (`<div class='horoline2' >${formule.compagnie}  ${formule.gamme} ${formule.nom_formule}  ${formule.prix}€   </div>`)})
         const description = `<div class='horoline1' > Email <a href=${url}  rel="noopener noreferrer"   target="_blank" class='horolink' >Devis conseiller</a>  envoyé</div>  ${listedesformules.join('')} `
        addEltHistory('emaildeviscrmsend',description)  ;



       const jsondocuments = {'courtier':lead.courtier, 'id_client': lead.id_client ,'id_lead':lead.id ,'type_file':'devis',
                               'commercial':  lead.commercial,'url' : url,'nomfichier' :url?.split('/')?.reverse()?.[0]}

        await  axios.post(  `/documents/registerdocument`,{...jsondocuments},{headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'}}) ;

        queryClient.invalidateQueries({ queryKey: ['histories',lead.id]}) ;
        queryClient.invalidateQueries({ queryKey: ['documents',lead.id]}) ;
        queryClient.invalidateQueries({ queryKey: ['devis',lead.id]}) ;

        popup('popupdevis');  
        
        if(!lead.date_rdvrelance || !testdateafter(lead.date_rdvrelance)){store.dispatch(modifyDisplaymodal('mod_calendarrelance'))  }
}




