import axios from "axios";

import { queryClient  } from '../../state/queryClient';
 

export function deleteNoAuthTarif(usernotarif,comp,gam,tarif){
    delete usernotarif?.[comp]?.[gam]?.[tarif] ;
    if(Object.keys(usernotarif?.[comp]?.[gam])?.length===0){delete usernotarif?.[comp][gam] }
    if(Object.keys(usernotarif?.[comp])?.length===0){delete usernotarif?.[comp]}
    return usernotarif 
}


function getOrCreate(obj,key,defaultValue){
    if(!obj.hasOwnProperty(key)){ obj[key]=  defaultValue }
    return obj[key]
}

export function createNoAuthTarif(usernotarif={},comp,gam,tarif){
    getOrCreate(getOrCreate(getOrCreate(usernotarif,[comp],{}),[gam],{}),[tarif],true) ;
    return usernotarif 
}

export async function changeGrouperoleAuth(actuauths,compagnie_mini,gamme_mini,type_tarif,ROLE){
    const user = queryClient.getQueryData(['user',localStorage.getItem('token')]);     
    let copyauths= {...actuauths}  ;
    const actuauthrole =copyauths[compagnie_mini]['gammes']?.[gamme_mini]['tarifs'][type_tarif]['autorisations'][ROLE]  ; 

    copyauths[compagnie_mini]['gammes'][gamme_mini]['tarifs'][type_tarif]['autorisations'][ROLE] = actuauthrole===0?1:0 ;
    await axios.post(`/cabinetscompagnies/updateauthcompagnies_courtier`,{'courtier':user?.['courtier'] , 'autorisation':copyauths , ROLE , 'type': actuauthrole===1?'deleteAuth':'addAuth' ,compagnie_mini,gamme_mini,type_tarif },{headers:{'Accept':'application/json','Content-Type':'application/json'} }); 


    return copyauths

}