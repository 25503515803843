import React ,{useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';


import {  modifyDisplaymodalCalendar,modifyDisplaymodal,  modifyQualiflead} from '../../state/redux';
import {rdvfromcalendar} from '../../utils/actions_calendar';
import { readabledate } from '../../utils/utils_dates';

import { addOneNote } from '../../state/reactquery';

import MiniListEvents from './MiniListEvents';

import './Calendar.scss'
const classNames = require('classnames');

const listemois = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']
const joursdelasemaine = ["Dimanche","Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi"]
const listehoraires = []; for(let i=8;i<20;i++){for(let j=0;j<4;j++){listehoraires.push(i.toString().padStart(2,'0')+":"+(15*j).toString().padStart(2,'0'))}}

const today = new Date();   const [anneeactu, moisactu] = [today.getFullYear(),today.getMonth()];

function Calendar({type}){
    const dispatch= useDispatch()
     
    const eventtochange= useSelector(state => state.calendar.eventtochange);
    const lead = useSelector(state=>state.lead.leadactu);

    const [eventtocalendar, setEventtocalendar]  = useState({'note': null});
    const [openhoraire, setOpenhoraire] = useState(false);
    const [horaireconf, setHoraireconf] = useState();
    const [mois, setMois]  = useState('');
    const [annee, setAnnee]  = useState('');
    const [nbjoursmois, setNbjoursmois] = useState('');
    const [displaycommentaire, setDisplaycommentaire] = useState(false);
    const [indexfirstday, setIndexfirstday]  = useState();

    useEffect(()=>{setMois(moisactu) ; setAnnee(anneeactu) ;  setNbjoursmois(new Date(anneeactu,moisactu+1,0).getDate()) ;
                    setIndexfirstday(joursdelasemaine.map(x=>x.toLowerCase()).indexOf(new Date(anneeactu+'-'+(moisactu+1)+'-0'+1).toLocaleDateString('fr-FR', { weekday: 'long'})))   ;
                },[])
    
    useEffect(()=>{if(eventtochange.id){ let date = new Date(eventtochange.startDatetime)  ; 
                                      setHoraireconf(date.getHours().toString().padStart(2,'0')+":"+date.getMinutes());setOpenhoraire(true) ;
                                     setEventtocalendar({...eventtochange,summary:eventtochange.summary,note:eventtochange.description.split('#ID')[0],idclient:eventtochange.description.split('#ID')[1],startDatetime:new Date(eventtochange.startDatetime)})
                                  }
               },[eventtochange])
    
    const changemonth = (e)=>{const [newmonth,newyear]=e.target.id==='next'?[(mois+1)%12,annee+(mois===11?1:0) ]:[mois===0?11:(mois-1)%12,annee-(mois===1?1:0)]; 
                                let stringdate = newyear+'-'+((newmonth+1).toString().padStart(2, '0'))+'-01';
                                let firstdaynewmonth = new Date(stringdate);

                              setMois(newmonth) ;  setAnnee(firstdaynewmonth.getFullYear()); 
                              setNbjoursmois(new Date(new Date(firstdaynewmonth).getFullYear(),new Date(firstdaynewmonth).getMonth()+1, 0).getDate());
                            setIndexfirstday(joursdelasemaine.map(x=>x.toLowerCase()).indexOf(firstdaynewmonth.toLocaleDateString('fr-FR',  { weekday: 'long'} )));
                            }

    const clickjour = (e)=>{ const date_rdv = new Date(annee,mois,e.target.id);setOpenhoraire(true) ; setEventtocalendar({...eventtocalendar,'startDatetime': (new Date(date_rdv)).toDateString() })}

    const confirmeheure = (e) =>{setDisplaycommentaire(true) ; 
                                setEventtocalendar({...eventtocalendar,'startDatetime':(new Date ((new Date(eventtocalendar.startDatetime)).setHours(e.target.id.split(':')[0],e.target.id.split(':')[1],0,0))).toISOString() }) ; }

    const changecomment = (e) =>{   setEventtocalendar({...eventtocalendar,'note':e.target.value}) ;   }
    
    const confirmrdv =()=>{   
        rdvfromcalendar(eventtocalendar);  
       ( eventtocalendar.note?.length >0 && eventtocalendar.note!=='Ajoutez une note au rendez-vous' ) &&  addOneNote(lead.id,eventtocalendar.note) ; 
       dispatch(modifyDisplaymodalCalendar(null)) ; dispatch(modifyQualiflead(true))  ; dispatch(modifyDisplaymodal(null)) ;
    
    }

    const datepicker =()=>( <div className='datepicker' >
                                <div className='datepicker_titre' >Sélectionner un jour</div>
                                <div className='datepicker_top' >
                                        <div id='back' className={classNames({'noback':  mois===moisactu && annee===anneeactu})} onClick={changemonth} ></div>
                                        <div className='datepicker_top_middle'>{listemois[mois]} {annee}</div>
                                        <div id='next' onClick={changemonth} ></div>
                                </div>
                              
                                <div className='datepicker_pickercontainer' >
                                    <div className='datepicker_picker' >
                                            {joursdelasemaine.map(x=>(<div  key={x.substring(0,2)}  className='day2lettres' >{x.substring(0,2)}</div>))}
                                            {[...Array(indexfirstday).keys()].map((x)=>(<div key={'vide'+x}  ></div>))  }
                                            {[...Array(nbjoursmois).keys()].map(x=>x+1).map((x,i)=>(<div key={i} id={x} onClick={clickjour} 
                                                                                                    className={classNames({'today':  mois===moisactu && annee===anneeactu && x===today.getDate()},
                                                                                                                        {'disable':  mois===moisactu && annee===anneeactu && x<today.getDate()},
                                                                                                                        {'selected':  mois===moisactu && annee===anneeactu && x=== (eventtocalendar.startDatetime? new Date(eventtocalendar.startDatetime).getDate(): -1)})}  >
                                                                                                    {x} </div>))  }
                                    </div>
                                </div>
                            </div>)

    const horaire=()=>(<div className={classNames('horaire',{'no': !openhoraire })  } >
                                <div className='horaire_titre' >Sélectionner une heure</div>
                                <div className='horaire_date' >{ eventtocalendar.startDatetime && readabledate(new Date(eventtocalendar.startDatetime))}  </div>
                                <div className='horaire_liste' >
                                        {listehoraires.map(x=><div key={x}>{horaireconf!==x ?<div className='heure' onClick={()=>{setHoraireconf(x)}}  >{x}</div>:
                                                                                            <div className='heure_confirme'>
                                                                                                        <div className='heure_confirme_hmin' >{x}</div> 
                                                                                                        <div id={x} className='heure_confirme_btn' onClick={confirmeheure}  >Confirmer</div>
                                                                                                </div>}
                                         </div>)}
                            </div>
                    </div>)

    const divcommentaire = ()=>(<div className='divcommentaire' > 
                                    <div>
                                        <div className='' >{ eventtocalendar.startDatetime && readabledate(new Date(eventtocalendar.startDatetime))}  </div>
                                        <div className='' >{ eventtocalendar.startDatetime && ((new Date(eventtocalendar.startDatetime)).getHours()+'h'+ (new Date(eventtocalendar.startDatetime)).getMinutes())  }  </div>
                                    </div>
                                    <div className='divcommentaire_right'>
                                        <textarea className='commentaire' value={eventtocalendar.note}  onChange={changecomment}  placeholder='Ajoutez une note au rendez-vous'  />
                                        <div  className='heure_confirme_btn' onClick={confirmrdv}  >Confirmer</div>
                                    </div>
                                </div>)

    const titrecalendar =    (eventtochange.id && type ==='rdv' && lead.statut_client?.includes('rdv') ) ? 'Modification de RDV':
                            (eventtochange.id && type ==='relance' && lead.statut_client?.includes('relance')) ? 'Modification de Relance':
                            (type ==='rdv') ? 'Prendre rendez-vous': "Relancer le prospect"

    return(<div className='containergray' >
                <div className='calendrier' >
                    <div className='calendrier_top'  >
                        <div className='calendrier_top_texte' >{titrecalendar}</div>
                        <div className='calendrier_top_close' onClick={()=>{ dispatch(modifyDisplaymodalCalendar(null))   }}  ></div>
                    </div>
                    <div className='calendrier_content' >
                        {displaycommentaire && divcommentaire()}<MiniListEvents  /> {datepicker()}{horaire()}
                    </div>
                </div>
            </div>)
}

export default Calendar