import { store } from '../state/redux';
import { modifyChampsLeadmemo , deleteChampsLeadmemo  } from '../state/redux';


export const initializeAutocomplete = (memonumdep,setValid,setMemonumdep)=>{
    
    function  onPlaceChanged(){

        const regexchamp = /^[0-9]{1,5}$/;
        const place = this.getPlace();
       if(!place.address_components){ return }
        const champs = place.address_components.reverse()
 
        if(regexchamp.test(champs[0].long_name)){ 
            const numerodepartement = champs[0].long_name.substring(0,2); 
            const nomville = champs.length>5? place.formatted_address.split(',').reverse()[1].replace(champs[0].long_name,'').trim() : undefined; 
            const adr =  champs.length>5?place.formatted_address : undefined;  
            const codepostal =  champs.length>5?champs[0].long_name: undefined; 
            
            setValid(true); 
             store.dispatch(modifyChampsLeadmemo({num_departement :numerodepartement, ville:nomville,  adresse: adr,code_postal: codepostal})); 
            if(numerodepartement!==memonumdep){  setValid(true); setMemonumdep(numerodepartement)}
         
        }
        else{ 
                 store.dispatch(deleteChampsLeadmemo(['num_departement', 'ville','adresse','code_postal']))
        }
    }

    const google = window.google;
    const element = document.getElementById("user_input_autocomplete_address");
    element.value=''
    const autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] ,  options : {componentRestrictions: {country: 'fr'}}});
    google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChanged);

}
