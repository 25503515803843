import React  from "react";
import { useSelector } from 'react-redux';


import ClientEmail from "./ClientEmail";
import SendMail from "./SendMail";


import './PostMail.scss';

function Postmail(){
    const ecranclientshowsendmail = useSelector(state=>state.display.ecranclientshowsendmail);

    return(<div  className="emailing">
            <ClientEmail   />
            {ecranclientshowsendmail &&   <div id="containersendemail" ><SendMail    /> </div> }
        </div>)}


export default Postmail

