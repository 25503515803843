import React, { useState , useEffect } from "react";
import {useDispatch,batch} from 'react-redux';
import axios from "axios";

import { queryClient } from '../../state/queryClient';
import { useUser } from "../../state/reactquerycabinets";
import { initialiseLeadactu , modifyEcran} from '../../state/redux';

import { getallformules } from "../../utils/calculstarifs";
import { formatdatenotif } from "../../utils/utils_dates";
import { sendemail } from '../../utils/email';
import { modifyMailRead  } from "../../utils/utils_email";

import ButtonSend from "../../Components/Buttons/ButtonSend";
import ButtonImgLeft from "../../Components/Buttons/ButtonImgLeft";
import FavoriMail from "./FavoriMail";
import FavoriSelect from "./FavoriSelect";

import './EcranEmails.scss' ;
const classNames = require('classnames');
function  EcranEmails({emails,type}){
    const dispatch = useDispatch(); 
    
    const {data: user} = useUser(localStorage.getItem('token')) ;
  
    const [choice , setChoice] = useState('selectemailsentrants')  ;
    const [emailchoice , setEmailchoice] = useState(null)  ;
    const [namesearch, setNameseach]  = useState('')  ;
    const [leadmail, setLeadmail] = useState(null) ;
    const [emailsaffiches, setEmailsaffiches] = useState([]) ;
    const [show, setShow] = useState(false) ;
    const [message,setMessage] = useState('') ;
    const [attachments, setAttachments] = useState([]);
    const [loading , setLoading ] = useState(false);
    const [selectfavoris , setSelectfavoris] = useState(false)  ;
    const mailbox  = type==='user'?(user?.postmail):user.email_gestion ; 

    const mailsnonouverts = emails?.filter(x=>x.emailTo ===mailbox && x.RecordType!=='Open');
    const emails_IN = emails?.filter(x=>x.emailTo === mailbox).sort((a,b) => new Date(b.created_at) - new Date(a.created_at)) ;
    const emails_OUT =  emails?.filter(x=>x.emailFrom === mailbox).sort((a,b) => new Date(b.created_at) - new Date(a.created_at)) ;
   const emailsaffichesselected   = selectfavoris? emailsaffiches.filter(m=>m.favori===1): emailsaffiches ; 
    useEffect(()=>{choice==='selectemailsentrants' ?setEmailsaffiches(emails_IN) : setEmailsaffiches(emails_OUT)},[choice,emails])  ;

    const changenamesearch =(e)=>{setNameseach(e.target.value) ;
                                  e.target.value?.length ===0 ? setEmailsaffiches(emails) : setEmailsaffiches(emails.filter(x=>x.nom?.toLowerCase()?.includes(e.target.value?.toLowerCase())));}

    const gotothislead =async()=>{
        const response = await axios.post(`/clients/searchclientbyid/`,{id:leadmail?.id},{headers:{'Accept':'application/json','Content-Type':'application/json'}}) ;
        if(!response?.data?.id){ return }
        batch(async ()=>{ await dispatch(initialiseLeadactu(response.data));  dispatch(getallformules()); dispatch(modifyEcran('ficheclient'));  })
    }

    const choiceemail =async (e)=>{
        if(e.target.className.includes('mailfavori')){ return  ;}
        
        setLeadmail({}) ;setShow(false) ; setMessage('') ; setAttachments([]) ;
        const  messagtechoiced = emails.filter(x=>x.id===e.currentTarget.id)?.[0] ;

        setEmailchoice(messagtechoiced); 
        if(messagtechoiced?.RecordType!=='Open'&&  messagtechoiced.emailTo===mailbox ){  modifyMailRead(type,messagtechoiced?.id)}

        const reqclient = await  axios.post(  `/clients/searchclientbyid`,{'id' :messagtechoiced?.id_lead });
        setLeadmail(reqclient.data) ;
    }


const classmail =(recordtype) =>{
    return recordtype==='Open'? 'openmail':
            recordtype==='Delivery'? 'deliverymail':
            recordtype==='SubscriptionChange'? 'subscribemail':
            recordtype==='Click'? 'openmail':
            recordtype==='Bounce'? 'bouncemail':
            recordtype==='SpamComplaint'? 'complaintmail':
            recordtype=== 'HardBounce'? 'bouncemail':
            recordtype==='error'? 'errormail':
            recordtype==='success'? 'successmail':'unknowntypemail'
 }  

 // opemmail  deliverymail   subscriptionchangemail   bouncemail errormail  success

 const envoyermessage= async ()=>{
    setLoading(true) ;
    await  sendemail({lead:leadmail,email:emailchoice.emailFrom,objet : 'Re:'+emailchoice.Subject,message,attachments})  ; 
    queryClient.invalidateQueries({ queryKey: ['clientmails',user['postmail'],leadmail.email]}) ;  
    type='user' && queryClient.invalidateQueries({ queryKey: ['listmails']})  ;
    type='gestion' && queryClient.invalidateQueries({ queryKey: ['listgestionmails']})  ;
    setShow(false) ; setMessage('') ; setAttachments([]) ; setLoading(false) ;
 }

const mail_button = (message)=>(<div key={message?.id} className={classNames("mailbutton",{"emailselected": message?.id===emailchoice?.id } , {"emailnonlu": message.RecordType!=='Open'})  } id={message?.id} onClick={choiceemail} >
                                  <div className="mailbutton_initiales" >{message?.nom?.[0]}{message?.prenom?.[0]}  </div>
                                  <div className='mailbutton_details'  >
                                         <div className={`mailbutton_details_recordtype `+classmail(message?.RecordType)} >{message?.RecordType}  </div>
                                        <div className="mailbutton_details_time" >{formatdatenotif(message?.created_at)}  </div>
                                        <div className="mailbutton_details_nomprenom" >{message?.nom} {message?.prenom}  {type==='gestion' && message?.emailTo}   </div>
                                        <div className="mailbutton_details_subject" >{message?.Subject}</div>
                                        <FavoriMail message={message} type={type} />
                                  </div>
                                </div>);
    const handleFileChange = (e) => {  setAttachments(attachments=>[...attachments,...e.target.files]);};
    const disable = message?.length===0 ;
    const choicemailsentrants = () =>{setChoice('selectemailsentrants'); setEmailchoice(emails_IN?.[0]);setShow(false) ; setMessage('') ; setAttachments([]) ; }
    const choicemailssortants = () =>{setChoice('selectemailsortants'); setEmailchoice(emails_OUT?.[0]);setShow(false) ; setMessage('') ; setAttachments([]) ;}
    return(<div className="container_screenmails">
                 <div className="ecranmails_left">
                        <div className="ecranmails_left_top">
                            <div className={classNames("ecranmails_left_top_btn",{"btnchoiced":choice==="selectemailsentrants"})} onClick={choicemailsentrants}  >Messages reçus <div className="nbmailsnonouverts">{mailsnonouverts?.length}</div>  </div>
                            <div className={classNames("ecranmails_left_top_btn",{"btnchoiced":choice==="selectemailsortants"})}  onClick={choicemailssortants}   >Messages envoyés</div>
                        </div>
                        <div className="flexinpurcoeur">
                        <input className="ecranmails_left_search"  value={namesearch}  onChange={changenamesearch}  placeholder="Rechercher un nom" />
                        <FavoriSelect    selectfavoris={selectfavoris}  setSelectfavoris={setSelectfavoris}    />
                            
                        </div>

                        <div className="ecranmails_left_liste">
                            {emailsaffiches?.length >0 && emailsaffichesselected.map(email => mail_button(email)  )}
                        </div>
                 </div>
                 <div className="ecranmails_right">
                   {emailchoice && <div className="ecranmails_right_top">
                            {leadmail && <><div className={classNames("ficheclienttopleft_flamme", { "cold": leadmail?.hot === 0 }, { "hot": leadmail?.hot === 1 })}  ></div>
                            <div className="ficheclienttopleft_avatar"  >
                                <div className="avatar">  {(leadmail?.nom && leadmail?.prenom) ? leadmail?.nom[0] + leadmail?.prenom[0] : leadmail?.email ? leadmail?.email[0] : ''} </div>
                            </div>
                                <div  className="ecranmails_right_top_texte"   > {  leadmail?.nom ? leadmail?.nom + ' ' + leadmail?.prenom : leadmail?.email}
                                    {(leadmail?.statut_client?.includes('rdv')) ? <span className='statutcontacttop'> RDV </span> : <></>}
                                    {(leadmail?.statut_client?.includes('relance')) ? <span className='statutcontacttop'> RELANCE  </span> : <></>}
                                    {(leadmail?.statut_client?.includes('nrp')) ? <span className='statutcontacttop'> NRP </span> : <></>}
                                </div> 
                            <div  className="ecranmails_right_gotolead" onClick={gotothislead}   >      
                            </div></>}
                            <div className="ecranmails_right_fromto">De {emailchoice.emailFrom} à {emailchoice.emailTo} </div>
                    </div>}
                    { emailchoice &&   <div className="ecranmails_right_bottom">
                         <div className="ecranmails_right_bottom_email">
                            <div className="ecranmails_right_bottom_email_titre">{emailchoice?.Subject} </div>
                            {emailchoice?.listattachements?.map((link, index) => (<a key={index} className="attachementlink" href={link} target="blank" >{link?.split('/')?.reverse()[0]}</a>))}
                            <div className="ecranmails_right_bottom_email_time"> {formatdatenotif(emailchoice?.created_at)}</div>  
                            <div className="ecranmails_right_bottom_email_content"  dangerouslySetInnerHTML={{ __html: emailchoice?.TextBody?.replaceAll('\n',' <br/>') .replaceAll('\\n','<br/>') }} ></div>
                            {/* <div className="ecranmails_right_bottom_email_content"   >{emailchoice?.TextBody}</div> */}
                            {/* <div className="ecranmails_right_bottom_email_content"  dangerouslySetInnerHTML={{ __html: benji}} ></div> */}
                        </div>
                        {(choice==='selectemailsentrants')&&<><div className="ecranmails_right_bottom_btncontainer" >
                                                                        <ButtonImgLeft id='repondre' texte="Répondre" color='bouton_color_orange' size='bouton_size_normal' image='imagemail' onclick={()=>{setShow(!show)}}   /> 
                                                                    </div> </>} 
                         {(show && choice==='selectemailsentrants')&&<> <textarea className='ecranmails_right_bottom_reponse'  value={message}  onChange={(e)=>{setMessage(e.target.value)}}   />
                                        <div className='sendmail_bottom_right'>  
                                            <div className="inputmessageattachement_container">
                                                <input type="file" multiple onChange={handleFileChange} />
                                            </div>
                                            <ButtonSend texte="Envoyer" 
                                                color={disable?'color_disable':'color_blue'}
                                                size='bouton_size_normal' 
                                                image='imagemail' 
                                                loading={loading}
                                                onclick={envoyermessage} /> 
                                    </div> </>} 
                    </div>}


                 </div>
</div>)
}

export default EcranEmails