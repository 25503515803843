import  React from 'react' ;
import { useSelector } from 'react-redux';


function ComGestionFin ({attachments}){
    const comgestion = useSelector(state=>state.comgestion.value)  ;

    const ligne1= { 'remboursement': `Votre demande de remboursement va être transmise à ${comgestion.nom_compagnie}.`,
                    'cotation': `Votre demande de cotation va être transmise à ${comgestion.nom_compagnie}.`,
                    'ajoutbeneficiaire':  `Votre demande d'ajout de bénéficiaire va être transmise à ${comgestion.nom_compagnie}.`,
                    'supprbeneficiaire':  `Votre demande de suppression du bénéficiaire va être transmise à ${comgestion.nom_compagnie}.`,
                    'decalageeffet': `Votre demande de décalage d’effet va être transmise à ${comgestion.nom_compagnie}.`,
                    'resiliation':  `Votre demande de résiliation d’effet va être transmise à ${comgestion.nom_compagnie}.`,
                    'gestecom': "Votre demande de geste commercial va être transmise au sevice gestion.",
                    'nonliste': "Votre demande  va être transmise au sevice gestion.",
                    'parrainage' : "Votre demande de parrainage va être transmise au sevice gestion."}

    const ligne2 = attachments?.length===0?`Celle ci ne contient aucun document joint.`:
                    attachments?.length===1?`Celle ci contient un document joint.`:
                                            `Celle ci  contient ${attachments?.length} documents joints.`

    return (<><div className='comgestion_fin_line'  >{ligne1[comgestion['type_gestion']]}</div>
              <div className='comgestion_fin_line'>{ligne2}</div></>)
}


export default ComGestionFin