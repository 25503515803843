
import React , { useState , useEffect} from "react";
import axios from "axios";

import { queryClient } from '../../../state/queryClient';
import {  useCourtier } from "../../../state/reactquerycabinets";

import FiltresCompagnies from "../../FiltresCompagnies";
import ButtonSimple from "../../Buttons/ButtonSimple";
import TabloAuthsUtilisateur from "./TabloAuthsUtilisateur";


import './ModifyAuthsUtilisateur.scss' ;
function ModifyAuthsUtilisateur({setShowmodifauthsuser,utilisateur}){
    const {data:courtier}  = useCourtier() ; 
    const jsonauths = JSON.parse(courtier?.autorisation||'{}') ;

    const [listecompagnies, setListeompagnies]=useState([])  ;
    const [listecompagniesaffichees, setListeompagniesaffichees]=useState([])  ;
    const [compagniesgammesaffichees, setCompagniesgammesaffichees]=useState({})  ;
    const [usernotarif, setUsernotarif] = useState(JSON.parse(utilisateur?.notarif||'{}')) ;

    useEffect(()=>{ 
        setListeompagnies(Object.keys(jsonauths)) ;
        setListeompagniesaffichees(Object.keys(jsonauths))  ;
        setCompagniesgammesaffichees(jsonauths) ; 
    },[courtier]) ;

    const clickCompagnie =(e)=>{
        const  newliste = listecompagniesaffichees.includes(e.target.id)?listecompagniesaffichees.filter(x=>x!==e.target.id):[...listecompagniesaffichees,e.target.id];
        let newcompgammes = {} ;
        newliste.forEach(l=>newcompgammes[l] =jsonauths[l] ) ;
        setCompagniesgammesaffichees(newcompgammes)  ;
        setListeompagniesaffichees(newliste)
    }
    
    const clickboxcompagnies= ()=>{
        listecompagniesaffichees?.length>0? setListeompagniesaffichees([]):setListeompagniesaffichees([...listecompagnies]);
        listecompagniesaffichees?.length>0? setCompagniesgammesaffichees({}):setCompagniesgammesaffichees(jsonauths);
    }
    const modifier = ()=>{ axios.post(`/users/modifusertarifsauth`,
                                {'id':utilisateur.id,'notarif': usernotarif},
                                {headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
                             queryClient.invalidateQueries({ queryKey: ['users',utilisateur?.courtier]})  ;
                             setShowmodifauthsuser(false) ;
    }
    const annuler = () =>{setShowmodifauthsuser(false)}

    return(<div className="containergray">
                <div  className="utilisateurmodifauths parametresmodal">
                    <div className="equipeajoutmodif_titre">{utilisateur.firstname} {utilisateur.lastname}</div>
                    <div className="equipeajoutmodif_titremin mb30">Compagnies et tarifs</div><br/>
                    <FiltresCompagnies  listenomscompagnies ={listecompagnies?.sort()?.map(comp=>jsonauths[comp]?.nom_compagnie)}
                                        listecompagnies={listecompagnies?.sort()}
                                        listecompagniesaffichees={listecompagniesaffichees?.sort()} 
                                        clickCompagnie={clickCompagnie}  
                                        clickboxcompagnies={clickboxcompagnies}  />
                    <TabloAuthsUtilisateur  compagniesgammesaffichees={compagniesgammesaffichees} 
                                            usernotarif={usernotarif} 
                                            setUsernotarif={setUsernotarif}  />
                    <div className="groupeboutons">
                        <ButtonSimple  texte="Retour" containerbouton='fullbuton white'   color='bouton_color_blancgris  noborder' size='bouton_size_normal'  onclick={annuler}    />   
                        <ButtonSimple  texte="Modifier" containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal' disable={false} onclick={modifier}  />    
                    </div>
                </div> 
             </div>)

}


export default ModifyAuthsUtilisateur ; 