
import { majuscule } from "./validations";

const listemois = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']


export const formatdate=(date)=>{
  if(!date) return ''; 
  const heures = (new Date(date)).getHours().toString().padStart('2',0);
  const minutes = (new Date(date)).getMinutes().toString().padStart('2',0);
  const datefr = new Date(date).toLocaleString("fr-FR", {timeZone: "Europe/Paris"}).split(' ')[0] ;
  
return datefr+" à "+heures+'h'+minutes ;
}

export const  testdateafter= (date) =>{return  (new Date( new Date(date) -  new Date())).getTime()  >0  }

export const readabledate = (date) => { return  majuscule(date.toLocaleDateString('fr-FR',{ weekday:'long'}))+' '+ date.getDate() +' '+ listemois[date.getMonth()]+' '+date.getFullYear() }

export const convertdateXX=(date)=>{ return "Arrivé le  "+ (new Date(date??new Date())).toLocaleDateString('fr-fr') }

export   const dateconvert = (date) =>{
  if(!date) return '' ; 
  return   date.split('/')[2]+'-'+date.split('/')[1]+'-'+date.split('/')[0] }

export const convertdate = (date)=>{
  return (new Date(date??new Date())).getDate().toString().padStart(2,'0')+'/'+((new Date(date??new Date())).getMonth()+1).toString().padStart(2,'0')+'/'+(new Date(date??new Date())).getFullYear()
 }

 export const convertdatesignature = (date)=>{ if(!date){return null}
   return  (new Date()).getFullYear(date)+'-'+((new Date(date)).getMonth()+1).toString().padStart(2,'0')+'-'+(new Date(date)).getDate().toString().padStart(2,'0')

 }


export const rdvfutur=(date)=>{
    let demain = (new Date((new Date((new Date().getTime()+24*60*60*1000)).setHours(0,0,0,0)))) ;
    let test = (new Date(new Date(date) - new Date(demain))).getTime() ;
    return test>0 ? true :false
  }

  export const formatdatefrance=(date)=>{
    const datefrance =  (new Date(date)).toLocaleDateString("fr-FR", {weekday: "long", year: "numeric", month: "long", day: "2-digit"})
    return datefrance[0].toUpperCase() +   datefrance.slice(1)
  }

// AVOIR   Intl DateTimeFormat
export const formatedate = (date)=>{ if(!Number.isNaN(new Date(date).getTime())  || !date ){  return date}
      return  new Intl.DateTimeFormat('fr-FR',{  dateStyle: 'medium', timeStyle: 'medium',timeZone: "Europe/Paris" }).format(new Date(date))
}

export const add15minutes=(date)=>{ return  (new Date((new Date(date)).getTime()+15*60*1000)).toISOString()}

export const tenmonthafter = (date)=>{ 
    if(!date) return (new Date((new Date((new Date().getTime()+10*29*24*60*60*1000)).setHours(10,0,0,0)))).toISOString();
    return  (new Date((new Date((new Date(date).getTime()+10*29*24*60*60*1000)).setHours(10,0,0,0)))).toISOString();
}

export const threeyearsafter = (date)=>{ 
  if(!date) return (new Date((new Date((new Date().getTime()+2.5*365*24*60*60*1000)).setHours(12,0,0,0)))).toISOString();
  return  (new Date((new Date((new Date(date).getTime()+2.5*365*24*60*60*1000)).setHours(12,0,0,0)))).toISOString();
}

export const dateheuremin=(date)=>{  return  (new Date(date)).getHours().toString().padStart(2, '0')+"h" +(new Date(date)).getMinutes().toString().padStart(2, '0')}

export const dateeffetavanttoday=(date)=>{ 
  if(!date){return true}
  const hier = new Date((new Date()).getTime())-24*60*60*1000
  const dateef = date.split('/')[2]+'-'+date.split('/')[1]+'-'+date.split('/')[0] ;
  return   (new Date(dateef)).getTime() - (new Date(hier)).getTime() <0
}




export const  nbjours=(lead)=>{
  return  Math.ceil(((new Date()).setHours(0, 0, 0, 0) - (new Date(lead.date_reception??new Date() )).setHours(0, 0, 0, 0) ) / 86400000);
}

export const   verifleadgagne_arecycle=(lead)=>{
  if(!lead.date_effet || lead.statut_client!=='gagne'){return false ;}
  return  lead.recyclage===2 && lead.statut_client==='gagne' &&(new Date( dateconvert(lead.date_effet)).getTime()) <  (new Date().getTime()-12*31*24*60*60*1000)

}

export const formatdatecalendar=(date)=>{
      if(!date) return ''; 
    const today = (new Date()).setHours(0, 0, 0, 0) ;
    const tomorrow =  (new Date(new Date().getTime() + 24*60*60*1000)).setHours(0, 0, 0, 0);
    const hier =  (new Date(new Date().getTime() - 24*60*60*1000)).setHours(0, 0, 0, 0);
    const datetime = (new Date(date)).setHours(0, 0, 0, 0) ;
    const heures = (new Date(date)).getHours().toString().padStart('2',0);
    const minutes = (new Date(date)).getMinutes().toString().padStart('2',0);
    const datelong = (new Date(date)).toLocaleDateString("fr-FR", { weekday: 'long',  month: 'long', day: 'numeric' });

    return datetime === hier     ? 'Hier à '+heures+':'+minutes :
            datetime === today    ? "Aujourd'hui à "+heures+':'+minutes :
            datetime === tomorrow ? "Demain à "+heures+':'+minutes :
                                    datelong.charAt(0).toUpperCase()+datelong.slice(1,)+" à "+heures+'h'+minutes ;
 }

 const listemoismini = ['Jan','Fev','Mars','Avr','Mai','Juin','Juil','Août','Sept','Oct','Nov','Dec'];
export const formatdatemini = (date)=>{if(!date)  return ''  ; 
  return   listemoismini[(new Date(date)).getMonth()]+' '+(new Date(date)).getDate()+' ,     '+ (new Date(date)).getFullYear()
}
export const comparedates=(a,b)=>{ return     (new Date(b.date_reception)).getTime() - (new Date(a.date_reception)).getTime() }

 export const convertdateAA=(date)=>{
  if(!date) return ''; 
      const aujourdhui = (new Date().toLocaleString("fr-FR", {timeZone: "Europe/Paris"})).split(' ')[0]; 
      const hier =  (new Date(Date.now()-24*60*60*1000).toLocaleString("fr-FR", {timeZone: "Europe/Paris"})).split(' ')[0]; 
     const madate = (new Date(date)).toLocaleString("fr-FR");
      const [day,time] = madate.split(' '); 
      const jour =day===aujourdhui?"Aujourd’hui ":day===hier?"Hier ": day  

      return jour+" à "+time.split(':')[0]+'h'+time.split(':')[1]
 }

 export const formatdatehistory=(date)=>{
      if(!date) return ''; 
      const today = (new Date()).setHours(0, 0, 0, 0) ;
      const tomorrow = (new Date(new Date().getTime() + 24*60*60*1000)).setHours(0, 0, 0, 0);
      const hier = (new Date(new Date().getTime() - 24*60*60*1000)).setHours(0, 0, 0, 0);
      const datetime = (new Date(date)).setHours(0, 0, 0, 0) ;
      const heures = (new Date(date)).getHours().toString().padStart('2',0);
      const minutes = (new Date(date)).getMinutes().toString().padStart('2',0);
      const datefr = new Date(date).toLocaleString("fr-FR", {timeZone: "Europe/Paris"}).split(' ')[0] ;
      
    return datetime ===hier ? 'Hier à '+heures+':'+minutes :
          datetime ===today ? "Aujourd'hui à "+heures+':'+minutes :
          datetime ===tomorrow ? "Demain à "+heures+':'+minutes :
                                  "Le "+datefr+" à "+heures+':'+minutes ;
 }

 export const formatdatenotif=(date)=>{
  if(!date) return ''; 
  const today = (new Date()).setHours(0, 0, 0, 0) ;
  const hier =  (new Date(new Date(today).getTime() - 24*60*60*1000)).setHours(0, 0, 0, 0);
  const datetime = (new Date(date)).setHours(0, 0, 0, 0) ;
  const nbminutes = Math.trunc(((new Date()) - (new Date(date))) /60000 ); 
  const nbmin = nbminutes%60;

  const heures = (new Date(date)).getHours().toString().padStart('2',0);
  const minutes = (new Date(date)).getMinutes().toString().padStart('2',0);
  const datelong = (new Date(date)).toLocaleDateString("fr-FR", { weekday: 'long',  month: 'long', day: 'numeric' });
 
  return   datetime === hier                       ? 'Hier à '+heures+':'+minutes :
          (datetime === today  &&  nbminutes < 61) ? "Il y a "+nbmin+" minutes" :
          (datetime === today  &&  nbminutes >60)  ? "Aujourd'hui à "+heures+':'+minutes :
                                                     "Le "+datelong+" à "+heures+'h'+minutes ;

   }
