import React  from "react";

import { logocompagnie } from "../../utils/logocompagnie";
import { useUser } from "../../state/reactquerycabinets";

import { isclassblur } from "../../utils/radin";

import Jauge from "./Jauge";
import AjoutDevis from "./AjoutDevis";
import AccessToDocs from "./AccessToDocs";

import './CardFormuleDevis.scss';


const classNames = require('classnames');
function CardFormuleDevis({formule={},last}){
        const {data: user} = useUser(localStorage.getItem('token'))  ;
        function formatprix(prix){ return prix?   (Math.round(prix*100)/100).toString().split('.').join(","):'0'}
        const formattexte=(texte)=>{ return ( texte?texte.replace('detartrage','det.').replace('détartrage','det.').replace('consultation','consult.').replace('personne','pers.').replace(' / ','/').replace('/ ','/').replace(' /','/').replace(',00',''):texte)  }
        const datas =(formule) =>[{nb :formule.hospi, liste:[formule.hospi_honoraire,formule.hospi_chambre]},
                                {nb :formule.consults_specialistes_nb, liste:[formule.consults_generalistes_p,formule.consults_generalistes_p_non_dptam,formule.consults_specialistes_p,formule.consults_specialistes_p_non_dptam,formule.consults_pharma_nr]},
                                {nb :formule.optique_lunettes_nb , liste:[formule.optique_lunettes]},
                                {nb :formule.optique_lentilles_nb, liste:[formule.optique_lentilles]},
                                {nb :formule.dentaire_prothese_nb , liste:[formule.dentaire_detartrage_p,formule.dentaire_prothese_p]},
                                {nb :formule.dentaire_implant_nb, liste:[formule.dentaire_implant]},
                                {nb :formule.dentaire_orthodontie_nb, liste:[formule.dentaire_orthodontie_p]},
                                {nb :formule.prevention, liste:[formule.prevention_consult]}];

        return(<div className={classNames("devisformule",{"devisformule_last": last}   )} >
                        <div className="devisformule_top" >
                                <div className="devisformule_top_img">
                                        <img src={logocompagnie(formule.compagnie?.toUpperCase())}  width='60' alt='logoCompagnie'/>
                                </div>  
                                <div className="devisformule_nomgamme" >{formule.gamme}</div>    
                                <div className={`devisformule_formulenom ${isclassblur()}`} >{formule.nom_formule} </div> 
                                <div className={`devisformule_prix ${isclassblur()}`}>{formatprix(formule.prix)}€  <span className="devisformule_parmois" >/par mois</span>   </div> 
                                <div  className="devisformule_actions" > 
                                        <AjoutDevis formule={formule}   />    
                                </div>         
                        </div>  
                        { datas(formule).map((data, index) =>(<div   key={index}  className="blockinfopost">
                                                                <div>
                                                                        <div className="col1block_titre"></div>
                                                                        {[...data.liste].map((texte,indexe)=>(<div key={texte+indexe}  className={classNames("coll1block_line"  )} >
                                                                                                                        {formattexte(texte)}
                                                                                                                </div>))  }
                                                                </div>
                                                                <Jauge nb={data.nb} />
                                                        </div>))}
                        <div className="containeracesstodocs" > <AccessToDocs  nom={formule.compagnie+formule.gamme}  /> </div>
                </div>)
}

export default CardFormuleDevis

