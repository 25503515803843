
import React , {useState } from "react" ;
import { useSelector } from "react-redux";
import axios from "axios";
import { nanoid } from '@reduxjs/toolkit';

import { addEltHistory } from "../../state/reactquery";
import { queryClient } from '../../state/queryClient';

import { modifyonelead } from "../../utils/actions_lead";

import './ContratWinFicContratUpload.scss' ; 
function ContratWinFicContratUploadTwoFiles(){

    const lead = useSelector(state=>state.lead.leadactu) ;

    const [loading, setLoading] = useState(false) ; 

    
    const senddocdejasigne= async (e) =>{ 

        const texthistory =e.target.id === 'uploadbulletin' ? 'Fiche Conseil signée à l’extérieur  importé.':'Bulletin signé à l’extérieur  importé.' ;
        const type_file = e.target.id === 'uploadbulletin' ?'signature':'conseil' ; 
        const nomfichier = e.target.id === 'uploadbulletin' ?`contrat${(nanoid(5)).toString()}`:`conseil${(nanoid(5)).toString()}`
        const newlead = (e.target.id === 'uploadbulletin' && lead.sign_fic===2&& lead.sign_docs===2 )?{...lead,'sign_bulletin':2, 'statut_client':'gagne'}:
                        (e.target.id === 'uploadbulletin' && lead.sign_fic===2&& lead.sign_docs!==2 )?{...lead,'sign_bulletin':2, 'statut_client':'attente_documents'}:
                        (e.target.id === 'uploadbulletin' && lead.sign_fic!==2 )?{...lead,'sign_bulletin':2, 'statut_client':'attente_signature'}:
                        (e.target.id === 'uploadfic' && lead.sign_bulletin===2 && lead.sign_docs===2  )?{...lead,'sign_fic':2, 'statut_client':'gagne'}:
                        (e.target.id === 'uploadfic' && lead.sign_bulletin===2 && lead.sign_docs!==2  )?{...lead,'sign_fic':2, 'statut_client':'attente_documents'}:
                        {...lead,'sign_fic':2, 'statut_client':'attente_signature'};
    
        setLoading(true)   ; 
        const filecontrat = e.target.files[0] ; 

        const data = new FormData() ;
        data.append('datajson', JSON.stringify({courtier:lead?.courtier,id_client:lead.id_client ,id_lead:lead.id ,type_file,commercial:lead?.commercial,nomfichier }) )
        data.append('file',filecontrat,`/${nomfichier}.${e.target.files[0].name.split('.')[1]}`);
        await axios.post(`/files/addclientfile/`, data ,{  headers:  { 'Accept': 'application/json',"Content-Type": "multipart/form-data"}  });
        modifyonelead(newlead) ;   
        addEltHistory('documents',  `<div class='horoline1' >${texthistory}</div>`) ;
        setTimeout(()=>{document.getElementById('uploadfic') && (document.getElementById(e.target.id).value= null )  },1000); 
        queryClient.invalidateQueries({ queryKey: ['documents',lead?.id]}) ; 
        setLoading(false)   ; 
    }

    return( <div className='contratwin_box2'>
                            <div className="contratwindevis_upload2">
                                    <div className="contratwindevis_upload_texte"  >{loading ?<div className="loadingupload"></div> :'Fiche Conseil signée'}</div>
                                    <input type='file' id='uploadfic' className="uploadvalidcontrat_input"
                                            onChange={senddocdejasigne} />
                            </div>
                            <div className="contratwindevis_upload2">
                                    <div className="contratwindevis_upload_texte"  >{loading ?<div className="loadingupload"></div> :'Bulletin signé'}</div>
                                    <input type='file' id='uploadbulletin' className="uploadvalidcontrat_input"
                                            onChange={senddocdejasigne} />
                            </div>
            </div>);
}



export default ContratWinFicContratUploadTwoFiles ;



