import React , {useEffect, useState}  from 'react';
import { useSelector , useDispatch} from 'react-redux';
import axios from 'axios';

import {  defineDevisFormules} from '../../state/redux';

import { useDevis } from '../../state/reactquery';
import GroupFiltres from './GroupFiltres';
import ListeFormules from './ListeFormules';


import FormulesDevis from './FomulesDevis';
import Historique from './Historique'; 
import InfosContrat from './InfosContrat';
import CardFormuleDetail from '../../Components/CardsFormule/CardFormuleDetail';
import Postmail from '../../Components/Email/PostMail';

import './FicheClientBottom.scss';


const rightchoices = {'rightselect1':"Formules",'rightselect2':"Favoris" ,'rightselect3':"Messagerie",'rightselect4':"Contrat",'rightselect5':"Historique"};

const classNames = require('classnames');


function FicheClientBottomRight(){
  const dispatch = useDispatch();
  const lead = useSelector(state=>state.lead.leadactu) ;
  const formuleshow= useSelector(state=>state.formules.formuleshow) ;
  const {data: devis} = useDevis(lead?.id) ; 
  const [show, setShow] = useState('rightselect1'); 
  const [showfiltres, setShowfiltres] = useState(true)
  
  const devisformules =   useSelector(state=>state.formules.devisformules) ;



  const fetchDevisToForms = async()=>{
    let mesformules = [] ; 
    if(!devis){ mesformules = []  ; return }

    const listeids = [devis.id_1,devis.id_2,devis.id_3]?.filter(i=>i!==undefined)?.map(x=>x?.split('_')?.[0]) ;
    if(listeids.length===0){ mesformules = []  ; return }

    const reqforms =  await axios({method:'POST',url:`/devis/findformulesfromliste`,data:{listeids},headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  } });

    let form1 = reqforms?.data?.filter(x=>x.id===devis.id_1?.split('_')?.[0])?.[0] ;
    let form2 = reqforms?.data?.filter(x=>x.id===devis.id_2?.split('_')?.[0])?.[0] ;
    let form3 = reqforms?.data?.filter(x=>x.id===devis.id_3?.split('_')?.[0])?.[0] ;

    devis.id_1 && mesformules.push({...form1,id:devis.id_1 ,prix: devis.prix_1 })
    devis.id_2 && mesformules.push({...form2,id:devis.id_2 ,prix: devis.prix_2 })
    devis.id_3 && mesformules.push({...form3,id:devis.id_3 ,prix: devis.prix_3 })


   dispatch(defineDevisFormules(mesformules))

 }

 useEffect(()=>{ fetchDevisToForms() ;  },[devis?.id_1,devis?.id_2,devis?.id_3])
  

 const clickselectright =(e)=>{
  setShow(e.target.id) ;
  e.target.id!=='rightselect1' ? setShowfiltres(false) : setShowfiltres(true) ; 
}

    return(<div className="ficheclient_bottom_right">
              <div className="ficheclient_bottom_right_top">
                   {Object.keys(rightchoices).map(id=>(<div key={id} id={id} 
                                                            className={classNames('case', {'selected':show===id})}  
                                                            onClick={clickselectright}>
                                                                    {rightchoices[id]}
                                                                    {id==='rightselect2' && <div className='nbdevis'>{devisformules.length}</div>}  
                                                        </div>))  }
                </div>
                <div className="ficheclient_bottom_right_content">
                    <div  className={classNames({'nonvisible positionabsolute': (show!=='rightselect1' || formuleshow===null )},{'visible': show==='rightselect1' && formuleshow!==null})}> <CardFormuleDetail   /> </div>
                    <div className={classNames({'nonvisible positionabsolute': (show!=='rightselect1' || formuleshow!==null)},{'visible ficheclient_containerformules': show==='rightselect1' &&  formuleshow===null })}>
                         {(showfiltres && !formuleshow?.id ) && <GroupFiltres />}
                        <ListeFormules  typeliste='ficheclient'  />
                    </div>  
                    <div  className={classNames('positionabsolute',{'nonvisible': (show!=='rightselect2')},{'visible': show==='rightselect2'})}><FormulesDevis setShow={setShow}  /></div>
                    <div  className={classNames('positionabsolute',{'nonvisible': (show!=='rightselect3')},{'visible': show==='rightselect3'})}><Postmail  /></div>
                    <div  className={classNames('positionabsolute  width100',{'nonvisible': (show!=='rightselect4')},{'visible': show==='rightselect4'})}><InfosContrat /></div>
                    <div  className={classNames('positionabsolute',{'nonvisible': (show!=='rightselect5')},{'visible': show==='rightselect5'})}><Historique  /></div>
                </div>
              </div> );
}


export default FicheClientBottomRight ;

