import AircallPhone from 'aircall-everywhere' ; 
import { batch } from 'react-redux';

import { addEltHistoryIdLead } from '../state/reactquery';
import { store  ,modifyQualiflead,modifyDisplaymodal,modifyEtattel,
         initialiseLeadactu,  initialiseLeadappelant , modifyEcran ,
		 allFormules , defineDevisFormules ,filterFormules, modifyMessagetel} from '../state/redux';

import { findclientfromtel } from './phone';
import { getallformules } from './calculstarifs';
import { modifyonelead } from "./actions_lead";


let phone; 
export const aicallphone = {
    getTelephone : ()=> phone, 
    setTelephone : (t)=>{ phone = t }
}



export async function initialiseaircall(){
  const phone = new AircallPhone({
    domToLoadPhone: '#aircallphone',
    size: 'small',
    onLogin: settings => { console.log(settings) },
    onLogout: () => {
      // ...
    }
  });

  
    aicallphone.setTelephone(phone)

    console.log('PHONE', phone)

    phone.on('onLogin', callInfos => {
      console.log('LOGIN', callInfos)
    });

      phone.on('incoming_call', async (callInfos) => {
        console.log('incoming_call', callInfos) ;
        // incoming_call {from: '+33757598999', to: '+33743392652', call_id: 2276769500}
        // POPUP si 
        store.dispatch(modifyEtattel('appel'));
        store.dispatch(modifyMessagetel(callInfos.from));
        const nb = store.getState().lead.leadactu.nbappels || 0 ;
        modifyonelead({'nbappels': nb+1}) ;
        const id_lead = await findclientfromtel(callInfos.from);
        id_lead && addEltHistoryIdLead(id_lead ,'appelsortant',  `<div class='horoline1' >Appel entrant</div>` ) ;
        document.getElementById('aircallphone').classList.toggle('displaynone') ; 
      });
      
      phone.on('call_end_ringtone', callInfos => {
        // call_end_ringtone {answer_status: 'answered', call_id: 2276769500}
        if(callInfos.answer_status=='answered'){
          store.dispatch(modifyQualiflead((store.getState().lead.leadappelant.id && store.getState().lead.leadappelant!=='gagne')?false:true));  
          store.dispatch(allFormules([]));  
          store.dispatch(defineDevisFormules([])); 
          store.dispatch(filterFormules({'type':'liste', 'liste':[]})); 
          store.dispatch(modifyEtattel('appel')) ;
        
          batch( () => {store.getState().lead.leadappelant.id && store.dispatch(initialiseLeadactu( store.getState().lead.leadappelant));  
                   store.getState().lead.leadappelant.id && store.dispatch(modifyEcran('ficheclient'));  });
          setTimeout(()=>{ store.getState().lead.leadappelant.id &&  store.dispatch(getallformules());}, 1000) ;
          setTimeout(()=>{store.dispatch(initialiseLeadappelant({})) ; }, 2000) ;
        }
        
      });
      
      phone.on('call_ended', callInfos => {
        // call_ended {duration: '00:29', call_id: 2276769500}
        store.dispatch(modifyEtattel('normal')) ;
        store.dispatch(initialiseLeadappelant({})) ;
        (store.getState().lead.qualiflead===false && !store.getState().display.displaymodal?.includes('mod_qualiflead') )&& store.dispatch(modifyDisplaymodal('mod_qualiflead'));
        console.log('call_ended', callInfos) ;

      });
      
      phone.on('comment_saved', callInfos => {
        console.log('comment_saved', callInfos)
      });
      
      phone.on('outgoing_call', callInfos => {
        store.dispatch(modifyEtattel('appel'));
        const nb = store.getState().lead.leadactu.nbappels || 0 ;
        modifyonelead({'nbappels': nb+1}) ;
       console.log('outgoing_call', callInfos)
      });
      
      phone.on('outgoing_answered', callInfos => {
        console.log('outgoing_answered', callInfos)
      });


}



export const phonesend = async(numtel) =>{
    const phone = aicallphone.getTelephone() ;
    await phone.send('dial_number',{phone_number: numtel },
        (success, data) => {  
          
            console.log('SUCESS', success) ;   
            console.log('DATA', data);
   
        }
      );
    //   dialButton.click() ; 
}
// dialButton?.addEventListener(
//     'click',
//     () => {
//        console.log('CLICKPHONE')
//     },
//     false
//   );




