import React ,{ useState , useEffect}from "react";
import { useSelector } from "react-redux";

import { enregistrerformulelibre } from "../../utils/utils_contratwin";
import { queryClient } from '../../state/queryClient';

import Button from "../Buttons/Button";

import croixclose from '../../images/croixclose.svg';

import './ContratWinFormule.scss' ; 

const listchamps ={'nom_titulaire':'Nom du titulaire','prenom_titulaire':'Prénom du titulaire',
                    'formule':'Formule','prix':'Prix','date_effet':'Date d’effet'}  

const classNames = require('classnames');
function ContratWinFormuleExterne({setDisplayformexterne}){ 
    const lead = useSelector(state=>state.lead.leadactu) ;
    const [compgammes, setCompGammes] = useState({}) ; 
    const [comp, setComp] = useState('') ; 
    const [gammes, setGammes] = useState([]) ; 
    const [gamme, setGamme] = useState('') ; 
    const [loading, setLoading] = useState(false) ;
    const jsongammesbulletins= useSelector(state=>state.courtier.jsongammesbulletins) ;

    const formsigninitialexterne = {'courtier':lead.courtier,
                                    'id_lead':lead.id,
                                    'id_client':lead.id_client,
                                    'nom_titulaire':lead.nom,
                                    'prenom_titulaire':lead.prenom,
                                    'commercial':lead.commercial,
                                    'email':lead.email,
                                    'date_effet':lead.date_effet,
                                    'etat_contrat':'projet'}

    const [contrat, setContrat] = useState(formsigninitialexterne) ; 
                                                

   useEffect(() => {setCompGammes(jsongammesbulletins) ; 
                    const compagniefirst = Object.keys(jsongammesbulletins)?.[0] ;
                    const gammescompagniefirst  = Object.keys(jsongammesbulletins[compagniefirst]) ;
                    const gammefirst = gammescompagniefirst?.[0]  ;
                    setContrat({...contrat,'compagnie':compagniefirst ,'gamme':gammefirst}) ;
                    setComp(compagniefirst) ; setGammes(gammescompagniefirst) ; setGamme(gammefirst);
    }, []); 

    const handleinput =(e)=>{setContrat({...contrat,[e.target.id]:e.target.value }) ;}

    const handleselectgamme =(e)=>{  setGamme(e.target.value) ;  setContrat({...contrat,'gamme':e.target.value});}

    const handleselectcompagnie =(e)=>{ setComp(e.target.value)  ;
                                        setGammes( Object.keys(jsongammesbulletins[e.target.value])) ;
                                        setGamme( Object.keys(jsongammesbulletins[e.target.value])?.[0])
                                       setContrat({...contrat,'compagnie':e.target.value,
                                                              'gamme': Object.keys(jsongammesbulletins[e.target.value])?.[0] } ) ;}

    const divlabelinput= (id)=>( <div className={classNames("contratext_labelinput",{'smallinput':id==='num_contrat' || id==='date_signature'},{'miniinput':id==='prix' || id==='date_effet'})} >
                                        <div className="contratext_labelinput_label" >{listchamps[id]}</div>
                                        <input id={id}    value={contrat[id]}    onChange={handleinput}
                                                pattern={( id==='date_signature')?"d{2}/d{2}/d{4}":null}
                                                type={( id==='date_signature')?"date":"text" } 
                                                disabled ={id==='date_effet'} />
                                 </div>) ;

    const divlabelselectcompagnie = ()=>( <div className="contratext_labelinput smallinput" >
                                                <div className="contratext_labelinput_label" >Compagnie</div>
                                                {Object.keys(compgammes)?.length >0 && <select value={comp||''} className='selectcompgamme' onChange={handleselectcompagnie} >
                                                            {Object.keys(compgammes)?.sort()?.map((y,i)=><option key={i}  value={y} >{y}</option>)}
                                                </select>}
                                            </div>) ;

    const divlabelselectgamme = ()=>( <div className={classNames("contratext_labelinput deuxtiers")} >
                                             <div className="contratext_labelinput_label" >Gamme</div>
                                            {gammes?.length >0 && <select value={gamme||''} className='selectcompgamme' onChange={handleselectgamme} >
                                                    {gammes?.map((y,i)=><option key={i}  value={y} >{y}</option>)}
                                            </select>}
                                        </div>) ;

    const enregistrerformule = async()=>  {
            setLoading(true) ;
            await enregistrerformulelibre(contrat)  ;
            setTimeout(()=>{setDisplayformexterne(false);setLoading(false) ;
                            queryClient.invalidateQueries({queryKey:['contrats',lead?.email,lead?.courtier]}) ;
                            queryClient.invalidateQueries({ queryKey: ['devis',lead?.id]}) ; 
            },1000); 
    }

    return(<>
                <div className="contratwin2_divformext">
                    <div className="cardresilitationclose" >
                        <img src={croixclose}  alt="imgclose" onClick={()=>setDisplayformexterne(false)}  />
                    </div>
                    <div className="contratwin2_divformext_titre"> Formule non listée </div>
                    <div className="contratwin2_divformext_form"> 
                        {divlabelinput('nom_titulaire')}
                        {divlabelinput('prenom_titulaire')}
                        {divlabelselectcompagnie()}
                        {divlabelselectgamme()}
                        {divlabelinput('formule')}
                        {divlabelinput('prix')}
                        {divlabelinput('date_effet')}
                    </div>
                </div>
                <div className="contratwin2_divformext_confirme"> 
                    <Button texte={loading ? 'Envoi en cours' : "Confirmer" }
                            disable={!contrat.compagnie || !contrat.gamme ||!contrat.formule||!contrat.prix}
                            color={(!contrat.compagnie || !contrat.gamme ||!contrat.formule||!contrat.prix)?'bouton_color_disable':'bouton_color_orange'}
                            loading={loading}
                            size='bouton_size_normal'
                            onclick={enregistrerformule} />
                </div>
            </> )
}

export default ContratWinFormuleExterne