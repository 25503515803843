import React , {useState} from "react";

import axios from "axios";

import { useUser } from '../../state/reactquerycabinets';

import MiniTablo from "./MiniTablo";

import './EcranGestionLeads.scss'

var classNames = require('classnames');

function EnterLeadsFauxNumeros(){
    const {data: user} = useUser(localStorage.getItem('token'))  ;

    const [choiceType,setChoiceType] = useState('arriveeleads') ;
    const [choicePeriode,setChoicePeriode] = useState('jour') ;
    const [stats, setStats] = useState({});
    const [leads,setLeads] = useState([]);

    const search = async ()=>{ const jsondata= {'periode':choicePeriode,'type':choiceType ,'courtier': user['courtier']};
                                const response =  await axios.post(`/gestion/getenterfauxnum`,jsondata);
                                if(response.status===400){ return  }
                                  setStats(response?.data?.stats) ; 
                                  setLeads(response?.data?.leads) ;
                              }

    return (<div className="gestion_bottom" >
                <div className="gestion_bottom_left" >
                    <div className="gestion_choicetype" >
                        <button className={classNames('bouton bleue',{'selected': choiceType === 'arriveeleads' })} onClick={() => setChoiceType('arriveeleads')} >Arrivee des Leads</button>
                        <button className={classNames('bouton bleue ml20',{'selected': choiceType === 'fauxnumeros' })} onClick={() => setChoiceType('fauxnumeros')} >Faux Numeros</button>
                    </div>
                    <div className="gestion_choiceperiode">
                        <button className={classNames('bouton vert',{'selected': choicePeriode === 'jour' })} onClick={() => setChoicePeriode('jour')} >{choiceType === "arriveeleads" ? "Leads" : "Faux Numeros"} du jour</button>
                        <button className={classNames('bouton vert',{'selected': choicePeriode === 'semaine' })} onClick={() => setChoicePeriode('semaine')}>{choiceType === "arriveeleads" ? "Leads" : "Faux Numeros"} de la semaine</button>
                        <button className={classNames('bouton vert',{'selected': choicePeriode === 'semainelast' })} onClick={() => setChoicePeriode('semainelast')}>{choiceType === "arriveeleads" ? "Leads" : "Faux Numeros"} de la semaine passee</button>
                        <button className={classNames('bouton vert',{'selected': choicePeriode === 'mois' })} onClick={() => setChoicePeriode('mois')}>{choiceType === "arriveeleads" ? "Leads" : "Faux Numeros"} du mois</button>
                        <button className={classNames('bouton vert',{'selected': choicePeriode === 'moislast' })} onClick={() => setChoicePeriode('moislast')}>{choiceType === "arriveeleads" ? "Leads" : "Faux Numeros"} du mois passe</button>
                    </div>
                    <div className="gestion_choicetype" >
                        <button className='bouton rougee' onClick={search} >Recherche</button>
                      
                    </div>
                </div>
                <div className="gestion_bottom_right" >
                    {Object.keys(stats)?.map(org=> <MiniTablo origine={org} leads={leads.filter(x=>x.origine===org)}  stats={stats[org]}  />)}
                </div>
            </div> );
}


export default EnterLeadsFauxNumeros

