import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { modifyChampsLeadactu , getAllClients ,  modifyDisplaymodal } from '../../state/redux';

import { convertdateXX} from "../../utils/utils_dates";



import './Famille.scss';
function Famille(){
  const dispatch = useDispatch()
  const lead =  useSelector(state=>state.lead.leadactu);
  const clients = useSelector(state=>state.datagestion.clients);

  const valider = ()=>{
    const items =document.getElementById('famille').getElementsByClassName('champdata') ;
    let newlead={} ; [].map.call(items, item => newlead[item.id] = item.textContent.trim());
    const othersclients = clients.filter(x=>x.id!==lead.id); 
    dispatch(modifyChampsLeadactu({...newlead}));
    dispatch(getAllClients([...othersclients,newlead]));
    const leadupdate =  {...lead,...newlead} ;
    Object.keys(leadupdate).map(key=>(leadupdate[key]==="" ||leadupdate[key]===null || leadupdate[key]===undefined )&& delete leadupdate[key]);

    axios({method:'POST',url:`/clients/updateclients/`,data:{id:lead.id,...leadupdate }});
}

const clickchamp = (e)=>{if(e.target.id!=='email'){return }
                        dispatch(modifyDisplaymodal('mod_changemail')) ;}

  const line = (label , idchamp)=> (<div className='infosfamille_line' > 
                                        <div className='infosfamille_line_label' > {label}  </div>
                                        <span id={idchamp} className='champdata' onClick={clickchamp} contentEditable={idchamp==='email'?"false":"true"} suppressContentEditableWarning={true}> {lead[idchamp]||' '}   </span>  
                                        <div  className='logocopygestion'  name={idchamp }  onClick={()=>{ navigator.clipboard.writeText(lead[idchamp]|| '') }}  ></div>
                                     </div>)

    return(<div>
                <div id="famille" className="famille" >
                      <div className="titre"><div className="titre_left">SOUSCRIPTEUR</div><div className="titre_right">{lead.date_reception && convertdateXX(lead.date_reception)}</div> </div>
                      {line('Titre','civilite')} {line('Nom','nom')}{line('Prénom','prenom')}{line('Adresse','adresse')}{line('Code Postal','code_postal')}{line('Ville','ville')}
                      {line('Email','email')}{line('Telephone 1','telephone')}{line('Telephone 2','telephone2')}{line('IBAN','iban1')}{line('Date de naissance','date_naissance')}{line('Regime','regime')}{line('Nº de SS','numsecu')}
                      <div className="titre"><div className="client_titre_left">CONJOINT</div></div>
                      {line('Titre','civilite_conjoint')} {line('Nom','nom_conjoint')}{line('Prénom','prenom_conjoint')}{line('Date de naissance','date_naissance_conjoint')}{line('Regime','regime_conjoint')}{line('Nº de SS','numsecu_conjoint')}
                      <div className="titre"><div className="client_titre_left">Enfant 1</div></div>
                      {line('Titre','civilite_enf1')} {line('Nom','nom_enf1')}{line('Prénom','prenom_enf1')}{line('Date de naissance','date_naissance_enf1')}{line('Regime','regime_enf1')}{line('Nº de SS','numsecu_enf1')}
                      <div className="titre"><div className="client_titre_left">Enfant 2</div></div>
                      {line('Titre','civilite_enf2')} {line('Nom','nom_enf2')}{line('Prénom','prenom_enf2')}{line('Date de naissance','date_naissance_enf2')}{line('Regime','regime_enf2')}{line('Nº de SS','numsecu_enf2')}
                      <div className="titre"><div className="client_titre_left">Enfant 3</div></div>
                      {line('Titre','civilite_enf3')} {line('Nom','nom_enf3')}{line('Prénom','prenom_enf3')}{line('Date de naissance','date_naissance_enf3')}{line('Regime','regime_enf3')}{line('Nº de SS','numsecu_enf3')}
                      <div className="titre"><div className="titre_left">Enfant 4</div></div>
                      {line('Titre','civilite_enf4')} {line('Nom','nom_enf4')}{line('Prénom','prenom_enf4')}{line('Date de naissance','date_naissance_enf4')}{line('Regime','regime_enf4')}{line('Nº de SS','numsecu_enf4')}
                      <div className="titre"><div className="titre_left">Enfant 5</div></div>
                      {line('Titre','civilite_enf5')} {line('Nom','nom_enf5')}{line('Prénom','prenom_enf5')}{line('Date de naissance','date_naissance_enf5')}{line('Regime','regime_enf5')}{line('Nº de SS','numsecu_enf5')}
                  </div><br/>
                  <button className="btnenregistrer" onClick={valider}>Valider</button>
              </div>)

}

export default Famille