
import axios from "axios";
import { batch } from 'react-redux' ;
 

import { store, allFormules,filterFormules,  defineListeCompagnies,
         defineListeCompagniesAffichees , modifyDisplaymodal} from "../state/redux";
import { queryClient } from '../state/queryClient';





export const isclassblur =() =>{
        const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
        return  user?.showtarifs!==1?'blur':'' };

export const radintarifs =async () =>{
    let reqformules = await axios.get(`/gestion/getallfomulesempty`,{headers:{'Accept':'application/json','Content-Type':'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}`} });
   
    batch( () => {  store.dispatch(allFormules(reqformules.data.map(f=>{return {...f,'prix':(Math.floor(Math.random() * 10000))/100 }}))); 
                    store.dispatch(filterFormules({'type':'all'})); 
                    store.dispatch(defineListeCompagnies([...new Set(reqformules.data.map(x=>x.compagnie))].sort()));
                    store.dispatch(defineListeCompagniesAffichees([...new Set(reqformules.data.map(x=>x.compagnie))].sort()));
        });
}

export const radinpopup = ()=>{
        store.dispatch(modifyDisplaymodal('mod_popuradin')) 

}