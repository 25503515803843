import React from 'react';


import FicheClientBottomLeft from './FicheClientBottomLeft';
import FicheClientBottomRight from './FicheClientBottomRight';

import './FicheClientBottom.scss';


function FicheClientBottom(){
    return(<div className="ficheclient_bottom">
              <FicheClientBottomLeft  />
              <FicheClientBottomRight  /> 
    </div>)
}


export default FicheClientBottom