import axios from "axios";

import { store } from '../../state/redux';
import { queryClient  } from '../../state/queryClient';

export const handle_equipment = async(equipement, files,isnewequipement)=>{
    const lead = store.getState().lead.leadactu; 
    
    const url = isnewequipement?"/equipement/create":`/equipement/modify` ;

    const datas = new FormData();
    datas.append("data", JSON.stringify(equipement));
    for (let i = 0; i < files.length; i++) {datas.append("files", files[i]);}
    const headers = {'Accept': 'application/json','Content-Type': "multipart/form-data",
                     'Authorization': `Bearer ${localStorage.getItem('token')}`}
    await axios.post( url , datas,{headers:headers});
    // INVALIDATION DES EQUIPEMENTS ET FICHIERS
    queryClient.invalidateQueries({ queryKey: ['documents',lead.id]}) ;
    queryClient.invalidateQueries({ queryKey: ['equipement',lead.id]}) ;
}



export const listecategories = ['Assurance de Personne','Assurance IARD','Assurances Pro','Autre'] ;

export const listetypesprix = ['Mensuel','Trimestriel', 'Semestriel', 'Annuel'] ;


export const jsontypeequipement = {
    'Assurance de Personne':
        ['Santé', 'Prévoyance (Hospi, décès, scolaire)', 'Assurance de Prêt', 'Animaux', 'Protection juridique'],
    'Assurance IARD':
        ['Auto', ' Moto', 'Habitation', 'Propriétaire non occupant', 'Loyer impayés'],
    'Assurances Pro': ['Santé Madelin', 'Prévoyance Madelin', 'Retraite Madelin', 'Multirisque Pro', 'RC Pro', 'PJ', 'Protection fiscale', 'Flotte Auto', 'Flotte Cyclo'],
    'Autre': [' ']
}