
import React , { useEffect}  from "react";
import { useDispatch , useSelector} from "react-redux";

import { getallleads } from '../../utils/axios_functions';

import DashboardChoices from "./DashboardChoices";
import DashboardFiltres from "./DashboardFiltres";
import DashBoardLeads from "./DashBoardLeads";
import DashboardTop from "./DashboardTop";
import ListEvents from "../../Components/Calendar/ListEvents";

import './EcranDashboard.scss'; 
function Dashboard(){
    const dispatch = useDispatch() ;
    const {leads }= useSelector(state=>state.leads);

   useEffect( ()=>{ leads?.length===0 && dispatch(getallleads()) ; },[]);

    // useEffect( ()=>{  dispatch(getallleads()) ; },[]);

     return(<div className="dashboard" >
   
                <DashboardTop />
                <DashboardChoices  />
                <DashboardFiltres  />
                <DashBoardLeads   />
                <ListEvents  position="fixedright" />
            </div>)
}


export default Dashboard ; 