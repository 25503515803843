import React from "react";


import './ButtonImgTel.scss' ;
function ButtonImgTel({texte, type,onclick}){

    return(<div className={"boutontelephonie "+type }  onClick={onclick}  > 
                <div className="boutontelephonie_interieur" >  {texte}</div>
            </div>)
}


export default ButtonImgTel