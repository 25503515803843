import React , { useState}  from "react";

import { useUser } from "../../state/reactquerycabinets";


import Coordonnees from '../../Components/EcranParametres/Coordonnees/Coordonnees';
import Equipes from "../../Components/EcranParametres/Equipes/Equipes";
import Utilisateurs from "../../Components/EcranParametres/Utilisateurs/Utilisateurs";
import Compagnies from "../../Components/EcranParametres/Compagnies/Compagnies";
import Leads from '../../Components/EcranParametres/Leads/Leads';
import Abonnement from '../../Components/EcranParametres/Abonnement/Abonnement' ;
import Facturation from '../../Components/EcranParametres/Facturation/Facturation' ;


import './EcranParametres.scss';
function EcranParametres(){
    const [choice , setChoice]  = useState('equipes') ;
    const Tabs = {'coordonnees': {"nom":"Coordonnées","component":<Coordonnees  />} ,
                  'equipes' : {"nom":"Équipes","component":<Equipes /> },
                  'utilisateurs': {"nom":"Utilisateurs","component":<Utilisateurs  />} ,
                  'compagnies' : {"nom":"Compagnies","component":<Compagnies /> },
                } ;
// 'leads': {"nom":"Leads","component":<Leads  />} ,
// 'abonnement' : {"nom":"Abonnement","component":<Abonnement /> },
// 'facturation' : {"nom":"Facturation","component":<Facturation /> },

    return(<div className="ecranparametres" >
                <div className="ecranparametrestop"  >
                    <div className="ecranparametrestop_titre"  >Paramétrage du cabinet</div>
                    <div className="ecranparametrestop_menu"  >
                        {Object.keys(Tabs).map(id=>(<div id={id}  key={id} className={choice===id?"selected":""} onClick={(e)=>{setChoice(e.target.id)}} >{Tabs[id]["nom"]}</div>))}
                    </div>   
                </div>
                <div  >
                    {Tabs[choice]['component']}
                </div>
           </div>)
}


export default EcranParametres
