import React from "react";
import './Check.scss';

const classNames = require('classnames');

function Check({checked,onclick,css}){

    return(<div  className={classNames('checkbullet',css,{'checked':checked})}  onClick={onclick} >
            </div>);
}

export default Check ;


