import React , {useState, useEffect}  from "react";
import { useSelector} from 'react-redux';
import axios from 'axios';

import {  useContrats, useResil , useYousign } from "../../state/reactquery";
import { useUser  } from "../../state/reactquerycabinets";
import { queryClient } from '../../state/queryClient';

import { modifyoneleadfront } from "../../utils/actions_lead";
import { convertdate , formatdate } from "../../utils/utils_dates";
import { champresiliation , listmotifs , listmodes , datasresilinitial ,
        textefin, messagefin, titrecontent , titrefin} from "../../utils/utils_resil";

import Button from "../../Components/Buttons/Button";
import Boulettes from "./Boulettes";

import './ContraWinResiliation.scss';
import './ContraWinResiliation2.scss';
          
const classNames = require('classnames');
function ContratWinResiliation(){
    const lead = useSelector(state=>state.lead.leadactu);
    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const {data: contrats} = useContrats(lead?.email,user?.courtier) ; 
    const {data: resil} = useResil(lead?.id_resil) ;
    const lastcontrat =  contrats?.sort((a,b)=> new Date(b.date_insert) -new Date(a.date_insert))?.[0]; 

    const [horaire, setHoraire]  = useState(null) ;
    const [etape, setEtape] = useState(0) ;
    const [titulaire, setTitulaire] = useState('s');
    const [loading, setLoading]  = useState(false) ; 
    const [champsformulaire,setChampsFormnulaire]= useState({}) ;
    const [datasresil, setDatasresil] = useState(datasresilinitial(lead,lastcontrat));           
    const step =lead?.['sign_resil']||0 ;

    const {data: yousign} = useYousign(lead?.id_resil) ; 



    useEffect(()=>{yousign?.status==='ongoing' && setHoraire(formatdate(yousign?.dae_initialisation)) ;
                   yousign?.status==='done' && setHoraire(formatdate(yousign?.date_signature)) ;
    },[yousign?.id])


    const clicktyperesil = (e)=>{setDatasresil({...datasresil,type : e.target.id   }) ;  }
    const definemotif =(e)=>{setDatasresil({...datasresil,'objet': e.target.id ,'type_resil': listmotifs[e.target.id] }) }

    const linequestioninputradio = (id,texte,validate,click)=>(<div key={id} className={"linequestioninputradio  "+(Object.keys(listmotifs).includes(id) ? 'half':'all')}  >
                                                                <div  id={id}    className={classNames("buttonradio",{'validated':validate} ) }  onClick={click} ></div>
                                                                <div className="linequestioninputradio_question"  >{texte}</div>
                                                              </div>);
    const changechmap =(e)=>{  setDatasresil({...datasresil,[e.target.id]: e.target.value })}
    const grplabelinput = (x,label)=>( <div key={x}  className={"containerlabelinputresil "  +((x==='complement_adresse_compagnie')?'deuxtiers':'tiers') } >
                                            <div className="inputtextelabel">{label}</div>
                                              <input id={x}  value={datasresil[x]||''}  
                                                      type={x==='date_resile'|| x==='date_signature' ?"date":"text"} 
                                                      onChange={changechmap}
                                                      className="inputretractation"  />
                                         </div>) ;
                                          
    const resiliation = async ()=>{
           setLoading(true) ;                             
            datasresil['date_resile'] && (datasresil['date_resile']= convertdate(datasresil.date_resile)) ;
            datasresil['date_signature'] && (datasresil['date_signature']= convertdate(datasresil.date_signature)) ;
            const myheaders = {'Accept':'application/json','Content-Type':'application/json','Authorization':`Bearer ${localStorage.getItem('token')}`} ;
            await axios.post(`/resiliation/resiliation${datasresil['type']}`,{lead,datasresil}, {headers:myheaders});
            setLoading(false) ; 
            setEtape(etape=>etape+1) ;
            modifyoneleadfront({...lead,'sign_resil':datasresil['type']==="mandat"?1:2}) ; 
            queryClient.invalidateQueries({ queryKey: ['listmails',user['postmail'],lead.email]}) ;  
            queryClient.invalidateQueries({ queryKey: ['histories',lead.id]});
            queryClient.invalidateQueries({ queryKey: ['documents',lead.id]});
     }    

    const nextetape =  ()=>{  etape===1 && setChampsFormnulaire(champresiliation(datasresil.objet,datasresil.type))
                              etape===2 ? resiliation():setEtape(etape=>etape+1)  ;  }  
            
    const questionnaire=  (etape===0?<>{ Object.keys(listmodes).map(x=>(  linequestioninputradio(x,listmodes[x],datasresil['type']===x,clicktyperesil)   ))   } </>:
                          etape===1?<>{ Object.keys(listmotifs).map(x=>( linequestioninputradio(x,listmotifs[x],datasresil['objet']===x ,definemotif)))} </>:
                          etape===2?<>{ Object.keys(champsformulaire).map(x=>( grplabelinput(x,champsformulaire[x]) )) } </>:<></>  );

   const disable =  (etape===2 &&  datasresil['type']!=="mail") ? (!datasresil['compagnie'] || !datasresil['codepostal_compagnie'] || !datasresil['adresse_compagnie'] || !datasresil['ville_compagnie']) :
                    (etape===2 &&  datasresil['type']==="mail") ? !datasresil['compagnie'] :
                     etape===3? (!datasresil['num_anciencontrat'] || !datasresil['date_resile'] )  : false
    
   const grpbuttons = etape===0?<Button  texte="Continuer"  containerbouton='fullbuton'  color='bouton_color_orange' size='bouton_size_normal'  onclick={()=>{setEtape(1)}}   />:
                                <><Button  texte="Retour"   color='bouton_color_blancgris' size='bouton_size_normal'  onclick={()=>{setEtape(etape=>etape-1)}} />   
                                  <Button  texte={loading?"Envoi en cours":"Continuer"} loading={loading}  color='bouton_color_orange' size='bouton_size_normal' disable={disable} onclick={nextetape} /></>

  const definetitulaire= (e)=>{
      const datas_titulaire = {'nom_titulaire':e.target.id==='s'?lead.nom:lead.nom_conjoint,
                               'prenom_titulaire':e.target.id==='s'?lead.prenom:lead.prenom_conjoint,
                               'numsecu_titulaire':e.target.id==='s'?lead.numsecu:lead.numsecu_conjoint};

      setTitulaire(e.target.id);setDatasresil({...datasresil,...datas_titulaire });
  }

  
  const textbottom = step==0?"Signez avec ":step==1?`Envoyé le ${horaire} avec `:`Signé le ${horaire} avec ` ;

    return(  <div className='contratwin_box '> 
                    {etape<=2 && <> <div className='contratwin_box_top'>
                                        <div className='resiliation_top_titre'>Résiliation d’un ancien contrat </div>
                                        <div className='resiliation_top_soustitre'>
                                          {(etape === 2 && lead.date_naissance_conjoint) ? <div className="linequestioninputradiowin">
                                          Détails du contrat
                                            <div  id='s'    className={classNames("buttonradio ml25",{'validated':titulaire==='s'} ) }  onClick={definetitulaire} ></div>
                                            <div className="mr25">Titulaire</div>
                                            <div  id='c'    className={classNames("buttonradio",{'validated':titulaire==='c'} ) }  onClick={definetitulaire} ></div>
                                            <div>Conjoint</div>
                                          </div>:<>{titrecontent(etape)} </>}
                                         </div>
                                        <div className={classNames({'contratwin_box_flex':etape!==2},{'contratwin_box_flex_questionnaire':etape===2},{'resiliation_top_questions':etape!==2},{'resiliation_top_formulaire':etape!==2})} >
                                                {questionnaire}
                                        </div>
                                    </div>
                                    <div className='contratwin_box_bottom'>
                                        <div className='contratwin_box_bottom_left'>
                                            <Boulettes total={4} etape={etape} />
                                            {(datasresil?.['type']==="mandat")&& <div className='contratwin_box_bottom_message'>
                                              {textbottom}
                                              </div>}
                                        </div>
                                        <div className='contratwin_box_bottom_containerboutons'  >{grpbuttons}</div> 
                                    </div> </>} 
                    {etape===3 && <div className='resiliation_fin'>
                                        <div className='resiliation_top_titre'>{titrefin(datasresil['type'],lead)}</div>
                                        <div className="resiliation_textefin" >{textefin(datasresil)} </div>
                                        <div className='' > {messagefin(datasresil['type'])}  </div>
                                </div>}   
                </div>)
}

export default ContratWinResiliation