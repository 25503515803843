import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_URLBACK;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['crm'] = true;




// && process.env.NODE_ENV === 'development'
if (module.hot ) {
  module.hot.accept();
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
//   ,document.getElementById('root')
// );

ReactDOM.render( <App />,document.getElementById('root'));
