import axios from 'axios';
import { batch } from 'react-redux';
import { Device } from '@twilio/voice-sdk';

import { queryClient } from '../state/queryClient';

import { findclientfromtel } from './phone';
import { store  ,modifyMessagetel , modifyDisplaymodal  , modifyEtattel ,
         modifyRingfirst,modifyQualiflead,initialiseLeadactu,  initialiseLeadappelant , 
         modifyEcran ,allFormules , defineDevisFormules ,filterFormules} from '../state/redux';


import { modifyonelead } from './actions_lead';
import { getallformules } from './calculstarifs';


let tel= {device : null, connexion: null  , token:'' };


export const telephone = {
      failedrequest: false  ,
      getTelephone : ()=> tel, 
      setTelephone : (t)=>{tel = t}
  }

function convertteltotwilio(num){const numero = num?.replace(/([ .])/g,"");
                                  return numero[0]!=='+'?'+33'+numero.substring(1).toLowerCase():numero;}

async function updatestatustel(etat){
  if(telephone.failedrequest===true){ return }
  const user= queryClient.getQueryData(['user',localStorage.getItem('token')])   ;   
  try{  await axios.post(`/twilio/updatesatusphone/`,{'telephone':user['telephone'], 'status' : etat  });
  }catch(err){if(err.code.includes('ERR_NETWORK')){ telephone.failedrequest=true}  }                       
 
}

export const handleconnexion = (conn)=>{  
        conn &&   conn.on('accept', function(conn){ telephone.setTelephone({...telephone.getTelephone(), connexion : conn}) }); 
        conn &&   conn.on('cancel', function(conn){ console.log('CANCEL: CONNECTION NON ETABLIE REJETEE PAR CLIENT', conn); 
                                                    telephone.setTelephone({...telephone.getTelephone(), connexion: null})
                                                    store.dispatch(modifyMessagetel(''));
                                                    store.dispatch(modifyEtattel('normal')) ;
                                                    store.dispatch(initialiseLeadappelant({})) }); // APPEL EXT ABANDON EXT -   
        conn &&  conn.on('disconnect', function(conn){ console.log('DISCONNECT',conn._wasConnected);
                            const event = new Event('postappel');window.dispatchEvent(event);         
                           raccrochageintempestif() ;}); // APELL EXT    ABANDON INT ET  APPEL IMT 
        conn &&  conn.on('reject', function(conn){ console.log('REJECT')}); 
        conn &&  conn.on('error', (error) => { console.log('An error has occurred: ', error);});
        conn &&  conn.on('transportClose', (conn) => { console.log('transportClose ', conn);});
        conn &&  conn.on('ringing', function(conn) { console.log("Ringing...", conn);})
}
// Incoming... false
// DISCONNECT true
// CANCEL undefined
export async function initialisetwilio(){

  if(telephone.failedrequest===true){ window.location.reload(true) ; return }
  const user= queryClient.getQueryData(['user',localStorage.getItem('token')])   ;
  if(!localStorage.getItem('teltwilio') || !user?.['telephone']){ console.log('PASDETEL')  ;return }
     updatestatustel('idle') ; 
     let reqtoken  ; 
     try{ reqtoken =  await axios.post(`/twilio/token`,{numtel : localStorage.getItem('teltwilio')},{headers:{'Accept':'application/json','Content-Type':'application/json'}} );
     }catch(err){if(err.code.includes('ERR_NETWORK')){console.log('telephone.failedrequest_token',err.code , err.code.includes('ERR_NETWORK'))  ;  telephone.failedrequest=true  }  } 
     if(telephone.failedrequest===true){ return }
   
    const  device = new Device(reqtoken.data.token, {codecPreferences: ["opus", "pcmu"],fakeLocalDTMF: true, enableRingingState: true,enableIceRestart:true, disableAudioContextSounds: true });

    telephone.setTelephone({device : device, connexion: null  , token: reqtoken.data.token});

    device.register(); 
    device.on('registered', function () {console.log('registered!!');  telephone.failedrequest=false ;store.dispatch(modifyEtattel('normal'))});
    device.on('unregistered', function () {console.log('unregistered mon coco');  initialisetwilio();  store.dispatch(modifyEtattel('error'))  });
    device.on("error", function (error) {console.log('errortelephone-reinitialisation'); initialisetwilio();  store.dispatch(modifyEtattel('error'))    });
    device.on("connect", function (conn) {console.log("connect", conn);     handleconnexion(conn);});
    device.on('ringing', function(conn) { console.log("Ringing...", conn);  store.dispatch(modifyMessagetel(conn.parameters.From));
                                                                            store.dispatch(modifyEtattel('sonnerie')) ;});
    device.on("disconnect", function (conn) { console.log("disconnect"); initialisetwilio()});
    device.on('incoming',  function(conn) {   console.log("Incoming1...",conn.parameters.From ,  store.getState().tel.etat); 
                                          
                                              store.dispatch(modifyRingfirst(true)) ;
                                            telephone.setTelephone({...telephone.getTelephone(), connexion: conn }) ;
                                            
                                            if(store.getState().tel.etat!=='normal'){
                                            findclientfromtel(conn.parameters.From) ; return  ;} 
                                            handleconnexion(conn);
                                            findclientfromtel(conn.parameters.From) ;
                                            store.dispatch(modifyMessagetel(conn.parameters.From));
                                            store.dispatch(modifyEtattel('sonnerie')) ;});                                      

  }

export const phone = async(numtel) =>{
  store.dispatch(modifyEtattel('appel'));
  const nb = store.getState().lead.leadactu.nbappels || 0 ;
  const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
    const conn = await  telephone.getTelephone().device.connect({params: {From :  user['telephone'] ,To:convertteltotwilio(numtel)  }}  ) ;
    modifyonelead({'nbappels': nb+1}) ;
    handleconnexion(conn);
    updatestatustel('busy') ; 
  }

export const raccrocher_twilio  = ()=>{
      updatestatustel('idle') ; 
      telephone.setTelephone({...telephone.getTelephone(), connexion: null})
      telephone.getTelephone().device    &&  telephone.getTelephone().device.disconnectAll();
      batch( () => {
        store.dispatch(modifyEtattel('normal')) ;
        store.dispatch(modifyMessagetel(''));
        store.dispatch(initialiseLeadappelant({})) });
}

const raccrochageintempestif = ()=>{
  raccrocher_twilio() ;
 (store.getState().lead.qualiflead===false && !store.getState().display.displaymodal?.includes('mod_qualiflead') )&& store.dispatch(modifyDisplaymodal('mod_qualiflead'));
}


export const rejectcall= ()=>{  
      store.dispatch(modifyMessagetel(null)) ;
      telephone.getTelephone().connexion && telephone.getTelephone().connexion.reject();
      telephone.getTelephone().connexion && telephone.getTelephone().connexion.ignore();
 }


export const deccrocher = ()=>{ store.dispatch(modifyQualiflead((store.getState().lead.leadappelant.id && store.getState().lead.leadappelant!=='gagne')?false:true));  
                               store.dispatch(allFormules([])); 
                               store.dispatch(defineDevisFormules([])); 
                               store.dispatch(filterFormules({'type':'liste', 'liste':[]})); 
                               
                                telephone.getTelephone().connexion.accept();
                                updatestatustel('busy') ; 
                                store.dispatch(modifyMessagetel(null))
                                batch( () => {store.dispatch(modifyEtattel('appel'));  
                                              store.getState().lead.leadappelant.id && store.dispatch(initialiseLeadactu( store.getState().lead.leadappelant));  
                                              store.getState().lead.leadappelant.id && store.dispatch(modifyEcran('ficheclient'));  });
                                setTimeout(()=>{ store.getState().lead.leadappelant.id &&  store.dispatch(getallformules());}, 1000) ;
                                setTimeout(()=>{store.dispatch(initialiseLeadappelant({})) ; }, 2000) ;
}


export const envoisms_twilio = async(message_sms)=>{
  const user= queryClient.getQueryData(['user',localStorage.getItem('token')])   ; 
    const message_from = user['telephone'] ;
    const message_to = store.getState().lead.leadactu.telephone ;

    try{  await axios.post(`/twilio/envoisms/`,{message_sms,message_from,message_to});
    }catch(err){console.log(err)} 

}

 





                            