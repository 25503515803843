import { store } from '../state/redux';

import {threeyearsafter } from './utils_dates';
import {rdvcalendar} from './actions_calendar';


export const rdvgagne = (id_client)=>{
    const leads = store.getState().leads.leads;
    const existlead = leads.filter(x=>x.id===id_client);

    if(existlead.length < 0){return }
    else{let lead= existlead[0];rdvcalendar(threeyearsafter(lead.date_lastmodif),'Relance Apres Contrat Signe')}

}

