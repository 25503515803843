import React from "react";

import { useSelector , useDispatch } from 'react-redux'; 

import {modifyDisplaymodal} from '../../state/redux';

import Formulaire from "./Formulaire";
import croixclose from '../../images/croixclose.svg'

import './EcranFormulaire.scss'


function EcranFormulaire(){
    const dispatch = useDispatch()
    const ficheclient = useSelector(state=>state.lead.leadactu) ;

   return(<div className="containergray"> 
                        <div className="ecranformulaire"> 
                            <div className="ecranformulaire_top">
                                {ficheclient.date_naissance?"Modifier le prospect":"Ajouter nouveau prospect" }
                                <img src={croixclose}  alt="imgclose" onClick={()=>dispatch(modifyDisplaymodal(null))  }  />
                            </div>
                           <Formulaire  ecran='ecranclient'  />
                        </div>
            </div>)
}

export default EcranFormulaire