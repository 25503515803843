
import React  , {useState}  from "react";
import axios from "axios";



import './Yoan.scss'
function Yoan(){
    const [myfile,setMyfile] = useState(null);

    const handleTextFormules = async(text)=>{
        const rows = text.replaceAll('"','').split('\n');let tablochamps=[];const tabformules=[]; 

        rows.forEach((row, i)=> { tablochamps.push(row.split(',')[0].trim())  });

        }
      

    const handleFile =async ()=>{
        const reader = new FileReader();  
        
        reader.onload = async function  (evt) {
            await axios.post(`/files/yoan/`, 
                             {'textfile': evt.target.result} ,
                              {  headers:  { 'Accept': 'application/json',"Content-Type": "application/json"}  }); }; 
          
         reader.readAsText(myfile,"UTF-8");
    }

        return(<div className="ecrandev">
            <h1>Yoan</h1><br/><br/>

            <input  id="s" type="file" name="file" onChange={(e)=>{setMyfile(e.target.files[0])}}/> <br/><br/>
            <button onClick={handleFile} disabled={myfile===null}   >Read File!</button>  


             </div>)
}

export default Yoan