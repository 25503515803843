import React from "react";



import './ButtonSend.scss' ;
const classNames = require('classnames');
function ButtonSend({texte, color,image, onclick, loading, loadingorange}){
// const loading = true ;
    return(
            <div className={classNames("buttonsend ",color,{[image]: !loading  && !loadingorange },{'loading': loading},{'loadingorange': loadingorange}  )  }   onClick={onclick} > 
                        {texte}
            </div>

    )
}


export default ButtonSend