import React from "react";
import { useDispatch , useSelector } from "react-redux";

import { modifyDisplaymodal , definetypetablo , definetypedate} from "../../state/redux";
import { useCourtier } from "../../state/reactquerycabinets";

import './EcranGestionTop.scss';
const classNames = require('classnames');
function EcranGestionTop(){
    const dispatch = useDispatch() ;
    const {data:courtier}  = useCourtier() ; 
    const {typedate,typetablo} = useSelector(state=>state.gestion);


    const changetype = ()=>{ dispatch(definetypetablo(typetablo==='gestion'?'analytique':'gestion')) ; }

    const changetypedate = ()=>{ dispatch(definetypedate(typedate==='date_origine'?'date_lastmodif':'date_origine')) }


    return(<div className="ecrangestiontop">
                <div className="ecrangestiontop_left">
                    <div className="ecrangestiontop_left_nomcommercial"  >{courtier?.nomcommercial}</div>
                    <div className="ecrangestiontop_left_tablobord">
                        <div className="ecrangestiontop_left_tablobord_1">Tableau de bord</div>
                        {/* <div className="ecrangestiontop_left_tablobord_2">
                            <div>Gestion</div> 
                            <div className={classNames('cursor',{'yes':typetablo==='analytique'} ,{'no':typetablo==='gestion'} )} onClick={changetype}></div>
                            <div>Analytique</div>
                        </div> */}
                        <div className="ecrangestiontop_left_tablobord_2">
                            <div>Dates arrivée des leads</div> 
                            <div className={classNames('cursordate',{'yes':typedate==='date_origine'} ,{'no':typedate==='date_lastmodif'} )} onClick={changetypedate}></div>
                            <div>Dates d'activité</div>
                        </div>
                    </div>
                    
                </div>
                <input  className="dashbordtop_input" placeholder='Recherche' type="text"  onFocus={()=>{dispatch(modifyDisplaymodal('mod_searchgestion'))   }}     />
            </div>) ;

}

export default EcranGestionTop ; 