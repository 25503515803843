import React  , {useState}  from "react";
import { useSelector } from "react-redux";

import { queryClient } from '../../state/queryClient';

import { enregistrerformuleinterne } from "../../utils/utils_contratwin";
import { dateconvert } from "../../utils/utils_dates";


import ButtonSimple from "../Buttons/ButtonSimple";

import './ModifContrat.scss' ;
import './FormuleDetail.scss' ; 

const listchamps ={'nom_titulaire':'Nom du titulaire','prenom_titulaire':'Prénom du titulaire',
                   'prix':'Prix','date_effet':'Date d’effet'}  

function FormuleDetail({setShowFormuleDetail,choicedformule}){
        const lead = useSelector(state=>state.lead.leadactu) ;
        const formsigninitial = {'courtier':lead.courtier, 'id_lead':lead.id,'id_client':lead.id_client,
                                'nom_titulaire':lead.nom,'prenom_titulaire':lead.prenom,
                                'commercial':lead.commercial,'email':lead.email,'date_effet':lead.date_effet,
                                'compagnie': choicedformule.compagnie,'gamme':choicedformule.gamme,
                                'formule' :choicedformule.nom_formule,'etat_contrat':'projet',
                                'prix': choicedformule.prix}

        const [contrat, setcontrat] = useState(formsigninitial) ; 
          
        const handleChange =(e)=>{ setcontrat({...contrat,[e.target.id]: e.target.value}) ;}

        const grplabelinput = (x,label)=>( <div key={x}  className="formuledetail_labelinput" >
                                                <div className="formuledetail_label">{label}</div>
                                                <input id={x} type={(x==='date_effet' )?"date":"text"} disabled={x==='date_effet'} onChange={handleChange}
                                                      value={(x==='date_effet' )?dateconvert(contrat[x]) :contrat[x]}  />
                                           </div>) ;

        const enregistrerformule_interne =async()=>{ setShowFormuleDetail(false);
                                                     await enregistrerformuleinterne(choicedformule,contrat)  ;
                                                      queryClient.invalidateQueries({queryKey:['contrats',lead?.email,lead?.courtier]}) ;
                                                      queryClient.invalidateQueries({ queryKey: ['devis',lead?.id]}) ; 
                                                   }

        return(<><div className="modifcontrat_form">{Object.keys(listchamps).map(x=>( grplabelinput(x,listchamps[x]) ))}</div>
                <div className="formuledetail_btn">
                   <ButtonSimple  texte="Valider"   color='bouton_color_orange' size='bouton_size_normal'  disable={false}  onclick={()=>enregistrerformule_interne(choicedformule)}     /> 
                </div> 
               </> )

}
   

export default FormuleDetail