
import { batch } from 'react-redux';
import axios from 'axios';


import { queryClient } from '../state/queryClient';
import { addEltHistory , addnotiffromfront } from '../state/reactquery';
import { store , modifyQualiflead , initialiseLeadappelant } from '../state/redux';


import { phone , raccrocher_twilio} from './twilio';
import { simpleSDK } from "../utils/ringover";
import {  phonesend} from './aircall';


export const handlephone = async(numtel) =>{
      const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
      addEltHistory('appelsortant',  `<div class='horoline1' >Appel sortant</div>` ) ;
      batch( () => {  
        !store.getState().lead.leadactu.statut_client?.includes('gagne') && store.dispatch(modifyQualiflead(false));
      }) ;
    // user.type_tel === 'twilio' && store.dispatch(modifyPopupmultitel(true)) ; 
    user.type_tel === 'twilio' &&  phone(numtel) ; 
    user.type_tel === 'aircall' &&  phonesend(numtel) ; 
    user.type_tel === 'aircall' && document.getElementById('aircallphone').classList.remove('displaynone') ;
    user.type_tel === 'ringover' && simpleSDK.dial(numtel);	
  }


  export const finappel = ()=>{
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);
    user.type_tel === 'twilio' &&  raccrocher_twilio() ; 
}


 


export const findclientfromtel = async (numtel) => {
  let leadappelentrant;
  const user = queryClient.getQueryData(['user', localStorage.getItem('token')]);
  const response = await axios.post(`/clients/searchclientbytel/`, { numtel, commercial: user['nom'] , courtier:user['courtier'] }, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } });
  if (response.data.id === undefined) { leadappelentrant = {} }
  else { leadappelentrant = response.data }
  store.dispatch(initialiseLeadappelant(leadappelentrant));

  if (leadappelentrant.id) {
    addnotiffromfront({ id_lead: leadappelentrant.id, notification: `Appel entrant <span>${store.getState().tel.messagetel}</span><span> ${leadappelentrant.nom} </span><span>${leadappelentrant.prenom}</span>`, postmail: user?.postmail, type_notif: 'appelentrant' });
  } else {
     addnotiffromfront({ notification: `Appel entrant <span>${store.getState().tel.messagetel}</span>`, postmail: user?.postmail, type_notif: 'appelentrant' }); 
  }

  return leadappelentrant.id
}
                            