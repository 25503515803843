import React, { useState,useEffect, useRef} from "react";

import { useDispatch, useSelector } from 'react-redux'; 
import { initializeAutocomplete } from "../../utils/googlemaps";
import { deleteChampsLeadmemo } from '../../state/redux';


import './Infos.scss';

const classNames = require('classnames'); 
function TitleInputAdresse({ecran}){
    const dispatch = useDispatch() ;
    const elementRef = useRef();
    const ficheclient  = useSelector(state=>state.lead.leadactu);

    const fichememo  = useSelector(state=>state.lead.leadmemo);
 
    const[memonumdep, setMemonumdep] =  useState('')
    const [focus, setFocus] = useState(false);
    const [valid, setValid] = useState(fichememo.adresse?true :  undefined)   

    useEffect(()=>{setMemonumdep(ficheclient.num_departement)},[ficheclient.num_departement])
        
    const handleChange = ()=>{dispatch(deleteChampsLeadmemo(['num_departement','ville','code_postal','adresse'] )) }
    
    const handleFocus = ()=>{initializeAutocomplete(memonumdep,setValid,setMemonumdep);setFocus(true); }

    const handleBlur = ()=>{setFocus(false) ;  fichememo.adresse=== undefined  && setValid(false)  }

    const copytoclipboard =()=>{  navigator.clipboard.writeText(elementRef.current.value||elementRef.current.placeholder);}

    return( <div className="grouptitleinput  grpadresse"> 
                    <div className={classNames("grouptitleinput_containerinput",'adresse' ,{"grouptitleinput_containerinput_valid": valid===true} ,{"grouptitleinput_containerinput_error": valid===false  } , {"grouptitleinput_containerinput_focus": focus} )}> 
                               <div className='grouptitleinput_containerinput_label' >
                                        {ecran==='souscription'?'Adresse':'Adresse ou département'}
                                        {(ecran==='souscription')&&<span className='grouptitleinput_containerinput_labelobliged'>*</span>}
                                </div>
                                <input style={{fontSize: '16px'}} disabled={ecran==='souscription'}  ref={elementRef}  id="user_input_autocomplete_address" placeholder={fichememo.adresse}  onFocus={handleFocus} onChange={handleChange}  onBlur={handleBlur}  />
                                <div  className='logocopy' onClick={copytoclipboard}  ></div>      
                    </div>
                 
            </div>
    )
}



export default TitleInputAdresse