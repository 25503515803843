

import socketIOClient from "socket.io-client";


import { threeyearsafter } from "./utils_dates";
import {alertpbcontrat} from "./actions_lead";
import { rdvgagne } from "./actions_gagne";




import { queryClient }  from '../state/queryClient';
import { addEltHistory , addnotif  , addTicket  }  from '../state/reactquery';

import {store,addlead,allLeads,modifyChampsLeadactu,  defineNotifnew , deletelead  } from "../state/redux";

const url =  process.env.REACT_APP_URLSOCKET


let socket = null;

export const socketio  = {
    getSocket : ()=> socket, 
    setSocket: (s)=>{socket = s}
}


function handleincominglead(stringlead){
    const lead= JSON.parse(stringlead);
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')])
   
    if((lead.campagne && (lead.campagne.includes(user['nom']) ))|| lead.commercial===user['nom'] ){
       
         store.dispatch(addlead(lead));
    }
}



function handleincomingpbcontrat(stringlead){
    const lead= JSON.parse(stringlead);
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')])
 
    if((lead.campagne && (lead.campagne.includes(user['nom']) ))|| lead.commercial===user['nom'] ){
        alertpbcontrat(lead.nom,lead.prenom,lead.email,lead.origine )
        store.dispatch(addlead(lead))
    }
}





function  handlesignatureyousign(args){


    const argsock= JSON.parse(args);

    const {id_lead, date_rdvrelance,...newleadactu } = argsock

    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]) ;
    const leadtochange = store.getState().leads.leads.filter(x=>x.id===id_lead)[0];
    const  otherleads = store.getState().leads.leads.filter(x=>x.id!==id_lead);

    queryClient.invalidateQueries({ queryKey: ['documents',leadtochange?.id]}) ;
    queryClient.invalidateQueries({ queryKey: ['contrats',leadtochange?.email,user?.courtier]}) ;

    if(store.getState().lead.leadactu.id === id_lead){
        store.dispatch(modifyChampsLeadactu({...newleadactu, 'date_rdvrelance':threeyearsafter(leadtochange.date_lastmodif)})); // , 'recyclage' : 2
    }

   leadtochange?.id && store.dispatch(allLeads([...otherleads,leadtochange])); 

}

function handlesignature(args){

    const argsock= JSON.parse(args); 
  
    const id_lead = argsock.id_lead ;
 
    const  otherleads = store.getState().leads.leads.filter(x=>x.id!==id_lead);
   const leadtochange = store.getState().leads.leads.filter(x=>x.id===id_lead);
   queryClient.invalidateQueries({ queryKey: ['histories',id_lead]})
   queryClient.invalidateQueries({ queryKey: ['documents',leadtochange?.id]}) ;
     //DEV
     (leadtochange.documents===1  && store.getState().lead.leadactu.id === id_lead)  &&  addEltHistory('leadgagne', `<div class='horoline1' > <span className=horobold'>Lead passé en Gagné ! </span> </div>` )   
   (leadtochange.documents===1  && store.getState().lead.leadactu.id === id_lead)  &&  store.dispatch(modifyChampsLeadactu({'statut_client':'gagne','contratsigne':1,'date_rdvrelance':threeyearsafter(leadtochange.date_lastmodif), 'hot':0})); // , 'recyclage' : 2

   (leadtochange.documents===1  && store.getState().lead.leadactu.id === id_lead)  &&  rdvgagne(id_lead); 
   (leadtochange.documents!==1  && store.getState().lead.leadactu.id === id_lead)  &&  store.dispatch(modifyChampsLeadactu({'statut_client':'attente_documents','contratsigne':1})); 

 leadtochange.id && store.dispatch(allLeads([...otherleads,leadtochange])); 
}


function handlesignaturemandat(args){
      const docu = JSON.parse(args); 
    const docactus = queryClient.getQueryData(['documents',docu.id])  ?? [];
    queryClient.setQueryData(['documents',docu.id],[...docactus,docu])
}


function handleaffectationticket(args){

    const  notif= JSON.parse(args);
    store.dispatch(defineNotifnew({...notif})); 
    queryClient.invalidateQueries({ queryKey: ['tickets']})  ;
    setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000)
    addnotif({...notif})

}



function handleenterticket(){
    queryClient.invalidateQueries({ queryKey: ['tickets']})  ;
}

function handlenotif(args){ 
    const  notif= JSON.parse(args);
    store.dispatch(defineNotifnew({...notif})); 
    
    notif['type_notif']==='enterlead' &&  queryClient.invalidateQueries({ queryKey: ['notes',notif.id_lead]})  ;
    queryClient.invalidateQueries({ queryKey: ['histories',notif.id_lead]}) ;
    (notif['type_notif']==='email' &&  notif['email']  )  &&   queryClient.invalidateQueries({ queryKey: ['clientmails',notif.postmail,notif['email'] ]})  ;
     notif['type_notif']==='email' && queryClient.invalidateQueries({ queryKey: ['listmails']})  ;
     notif['type_notif']==='affectationticket' && queryClient.invalidateQueries({ queryKey: ['tickets']})  ; 
     
    setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000)
    addnotif({...notif})
}


export async function initialiseSocket(){
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]) ;
    if(!user?.['postmail'] ) {return }
    
    const sock = socketIOClient(url, {query : {'postmail': user['postmail']} });  // ,'uuid': (new Date()).getTime() 
    sock && socketio.setSocket(sock);

    sock.on('connect',()=>{  console.log('SOCKETCONNEXION') ;})
    sock.on('disconnect', () => {console.log('DISCONNECT!!!!') ;})

    sock.on('allo',async()=>{   window.location = `${window.location.origin}/login`;});

  // sock.on('affectationticket', (arg)=>{ handleaffectationticket(arg) });
    sock.on('changestatutcontrat', (arg)=>{ handleincomingpbcontrat(arg) });
    sock.on('changecommercialrecieve', (arg)=>{ handleincominglead(arg) });
    sock.on('supprimeleadcommercialrecieve', (arg)=>{ handledeletelead(arg) });
    sock.on('signature', (arg)=>{ handlesignature(arg) });
    sock.on('signaturemandat',  (arg)=>{ handlesignaturemandat(arg) });
    sock.on('enterticket',  (arg)=>{ handleenterticket(arg) });
    sock.on('notif',(args)=>{ handlenotif(args) });
    sock.on('signatureyousign', (arg)=>{ handlesignatureyousign(arg) });

    //ANCIEN
    sock.on('enterlead', (arg)=>{ handleincominglead(arg) });
    sock.on('changestatutcontrat', (arg)=>{ handleincomingpbcontrat(arg) });
  
    
    // NOUVEAU SOCKET 
    sock.on('arriveelead', (args)=>{ handlearriveelead(args) });
    sock.on('changecontratstatut', (args)=>{ handlechangecontratstatut(args) });
    sock.on('changestatutmail', (arg)=>{ handlechangestatutmail(arg) });
    sock.on('affectationticket', (arg)=>{ handleaffectationticket(arg) });
    sock.on('yousignsignature', (arg)=>{ handleyousignsignature(arg) });
    sock.on('arriveeticket', (args)=>{ handlearriveeticket(args) });
    sock.on('maildelivery', (args)=>{ handlemaildelivery(args) });
    // NOUVELLES FONCTIONS


    function handlemaildelivery(args){
        const {email,notif} = JSON.parse(args) ;

        const user= queryClient.getQueryData(['user',localStorage.getItem('token')]) ;
        
        // GESTION DE LA NOTIF
        store.dispatch(defineNotifnew({...notif})); 
        setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000) ;
        addnotif({...notif}) ;

       // GESTION DE L EMAIL         
        setTimeout(()=>{
           (user['postmail'] && email )  &&  (   queryClient.invalidateQueries({ queryKey: ['clientmails',user['postmail'],email ]}))  ;
            queryClient.invalidateQueries({ queryKey: ['listmails']})  ;
        },4000)
    
    }

    
    function handledeletelead(args){
        store.dispatch(deletelead(JSON.parse(args)?.id));
        // store.dispatch(allLeads([...otherleads,leadtochange])); 
    }



    
    function handleyousignsignature(args){
     console.log('handleyousignsignature',args) ; 
        const {lead, notif} = JSON.parse(args);
        const { id,...champslead } = lead ;
        // GESTION DE LA NOTIF
        store.dispatch(defineNotifnew({...notif})); 

      
        // GESTION DU LEAD
        const  otherleads = store.getState().leads.leads.filter(x=>x.id!==id);
       const leadtochange = store.getState().leads.leads.filter(x=>x.id===id)[0];
       leadtochange?.id && store.dispatch(allLeads([...otherleads,leadtochange])); 
       store.getState().lead.leadactu.id === id  &&  store.dispatch(modifyChampsLeadactu({...champslead})); 

       // INVALIDATIONS 
        queryClient.invalidateQueries({ queryKey: ['documents',leadtochange?.id]}) ;
        queryClient.invalidateQueries({ queryKey: ['histories',id]}) ;
        lead?.id_fic && queryClient.invalidateQueries({ queryKey: ['fic',lead?.id_fic]}) ;
        leadtochange?.id_resil && queryClient.invalidateQueries({ queryKey: ['resil',leadtochange?.id_resil]}) ;
        
        setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000);

       
        addnotif({...notif}) ;
    
    }


    const handlearriveelead = (args)=>{

            const {lead, notif} = JSON.parse(args);
            // GESTION DE LA NOTIF
            store.dispatch(defineNotifnew({...notif})); 
            queryClient.invalidateQueries({ queryKey: ['notes',notif.id_lead]})  ;
            queryClient.invalidateQueries({ queryKey: ['histories',notif.id_lead]}) ;
            
            setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000)
            addnotif({...notif})

            store.dispatch(addlead(lead));
            
    }

    const handlearriveeticket = (args)=>{
        const{ ticket} = JSON.parse(args);
        addTicket({...ticket})
    }

    const handlechangecontratstatut = (args) =>{
        const {lead, notif} = JSON.parse(args);

        // GESTION DE LA NOTIF
        store.dispatch(defineNotifnew({...notif})); 
        queryClient.invalidateQueries({ queryKey: ['contrats',lead?.email,lead?.courtier]}) ;
        queryClient.invalidateQueries({ queryKey: ['histories',notif.id_lead]}) ;
        
        setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000)
        addnotif({...notif})
    }

    function handlechangestatutmail(args){

        const user= queryClient.getQueryData(['user',localStorage.getItem('token')]) ;
        const {email, notif} = JSON.parse(args) ;
        notif?.id && store.dispatch(defineNotifnew({...notif})); 
        queryClient.invalidateQueries({ queryKey: ['clientmails',user?.['postmail'],email]})  ;
        queryClient.invalidateQueries({ queryKey: ['listmails']})  ;
        setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000)
        notif?.id && addnotif({...notif})
    
    }

    function handleaffectationticket(args){ 
        const { notif , ticket} = JSON.parse(args);
        store.dispatch(defineNotifnew({...notif})); 
        addTicket({...ticket})
        setTimeout(()=>{ store.dispatch(defineNotifnew({}));},3000)
        addnotif({...notif})
    }
   

    // DEV SOCKET 
    sock.on('connect_error', err => console.log('connect_error',err))
    sock.on('connect_failed', err => console.log('connect_failed',err))
    sock.on('disconnect', err => console.log('connect_disconnect!!!',err))

}

