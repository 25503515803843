
export const listestatus =  {'nontraite':"Non traité",  'nrp':'NRP',  'curieux':'Echec curieux', 'signeconcurrence':"Signé concurence", 
                                "mutuelleentreprise":"Mutuelle entreprise",  'mutuellelabellisee':"Mutuelle labellisée", 'cmu':"CMU",'relance':'Relance',
                                 'rdv':"RDV", 'devisenvoye':"Devis Envoyé", 'tropcher':"Trop Cher",'pasproduitadapte':"Produit Non Adapté",
                                 'documents':"Attente documents", 'signature':"En attente de signature",
                             'gagne':"Gagne",'1mois':"Impayé 1 mois" , '2mois':"Impayé 2 mois" , '3mois':"Impayé 3 mois" , 'resiliecmugroupe':"Résilié CMU-Groupe",
                              'resilieecheanceria':"Résilié echeance RIA", 'radiation':"Radiation",'retractation':"Rétractation", 'resilieautre':"Resilié Autre motif","plusdereponse":"NRP",
                            'fauxnum':"Faux Numéro"}


export const progressions = ['nontraite', 'contact_nrp',  'contact_echec_curieux', 'contact_echec_signeconcurrence', 'contact_echec_nrp', 'contact_echec_mutuelleentreprise', 'contact_echec_mutuellelabellisee', 
                            'contact_echec_cmu', 'contact_relance', 'contact_rdv',
                            'devisenvoye', 'devisenvoye_nrp',  'devisenvoye_rdv', 'devisenvoye_relance','devisenvoye_echec_tropcher', 'devisenvoye_echec_pasproduitadapte',
                            'devisenvoye_echec_signeconcurrence', 'devisenvoye_echec_nrp', 'devisenvoye_echec_mutuelleentreprise', 'devisenvoye_echec_mutuellelabellisee', 'devisenvoye_echec_cmu',
                            'attente_documents_relance', 'attente_documents_rdv', 'attente_documents_nrp','attente_documents_echec_tropcher', 'attente_documents_echec_pasproduitadapte', 
                            'attente_documents_echec_signeconcurrence', 'attente_documents_echec_nrp', 'attente_documents_echec_mutuelleentreprise',  'attente_documents_echec_mutuellelabellisee', 'attente_documents_echec_cmu',
                            'attente_signature_relance', 'attente_signature_rdv', 'attente_signature_nrp','attente_signature_echec_tropcher', 'attente_signature_echec_pasproduitadapte', 
                            'attente_signature_echec_signeconcurrence', 'attente_signature_echec_nrp', 'attente_signature_echec_mutuelleentreprise','attente_signature_echec_mutuellelabellisee', 'attente_signature_echec_cmu',
                            'gagne', 'gagne_impaye_1mois', 'gagne_impaye_2mois', 'gagne_impaye_3mois', 'gagne_resiliecmugroupe', 'gagne_resilieecheanceria', 'gagne_radiation',
                            'gagne_retractation', 'gagne_resilieautre','contact_rdv',   'attente_documents_rdv',  'attente_signature_rdv',
                         'attente_documents_relance',  'attente_signature_relance', 'gagne',
                             'contact_nrp',  'devisenvoye_nrp', 'attente_documents_nrp', 'attente_signature_nrp']


export const progressions_echecs = ['contact_echec_curieux', 'contact_echec_signeconcurrence', 'contact_echec_nrp', 'contact_echec_mutuelleentreprise', 'contact_echec_mutuellelabellisee', 
                                    'contact_echec_cmu', 'contact_relance', 'contact_rdv','devisenvoye_echec_tropcher', 'devisenvoye_echec_pasproduitadapte',
                                      'devisenvoye_echec_signeconcurrence', 'devisenvoye_echec_nrp', 'devisenvoye_echec_mutuelleentreprise',  'devisenvoye_echec_mutuellelabellisee', 'devisenvoye_echec_cmu',
                                      'attente_documents_echec_tropcher', 'attente_documents_echec_pasproduitadapte', 'attente_documents_echec_signeconcurrence', 'attente_documents_echec_nrp', 
                                      'attente_documents_echec_mutuelleentreprise',  'attente_documents_echec_mutuellelabellisee', 'attente_documents_echec_cmu','attente_signature_echec_tropcher', 'attente_signature_echec_pasproduitadapte', 
                                              'attente_signature_echec_signeconcurrence', 'attente_signature_echec_nrp', 'attente_signature_echec_mutuelleentreprise', 'attente_signature_echec_mutuellelabellisee', 'attente_signature_echec_cmu']

// DEV
export const progressions_contact = ['nontraite', 'contact_nrp',  'contact_echec_curieux', 'contact_echec_signeconcurrence', 'contact_echec_nrp', 'contact_echec_mutuelleentreprise', 'contact_echec_mutuellelabellisee', 
                                    'contact_echec_cmu', 'contact_relance', 'contact_rdv']


export const progressions_devisenvoye = ['devisenvoye', 'devisenvoye_nrp',  'devisenvoye_rdv', 'devisenvoye_relance','devisenvoye_echec_tropcher', 'devisenvoye_echec_pasproduitadapte',
                                       'devisenvoye_echec_signeconcurrence', 'devisenvoye_echec_nrp', 'devisenvoye_echec_mutuelleentreprise','devisenvoye_echec_mutuellelabellisee', 'devisenvoye_echec_cmu']


export const progressions_attentedocuments =['attente_documents', 'attente_documents_relance', 'attente_documents_rdv', 'attente_documents_nrp','attente_documents_echec_tropcher', 'attente_documents_echec_pasproduitadapte', 
                                          'attente_documents_echec_signeconcurrence', 'attente_documents_echec_nrp', 'attente_documents_echec_mutuelleentreprise','attente_documents_echec_mutuellelabellisee', 'attente_documents_echec_cmu']


 export const progressions_attentesignature = ['attente_signature','attente_signature_relance', 'attente_signature_rdv', 'attente_signature_nrp','attente_signature_echec_tropcher', 'attente_signature_echec_pasproduitadapte', 
                                               'attente_signature_echec_signeconcurrence', 'attente_signature_echec_nrp', 'attente_signature_echec_mutuelleentreprise', 'attente_signature_echec_mutuellelabellisee', 'attente_signature_echec_cmu']


export const progressions_gagne = ['gagne', 'gagne_impaye_1mois', 'gagne_impaye_2mois', 'gagne_impaye_3mois', 'gagne_resiliecmugroupe', 'gagne_resilieecheanceria', 'gagne_radiation', 'gagne_retractation', 'gagne_resilieautre']

export const progressions_rdv = ['contact_rdv',  'devisenvoye_rdv', 'attente_documents_rdv',  'attente_signature_rdv']

export const progressions_relance = ['contact_relance',  'devisenvoye_relance', 'attente_documents_relance',  'attente_signature_relance', 'gagne']

export const progressions_nrp = [ 'contact_nrp',  'devisenvoye_nrp', 'attente_documents_nrp', 'attente_signature_nrp']


export const listeechecs ={"echec_curieux":"Curieux","echec_tropcher":"Trop cher","echec_pasproduitadapte":"Pas de produit adapté","echec_signeconcurrence":"Signé à la concurrence",
                    "echec_plusdereponse":"Ne répond plus","echec_mutuelleentreprise":"Mutuelle entreprise","echec_mutuellelabellisee":"Mutuelle labellisée","echec_cmu":"CMU"}

