import React from 'react';

import './Img.scss'
const classNames = require('classnames'); 
function Imgcolor(props){
	const orange = (props.color==='orange' && props.selected!==true);
    const gris = (props.color==='gris' && props.selected!==true);
    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 447.9 447.9" >
<path   className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true},{'svgcolor0_noselect':props.selected!==true &&  props.color!=='orange'})} 
       d="M285.3,111.2l9-78.2l-52.6,48.2l-34.7-62L172.3,80l-61.9-57c0,0,18.3,151.8,20.5,151.1
	c16.3-5,33.3-7.7,49.8-9.1c-2.8,4.6-5.5,9.6-8.3,15c-33.5,66.3-26.4,94.6,33.2,94.6s95.5,34.9,136.3-57.3
	C364.2,166.9,338.5,116.6,285.3,111.2z"/>
<path className={classNames({'svgorange1':orange && props.selected!==true },{'svggris1':gris},{'svgcolor1_selected':props.selected===true},{'svgcolor1_noselect':props.selected!==true &&  props.color!=='orange'})} 
     d="M355.8,151.2c-5.3-16.1-17.8-29-37-38.3c-9.6-4.7-19.7-6.4-30.1-6.1c0.9-17.1,3.2-33.6,9-66.2l0.1-0.1
	c0.7-4,2.5-17.3,3.3-21.9l-16.3,14.8L241.9,71l-31.3-55.2l-4.8-7.9l-4.9,7.9l-32,56.3l-45.1-39.6c-0.1-0.6-16.7-15.6-16.7-15.6
	l2.7,17.2c0.1,0.5,5.6,31.8,9.1,75.3c-4,0.5-7.9,1.4-11.9,2.6c-24.6,7.6-40.6,20.5-47.5,38.5C45.1,188,74.3,236.7,91.7,265.8
	c2.4,4.1,5.1,8.5,7,12c0.1,0.1,0.1,0.2,0.2,0.4c1.5,2.7,2.5,4.8,2.8,5.9c0,2.5,0.1,5.2,0.1,8.1c0.3,23.7,0.7,59.6,7.5,90.3
	c8.6,39.2,25.7,60.1,50.7,62h0.5c1.5,0,3.1-0.5,4.3-1.5c12.9-10,10.4-30.2,7.8-51.6c-4.3-34.4-5.2-59.5,22-69
	c24-8.4,40-2.8,50.7,17.4c8.4,16,5.7,36.1,3.3,53.8c-0.5,3.7-0.9,7.1-1.3,10.5c-2.1,19.1,3.1,34,13.6,38.7c5,2.3,15.3,4,28.1-10
	c26.3-28.6,29.4-96.2,29.1-151.9c0-1.1,0.2-2.5,0.7-4c0.1-0.2,0.2-0.5,0.2-0.7c2.1-6.9,7.5-17.3,12.9-27.4
	C346.3,221,366.3,183.1,355.8,151.2z M174,92.1l31.8-56.5L236.1,89l2.1,3.9l2.2-2.1L281,55.2c-7,41.9-6.9,54.2-6.8,92.7
	c0,3.8,0,7.9,0,12.4c-21.8-4.4-68.6-10-138.7,2.1c-0.7-44.2-5.3-84.4-8.5-108.2L174,92.1z M278.6,423.2c-4.9,5.3-9.5,7.9-12,6.7
	c-3.2-1.5-7-10-5.5-24.5c0.4-3.3,0.8-6.6,1.3-10.2c2.6-19.8,5.6-42.2-4.8-62.1c-9.9-19-24.8-28.7-43.7-28.7c-7.4,0-15.4,1.5-24,4.5
	c-17.7,6.2-28.5,18.6-32.2,36.8c-3,15-0.9,32.1,1,47.1c1.9,14.9,3.8,30.4-0.7,37c-40.6-7.4-41.6-95.3-42.1-138.1
	c0-3-0.1-5.8-0.1-8.4c0-0.6-0.1-1.3-0.2-2h188.5C304.1,313.1,304.4,395.1,278.6,423.2z M319.3,242.1c-5.1,9.6-9.5,18.1-12.3,25.3
	H109c-1.5-2.6-3.3-5.5-5.3-9c-16.1-26.9-43.1-72-31.1-103.1c5.3-13.7,18.2-23.9,38.5-30.1c3-0.9,5.9-1.6,8.8-2.1
	c0.9,13.2,1.5,27.3,1.7,41.8c0,3,0.1,6.1,0.1,9.2h0.1v4.9c103.4-20.8,166-1.1,166.5-0.9v-10.7c0-1.2,0-2.4,0-3.6
	c0-5.8,0-11.1,0-15.9c0-10.7,0-19.3,0.1-27c8.6-0.3,16.9,1,24.4,4.6c16,7.7,25.7,17.6,29.8,30.1
	C351.1,181.8,333.5,215.2,319.3,242.1z"/>
	
</svg>







    )
}

export default Imgcolor 