
import  React , { useState , useEffect } from 'react';
import axios from 'axios';

import Button from '../../Components/Buttons/Button';


import './DashTemplates.scss'

function DashTemplates(){
    const [all, setAll] = useState() ;
    const [liste, setListe] = useState([]) ; 
    const [grp, setGrg] = useState(null) ;
    const [type, setType] = useState(null) ;
    const [listeingrp, setListeingrp] = useState([]) ; 
    const [bodyHtml, setBodyHtml] = useState('') ;
    const [bodyText, setBodyText] = useState('') ;


    const definall = (newgrp,all)=>{setGrg(newgrp) ;
        const newlisteingroupe = all?.filter(x=>x.groupe===newgrp).map(t=>t.type) ;
        const newhtmlbody = all?.filter(x=>x.groupe===newgrp && x.type ===newlisteingroupe[0])[0]?.htmlbody;
        const newtextbody = all?.filter(x=>x.groupe===newgrp && x.type ===newlisteingroupe[0])[0]?.textbody;
        setListeingrp(newlisteingroupe);setType(newlisteingroupe?.[0]);setBodyHtml(newhtmlbody) ;
        newtextbody ?setBodyText(newtextbody):setBodyText('');
    }


    async function fetchTemplates() { 
        const res = await axios.get(`/emailgestion/getall`,{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
        setAll(res.data) ;
        setListe([...new Set([...res.data.map(x=>x.groupe)])]) ;
        definall([...new Set([...res.data.map(x=>x.groupe)])][0],res.data);
    } 

     useEffect(() => { fetchTemplates();   }  , []); 

     const selectgrouperesil = (e)=>{definall(e.target.value,all);}
    
    const selecttemplate = (e)=>{setType(e.target.value);
                                 setBodyHtml(all.filter(x=>x.groupe===grp && x.type ===e.target.value)[0].htmlbody) ;
                                 setBodyText(all.filter(x=>x.groupe===grp && x.type ===e.target.value)[0].textbody) ;}

    const enregistrermodification =async (e)=>{  console.log(e.target.id)  ; 
        console.log('enregistrermodification',bodyText);
        await axios.post(`/emailgestion/modifyhtmlbody`,{'groupe': grp, 'type': type ,'htmlbody':bodyHtml ,'textbody':bodyText , 'champ' : e.target.id },{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
    }


    return(<div>
                <select className='selectchoice wd150' onChange={selectgrouperesil} >
                {liste?.length >0 && liste.map((typetemplate,i)=><option key={i}  value={typetemplate} >{typetemplate}</option>)}
                </select>
                <select  className='selectchoice wd150' onChange={selecttemplate} >
                    {listeingrp?.length >0 && listeingrp.map((template,i)=><option key={i}  value={template} >{template}</option>)}
                </select>
                <br/><br/>
               <>
                    HTML BODY<br/>
                    <textarea className='templates_textarea'  value={bodyHtml}  onChange={(e)=>{setBodyHtml(e.target.value)}}   />
                    <Button id='htmlbody' texte="Enregistrer"   color='bouton_color_orange' size='bouton_size_normal'  disable={false}  onclick={enregistrermodification}     /> 
                    <br/><br/><br/><br/>
                </>
                {bodyText?.length >0 && <>
                    TEXT BODY<br/>
                    <textarea className='templates_textarea small'  value={bodyText}  onChange={(e)=>{setBodyText(e.target.value)}}   />
                    <Button  id='textbody'  texte="Enregistrer"   color='bouton_color_orange' size='bouton_size_normal'  disable={false}  onclick={enregistrermodification}     /> 
                    <br/><br/>
                </>}
        </div>)
}

export default DashTemplates