
import React from 'react';
import './Img.scss'
const classNames = require('classnames'); 
function Imgcolor(props){
	
	const orange = (props.color==='orange' && props.selected!==true);
    const gris = (props.color==='gris' && props.selected!==true);   


    return (
        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0  447.9  447.9" >

<path  className={classNames({'svgorange0':orange},{'svggris0':gris},{'svgcolor0_selected':props.selected===true})}
		d="M341.9,200.8c-43.7,72.7-22.2,73-136.3,73c-59.6,0-66.7-48.5-33.2-104.6c40-66.9,127.5-64.6,145.6-64.6 C318,104.6,368.3,156.9,341.9,200.8z"/>
<path  className={classNames({'svgorange1':orange },{'svggris1':gris},{'svgcolor1_selected':props.selected===true})} d="M355.3,143.3c-5.2-16.2-17.7-29.2-37.3-38.7c-28.6-13.9-61.9-1.5-91.2,9.5c-14.9,5.5-28.9,10.8-40.1,11.7
	c-1.7-1.4-3.4-2.7-5-4c-2.6-2.1-5-4.1-7.5-5.8c-20.5-15.1-44.7-19.4-68-12.3c-24.2,7.4-40,19.9-46.9,37
	C45.9,174,69.3,216.1,84.7,244c15.2,27.4,29.9,28.6,52.3,30.3c1.4,0.1,2.9,0.2,4.4,0.3c0.2,0,0.4,0,0.6,0h4.1l2.8,29.7h-13.8
	c-3.9,0-7,3.1-7,7s3.1,7,7,7h15.1l3,31.5H140c-3.9,0-7,3.1-7,7s3.1,7,7,7h14.6l3,31.5h-12.8c-3.9,0-7,3.1-7,7s3.1,7,7,7h14.1
	l3.7,39.1c0.3,3.6,3.4,6.3,7,6.3h74.3c3.6,0,6.6-2.7,7-6.3l3.7-39.1h14.1c3.9,0,7-3.1,7-7s-3.1-7-7-7h-12.8l3-31.5h14.6
	c3.9,0,7-3.1,7-7s-3.1-7-7-7h-13.3l3-31.5h15.1c3.9,0,7-3.1,7-7s-3.1-7-7-7h-13.8l2.9-30.5l11.7-0.1c27.2-0.2,32.8-1.1,47.4-28
	c1.4-2.5,2.8-5.2,4.3-7.9C345.4,211.2,365.4,174.8,355.3,143.3z M237.5,440.8H176l-3-31.5h67.5L237.5,440.8z M241.8,395.4h-70.1
	l-3-31.5h76.1L241.8,395.4z M246.1,349.8h-78.8l-3-31.5h84.8L246.1,349.8z M250.4,304.3H163l-2.8-29.8l93.1-0.7L250.4,304.3z
	 M318.5,231c-1.5,2.8-3,5.5-4.4,8.1c-5.6,10.4-9.2,16.1-13.2,18.4c-4,2.2-10.3,2.2-21.9,2.3l-136.8,1c-1.4-0.1-2.8-0.2-4.1-0.3
	c-21.5-1.7-29.6-2.3-41.1-23.1c-14.2-25.6-35.6-64.2-24.6-91.4c5.3-13,18-22.7,38-28.9c6.4-2,12.4-2.8,18.1-2.8
	c15.9,0,28.7,6.5,37.5,13c2.2,1.6,4.5,3.5,7,5.5c2.2,1.8,4.6,3.7,7.2,5.7c0.1,0.1,0.2,0.1,0.2,0.2c10.6,8.1,24.6,16.9,44.5,20.5
	c3.8,0.7,7.5-1.8,8.1-5.6c0.7-3.8-1.8-7.5-5.6-8.1c-9.4-1.7-17.3-4.8-24-8.4c9-2.4,18.5-6,28.4-9.7c26.7-10,57-21.2,80.2-10
	c16.2,7.8,26,17.8,30.1,30.3C350.2,173.3,331.9,206.6,318.5,231z"/>
	
</svg>







    )
}

export default Imgcolor 