import React , { useState}  from "react";
import axios from "axios";

import { queryClient } from "../../state/queryClient";

import { useTickets  } from '../../state/reactquery';

import { changestatuticket } from "../../utils/tickets";

import Ticket from "./Ticket";
import TicketModal from "./TicketModal";

import './EcranTickets.scss';   // PRETIIER
function EcranTickets(){

    const [modal, setModal ]= useState(false) ;
    const [ticketchoiced, setTicketchoiced] =useState({});
    const [tickdata, setTickdata] =useState({});
    const {data:tickets}  = useTickets() ; 

  


    const clickticket =(e)=>{ let tick = tickets.filter(x=>x.id=== e.target.closest((".ticketclick")).id )[0] ;
                               setTicketchoiced(tick) ;
                               tick['statut']==='new' && changestatuticket({...tick,'statut':'inprogress'})
                               setModal(true);
    }

    const onDragOver = (event) => {
      const elementDropPoint = document.elementFromPoint(event.clientX, event.clientY);
      var placeholder = document.createElement('div');
      placeholder.className = 'ticket newdiv';
      if (elementDropPoint.className === "ticket") {
              let tickon = tickets.filter(x=>x.id=== elementDropPoint.id)[0]  ;
              setTickdata({statut:tickon?.statut})
              document.getElementsByClassName("newdiv")?.[0]?.remove(); 
              elementDropPoint.after(placeholder);
      }else if(elementDropPoint.closest((".ecrantickets_section_top"))){
              document.getElementsByClassName("newdiv")?.[0]?.remove(); 
              elementDropPoint.after(placeholder);
              let newstatut  = elementDropPoint.closest((".ecrantickets_section")).id  ;
              setTickdata({statut:newstatut})
      }
      
    event.preventDefault();  
  }


  const onDrop =async (event) => {    // return

   let tick = tickets.filter(x=>x.id=== event.dataTransfer.getData("idticket"))[0]  ;
   const newticket = {...tick ,...tickdata }
   
   await axios({method:'POST',url:`/tickets/updateticket`,data:{... newticket },headers:{'Accept':'application/json','Content-Type':'application/json'}});

    queryClient.invalidateQueries({ queryKey: ['tickets']})  ; 
    document.getElementsByClassName("newdiv")?.[0]?.remove(); 
    event.preventDefault();  
 }


     return (<div  className="ecrantickets"  >
                {modal && <TicketModal ticket={ticketchoiced}  setModal={setModal}  />  }
                <div  className="ecrantickets_titre"   >Messages vocaux</div> 
                <div  className="ecrantickets_sections"  >  
                      <div  className="ecrantickets_section new" id="new"  onDragOver={(event)=>onDragOver(event)}   onDrop={(event)=>onDrop(event)} > 
                                  <div  className="ecrantickets_section_top"  >Nouveau </div>  
                                  <div  className="ecrantickets_section_scroll"  >
                                  { [...tickets?.filter(x=>x?.statut==='new').sort((b,a)=>new Date(new Date(a.date_ticket) - new Date(b.date_ticket)))].map(ticket=>(<Ticket key={ticket.id}  ticket={ticket}  clickticket={clickticket}  /> ))     }
                                  </div>
                                  
                      </div>     
                      
                      <div  className="ecrantickets_section  inprogress " id="inprogress"   onDragOver={(event)=>onDragOver(event)}   onDrop={(event)=>onDrop(event)}  > 
                                  <div  className="ecrantickets_section_top"  >En cours </div>  
                                  <div  className="ecrantickets_section_scroll"  >
                                  {[...tickets?.filter(x=>x?.statut==='inprogress').sort((b,a)=>new Date(new Date(a.date_ticket) - new Date(b.date_ticket)))].map(ticket=>(<Ticket  key={ticket.id} ticket={ticket}    clickticket={clickticket} /> ))     }
                                  </div>  
                      </div>     
                      <div  className="ecrantickets_section finish" id="finish"  onDragOver={(event)=>onDragOver(event)}   onDrop={(event)=>onDrop(event)}  > 
                                  <div  className="ecrantickets_section_top"  >Clôturé</div>  
                                  <div  className="ecrantickets_section_scroll"  >
                                  { [...tickets?.filter(x=>x?.statut==='finish').sort((b,a)=>new Date(new Date(a.date_ticket) - new Date(b.date_ticket)))].map(ticket=>(<Ticket key={ticket.id}  ticket={ticket}   clickticket={clickticket} /> ))     }
                                  </div>
                      </div>    
                </div>                   
         </div>)
}

export default EcranTickets