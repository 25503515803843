
import axios from 'axios' ;
import { useQuery } from '@tanstack/react-query';


const queryLeadsFxNumeros= async(debut,fin,typedate)=>{  
  let response = await axios.post(`/gestion/searchleadsfxnumeros`,{debut,fin,typedate},{headers:{'Accept':'application/json','Content-Type':'application/json','Authorization':`Bearer ${localStorage.getItem('token')}`} });
  return {'leads':response?.data?.leads || [] , 'fauxnumeros':response?.data?.fauxnumeros || []  }
} 
export const useLeadsFxNumeros= (debut,fin,typedate,enabled)=>{  
  return      useQuery({ queryKey: ['leadsfauxnumeros',debut,fin,typedate],queryFn:async () =>await queryLeadsFxNumeros(debut,fin,typedate) ,  
                 staleTime: Infinity ,enabled :enabled  });}


const queryCommerciaux= async()=>{  
  let response = await axios.get(`/gestion/getcommerciaux`,{headers:{'Accept':'application/json','Content-Type':'application/json','Authorization':`Bearer ${localStorage.getItem('token')}`} });
  return response?.data || []
} 

export const useCommerciaux= ()=>useQuery({ queryKey: ['leadsdaydateorigine'], queryFn:async () =>queryCommerciaux() ,    staleTime: Infinity  });

