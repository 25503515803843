import React  from "react" ;

import GammeTarif from "./GammeTarif";

import './TabloCompagnieGammes.scss';

function TabloCompagnieGammes({compagniesgammes, actuauths, setActuauths}){
    console.log(compagniesgammes)
    const typetarifgamme=(index,nom_compagnie,compagnie_mini,nom_gamme,gamme_mini,type_tarif,datatarif)=>{

        return(<GammeTarif  key={index}
                            index={index}
                            nom_compagnie={nom_compagnie}
                            compagnie_mini = {compagnie_mini}
                            gamme_mini={gamme_mini} 
                            nom_gamme={nom_gamme} 
                            type_tarif={type_tarif}
                            type_tarif_data={datatarif} 
                            appellation={datatarif['appellation']}
                            actuauths={actuauths} 
                            setActuauths={setActuauths}/>)  
    }

    const gamme=(nom_compagnie,compagnie_mini,gamme_mini,datagamme)=>{
        const {nom_gamme, tarifs} = datagamme ;
        return(<>{Object.keys(tarifs).map((tarif,index)=>typetarifgamme(index,nom_compagnie,compagnie_mini,nom_gamme,gamme_mini,tarif,tarifs[tarif]))}</>)
    }

    const compagnie = (compagnie_mini, datacompagnie)=>{
        const {gammes,nom_compagnie} = datacompagnie; 
        return(<>{Object.keys(gammes).map((gam)=>gamme(nom_compagnie,compagnie_mini,gam,gammes[gam]))}</>)
    }

    return(<div className="tablogammes_tablo bigtablo">
                <div className="tablogammes_tablo_line1" >
                    <div>Compagnie</div><div>Gamme</div><div>Tarification</div><div>Appellation</div>
                    <div>Conseiller</div><div>Manager</div><div>Gestion</div><div>Admin</div>
                </div>
                <div className="tablogammes_tablo_lines overflowyscroll">
                    {compagniesgammes && Object.keys(compagniesgammes)?.sort()?.map((comp) => compagnie(comp,compagniesgammes[comp]))}
                </div> 
            </div>)

}

export default TabloCompagnieGammes


