import React , {useEffect, useState} from 'react';

import { phonefromlead, phonefromleadinjoignables} from '../../utils/actions_lead';
import { nbjours ,  verifleadgagne_arecycle , convertdateAA  } from '../../utils/utils_dates';
import { modifyhot , searchandinitialiseclient ,  demarrersequence} from '../../utils/actions_lead';
import { progressions , listestatus } from '../../utils/listeprogression';
import { imagesorigine } from '../../utils/utils_courtier';

import Periodes from './Periodes';

import recyclage from '../../images/recyclage.svg';

import tel from '../../images/tel.svg';


import './TableLeads.scss';

const dates ={ "newleads":{"champ":"date_reception","titre":"Date de réception"},
                "rdv":{"champ":"date_rdvrelance","titre":"Date de rendez-vous"},
                "relances":{"champ":"date_rdvrelance","titre":"Date de relance"},
                "injoignables":{"champ":"date_lastmodif","titre":"Date du dernier contact"},
                "injoignablesdevis":{"champ":"date_lastmodif","titre":"Date du dernier contact"},
                "waitsign":{"champ":"date_lastmodif","titre":"Date du dernier contact"},
                "waitdocs":{"champ":"date_lastmodif","titre":"Date du dernier contact"},
                "hot":{"champ":"date_lastmodif","titre":"Date du dernier contact"},
                'searchtable':{"champ":"date_reception","titre":"Date de réception"},
                'customchoice':{"champ":"date_reception","titre":"Date de réception"},
                "impayes":{"champ":"date_lastmodif","titre":"Date du dernier contact"} }


const step = 40 ;
const heightelement = 80 ;

const classNames = require('classnames');
function TableLeads({id,titre,datas=[]}){

        const [leadsaff , setLeadsaff] = useState([]) ;

        useEffect(()=>{setLeadsaff(datas?.filter((x,index)=> index< step ));},[datas]);
        useEffect(()=>{ 
            const func = (event) =>{ 
                const indexedebut =step* Math.floor(event?.target?.scrollTop / (heightelement*step/2)) ;
                if(leadsaff.length >= indexedebut +step )return  ; 
                const newaff = datas.filter((x,index)=> (index>= indexedebut && index < indexedebut + step)  ) ;
                setLeadsaff(leadsaff=>[...new Set([...leadsaff, ...newaff])])  ; 
            }
            document.getElementById(id)?.addEventListener('scroll', func) ;
            return () =>{document.getElementById(id)?.removeEventListener('scroll', func) ;}
        }) ;
    
   
        const  clickline = (x) => { searchandinitialiseclient(x?.id,false)  }

        const clicktel = (e)=>{id==="injoignables"?phonefromleadinjoignables(e):phonefromlead(e,datas) }

        let maliste =[]
        progressions.map(x=>x.split('_').map(x=>maliste.push(x)))  ;
   
        const click = (e) => {const lead =  datas.filter(x=>x.id === e.target.closest('tr').id)[0]; 
                            if(e.target.closest('.caseimgactions')===null && e.target.closest('.flamme')===null ){clickline(lead) } }


        const classe_iamge = (x)=>{
              return (  x.date_rdvrelance===undefined ? (x.hot===1 ? 'flamme hot' : 'flamme cold')  :
                        x?.statut_client?.includes('relance') || x?.statut_client?.includes('rdv') ) && ((new Date(x.date_rdvrelance)).getTime() < (new Date()).getTime()   ) ? 'alert':
                        x.hot===1? 'flamme hot' : 'flamme cold'
        }
 //  id={id} 
        return(<div className={titre !=='notitre'?'tableads':""}>
                       {titre !=='notitre' && <div className='tableads_titre  '>
                                                  <div className='texte'>{titre}</div> 
                                                  {id==='injoignables' && <Periodes /> }
                                                  {((id==='newleads' || id==='waitsign'|| id==='waitdocs' || id==='hot' || id==='impayes' || id==='customchoice') && datas?.length >0 )&&  <div className='demarrersequence'onClick={() =>{demarrersequence(datas)}}  >Démarrer la séquence</div> }
                         </div>}
                                                        
                         <div className='toptablo' >
                         <div className="col0 pl10">{(id==="injoignables") && "Reception"}</div>
                                       {/* <div className="col1">{dates_titres[id]}</div> */}
                                       <div className="col1">{dates[id]['titre']}</div>
                                        <div className="col2 pl10">Prénom et Nom</div>
                                        <div className="col3 pl0">Origine</div>
                                        <div className="col4 pl10">Statut</div>
                                        <div className="col5 pl10">  </div>
                         </div>
                         <div  className={classNames('containertablo',{'containertablo_search':id==='searchtable'})}  id={id}  >
                          <table>
                                <tbody className='tbodytableads'>{leadsaff?.length > 0 &&  [...leadsaff].map(x=>(<tr  className='trpositionabsolute' onClick={click}  id={x.id} key={x.id}  >
                                       <td className="col0"  >{id==="injoignables" ?  <div className='nbjours'>{nbjours(x)}j</div>  :<div className={classe_iamge(x)} onClick={()=>{modifyhot(x)}}  ></div>}</td> 
                                                        <td className="col1"  > <div  >{convertdateAA(x[dates[id]['champ']])} </div></td>
                                                        <td className="col2"  ><div className='tablecasenomtel' > <span>{x.nom} {x.prenom}</span><span>{x.telephone}</span> </div></td>
                                                        <td  className='col3 caseimg' >
                                                                <img height="30" width="30"   src={verifleadgagne_arecycle(x)?recyclage :imagesorigine(x.origine) } alt="imageorigine"   />  
                                                                <span  className='nomcampagne'> {x?.campagne?.split('/')?.[0]}</span>
                                                        </td>
                                                        <td  className="col4" >
                                                       <div>
                                                           {(x?.statut_client?.split('_'))?.map((y,i)=> (listestatus[y] &&<span  key={i} className={classNames('minibadge',{"minibadgeechec":x?.statut_client?.includes('echec')},{"minibadgegagne":x?.statut_client?.includes('gagne')})}> {listestatus[y]} </span> )) }
                                                       </div>
                                                       </td>
                                                        <td  className='col5 caseimgactions' >
                                                                <img src={tel} height="22" alt="imgtel" onClick={clicktel}  />
                                                                <div className={classNames('countappels',{'small':x.nbappels>99},{'medium':x.nbappels<100 && x.nbappels>9},{'big': x.nbappels < 10})}>{x.nbappels}</div>
                                                        </td>
                                                </tr>))}
                                </tbody>
                        </table>
                        </div>
                </div>)
}

export default TableLeads
