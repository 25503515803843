import React , {useEffect, useState} from "react";
import axios from "axios";

import FiltresCompagnies from "../../Components/FiltresCompagnies";
import Tablo from "./Tablo";


function Compagnies({actucourtier , setActucourtier}){
    const [allgammes , setAllgammes] = useState([]) ;
    const [listecompagniesaffichees,setListeompagniesaffichees]= useState([]) ;
    const [listecompagnies, setListeompagnies]=useState([])  ;

    async function fetchGammes() { 
        const res = await axios.get(`/gammesnew/getallgammes`,{headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' , 'Authorization': `Bearer ${localStorage.getItem('token')}`  }});
        setAllgammes(res.data?.filter(x=>x.type_tarif!=='no')?.sort((b,a)=>{return  b.compagnie_mini?.toLowerCase() > a.compagnie_mini?.toLowerCase() ? 1:b.compagnie_mini?.toLowerCase()  < a.compagnie_mini?.toLowerCase()?-1 :0   }))
        setListeompagnies([...new Set(res.data?.map(x=>x.compagnie))].sort())
    } 

    useEffect(()=>{fetchGammes() ; },[]) ;

      
    const clickCompagnie =(e)=>{
       const  newliste = listecompagniesaffichees.includes(e.target.id)?listecompagniesaffichees.filter(x=>x!==e.target.id):[...listecompagniesaffichees,e.target.id];
       setListeompagniesaffichees(newliste)
    }

    const clickboxcompagnies= ()=>{
         listecompagniesaffichees?.length>0? setListeompagniesaffichees([]):setListeompagniesaffichees([...listecompagnies]);
    }


    return(<> 

                <FiltresCompagnies  listenomscompagnies ={[...new Set(allgammes?.map(x=>x.compagnie))]}
                                    listecompagnies={[...new Set(allgammes?.map(x=>x.compagnie))]}
                                    listecompagniesaffichees={listecompagniesaffichees?.sort()} 
                                    clickCompagnie={clickCompagnie}  
                                    clickboxcompagnies={clickboxcompagnies} />  
                <Tablo allgammes={allgammes} 
                       actucourtier={actucourtier} 
                       setActucourtier={setActucourtier} 
                       listecompagniesaffichees={listecompagniesaffichees?.sort()}/>

            </>)
}

export default Compagnies