
import Upload from "../UploadFiles/Upload"


import './ContratWinDocsClient.scss'

function ContratWinDocsClient(){

    return(<div >
                <div className="contratwin_left_titre">Documents du client</div>
                <div className="contratwin3_texte2">Veuillez glisser les pièces manquantes</div>
                <div  className="contratwin_box contratwindocs_upload"  ><Upload /></div>
           </div>)
}



export default ContratWinDocsClient


