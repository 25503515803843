import React  from "react";


import EcranGestionTop from "./EcranGestionTop";
import EcranGestionFiltersSearch from "./EcranGestionFiltersSearch";
import EcranGestionFilterLeads from "./EcranGestionFilterLeads";
import EcranGestionListe from "./EcranGestionListe";


import './EcranGestion.scss';
function EcranGestion(){


    return(<div className="ecran_gestion">
                <EcranGestionTop     />
                <EcranGestionFiltersSearch  />
                <EcranGestionFilterLeads  />
                <EcranGestionListe  />
             </div>)
}

export default EcranGestion ; 


