import axios from 'axios' ;

function cleanleadnull(lead){
    const leed =   {...lead};
    Object.keys(leed).map(key=>(leed[key]==="" ||leed[key]===null || leed[key]===undefined )&& delete leed[key]);
    return  {...leed}
  }

export const champsobligatoires = {'remboursement':['num_contrat','nom_compagnie'],
                                    'cotation':['num_contrat','nom_compagnie'],
                                    'decalageeffet':['num_contrat','nom_compagnie'],
                                    'gestecom':['num_contrat','nom_compagnie','montant_gestecom'],
                                    'parrainage':['num_contrat','nom_compagnie','nom_filleul','montant_parrainage'],
                                    'ajoutbeneficiaire':['num_contrat','nom_compagnie','nom_newbenef','prenom_newbenef','date_naissance_newbenef',
                                                        'numsecu_newbenef','organisme_newbenef','date_ajout_newbnef'],
                                    'supprbeneficiaire':['num_contrat','nom_compagnie','nom_supprbenef','prenom_supprbenef','date_supprbenef'],
                                    'resiliation':['num_contrat','nom_compagnie'],
                                    'cartetierspayant' :['num_contrat','nom_compagnie'],
                                    'nonliste'  :['nonliste_titre','nonliste_message']}




export const  soustitres = {'type_ticket':'Quel type de ticket souhaitez-vous créer ?' ,
                            'formulaire':'Informations Contrat et Souscripteur',
                            'documents': 'Uploader le(s) document(s)',
                            'nonliste': 'Envoyer un message a la gestion' ,
                            'fin': 'Confirmation.....'}

export const listecompagnies =["Alptis", "ASAF AFPS", "Apivia", "April", "Cegema", "Coverity", "FFA", "FMA", "Henner", "Kiassure",
                               "Malakoff Humanis", "Modulassur", "Néoliane", "SollyAzar", "SwissLife", "Wazari", "SPVIE","Selchesi"];



export  const sendticket = async (comgestion,attachments) => {   

    const datasmessage = new FormData();
    datasmessage.append("data", JSON.stringify(cleanleadnull(comgestion)));
    for (let i = 0; i < attachments.length; i++) {datasmessage.append("attachments", attachments[i]);}
    const headers = {'Accept': 'application/json',
                    'Content-Type': "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`}
    const rep =  await axios.post(  `/emailgestion/sendmailgestion`, datasmessage,{headers:headers});

    return rep
};


