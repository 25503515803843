import axios from "axios";

import { queryClient } from '../state/queryClient';

export const sendemail = async ({lead,email,email2,objet,message,attachments}) => {  
    
    let reqmail='';
    const datasmessage = new FormData();
    const user= queryClient.getQueryData(['user',localStorage.getItem('token')]);

    datasmessage.append("courtier",user['courtier']);
    datasmessage.append("Subject", objet);
    datasmessage.append("TextBody", message);
    datasmessage.append("From", user['postmail']);
    datasmessage.append("To",email);
    lead.id && datasmessage.append("id_lead",lead.id);
    lead.id_client && datasmessage.append("id_client",lead.id_client);
    email2 && datasmessage.append("email2",  email2);
    lead.nom && datasmessage.append("nom",lead.nom);
    lead.prenom && datasmessage.append("prenom",lead.prenom);

    for (let i = 0; i < attachments.length; i++) {datasmessage.append("attachments", attachments[i]);}
    try { reqmail  = await axios.post(  `/email/send/`, datasmessage, { headers: {"Content-Type": "multipart/form-data"}  , 'Authorization': `Bearer ${localStorage.getItem('token')}`});
    }catch (error) { console.error(error); }


    return reqmail.data?.listelienspj?.[0]
};