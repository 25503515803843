import React ,{useState} from 'react';
import {useDispatch , useSelector}from 'react-redux'


import { modifyEcran   , modifyDisplaymodal , modifyDisplaycalleft , addlead  } from '../state/redux';
import { useTickets, useNotifs, useAllmails, useGestionmails} from '../state/reactquery';
import { useUser} from '../state/reactquerycabinets';

import { simpleSDK } from '../utils/ringover';


import InfoUser from './InfoUser';

import iconmutuello from '../images/menu/iconmutuello.svg';
import iconmutuellobottom from '../images/menu/iconmutuellobottom.svg';
import visite  from '../images/visite.svg';
import iconringover  from '../images/iconringover.svg';

import './Sidebar.scss';

const classNames = require('classnames');
function Sidebar({setDisplaynotifs}){
       
        const dispatch = useDispatch();
        const ecran = useSelector(state=>state.display.ecran) ;
        const displaycalleft = useSelector(state=>state.display.displaycalleft) ;
        const qualiflead  = useSelector(state=>state.lead.qualiflead) ; 


        const {data: user} = useUser(localStorage.getItem('token'))  ;
        const {data : notifs} = useNotifs();
        const {data:tickets}  = useTickets() ; 
        const {data: emailsgestion} = useGestionmails() ; 
        const {data: emails} = useAllmails() ; 
        const [displayinfouser, setDisplayinfouser] = useState(false) ;

        const mailsnonouverts = emails?.filter(x=>x.emailTo ===user?.postmail && x.RecordType!=='Open').sort((a,b) => new Date(b.created_at) - new Date(a.created_at)) ;
        const mailsgestionnonouverts = emailsgestion?.filter(x=>x.emailTo ===user?.email_gestion && x.RecordType!=='Open').sort((a,b) => new Date(b.created_at) - new Date(a.created_at)) ;
  

        const ticketsnews = tickets?.filter(x=>x['statut']==='new')  ;
        const notifsnoopened  = notifs?.filter(x=>x['opened']===0)  ;

        const clickbell = (e)=>{  setDisplaynotifs(displaynotifs => !displaynotifs)}

        const clickeltmenu = (e)=>{
            if(e.target.id==='notifsbell'){   setDisplaynotifs(displaynotifs => !displaynotifs)}
            else {dispatch(modifyEcran(e.target.id)) ;dispatch(modifyDisplaymodal(null)) }
          }

      const avatar2 = ()=>(<div className='avatar2' >{(user['lastname']?.charAt(0).toUpperCase()??'' ) + (user['firstname']?.charAt(0).toUpperCase()??'')  }</div>)

      const iconsidebar = (id)=>{
        return(<div  id={id} className={classNames("iconsidebar",id,{'selected':ecran===id})} onClick={clickeltmenu}  >
              { ( id==='ecrantickets'  &&  ticketsnews?.length >0)  &&  <div className={classNames('nbtickets',{'small':ticketsnews.length>99 }  ,{'medium':ticketsnews.length<100 && ticketsnews.length>9 }  ,{'big': ticketsnews.length < 10}   )}  onClick={clickeltmenu}  > {ticketsnews.length} </div>}
              { ( id==='notifsbell'  &&  notifsnoopened?.length >0)  &&  <div className={classNames('nbtickets',{'small':notifsnoopened.length>99 }  ,{'medium':notifsnoopened.length<100 && notifsnoopened.length>9 }  ,{'big': notifsnoopened.length < 10}   )}  onClick={clickbell}  > {notifsnoopened.length} </div>}
              { ( id==='ecranemails'  &&  mailsnonouverts?.length >0)  &&  <div className={classNames('nbtickets',{'small':mailsnonouverts?.length>99 }  ,{'medium':mailsnonouverts.length<100 && mailsnonouverts.length>9 }  ,{'big': mailsnonouverts < 10}   )}  onClick={clickeltmenu}  > {mailsnonouverts?.length} </div>}
              { ( id==='ecranemailsgestion'  &&  mailsgestionnonouverts?.length >0)  &&  <div className={classNames('nbtickets',{'small':mailsgestionnonouverts?.length>99 }  ,{'medium':mailsgestionnonouverts.length<100 && mailsgestionnonouverts.length>9 }  ,{'big': mailsgestionnonouverts < 10}   )}  onClick={clickeltmenu}  > {mailsgestionnonouverts?.length} </div>}
               </div>) }

        const degrerole = user?.role ==="COMMERCIAL"?1:user?.role ==="MANAGER"?2:user?.role ==="GESTION"?3:user?.role ==="ADMIN"?4:5 ;

        return(<div className="sidebar">
                    <div className="sidebar_top">
                          <div className="logotop">
                                <img src={iconmutuello}  alt="icon"  heigth="16"/>
                                <img src={iconmutuellobottom}  alt="icon"  heigth="6"/>
                          </div> 
                          { degrerole >1 && iconsidebar("ecrangestion") }
                          {/* { degrerole >2 && iconsidebar("ecrangestionleads") } */}
                          { iconsidebar("dashboard") }
                          {/* { degrerole >1 && iconsidebar("manager") } */}
                          { iconsidebar("ecrantickets") }
                          { iconsidebar("ecranemails") }  
                          { degrerole >2 && iconsidebar("ecranemailsgestion") }
                          { degrerole >4 && iconsidebar("yoan") }
                    </div>  
                    <div className="sidebar_bottom"> 
                        { user?.role ==="SUPERADMIN"  &&  iconsidebar("ecransuperadmin")  }
                        { user.type_tel === 'aircall' && <img  id="aircall" className='visite'  src={iconringover}  onClick={()=>document.getElementById('aircallphone').classList.toggle('displaynone')}  alt="iconaircall"    />   }
                        { user.type_tel === 'ringover' && <img  id="sidebarringover" className='visite'  src={iconringover}  onClick={()=>simpleSDK.toggle()}  alt="iconringover"    />   }
                        { degrerole >4  &&  iconsidebar("dev") }
                        { degrerole >4  &&  iconsidebar("dev2") }
                        { degrerole >3  &&  iconsidebar("params") }
                        { iconsidebar("notifsbell") }
                        { ecran!=='dashboard' &&<div className='displaycalendar' onClick={()=>{dispatch(modifyDisplaycalleft(!displaycalleft))}}  ></div>}
                        <div  className='avatardivider'  ></div>
                        <div className='cursorpointer' onClick={()=>setDisplayinfouser(!displayinfouser)}>
                        { localStorage.getItem('imgavatar')?<img  className='avatar' src={localStorage.getItem('imgavatar')} alt='avatar' width='34' height="34"  /> : avatar2()} 
                        </div>    
                    </div>  
                   {displayinfouser && <InfoUser  /> }
            </div>)
        }

export default Sidebar


