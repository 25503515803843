import React , {useMemo} from "react";
import {useSelector} from 'react-redux';

import { formatdatefrance, dateheuremin}   from "../../utils/utils_dates";
import { orderevents} from "../../utils/actions_calendar";
import './MiniListEvents.scss';




const rdvpro = (y)=>{  return   y.description? ((y.description.indexOf("#ID")) !==-1)  : false }
const showdetailevent = (m)=>{ (rdvpro(m) && document.getElementById('detailrdv'+m.id) ) && document.getElementById('detailrdv'+m.id).classList.toggle('show') }

const classNames = require('classnames');
function MiniListEvents(){

  const listcalendarevents= useSelector(state => state.calendar.listcalendarevents);
  const listevents = useMemo(() => {return orderevents(listcalendarevents.filter(x=>(new Date(x.startDatetime) ).getTime()  >= (new Date() ).getTime()-24*60*60*1000 )) }, [listcalendarevents]);

    return (<div  className="minilistedesevents"    >
            {listevents && Object.keys(listevents).map((x,i)=> <div key={i}>
                                                          <div  className='miniformatjour'>{formatdatefrance(x)}</div>
                                                          {listevents && listevents[x].map(y=><div   key={y.id} ><div onClick={()=>showdetailevent(y)}  
                                                                                      className={classNames('miniformatrdv',{'miniformatrdv_pro':rdvpro(y)},{'miniformatrdv_nopro':!rdvpro(y)})}>
                                                                                          <div className='miniformatrdv_heuremin'>{dateheuremin(y.startDatetime)} </div>
                                                                                          <div  className='minisummary'>{y.summary} </div>
                                                                                </div>
                                                  
                                                                                </div>)}                             
                                                </div> )}    
              </div>      
            )
}


export default MiniListEvents