import React , {useState, useEffect} from 'react';

import { useDispatch , useSelector} from 'react-redux'; 
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";

import { modifyChampsLeadmemo } from '../../state/redux';
import { getallformules } from '../../utils/calculstarifs';
import { modifyonelead  } from '../../utils/actions_lead';


import './DateAdhesionPicker.scss'


registerLocale("fr", fr);

function DateAdhesionPicker({formulaire,setOpendatepicker}){
 
    const dispatch = useDispatch() ; 

    const lead = useSelector(state=>state.lead.leadactu);

    const aujourdhui =  new Date();
    const [newDate, setNewdate]=useState();

   
    useEffect(()=>{
            const datePickers = document.getElementsByClassName("react-datepicker__input-container");
            Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true))) ;   
    })

    useEffect(()=>{ if(lead.date_effet ){
                 const [jour,mois,annee]= lead.date_effet.split('/')  ;
                 !isNaN(new Date(annee+'-'+mois+'-'+jour))  && setNewdate(new Date(annee+'-'+mois+'-'+jour))  
            }
    },[lead.date_effet])

    const changedateadhesionleadactu = async (date)=>{
        await   modifyonelead({"date_effet":date}) ;
        dispatch(getallformules('recalcul'))
    }

    const changeDateAdhesion =async (date)=>{ 
          let newdate=((new  Date(date)).toLocaleString('fr-FR')).slice(0, 10); 
           formulaire ?  dispatch(modifyChampsLeadmemo( {date_effet:newdate})) : changedateadhesionleadactu(newdate); 
           setOpendatepicker(false)
    }

    return( <div className='containerdatepicker'><DatePicker  locale="fr"  minDate={aujourdhui}   selected={newDate}    onChange={changeDateAdhesion}  inline  /></div>)}



export default DateAdhesionPicker
