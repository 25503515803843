import { store, initialiseLeadMemoEnfants } from "../state/redux";


export function memoenfantsfromlead(lead){

    const { civilite_enf1,nom_enf1,prenom_enf1,regime_enf1,date_naissance_enf1,numsecu_enf1,organisme_enf1,
        civilite_enf2,nom_enf2,prenom_enf2,regime_enf2,date_naissance_enf2,numsecu_enf2,organisme_enf2,
        civilite_enf3,nom_enf3,prenom_enf3,regime_enf3,date_naissance_enf3,numsecu_enf3,organisme_enf3,
        civilite_enf4,nom_enf4,prenom_enf4,regime_enf4,date_naissance_enf4,numsecu_enf4,organisme_enf4,
        civilite_enf5,nom_enf5,prenom_enf5,regime_enf5,date_naissance_enf5,numsecu_enf5,organisme_enf5
        } = lead ;

    let memoenfants=[] ;

    date_naissance_enf1 && memoenfants.push({civilite_enf1,nom_enf1,prenom_enf1,regime_enf1,date_naissance_enf1,numsecu_enf1,organisme_enf1})
    date_naissance_enf2 && memoenfants.push({civilite_enf2,nom_enf2,prenom_enf2,regime_enf2,date_naissance_enf2,numsecu_enf2,organisme_enf2})
    date_naissance_enf3 && memoenfants.push({civilite_enf3,nom_enf3,prenom_enf3,regime_enf3,date_naissance_enf3,numsecu_enf3,organisme_enf3})
    date_naissance_enf4 && memoenfants.push({civilite_enf4,nom_enf4,prenom_enf4,regime_enf4,date_naissance_enf4,numsecu_enf4,organisme_enf4})
    date_naissance_enf5 && memoenfants.push({civilite_enf5,nom_enf5,prenom_enf5,regime_enf5,date_naissance_enf5,numsecu_enf5,organisme_enf5})

    store.dispatch(initialiseLeadMemoEnfants(memoenfants)) ;

}
