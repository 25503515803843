import React from 'react'
import { useDispatch , batch } from 'react-redux';

import { useUser } from '../../state/reactquerycabinets';
import { initialiseLeadactu, initialiseLeadmemo , modifyDisplaymodal,  initialiseLeadmemoConjoint, initialiseLeadMemoEnfants} from '../../state/redux';
import { telephone } from '../../utils/twilio';


import ButtonImgLeft from '../../Components/Buttons/ButtonImgLeft';

import './DashboardTop.scss'
 
function DashboardTop(){
    const dispatch = useDispatch();

    const {data: user} = useUser(localStorage.getItem('token'))  ;
    const newprospect= ()=>{ batch( () => {dispatch(initialiseLeadactu({}));
                                              dispatch(initialiseLeadmemoConjoint({}));
                                              dispatch(initialiseLeadMemoEnfants([]));
                                              dispatch(initialiseLeadmemo({'origine': 'crm' ,'commercial': user['nom'], 'statut_client':'nontraite','courtier':user?.courtier ?? 'fidelis', 'campagne': 'crm / '+user['nom']}))
                                              dispatch(modifyDisplaymodal('mod_infos'))   
                                      });
    }

    return(<div className="dashbordtop"  >
                <div  className="dashbordtop_top" >
                    <div><h1  >Hello {(user?.['firstname'])} 👋  </h1><h3 className='topnumtel'> {user?.['telephone']} {telephone?.getTelephone().ringfirst}  </h3> </div>
                    <div  className="dashbordtop_right" >
                            <input  className="dashbordtop_input" placeholder='Recherche' type="text"  onFocus={()=>{dispatch(modifyDisplaymodal('mod_search'))   }}     />
                            <ButtonImgLeft  texte="Nouveau Prospect"   
                                            color='bouton_color_orange' 
                                            size='bouton_size_normal' 
                                            image='imageplus' 
                                            onclick={newprospect}  /> <br/><br/>
                    </div>  
                  </div>
            </div>)
}

export default DashboardTop